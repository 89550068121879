import React, { Component } from 'react';
import Parse from 'parse';
import $ from "jquery";
class Menuleft extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isadminuser:0,
        menuactive:"",
        homepage:true,
        devsite:false,
    };    
    let currentUser = Parse.User.current(); 
    console.log('currentUser');
    console.log(currentUser);
    if(currentUser)
    {
      if((currentUser.get('isAdmin')==="1") || (currentUser.get('isBusiness')===1))
      {
        this.isadminuser = true;
      }
    }
    var url = window.location.href;
    if(url.includes('joelcreate.5edev.com'))//http://localhost/ joelcreate.5edev.com
    {
      this.devsite=true;
    }
     if(url.includes(''))
    {
      this.menuactive = 'collaborations';
    }
    if(url.includes('creators'))
    {
      this.menuactive = 'creators';
    }
    if(url.includes('posts'))
    {
      this.menuactive = 'posts';
    }
    if(url.includes('entries'))
    {
      this.menuactive = 'entries';
    }
    if(url.includes('campaigns'))
    {
      this.menuactive = 'campaigns';
    }
   
    if(url.includes('challenges'))
    {
      this.menuactive = 'challenges';
    }
  if((url.includes('challenges')) || (url.includes('campaigns')) || (url.includes('creators')) || (url.includes('business')) || (url.includes('collaborations')))    
    {
       this.homepage = false;
    }else
    {
      this.homepage = true;
    }
    this.handleNewfunc = this.handleNewfunc.bind(this);
  }
  handleNewfunc(event) {
        event.preventDefault();
        $('#new_open_popup').trigger('click');
  }
render() {   
 let isAdmin=this.isadminuser;
 let menuactive=this.menuactive;
 let homepage=this.homepage;
 let devsite=this.devsite;
 
    return (
        <>
          {homepage==true && isAdmin===true?
            <>
              <aside className="menu_sidebar">
                <div className="menu_sidebar-inner">
                  <h2>Campaigns</h2>
                  <ul className='list_grp'>
                    <li className={menuactive === 'entries' ? 'sbNavActive menu' : 'sbNavInactive menu'}>
                      <a href='/entries'>
                        <span className='thumb'>
                          <img src="/images/menu_left_post.svg" width="30px" height="30px" alt="Challenges"/>
                        </span>
                        <span className='name'>Entries</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="menu_sidebar-inner">
                  <h2>Manage</h2>
                  <ul className='list_grp'>
                    <li className={menuactive === 'challenges' ? 'sbNavActive menu' : 'sbNavInactive menu'}>
                      <a href='/business/challenges'>
                        <span className='thumb'>
                          <img src="/images/menu_challenges.svg" width="30px" height="30px" alt="Challenges"/>
                        </span>
                        <span className='name'>Challenges</span>
                      </a>
                    </li>
                    {devsite===true?<>
                    <li className={menuactive === 'collaborations' ? 'sbNavActive menu' : 'sbNavInactive menu'}>
                      <a href='/'>
                        <span className='thumb'>
                          <img src="/images/menu_collaborations.svg" width="30px" height="30px" alt="Collaborations"/>
                        </span>
                        <span className='name'>Collaborations</span>
                      </a>
                    </li>
                    </>:""}
                  </ul>
                </div>
                {/*{devsite===true?<>*/ }
                <div className="menu_sidebar-inner">
                  <h2>Creators</h2>
                  <ul className='list_grp'>
                    <li className={menuactive === 'creators' ? 'sbNavActive menu' : 'sbNavInactive menu'}>
                    
                      <a href='/creators'>
                        <span className='thumb'>
                          <img src="/images/menu_creators.svg" width="30px" height="30px" alt="Creators"/>
                        </span>
                        <span className='name'>Explore</span>
                      </a>
                    </li>
                  </ul>
                </div>
               
                {/*<div className="menu_sidebar-inner">
                  <h2>Creators</h2>
                  <ul className='list_grp'>
                    <li className={menuactive === 'challenges' ? 'sbNavActive menu' : 'sbNavInactive menu'}>
                      <a href='/business/challenges'>
                        <span className='thumb'>
                          <img src="/images/menu_challenges.svg" width="30px" height="30px" alt="Challenges"/>
                        </span>
                        <span className='name'>Challenges</span>
                      </a>
                    </li>
                    <li className={menuactive === 'collaborations' ? 'sbNavActive menu' : 'sbNavInactive menu'}>
                      
                      <a href='/'>
                        <span className='thumb'>
                          <img src="/images/menu_collaborations.svg" width="30px" height="30px" alt="Collaborations"/>
                        </span>
                        <span className='name'>Collaborations</span>
                      </a>
                    </li>
                    <li className={menuactive === 'creators' ? 'sbNavActive menu' : 'sbNavInactive menu'}>
                      <a href='/business/creators'>
                        <span className='thumb'>
                          <img src="/images/menu_creators.svg" width="30px" height="30px" alt="Creators"/>
                        </span>
                        <span className='name'>Creators</span>
                      </a>
                    </li>
                  </ul>
                </div>*/}

                <div className='add_new_btn cta_btn dropdown'>
                  <a href='javascript:void(0);' type="button" data-toggle="dropdown"  id="dropdownMenuButton" aria-haspopup="true" aria-expanded="false" className=' dropdown-toggle d-inline-flex flex-row flex-wrap align-items-center'>
                    <span className='thumb'>
                      <img src="/images/add_circle.svg" width="30px" height="30px" alt="Manage"/>
                    </span>
                    <span className='name'>New</span>
                  </a>
                  <div class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" type="button" href="/create-challenge">Challenges</a>

                    {devsite===true?<> {/*<a class="dropdown-item" type="button" href="javascript:;">Collaborations</a>*/} </>:""}
                  </div>                  
                </div>
              </aside>
            </>
            :
            <>
              {isAdmin===true?
                <>
                  <div className="menu_sidebar-inner">
                    <ul className='list_grp'>
                      <li className={menuactive === 'entries' ? 'sbNavActive menu' : 'sbNavInactive menu'}>
                        <a href='/entries'>
                          <span className='thumb'>
                            <img src="/images/menu_left_post.svg" width="30px" height="30px" alt="Challenges"/>
                          </span>
                          <span className='name'>Entries</span>
                        </a>
                      </li>
                    </ul> 
                  </div>
                  <div className="menu_sidebar-inner">
                    <h2>Manage</h2>
                    <ul className='list_grp'>
                      <li className={menuactive === 'challenges' ? 'sbNavActive menu' : 'sbNavInactive menu'}>
                        <a href='/business/challenges'>
                          <span className='thumb'>
                            <img src="/images/menu_challenges.svg" width="30px" height="30px" alt="Challenges"/>
                          </span>
                          <span className='name'>Challenges</span>
                        </a>
                      </li>
                       {devsite===true?<>
                      <li className={menuactive === '' ? 'sbNavActive menu' : 'sbNavInactive menu'}>
                        <a href='/'>
                          <span className='thumb'>
                            <img src="/images/menu_collaborations.svg" width="30px" height="30px" alt="Collaborations"/>
                          </span>
                          <span className='name'>Collaborations</span>
                        </a>
                      </li>
                        </>:""}
                    </ul> 
                  </div>
                      {/*  <a href='https://create.joelskitchen.com/business/'>*/}
                  {/*{devsite===true?<> */}
                <div className="menu_sidebar-inner">
                    <h2>Creators</h2>
                    <ul className='list_grp'>
                      <li className={menuactive === 'creators' ? 'sbNavActive menu' : 'sbNavInactive menu'}>
                  
                      <a href='/creators'>
                          <span className='thumb'>
                            <img src="/images/menu_creators.svg" width="30px" height="30px" alt="Creators"/>
                          </span>
                          <span className='name'>Explore</span>
                        </a>
                      </li>
                    </ul> 
                  </div>
                 
                  <div className='add_new_btn cta_btn dropdown'>
                  <a href='javascript:void(0);' type="button" data-toggle="dropdown"  id="dropdownMenuButton" aria-haspopup="true" aria-expanded="false" className=' dropdown-toggle d-inline-flex flex-row flex-wrap align-items-center'>
                    <span className='thumb'>
                      <img src="/images/add_circle.svg" width="30px" height="30px" alt="Manage"/>
                    </span>
                    <span className='name'>New</span>
                  </a>
                  <div class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" type="button" href="/create-challenge">Challenges</a>
               {devsite===true?<>   {/* <a class="dropdown-item" type="button" href="javascript:;">Collaborations</a>*/} </>:""}
                  </div>                  
                </div>
                </>
              :""}  
             


            
          
              {/*<div class="sbNavArea">
                <div  className={menuactive === 'challenges' ? 'sbNavActive' : 'sbNavInactive'}  ><a href="/business/challenges">Challenges</a></div>
                {isAdmin===true?<div>
                <div  className={menuactive === 'campaigns' ? 'sbNavActive' : 'sbNavInactive'}  ><a href="/">Collaborations</a></div>
                <div  className={menuactive === 'creators' ? 'sbNavActive' : 'sbNavInactive'}  ><a href="/business/creators">Creators</a></div>
                </div> :""}
                </div>*/
              }
            </>
          }
        </>
    );
  }
}

export default Menuleft;
