import '../global.js'
import Parse from 'parse';
import React from 'react';

class Challengeentries extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            entry:0
        };
        var query = new Parse.Query('Photo');
        query.equalTo("isChallengeEntry", "1");
        query.equalTo("challenge", "Photo$"+props.postData);
        query.limit(9999999999);
        var self = this;
        query.find().then(function(queryrresult){
            if(queryrresult){
                 self.setState({entry: queryrresult.length});
            }
        })
    }

  

  

    render() {
        var entrycount=this.state.entry;
       
        

          
        var result = (
             <div className="container">
             {entrycount} 
            </div>
        );
            
        
        return result;
      

    }


}

export default Challengeentries;