import '../global.js'
import Parse from 'parse';
import React from 'react';

class entriesLikeCount extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            likecount:0,
        };
      var self = this;
      var photoquery = new Parse.Query('Photo');  
      photoquery.equalTo('objectId', props.postData);
      photoquery.find().then(function(photo){
         let photo_ob=photo[0];
         var likeCount= photo_ob.get('likeCountNew')?photo_ob.get('likeCountNew'):0; 
        if(likeCount){
             self.setState({likecount: likeCount});
        }
        })
    }

    render() {
        var likecount=this.state.likecount;
        var result = (<>{likecount} </> );            
        return result;
    }


}

export default entriesLikeCount;