import '../global.js'
import React, { Component } from 'react';
import Parse from 'parse';

class Adminkpi extends React.Component {
    constructor(props) {
      super(props);
      this.state = { };
      let currentUser = Parse.User.current();   
        
        if(!currentUser || (currentUser.get('isAdmin')!==undefined && currentUser.get('isAdmin')!=="1"))
        {
            window.location.href = "/";
        }
    }
    render() {
      return (
        <div className="account_main_top kpi-listing">
          <div className="inner_wrap">
            <div className="table_top_searchbar post_topbar">
              <div className='left-part'>
                <h2>KPIs</h2> 
              </div>
            </div>
            <div className='admin_table_wrapper kpi-table'>
              <div className='admin_table_inner'>
                <div className="admin_table">
                  <table>
                    <thead>
                        <tr>
                            <th className='date'>Date</th>
                            <th className='sign-ups'>Sign Ups</th>                               
                            <th className='bounce-rate'>Bounce Rate</th>
                            <th className='daily_actve_user'>Daily Active Users</th>
                            <th className='monthly_actve_user'>Monthly Active Users</th>
                            <th className='challenges'>Challenges</th>
                            <th className='entries'>Entries</th>
                            <th className='posts'>Posts</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                          <td className='date'>7/1/2024</td>
                          <td className='sign-ups'>274</td>                               
                          <td className='bounce-rate'>50%</td>
                          <td className='daily_actve_user'>48</td>
                          <td className='monthly_actve_user'>10</td>
                          <td className='challenges'>5</td>
                          <td className='entries'>1</td>
                          <td className='posts'>10</td>
                        </tr>
                        <tr>
                          <td className='date'>7/1/2024</td>
                          <td className='sign-ups'>274</td>                               
                          <td className='bounce-rate'>50%</td>
                          <td className='daily_actve_user'>48</td>
                          <td className='monthly_actve_user'>10</td>
                          <td className='challenges'>5</td>
                          <td className='entries'>1</td>
                          <td className='posts'>10</td>
                        </tr>
                        <tr>
                          <td className='date'>7/1/2024</td>
                          <td className='sign-ups'>274</td>                               
                          <td className='bounce-rate'>50%</td>
                          <td className='daily_actve_user'>48</td>
                          <td className='monthly_actve_user'>10</td>
                          <td className='challenges'>5</td>
                          <td className='entries'>1</td>
                          <td className='posts'>10</td>
                        </tr>
                        <tr>
                          <td className='date'>7/1/2024</td>
                          <td className='sign-ups'>274</td>                               
                          <td className='bounce-rate'>50%</td>
                          <td className='daily_actve_user'>48</td>
                          <td className='monthly_actve_user'>10</td>
                          <td className='challenges'>5</td>
                          <td className='entries'>1</td>
                          <td className='posts'>10</td>
                        </tr>
                        <tr>
                          <td className='date'>7/1/2024</td>
                          <td className='sign-ups'>274</td>                               
                          <td className='bounce-rate'>50%</td>
                          <td className='daily_actve_user'>48</td>
                          <td className='monthly_actve_user'>10</td>
                          <td className='challenges'>5</td>
                          <td className='entries'>1</td>
                          <td className='posts'>10</td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
}
export default Adminkpi;