
import Parse from 'parse';
import React, { useState, useRef } from 'react';
import Textarea from 'react-textarea-autosize';
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import Dropdown from 'react-dropdown'
import ReactTable from "react-table-v6";
import 'react-table-v6/react-table.css'

const ingredTypeCount = 3;

const options = [
 "-",
  "betterfoodguru",
  "ben_dev",
"bohovegmom",
"cashewsandquinoa",
"cheerfulchoices",
"chickpea_and_herb",
"christa.maria",
"creators",
"dashofdelightful",
"darngoodveggies",
"dawnbstewart",
"glutenless_apron",
"happilybalancedmama",
"itsallgoodvegan",
"joelskitchen",
"katie",
"myveganpantrylifestyle",
"nestwellness",
"nomastehungry",
"nourished_neymans",
"orchidsnsweettea",
"plantivorekitchen",
"raesbakeryblog",
"stellascaramangos",
"thebigleybasics",
"theroguebrusselsprout",
"theurbenlife",
"tishasveggieeats",
"treksandbites",
"veganfoodcrazy",
"whatisonkatesplate",
"wildricecreative",
]

const createDropdownOptions = [
 "Recipe",
 "Challenge"
]
 /*"Collaboration",*/


const SampleIngredients = () => (
  <div class="sampleText"><i>
    Example:<br/>
    &nbsp;&nbsp;•&nbsp;  1 teaspoon flax seeds<br/>
    &nbsp;&nbsp;•&nbsp;  1 tablespoon vanilla<br/>
    &nbsp;&nbsp;•&nbsp;  2 cups flour</i>
  </div> 
)

const SampleInstructions = () => (
  <div class="sampleText"><i>
    Example:<br/>
    &nbsp;&nbsp;1)  Heat pan over medium heat.<br/>
    &nbsp;&nbsp;2)  Stir occasionally.<br/>
    &nbsp;&nbsp;3)  Serve and enjoy!</i>
  </div> 
)

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

class RecipeList extends React.Component {
/*
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const toggling = () => setIsOpen(!isOpen);

    const onOptionClicked = value => () => {
    setSelectedOption(value);
    setIsOpen(false);
    console.log(selectedOption);
    };
*/

    constructor(props) {
        super(props);


        this.state = {
            recipes: {},
            sampleRecipe: null,
            curRecipe: null,
            ingredients: null,
            instructions: null,
            notes: null,
            vegan: 0,
            vegetarian: 0,
            keto: 0,
            sugarFree: 0,
            allergenFree: 0,
            glutenFree: 0,
            ingredTypes: ["Ingredients", "Instructions", "Notes", "Grains", "Seeds & Nuts", "Spices", "Products", "Instructions"],
            ingredFields: ["ingredientsData", "instructionsData", "notesData", "grainsData", "seedsData", "spicesData", "productsData", "instructionsData"],
            ingredImages: ["veggies.png", "fruits.png", "beans.png", "grains.png", "seeds.png", "spices.png", "products.png", "instructions.png"],
            curRecipeName: "Default",
            ingredArr: [],
            text: "",
            text2: "",
            events: [],
            curRecipeCollabUN: "-",
            curRecipeCollabUser: null,
            selectedCreateType: "recipe",
            reactTableData: [],
            creatorMenuOptions: ['-'],
        };

        // Creates all lines:
        for(var i=0; i < 2; i++) {

          // Creates an empty line
          this.state.ingredArr.push([]);
          //this.state.ingredArr[i].push( []);

          // Adds cols to the empty line:
         //   this.state.ingredArr[i].push( new Array());
/*
          for(var j=0; j < cols; j++){
            // Initializes:
            arr[i][j] = defaultValue;
          }*/
        }


        this.handleRecipeNameChange = this.handleRecipeNameChange.bind(this);
        this.handleRecipeStoryChange = this.handleRecipeStoryChange.bind(this);
        this.handleRecipeIngredientsChange = this.handleRecipeIngredientsChange.bind(this);
        this.handleRecipeInstructionsChange = this.handleRecipeInstructionsChange.bind(this);
        this.handleRecipeNotesChange = this.handleRecipeNotesChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleRememberMeClick = this.handleRememberMeClick.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleAdd = this.handleAdd.bind(this);

        this.handleIngredNameChange = this.handleIngredNameChange.bind(this);
        this.handleIngredAmountChange = this.handleIngredAmountChange.bind(this);
        this.handleIngredDescripChange = this.handleIngredDescripChange.bind(this);
        this.handleIngredDelete = this.handleIngredDelete.bind(this);

        this.handleVegan = this.handleVegan.bind(this);
        this.handleVegetarian = this.handleVegetarian.bind(this);
        this.handleKeto = this.handleKeto.bind(this);
        this.handleGlutenFree = this.handleGlutenFree.bind(this);
        this.handleSugarFree = this.handleSugarFree.bind(this);
        this.handleAllergenFree = this.handleAllergenFree.bind(this);

        this.getRecipes = this.getRecipes.bind(this);
        this.getCurRecipe = this.getCurRecipe.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.handleChange2 = this.handleChange2.bind(this);


        this.getRecipesAux();
        this.getCreatorsAux();

        this._onSelect = this._onSelect.bind(this);
        this._onSelectNew = this._onSelectNew.bind(this);
        //this.handleNewbtnClick = this.handleNewbtnClick.bind(this);
        

        global.recipeList = this;
    }
    /*handleNewbtnClick()
    {
        console.log('handleNewbtnClick');
         this.setState({selectedCreateType: 'Collaboration'});
           this.forceUpdate();
            sleep(500).then(() => {
           if (global.productList && (global.productList != undefined))
                global.productList.forceUpdate();
                //window.location.href = "/";
        })
        console.log(this.state.selectedCreateType+'ddd');
    }*/

    _onSelectNew(option) {
        console.log('You selected ', option.label)
        this.setState({selectedCreateType: option.label})
    }

    _onSelect (option) {
        console.log('You selected ', option.label)
        this.setState({curRecipeCollabUN: option})

        var usernameSearchValue = option.label;

        if (usernameSearchValue == '-')
        {
            this.setState({curRecipeCollabUN: "-"});
            this.setState({curRecipeCollabUser: null});
            return;
        }
        const parseQuery = new Parse.Query(Parse.User);
          // Several query functions can be set to your Parse,Query, they will
          // only resolve when calling "find", for example
          if (usernameSearchValue !== '') {
            // "contains" will retrieve users whose username contain the searched value, case-sensitive
            //parseQuery.contains('displayUsername', usernameSearchValue);
            parseQuery.include('username');
            // 
            // or 
            // 
            // for case-insensitive string search, use "matches", that will take into account
            // an regexp for matching, in this case use only "i", which is the regexp modifier
            // for case-insensitive
            parseQuery.matches('displayUsername', usernameSearchValue, 'i');
          }


        var self = this;

        parseQuery.find().then(function(result){
            if(result){
                console.log(result);

              if (result[0])
              {
                  console.log('Successfully found ', option.label);
                  console.log(result[0].get("username"));

                  console.log('boomp1');


                  self.setState({curRecipeCollabUN: String(usernameSearchValue)});

                  console.log('curRecipeCollabUN ', self.state.curRecipeCollabUN);

                  self.setState({curRecipeCollabUser: result[0]});

                  console.log('curRecipeCollabUser', self.state.curRecipeCollabUser);
              }
              else
              {
                console.log("User not found");
                window.alert("User not found");
                this.setState({curRecipeCollabUN: ""});
                this.forceUpdate();
              }
/*
              this.state.curRecipe.set("collabUN", String(usernameSearchValue));

              console.log('boomp2');

              this.state.curRecipe.set("collabUser", result[0]);
              
              console.log('boomp3');

              console.log('curRecipe found ', this.state.curRecipe.get("collabUser"));
              console.log("Set curRecipe collabUser to " , this.state.curRecipe.get("collabUser"));

                this.setState({curRecipeCollabUN: String(usernameSearchValue)});
                this.setState({curRecipeCollabUser: result[0]});
*/
                // If result was defined, the object with this objectID was found
                //promise.resolve(result);
            } else {
              console.log('Error!');
               // promise.resolve(null);
              this.state.curRecipe.set("collabUser", null);
            }
        }, function(error){
                console.error("Error searching for Users  Error: " + error);
                //promise.error(error);
        });

    }

    getCurRecipe() {
        return this.curRecipe;
    }
    handleRememberMeClick() {
        this.setState({rememberMe: !this.state.rememberMe});
    }

    handleRecipeNameChange(event) {
        this.setState({curRecipeName: event.target.value});
        //this.state.curRecipe.set("name", event.target.value);
    }

    handleRecipeStoryChange(event) {
        this.setState({curRecipeStory: event.target.value});
        this.state.curRecipe.set("story", event.target.value);
    }

    handleRecipeIngredientsChange(event) {
        var str = event.target.value;

        //•
        console.log("Change");


        var i;
        var newStr = str;
/*
        for (i = 0; i < lines.length; i++)
        {
            var line = lines[i];

            if (i == (lines.length - 1))
            {
                if (str.length < 4)
                    line = " • " + line;
                else if (!((line.charAt(0) == ' ') && (line.charAt(1) == '•') && (line.charAt(2) == ' ') && (line.charAt(3) == ' ')))
                    line = " • " + line;

                line = line.replace("••", "•");
                line = line.replace("• •", "•");
                newStr += line;
                //if (i != (lines.length - 1))
                //    newStr += "\r\n";
            }
            else
                newStr += line;
        }
*/
        if (newStr.length == 1)
            newStr = "  • " + newStr;

        var lines = str.split(/\r?\n/);

        var prevToLastLine = "";
        var prevToLastIsBullet = false;
        var prevToLastIsNonBullet = false;

        for (i = 0; i < lines.length; i++)
        {
            var line = lines[i];

            if (i == lines.length - 2)
            {
                console.log("PrevToLastLine 0 " + JSON.stringify(line.charAt(0)));
                console.log("PrevToLastLine 1 " + JSON.stringify(line.charAt(1)));
                console.log("PrevToLastLine 2 " + JSON.stringify(line.charAt(2)));
                console.log("PrevToLastLine 3 " + JSON.stringify(line.charAt(3)));
                if (((line.charAt(0) == ' ') && (line.charAt(1) == ' ') && (line.charAt(2) == '•') && (line.charAt(3) == ' ') && (line.charAt(4) == '')))
                    prevToLastIsBullet = true;
                if (((line.charAt(0) == '‏‏‎ ‎') && (line.charAt(1) == '')))
                    prevToLastIsNonBullet = true;
            }
        }

        console.log("str.charAt(str.length - 1) " + str.charAt(str.length - 1));
        console.log("str.charAt(str.length - 2) " + str.charAt(str.length - 2));
        
        if (str.charAt(str.length - 1) == "\n")
        {
            //user just typed an enter
            //is it a double-enter?
            console.log("User typed enter");
            console.log("prevToLastIsBullet " + prevToLastIsBullet);
            if (prevToLastIsBullet)
            {
                //delete last line
                newStr = "";

                for (i = 0; i < lines.length; i++)
                {
                    var line = lines[i];

                    if (i < lines.length - 2)
                    {
                        newStr += line + "\r\n";
                    }
                    else if (i < lines.length - 1)
                        newStr += "‏‏‎ ‎";
                }
            }
            else if (!prevToLastIsNonBullet)
                newStr += "  • ";
        }


        console.log(newStr);

        this.setState({ingredients: newStr});
        //this.state.curRecipe.set("ingredients", newStr);
    }

    handleRecipeInstructionsChange(event) {
        //this.setState({instructions: event.target.value});
        //this.state.curRecipe.set("instructions", event.target.value);var str = event.target.value;

        //•
        console.log("Change");


        var i;
        var str = event.target.value;
        var newStr = str;
/*
        for (i = 0; i < lines.length; i++)
        {
            var line = lines[i];

            if (i == (lines.length - 1))
            {
                if (str.length < 4)
                    line = " • " + line;
                else if (!((line.charAt(0) == ' ') && (line.charAt(1) == '•') && (line.charAt(2) == ' ') && (line.charAt(3) == ' ')))
                    line = " • " + line;

                line = line.replace("••", "•");
                line = line.replace("• •", "•");
                newStr += line;
                //if (i != (lines.length - 1))
                //    newStr += "\r\n";
            }
            else
                newStr += line;
        }
*/
        if (newStr.length == 1)
            newStr = "  1) " + newStr;

        var lines = str.split(/\r?\n/);

        var prevToLastLine = "";
        var prevToLastIsBullet = false;
        var prevToLastIsNonBullet = false;
        var prevToLastNum = 0;

        for (i = 0; i < lines.length; i++)
        {
            var line = lines[i];

            //if (i == lines.length - 2)
            {
                console.log("PrevToLastLine 0 " + JSON.stringify(line.charAt(0)));
                console.log("PrevToLastLine 1 " + JSON.stringify(line.charAt(1)));
                console.log("PrevToLastLine 2 " + JSON.stringify(line.charAt(2)));
                console.log("PrevToLastLine 3 " + JSON.stringify(line.charAt(3)));
                //if (line.length == 5)
                {
                    console.log("Line5 is " + line);

                    if (((line.charAt(0) == ' ') && (line.charAt(1) == ' ') &&
                        (line.charAt(2) >= '0') && (line.charAt(2) <= '9') &&
                        (line.charAt(3) == ')') && (line.charAt(4) == ' '))
                        ||
                        ((line.charAt(0) == ' ') && (line.charAt(1) == ' ') &&
                        (line.charAt(2) >= '0') && (line.charAt(2) <= '9') &&
                        (line.charAt(3) >= '0') && (line.charAt(3) <= '9') &&
                        (line.charAt(4) == ')') && (line.charAt(5) == ' ')))
                    {
                        if (i == lines.length - 2) 
                        {
                            if (line.charAt(5) == '')
                                prevToLastIsBullet = true; //meaning an empty heading
                            //case for 2-digit number
                            if ((line.charAt(3) >= '0') && (line.charAt(3) <= '9') && (line.charAt(6) == ''))
                                prevToLastIsBullet = true; //meaning an empty heading
                        }
                        //for a filled heading, calc the number
                        console.log("Parsing prevToLast: " + line);

                        prevToLastNum = parseInt(line.charAt(2));
                        //check for second digit -- we handle up to 99
                        
                        if ((line.charAt(3) != ' ') && (line.charAt(3) != ')'))
                        {
                            prevToLastNum *= 10;
                            prevToLastNum += parseInt(line.charAt(3));
                        }
                    }
                }
            }
            if (i == lines.length - 2)
            {
                if (((line.charAt(0) == '‏‏‎ ‎') && (line.charAt(1) == '')))
                    prevToLastIsNonBullet = true;
            }
        }

        console.log("str.charAt(str.length - 1) " + str.charAt(str.length - 1));
        console.log("str.charAt(str.length - 2) " + str.charAt(str.length - 2));
        
        if (str.charAt(str.length - 1) == "\n")
        {
            //user just typed an enter
            //is it a double-enter?
            console.log("User typed enter");
            console.log("prevToLastIsBullet " + prevToLastIsBullet);
            if (prevToLastIsBullet)
            {
                //delete last line
                newStr = "";

                for (i = 0; i < lines.length; i++)
                {
                    var line = lines[i];

                    if (i < lines.length - 2)
                    {
                        newStr += line + "\r\n";
                    }
                    else if (i < lines.length - 1)
                        newStr += "‏‏‎ ‎";
                }
            }
            else if (!prevToLastIsNonBullet)
                newStr += "  " + (prevToLastNum + 1).toString() + ") ";
        }


        console.log(newStr);

        this.setState({instructions: newStr});
        this.state.curRecipe.set("instructions", newStr);
    }

    handleRecipeNotesChange(event) {
        this.setState({notes: event.target.value});
        this.state.curRecipe.set("notes", event.target.value);
    }

    handlePasswordChange(event) {
        this.setState({password: event.target.value});
    }

    handleSave(event) {
        console.log('Saving recipe! to ' + this.state.curRecipe.get("name"));
        /*
        console.log("Serialized ingredArr[0] " + JSON.stringify(this.state.ingredArr[0]));

        for (var i = 0; i < ingredTypeCount; i++)
        {
            var curIngredType = this.state.ingredTypes[i].toLowerCase();

            var serData =  JSON.stringify(this.state.ingredArr[i]);
            
            var fieldName = this.state.ingredFields[i]; ///curIngredType + "Data";

            this.state.curRecipe.set(fieldName, serData);
        }*/
        this.state.curRecipe.set("name", this.state.curRecipeName);
        this.state.curRecipe.set("story", this.state.curRecipeStory);
        //this.state.curRecipe.set("collabUN", String(this.state.curRecipeCollabUN));
        //this.state.curRecipe.set("collabUser", this.state.curRecipeCollabUser);

        if ((this.state.curRecipeCollabUN != null) && (this.state.curRecipeCollabUN != undefined) && (this.state.curRecipeCollabUN != '-') && (this.state.curRecipeCollabUN != ""))
        {
            console.log("Saving collabUN: ", this.state.curRecipeCollabUN);
            console.log("Saving collabUser: ", this.state.curRecipeCollabUser);
            this.state.curRecipe.set("collabUN", this.state.curRecipeCollabUN);
            this.state.curRecipe.set("collabUser", this.state.curRecipeCollabUser);
        }

        this.state.curRecipe.set("ingredients", this.state.ingredients);
        this.state.curRecipe.set("instructions", this.state.instructions);
        this.state.curRecipe.set("notes", this.state.notes);

        this.state.curRecipe.save();

        global.curRecipe = null;
        global.productList.forceUpdate();

        this.setState({curRecipe: null});
        this.setState({curRecipeStory: null});
        this.setState({curRecipeCollabUN: "-"});
        this.setState({curRecipeCollabUser: null});
        this.setState({ingredients: null});
        this.setState({ingredients: null});
        this.setState({instructions: null});
        this.setState({notes: null});

    }

    handleDelete(event) {

        if (window.confirm('Are you sure you wish to delete this recipe?'))
        {

            var curRecipe = this.state.curRecipe;


            curRecipe.destroy().then((myObject) => {
              // The object was deleted from the Parse Cloud.
                this.setState({curRecipe: null});;

                this.setState({curRecipe: null});
                this.setState({curRecipeStory: null});
                this.setState({curRecipeCollabUN: null});
                this.setState({curRecipeCollabUser: null});
                this.setState({ingredients: null});
                this.setState({instructions: null});
                this.setState({notes: null});

                this.setState({recipes: this.state.recipes});;

                this.forceUpdate();
                if (window)
                    window.location.reload();
                else if (Location)
                    Location.reload();
            }, (error) => {
              // The delete failed.
              // error is a Parse.Error with an error code and message.
            });
        }
    }

    handleCancel(event) {
        console.log('Canceling recipe ' + this.state.curRecipe.get("name"));
        this.setState({curRecipe: null});
        global.curRecipe = null;
        if (global.productList)
            global.productList.forceUpdate();

        this.state.curRecipe.fetch();
        this.setState({curRecipe: null});
        this.setState({curRecipeStory: null});
        this.setState({curRecipeCollabUN: null});
        this.setState({curRecipeCollabUser: null});
        this.setState({ingredients: null});
        this.setState({instructions: null});
        this.setState({notes: null});
    }

    handleVegan(event) {

        event.preventDefault();
        event.target.blur();

        this.state.vegan = this.state.curRecipe.get("vegan") == null ? 0 : this.state.curRecipe.get("vegan");
        this.state.vegan = !this.state.vegan;
        this.state.curRecipe.set("vegan", (this.state.vegan ? 1 : 0));
        this.forceUpdate();

    }
    handleVegetarian(event) {

        event.preventDefault();
        event.target.blur();
        this.state.vegetarian = this.state.curRecipe.get("vegetarian") == null ? 0 : this.state.curRecipe.get("vegetarian");
        this.state.vegetarian = !this.state.vegetarian;
        this.state.curRecipe.set("vegetarian", (this.state.vegetarian ? 1 : 0));
        this.forceUpdate();

    }

    handleKeto(event) {

        event.preventDefault();
        event.target.blur();
        this.state.keto = this.state.curRecipe.get("keto") == null ? 0 : this.state.curRecipe.get("keto");
        this.state.keto = !this.state.keto;
        this.state.curRecipe.set("keto", (this.state.keto ? 1 : 0));
        this.forceUpdate();

    }

    handleGlutenFree(event) {

        event.preventDefault();
        event.target.blur();
        this.state.glutenFree = this.state.curRecipe.get("glutenFree") == null ? 0 : this.state.curRecipe.get("glutenFree");
        this.state.glutenFree = !this.state.glutenFree;
        this.state.curRecipe.set("glutenFree", (this.state.glutenFree ? 1 : 0));
        this.forceUpdate();

    }

    handleSugarFree(event) {

        event.preventDefault();
        event.target.blur();
        this.state.sugarFree = this.state.curRecipe.get("sugarFree") == null ? 0 : this.state.curRecipe.get("sugarFree");
        this.state.sugarFree = !this.state.sugarFree;
        this.state.curRecipe.set("sugarFree", (this.state.sugarFree ? 1 : 0));
        this.forceUpdate();

    }

    handleAllergenFree(event) {

        event.preventDefault();
        event.target.blur();
        this.state.allergenFree = this.state.curRecipe.get("allergenFree") == null ? 0 : this.state.curRecipe.get("allergenFree");
        this.state.allergenFree = !this.state.allergenFree;
        this.state.curRecipe.set("allergenFree", (this.state.allergenFree ? 1 : 0));
        this.forceUpdate();

    }


    handleIngredNameChange(i, j, event) {

        event.preventDefault();
        j -= 1;
        j = event.target.getAttribute("data-k");

        console.log("i " + i + " j " + j + " object " + this.state.ingredArr[i][j]);
        if (this.state.ingredArr[i][j])
             this.state.ingredArr[i][j].name = event.target.value;
        this.forceUpdate();

    }

    handleIngredAmountChange(i, j, event) {

        event.preventDefault();
        j -= 1;
        j = event.target.getAttribute("data-k");
        
        console.log("i " + i + " j " + j + " object " + JSON.stringify(this.state.ingredArr[i][j]));
        if (this.state.ingredArr[i][j])
             this.state.ingredArr[i][j].amount = event.target.value;
        this.forceUpdate();

        
        //debug print ingredArr
        var line = "";
         for (var tempI = 0; tempI < 7; tempI++)
         {
            line += "IngredArr [" + tempI + "] :  ";
            for (var tempJ = 0; tempJ < this.state.ingredArr[tempI].length; tempJ++)
            {
                line += "'" + JSON.stringify(this.state.ingredArr[tempI][tempJ]) + "', ";
            }
            line += "\r\n";
         }
         console.log(line);
         this.forceUpdate();

         console.log("EVENT TARGET " + event.target.getAttribute("data-k"));
    }
    
    handleIngredDescripChange(i, j, event) {


        event.preventDefault();
        j -= 1;
        j = event.target.getAttribute("data-k");
        
        console.log("i " + i + " j " + j + " object " + JSON.stringify(this.state.ingredArr[i][j]));
        if (this.state.ingredArr[i][j])
             this.state.ingredArr[i][j].description = event.target.value;
        this.forceUpdate();
        
    }

    handleIngredDelete(i, j, event) {

        event.preventDefault();
        j -= 1;
        j = event.target.getAttribute("data-k");
        
        console.log("DELETE INGRED i " + i + " j " + j + " object " + JSON.stringify(this.state.ingredArr[i][j]));

        console.log("i " + i + " j " + j + " object " + this.state.ingredArr[i][j]);
        if (this.state.ingredArr[i][j])
        {
             console.log("POP");

             if (this.state.ingredArr[i].length == 1)
                this.state.ingredArr[i] = [];
            else
                console.log("Removed: " + this.state.ingredArr[i].splice(j, 1));
        }

        var line = "";
         for (var tempI = 0; tempI < 7; tempI++)
         {
            line += "IngredArr [" + tempI + "] :  ";
            for (var tempJ = 0; tempJ < this.state.ingredArr[tempI].length; tempJ++)
            {
                line += "'" + JSON.stringify(this.state.ingredArr[tempI][tempJ]) + "', ";
            }
            line += "\r\n";
         }
         console.log(line);

        this.forceUpdate();
    }

    handleNew(event) {
        event.preventDefault();

        if(this.state.selectedCreateType==='Challenge' || this.state.selectedCreateType==="Challenge")
       {
           console.log('Challenge');
           this.forceUpdate();
            sleep(500).then(() => {
           if (global.productList && (global.productList != undefined))
                global.productList.forceUpdate();

            window.location.href = "/create-challenge";



        })


       }else 
        {

        var recipe = new Parse.Object('RecipeV2');       
        recipe.set('user', Parse.User.current());
        
        console.log(this.state.selectedCreateType+'ddddd');

        if (this.state.selectedCreateType.includes('Collab'))
        {
            console.log('Creating new collaboration.');
            recipe.set('isCollab', true);
            recipe.set('name', ' Untitled Collaboration');
        }
        else
        {
            console.log('Creating new recipe.');
            recipe.set('isCollab', false);
            recipe.set('name', ' Untitled Recipe');
        }
        recipe.set('vegan', 0);
        recipe.set('vegetarian', 0);
        recipe.set('keto', 0);
        recipe.set('glutenFree', 0);
        recipe.set('sugarFree', 0);
        recipe.set('allergenFree', 0);

        var self = this;

        return recipe.save().then(function() {
          console.log('Created new recipe.');
          self.getRecipesAux();

          self.forceUpdate();

          sleep(500).then(() => {
            self.selectRecipe(recipe);
            })
        }
        , function(error) {
          console.log('Creating recipe object failed. Error: ' + error);
        });
       }
    }
 
    getCreatorsAux() 
    {
        var query = new Parse.Query('_User');
       
       //TEMP -- see all Products
        //if (!isAdmin)
        //    query.equalTo("user", Parse.User.current());

       console.log("getCreatorsAux()");

        //query.ascending("name");
        query.limit(500);

        query.equalTo("isCreator", 1);
        query.include("displayName");
        query.include("displayUsername");
        query.ascending("displayUsername");


        var self = this;

        query.find().then(function(result){
            if(result){
                console.log("getCreatorsAux() Result");

                console.log(result);

                var newOptions = ["-"];

                console.log("result length " + result.length);
            
                for (var i = 0; i < result.length; i++) 
                {
                    console.log("result " + i);
                    
                    var creator = result[i];
                    var creatorUn = creator.get("displayUsername");
                    console.log("result B " + i + creatorUn);
                    
                    newOptions.push(creatorUn);
                    console.log("result appending " + creatorUn);

                }

                console.log("result OPTIONS MENU");
                console.log("result newopt " + newOptions);
                console.log("result newopt length " + newOptions.length);
                self.setState({creatorMenuOptions: newOptions});

                // If result was defined, the object with this objectID was found
                //promise.resolve(result);
            } else {
                console.log("No Creators found");
               // promise.resolve(null);
            }
        }, function(error){
                console.error("Error searching for Creators  Error: " + error);
                //promise.error(error);
        });
    }

    getRecipesAux() {
        console.log('Getting recipes for current user');

        if (!Parse.User.current())
            return;

        var query1 = new Parse.Query('RecipeV2');
       
       //TEMP -- see all recipes
        if (!(Parse.User.current() && Parse.User.current().get("isAdmin")))
            query1.equalTo("user", Parse.User.current());

        var query2 = new Parse.Query('RecipeV2');
        query2.equalTo("collabUser", Parse.User.current());

        var query = Parse.Query.or(query1, query2);

        query.include("story");
        query.include("instructions");
        query.include("ingredients");
        query.include("notes");
        query.include("name");
        query.include("vegan");
        query.include("vegetarian");
        query.include("keto");
        query.include("glutenFree");
        query.include("sugarFree");
        query.include("allergenFree");
        query.include("user");
        query.include("user.displayName");
       // query.descending("createdAt");
        query.ascending("name");
        query.limit(500); // limit to at most 10 results

        var self = this;

        
        Parse.User.current().fetch();

        query.find().then(function(result){
            if(result){
                console.log(result);
                //console.log(self.state.recipes);


                var query = new Parse.Query('RecipeV2');
       /*
                query.get("nBuAGfOn2F").then((object) => {
                    // object is an instance of parse.object.
                    
                    self.state.sampleRecipe = object;*/
                {
                    self.setState({recipes: result});

                    self.reactTableData = [];

                    console.log("Result length " + result.length);

                    var newReactTableData = [];

                    for (var i = 0; i < result.length; i++) {

                        var recipe = result[i];
                        var wrapper = {};

                        var itemUser = recipe.get("user");

                        //itemUser.fetchIfNeeded();


                        var itemUsername = itemUser.get("displayName");
                        var user = Parse.User.current();
                        var myUsername = Parse.User.current().get("displayName");
                        var collabUsername = recipe.get("collabUN");

                        if (itemUsername == myUsername)
                            wrapper.originator = " Me";
                        else
                            wrapper.originator = itemUsername;

                        if (collabUsername == myUsername)
                            wrapper.collaborator = " Me";
                        else
                            wrapper.collaborator = collabUsername;

                        wrapper.recipe = recipe;
                        wrapper.title = recipe.get("name");
                        wrapper.lastModified = recipe.get("updatedAt");//.getTime();//.toString();
                        
                        wrapper.type = ((recipe.get("isCollab") || (recipe.get("collabUser")) ? "Collaboration" : "Recipe"));

                        //self.reactTableData.push(wrapper);
                        newReactTableData.push(wrapper);
                        const wrapperCopy = wrapper;
                        self.state.reactTableData.push(wrapperCopy);
                        const copy = self.state.reactTableData;

                        //console.log(wrapperCopy);
                    };

                    self.setState({reactTableData:newReactTableData});
                    //console.log("React table data: " + self.state.reactTableData);

                }
                /*).catch((error) =>  {
                 // error is an instance of parse.error.
                });
*/
                // If result was defined, the object with this objectID was found
                //promise.resolve(result);
            } else {
                console.log("No recipes found");
               // promise.resolve(null);
            }
        }, function(error){
                console.error("Error searching for recipes  Error: " + error);
                //promise.error(error);
        });

    }

    selectRecipe(recipe) {
      //this.setState({curRecipe:recipe});
        if (recipe)
          console.log('Select recipe ' + recipe.get("name"));

         if (this.state.curRecipe != recipe) 
        {
            var isCollab = ((recipe.get("collabUser") != null) || (recipe.get("isCollab") == true));

            this.setState({curRecipeIsCollab: isCollab});

            this.setState({curRecipe: recipe});
            this.setState({curRecipeName: recipe.get("name")});
            this.setState({ingredients: recipe.get("ingredients")});
            this.setState({instructions: recipe.get("instructions")});
            this.setState({notes: recipe.get("notes")});
            this.setState({curRecipeStory: recipe.get("story")});
            this.setState({curRecipeCollabUN: recipe.get("collabUN")});
            this.setState({curRecipeCollabUser: recipe.get("collabUser")});


            this.setState({vegan: ((recipe.get("vegan") == null ) ? 0 : recipe.get("vegan"))});
            this.setState({vegetarian: ((recipe.get("vegetarian") == null ) ? 0 : recipe.get("vegetarian"))});
            this.setState({keto: ((recipe.get("keto") == null )? 0 : recipe.get("keto"))});
            this.setState({sugarFree: ((recipe.get("sugarFree") == null) ? 0 : recipe.get("sugarFree"))});
            this.setState({glutenFree: ((recipe.get("glutenFree") == null) ? 0 : recipe.get("glutenFree"))});
            this.setState({allergenFree: ((recipe.get("allergenFree") == null) ? 0 : recipe.get("allergenFree"))});
            
            console.log("CurRecipe " + JSON.stringify(recipe));

            //load the itemArr
            var newIngredArr = [];

            for (var i = 0; i < ingredTypeCount; i++)
            {
                var curIngredType = this.state.ingredTypes[i].toLowerCase();

                var fieldName = this.state.ingredFields[i]; ///curIngredType + "Data";
                var serData = recipe.get(fieldName);
                if (serData)
                {
                    console.log("Parsing serial data " + fieldName + " " + serData);
                    var serObj = JSON.parse(serData);
                    newIngredArr.push(serObj);
                }
                else
                    newIngredArr.push([]);
            }
            this.setState({ingredArr: newIngredArr});

            this.forceUpdate();

            global.curRecipe = recipe;
            if (global.productList && (global.productList != undefined))
                global.productList.forceUpdate();
            console.log('recipe');
            console.log(recipe);
        }
        //this.state.curRecipe = recipe;
    }

    handleAdd(ingredIndex) {
      //this.setState({curRecipe:recipe});
        //if (ingredIndex)
          console.log('Add ingredient to section ' + ingredIndex);

        var newIngred = {};
        newIngred.name = "";
        newIngred.amount = "";
        newIngred.description = "";

        this.state.ingredArr[ingredIndex].push(newIngred);

        //debug print ingredArr
        var line = "";
         for (var tempI = 0; tempI < ingredTypeCount; tempI++)
         {
            line += "IngredArr [" + tempI + "] :  ";
            for (var tempJ = 0; tempJ < this.state.ingredArr[tempI].length; tempJ++)
            {
                line += "'" + JSON.stringify(this.state.ingredArr[tempI][tempJ]) + "', ";
            }
            line += "\r\n";
         }
         console.log(line);

          this.forceUpdate();
        //this.state.curRecipe = recipe;
    }

    getRecipes(event) {
        event.preventDefault();
        this.getRecipesAux();
    }

    renderIngredientType(ingredType, i) {
        let buffer = [];

        if (this.state.ingredArr[i] == undefined)
        	return (<div></div>);

        console.log("renderIngredientType " + i + " ingredArr len " + this.state.ingredArr[i].length)
        for (var k = 0; k < this.state.ingredArr[i].length; k++)
        {
            var ingred = this.state.ingredArr[i][k];

           // console.log("ingredArr " + i + " " + ingred.name);

            /* ======================== INGREDIENT COMPONENTS =========================== */

            if (i != 7) 
            {
            buffer.push( <table><tbody>
                    <tr class = "ingredRow" key={500+((i+1)*(k+1))}>
                           <td class = "ingredAmount" key={1000+((i+1)*(k+1))}>
                                <input  type="text" data-k={k} className="form-control" placeholder="Amount" 
                                onChange={(e) =>  this.handleIngredAmountChange(i, k, e)} value={this.state.ingredArr[i][k].amount}/>

                           </td>
                           <td class = "ingredName" key={2000+((i+1)*(k+1))} >

                                <input type="text" data-k={k} className="form-control" placeholder="Name" 
                                onChange={(e) => this.handleIngredNameChange(i, k, e)} value={this.state.ingredArr[i][k].name}/>

                           </td>
                           <td class = "ingredDescrip" key={3000+((i+1)*(k+1))} >

                                <input type="text"  data-k={k} className="form-control" placeholder="Description" 
                                onChange={(e) => this.handleIngredDescripChange(i, k, e)} value={this.state.ingredArr[i][k].description}/>

                           </td>
                           <td class = "ingredDelete"  key={4000+((i+1)*(k+1))} >
                        
                                <a  data-k={k} onClick={(e) => this.handleIngredDelete(i, k, e )} >x</a>

                           </td>
                        </tr>
                    </tbody></table>);
            }
            else
            {
                //instructions
                var stepText = "Step ";

                //**/
                buffer.push( <table><tbody>
                <tr class = "ingredRow" key={500+((i+1)*(k+1))}>
                           <td class = "ingredStep" key={1000+((i+1)*(k+1))}>

                               <div><b>{k+1}.</b>&nbsp;&nbsp;&nbsp;</div>

                           </td>
                           <td class = "ingredInstruction" key={3000+((i+1)*(k+1))} >

                                <input type="text"  data-k={k} className="form-control" placeholder={stepText + (k+1)} 
                                onChange={(e) => this.handleIngredDescripChange(i, k, e)} value={this.state.ingredArr[i][k].description} />

                           </td>
                           <td class = "ingredDelete"  key={4000+((i+1)*(k+1))} >
                        
                                <a  data-k={k} onClick={(e) => this.handleIngredDelete(i, k, e )} >&nbsp;&nbsp;x&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>

                           </td>
                        </tr>
                    </tbody></table>);
            }
        }
        //console.log("ingredArr length " + this.state.ingredArr[i].length);
        if ((this.state.ingredArr[i].length == 0) && (i != 7))
        {/*
            buffer.push( 
                            <span class="nonethismeal">None this meal.</span>
                        );*/
        }
        else if (i != 7)
        {/* *
            buffer.unshift( <table><tbody>
                            <tr class = "ingredRow" key={499+(i+1)}>
                           <td class = "ingredAmountHeader">

                                Amount

                           </td>
                           <td class = "ingredNameHeader">

                               Name

                           </td>
                           <td class = "ingredDescripHeader">

                               Description

                           </td>
                           <td class = "ingredDeleteHeader">
                            ..

                           </td>
                        </tr>
                    </tbody></table>
                        );*/
        }

        var ingredImage = "images/" + this.state.ingredImages[i];

        return (
            <div>
            <div class="joelSectionHeader" width="100%">
            <h3>
            {ingredType}<br/></h3><h4><a class="addIngred" onClick={() => this.handleAdd(i)} >+</a>
            </h4>
            </div>
            <div>
                    {buffer}
            </div>
            <hr/>
            </div>
            );
    }

    getRecipeNameIfNotUntitled()
    {
        var val = this.state.curRecipeName; //curRecipe.get("name");
        console.log("getRecipeNameIfNotUntitled '" + val + "'");
        if (val.indexOf("Untitled") >= 0)
            return "";
        if (val.indexOf("Default") >= 0)
            return "";
        //if (val != "Untitled Recipe")
         //   return val;
        //else
            return val;
    }

    renderIngredients()
    {
        //console.log("renderIngredients");
        //return      <span key="66"><h4> ingredType  <a onClick={() => this.handleAdd(0)} >Add</a></h4></span>;

        let buffer = [];

        for (var i = 0; i < this.state.ingredTypes.length; i++)
        {
            var ingredType = this.state.ingredTypes[i];
            if (ingredType != undefined)
            buffer.push(<div key={69*i}>
                            {this.renderIngredientType(ingredType, i)}
                        </div>);
        }
        

        return ( <div className="container flex center" key="11">
                {buffer}
                </div>
        );
        /*
        console.log("map" + ingredType + i);
            return (
               <div>
               <span key={i+100}><h4> {ingredType}  <a onClick={() => this.handleAdd(i)} >Add</a></h4></span>
               </div>
            );

            
        });*/
    }


    formatRange(range) {
    return range
      ? [range.index, range.index + range.length].join(',')
      : 'none';
    }

    handleChange(value) {
        console.log("handleChange");
        console.log("ingredients value: " + value);

        if (this.state.ingredients != value)
            this.state.ingredients = value;

        //this.setState({ingredients: value});
        //strip links
       // var value2 = value.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');

       // console.log("Editor value2: " + value2);

       // this.state.curRecipe.set("ingredients", value);
    }

    handleChange2(value) {
        console.log("handleChange2");
        console.log("instructions value: " + value);

        if (this.state.instructions != value)
            this.state.instructions = value;
        //this.setState({instructions: value});
        //strip links
        //value = value.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');

       // this.state.curRecipe.set("instructions", value);
    }
     handleSave(event) {
        
        window.alert("Hi");

        /*
        console.log('Saving recipe! to ' + this.state.curRecipe.get("name"));
       
        this.state.curRecipe.set("name", this.state.curRecipeName);
        this.state.curRecipe.set("story", this.state.curRecipeStory);
        
        if ((this.state.curRecipeCollabUN != null) && (this.state.curRecipeCollabUN != undefined) && (this.state.curRecipeCollabUN != '-') && (this.state.curRecipeCollabUN != ""))
        {
            console.log("Saving collabUN: ", this.state.curRecipeCollabUN);
            console.log("Saving collabUser: ", this.state.curRecipeCollabUser);
            this.state.curRecipe.set("collabUN", this.state.curRecipeCollabUN);
            this.state.curRecipe.set("collabUser", this.state.curRecipeCollabUser);
        }

        this.state.curRecipe.set("ingredients", this.state.ingredients);
        this.state.curRecipe.set("instructions", this.state.instructions);
        this.state.curRecipe.set("notes", this.state.notes);

        this.state.curRecipe.save();

        global.curRecipe = null;
        global.productList.forceUpdate();

        this.setState({curRecipe: null});
        this.setState({curRecipeStory: null});
        this.setState({curRecipeCollabUN: "-"});
        this.setState({curRecipeCollabUser: null});
        this.setState({ingredients: null});
        this.setState({ingredients: null});
        this.setState({instructions: null});
        this.setState({notes: null});*/

    }
/*
    modules = {
    toolbar: [
          ['bold', 'italic', 'underline'],
          [{'list': 'ordered'}, {'list': 'bullet'}]
        ],
      };

      formats = [
        'bold', 'italic', 'underline',
        'list', 'bullet', 'indent'
      ];
*/
    modules = {
    toolbar: [
          [{'list': 'bullet'}]
        ],
      };

      formats = [
        'list', 'bullet'
      ];

    modules2 = {
    toolbar: [
          [{'list': 'ordered'}]
        ],
      };

    formats2 = [
        'list', 'bullet'
      ];

    render() {
        //console.log("Rendering recipe list");
        var result = (
            <div className="container">
            </div>

        );
         /*
          const columns = [{
            Header: 'Name',
            accessor: 'name' // String-based value accessors!
          }, {
            Header: 'Age',
            accessor: 'age',
            Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
          }, {
            id: 'friendName', // Required because our accessor is not a string
            Header: 'Friend Name',
            accessor: d => d.friend.name // Custom value accessors!
          }, {
            Header: props => <span>Friend Age</span>, // Custom header components!
            accessor: 'friend.age'
          }]

*/
//<a onClick={() => self.selectRecipe(recipe)} >

        const columns = [
        /*{
            Header: 'Type',
            //accessor: 'recipe', // String-based value accessors!
            id: 'Type',
            accessor: d => { console.log("D is " + JSON.stringify(d)); return d.recipe; } , // Custom value accessors!
            Cell: props => <span className='number'>{(props.value != undefined) ?
            ( (props.value.get("isCollab") || (props.value.get("collabUser"))) ? "Collaboration" : "Recipe" )
            : "undef"}</span> // Custom cell components!
          },*/{
            Header: 'Type',
            //accessor: 'recipe', // String-based value accessors!
            id: 'Type',
            accessor: 'type',
          },{
            Header: 'Title',
            //accessor: 'recipe', // String-based value accessors!
            id: 'Title',
            accessor: d => { /*console.log("D is " + JSON.stringify(d));*/ return d.recipe; } , // Custom value accessors!
            Cell: props => <span className='number'><a onClick={() => self.selectRecipe(props.value)}>{(props.value != undefined) ? props.value.get("name") : "undef"}</a></span> // Custom cell components!
          }, {
            Header: 'Business Account', 
            accessor: 'originator'//,
            //Cell: props => <span className='number'><a href='http://google.com'>{props.value}</a></span> // Custom cell components!
          }, {
            //id: 'friendName', // Required because our accessor is not a string
            Header: 'Creator Account',
            accessor: 'collaborator',
            //accessor: d => { return d.friend.name; } // Custom value accessors!
          }, {
            //Header: props => <span>Friend Age</span>, // Custom header components!
            //accessor: 'friend.age'
            Header: 'Last Modified',
            id: 'lastModified',
            accessor: 'lastModified',
            //accessor: d => { return d.lastModified.getTime(); }, // Custom value accessors!
            sortType: (a, b) => {
                        var a1 = a.getTime();
                        var b1 = b.getTime();
                      if(a1<b1)
                      return 1;
                      else if(a1>b1)
                      return -1;
                      else
                      return 0;
                      },
            //accessor: d => { return d.toString(); }, // Custom value accessors!
            //Cell: props => <span className='number'>{return "hi";}</span> // Custom cell components!
            Cell: props => <span className='number'>{props.value != undefined ? ((props.value.getMonth()+1) + "/" + (props.value.getDate()+1) + "/" + props.value.getFullYear() ) : "undef"}</span> // Custom cell components!
           // Cell: props => <span className='number'>{props.value != undefined ? (props.value.toString() ) : "undef"}</span> // Custom cell components!
          }]

        var user = Parse.User.current();
        if (user != null)
        {
            let recipes = (this.state.recipes.length !== undefined) ? this.state.recipes : [];
            //console.log("Recipes length " + recipes.length);

            var self = this;

/*
                        <Textarea name = "instructions" rows="6" className="form-control" 
                                onChange={this.handleRecipeInstructionsChange} value={this.state.curRecipe.get("instructions")}
                                placeholder="Add your instructions here."
                                /> 
*/
    
            const newOptions = this.state.creatorMenuOptions;
           
            if (this.state.curRecipe != null)
            {
                result =  (
                 <div key="-1">
                    <form className="form-recipe" >

                        <div class="joelSectionHeader" hidden={!this.state.curRecipeIsCollab}>
                        <h3>Creator Account</h3>
                        <Dropdown options={newOptions} onChange={this._onSelect} value={this.state.curRecipeCollabUN} placeholder="Add a collaborator" hidden={!this.state.curRecipeIsCollab}/>
                        </div>


                         <div class="joelSectionHeader">
                        <h3>Title</h3>
                        </div>
                        <Textarea name="title" className="form-control" placeholder="Title of Recipe" required
                               autoFocus onChange={this.handleRecipeNameChange} value={this.getRecipeNameIfNotUntitled()}/>
                         <div class="joelSectionHeader">
                        <h3>Caption</h3>
                        </div>
                        <Textarea name="story" className="form-control" 
                                onChange={this.handleRecipeStoryChange} value={this.state.curRecipe.get("story") ? this.state.curRecipe.get("story") : "" } placeholder="Write a caption..."/>
                        <div class="joelSectionHeader">
                        <h3>Ingredients</h3>
                        </div>
                        <ReactQuill value={this.state.ingredients ? this.state.ingredients : ""}
                            onChange={this.handleChange} 
                            modules={this.modules}
                            formats={this.formats}
                            placeholder="Add your ingredients here." />
                        <br/>
                        <div class="joelSectionHeader">
                        <h3>Instructions</h3>
                        </div>
                        <ReactQuill value={this.state.instructions ? this.state.instructions : ""}
                            onChange={this.handleChange2} 
                            modules={this.modules2}
                            formats={this.formats2}
                            placeholder="Add your instructions here." />

                        <br/>

                                   
                        <div class="joelSectionHeader">
                        <h3>Diet</h3>
                        Tap the boxes that best fit your recipe so we can begin to personalize Joel’s Kitchen for everyone.<br/>

                        <div class="diet">
                        <button className={this.state.curRecipe.get("vegan") != 1 ? "btn btn-med btn-outline-primary btn-rounded" : "btn btn-med btn-success btn-rounded"} onClick={(e) => {self.handleVegan(e)}}>Vegan</button>&nbsp;
                        <button className={this.state.curRecipe.get("vegetarian") != 1 ? "btn btn-med btn-outline-primary btn-rounded" : "btn btn-med btn-success btn-rounded"} onClick={(e) => self.handleVegetarian(e)}>Vegetarian</button>&nbsp;
                        <button className={this.state.curRecipe.get("keto") != 1 ? "btn btn-med btn-outline-primary btn-rounded" : "btn btn-med btn-success btn-rounded"} onClick={(e) => self.handleKeto(e)}>Keto</button>&nbsp;
                        <button className={this.state.curRecipe.get("glutenFree") != 1 ? "btn btn-med btn-outline-primary btn-rounded" : "btn btn-med btn-success btn-rounded"} onClick={(e) => self.handleGlutenFree(e)}>Gluten-Free</button>&nbsp;
                        <button className={this.state.curRecipe.get("sugarFree") != 1 ? "btn btn-med btn-outline-primary btn-rounded" : "btn btn-med btn-success btn-rounded"} onClick={(e) => self.handleSugarFree(e)}>Sugar-Free</button>&nbsp;
                        <button className={this.state.curRecipe.get("allergenFree") != 1 ? "btn btn-med btn-outline-primary btn-rounded" : "btn btn-med btn-success btn-rounded"} onClick={(e) => self.handleAllergenFree(e)}>Allergen-Free</button>&nbsp;
                        <br/><br/>
                        </div>
                        </div>
                        
                        <button className="btn btn-lg btn-primary btn-block" onClick={(e) => self.handleSave(e)}>Save Recipe</button>
                        <button className="btn btn-lg btn-danger btn-block" onClick={(e) => self.handleDelete(e)}>Delete Recipe</button>
                   
                        <button className="btn btn-lg btn-primary btn-block" onClick={(e) => self.handleCancel(e)}>Cancel</button>
                    </form>
                </div>
              );

            }
            else if (global.curProduct == null)
             result =  (


                 <div className="containerWide" key="-1"  style={{"display": "none"}}>

                 <div class="createNewSection">
                    <center><div class="createNewBtnWrapper">
                        <br/>

                 
                    <button  className="btn btn-lg btn-primary btn-block createNewBtn" onClick={this.handleNew}>New</button>
                    </div>
                    &nbsp;
                    <div class="createNewDropdown">
                        <br/>
                        <Dropdown options={createDropdownOptions} onChange={this._onSelectNew} value="Recipe" placeholder="Recipe" />
                    </div>

                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    </center> 
                </div>

                 <div class="joelSectionHeader" width="100%">
                 <h3>Recipes</h3>
                 </div>

                 <ReactTable
                    data={this.state.reactTableData}
                    columns={columns}
                    defaultPageSize={100}
                  />

                 {/* recipes.map(function (recipe, i) {

                        var itemUser = recipe.get("user");
                        itemUser.fetch();
                        var itemUsername = itemUser.get("displayName");
                        Parse.User.current().fetch();
                        var user = Parse.User.current();
                        var myUsername = Parse.User.current().get("displayName");
                        var createdAt = recipe.get("createdAt").toString();

                        {

                            return (
                               <div class="recipeListEntry" key={i}> <a onClick={() => self.selectRecipe(recipe)} >&nbsp;{recipe.get("name")}</a> by {itemUsername}</div>
                            );
                        }

                    })*/}
                 <br/>
             </div>
              );

         /*

                  { self.state.ingredTypes.map(function (ingredType, i) {
                        return (
                           <span key={i+100}><h4> {ingredType}  <a onClick={() => self.handleAdd(i)} >Add</a></h4></span>

                           { self.state.ingredArr[i].map(function (ingred, j) {
                                return (
                                   <span key={(i*j)+100}><h4> {ingred}  <a onClick={() => self.handleDelete(i,j))} >Delete</a></h4></span>
                                );
                            })}
                        );
                    })}
                    */
/*
            result =  (
                 <div className="container" key="-1">
                 <h3>My Recipes</h3>
                 {buffer}
                 <span >Test<a onClick={this.selectRecipe}>(selectRecipe?)</a></span>
                 <br/>
                <form className="form-signup" onSubmit={this.handleNew}>
                    <button className="btn btn-lg btn-primary btn-block" type="submit">New Recipe</button>
                </form>
                <span><a onClick={this.selectRecipe}>
                       TEST
                    </a></span>
             </div>
              );
              */

/*
  <li>
                  <div className='view'>
                    <label>{recipe.title}</label>
                    <button className="destroy" onClick={this.deleteTodo.bind(null, todo)} />
                  </div>
                </li>

            result = (
                 <div className="container">
                 <h3>My Recipes</h3>
                 <br/>
                <form className="form-signup" onSubmit={this.handleNew}>
                    <br/><br/>
                    <button className="btn btn-lg btn-primary btn-block" type="submit">New Recipe</button>
                </form>
            </div>
            );


/*
            return (
               <span key={i+100}><h4> {ingredType}  <a onClick={() => self.handleAdd(i)} >Add</a></h4></span>

               { self.state.ingredArr[i].map(function (ingred, j) {
                    return (
                       <span key={(i*j)+100}><h4> {ingred}  <a onClick={() => self.handleDelete(i,j))} >Delete</a></h4></span>
                    );
                })}
            );*/


        }
        return result;
        /* 
                    <label className="checkbox">
                        <input type="checkbox" value="remember-me" onChange={this.handleRememberMeClick}/>
                        &nbsp; Remember me
                    </label>
                    */

    }


}

export default RecipeList;