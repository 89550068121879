import '../global.js'
import React, { Component } from 'react';
import Parse from 'parse';
import ReactTable from "react-table-v6";
import 'react-table-v6/react-table.css'
import Postcounts from "./postcounts.jsx";
import Challengeentries from "./Challengeentries.jsx";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import Dropdown from 'react-dropdown';
import Followerscounts from "./followerscounts.jsx";
import Engagementcounts from "./engagementcounts.jsx";
import $ from "jquery";
 function time_ago(time) {

    switch (typeof time) {
      case 'number':
        break;
      case 'string':
        time = +new Date(time);
        break;
      case 'object':
        if (time.constructor === Date) time = time.getTime();
        break;
      default:
        time = +new Date();
    }
    var time_formats = [
      [60, 's', 1], // 60
      [120, '1m', '1 minute from now'], // 60*2
      [3600, 'm', 60], // 60*60, 60
      [7200, '1h', '1 hour from now'], // 60*60*2
      [86400, 'h', 3600], // 60*60*24, 60*60
      [172800, '1d', 'Tomorrow'], // 60*60*24*2
      [604800, 'd', 86400], // 60*60*24*7, 60*60*24
      [1209600, '1w', 'Next week'], // 60*60*24*7*4*2
      [2419200, 'w', 604800], // 60*60*24*7*4, 60*60*24*7
      [4838400, '1m', 'Next month'], // 60*60*24*7*4*2
      [29030400, 'm', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
      [58060800, '1y', 'Next year'], // 60*60*24*7*4*12*2
      [2903040000, 'y', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
      [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
      [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    /* [60, 'seconds', 1], // 60
      [120, '1 minute ago', '1 minute from now'], // 60*2
      [3600, 'minutes', 60], // 60*60, 60
      [7200, '1 hour ago', '1 hour from now'], // 60*60*2
      [86400, 'hours', 3600], // 60*60*24, 60*60
      [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
      [604800, 'days', 86400], // 60*60*24*7, 60*60*24
      [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
      [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
      [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
      [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
      [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
      [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
      [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
      [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100*/
    ];
    var seconds = (+new Date() - time) / 1000,
    // token = 'ago',
      token = '',
      list_choice = 1;

    if (seconds == 0) {
    // return 'Just now'
      return ''
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      //token = 'from now';
      token = '';
      list_choice = 2;
    }
    var i = 0,
      format;
    while (format = time_formats[i++])
      if (seconds < format[0]) {
        if (typeof format[2] == 'string')
          return format[list_choice];
        else
        // return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
          return Math.floor(seconds / format[2]) + '' + format[1] + '' + token;
      }
    return time;
  }

class AdminAccounts extends React.Component {
    constructor(props) {
    super(props);
    this.state = {
      posts: [],
      loading: true,
      page: 1,
      pageSize: 100,
      total: 0,      
      filterTitle: '',
      pagestatus: 1,
      totalCommunitiesCount:0,
      creator_update_msg:null,
      selectedValue: {},
      loading1: true,
      page1: 1,
      pageSize1: 100,
      filterTitle1: '',
      totalCreatorCount:0,
      loading2: true,
      page2: 1,
      pageSize2: 100,
      filterTitle2: '',
      totalBusinessCount:0,
      businesses: [],
      creators: [],
      admins: [],
      totalAdminCount:0,
      loading4: true,
      page4: 1,
      pageSize4: 100,
      filterTitle4: '',
      sortAdminField: 'createdAt',
      sortAdminOrder: 'asc',
      sortCommunityField: 'createdAt',
      sortCommunityOrder: 'asc',
      sortCreatorField: 'createdAt',
      sortCreatorOrder: 'asc',
      sortBusinessField: 'createdAt',
      sortBusinessOrder: 'asc',
      selectedAdminUsers: [],
      selectedBusinessUsers: [],
      selectedCreatorUsers: [],
      selectedCommunityUsers: [],
    };
    let currentUser = Parse.User.current();   
        
        if(!currentUser || (currentUser.get('isAdmin')!==undefined && currentUser.get('isAdmin')!=="1"))
        {
            window.location.href = "/";
        }
  }

radioHandler = (pagestatus) => {
    this.setState({ pagestatus });
  };


  componentDidMount() {
    this.fetchData();
    this.fetchCreatorData();
    this.fetchBusinessData();
    this.fetchAdminData();
  }
  handlePageChange1 = (page1) => {
    this.setState({ page1, loading1: true }, () => {
      this.fetchCreatorData();//true
    });
  };
  handlePageChangeBusiness= (page2) => {
    this.setState({ page2, loading2: true }, () => {
      this.fetchBusinessData();//true
    });
  };
  handlePageChangeAdmin= (page4) => {
    this.setState({ page4, loading4: true }, () => {
      this.fetchAdminData();//true
    });
  };
  handlePageChange = (page) => {
    this.setState({ page, loading: true }, () => {
      this.fetchData();//true
    });
  };
    fetchBusinessData = () => {

    const { page2, pageSize2,filterTitle2,sortBusinessField,sortBusinessOrder  } = this.state;
    const skip2 = (page2 - 1) * pageSize2;

     var query = new Parse.Query('_User');
        query.limit(99999999999999999999999999);
        query.equalTo("isBusiness", 1);  
        query.include('Photo');      
        query.include("profilePictureSmall");
        query.include("displayName");  
        query.include("displayUsername");   
        query.include("instagram");
        query.include("facebook");
        query.include("pinterest");
        query.include("tiktok");
        query.include("youtube");
        query.include("website");
        query.include("name");
        query.include("email");
       // query.descending("createdAt");
        if(sortBusinessOrder==='desc')
        {
             query.descending(sortBusinessField);
        }else{
             query.ascending(sortBusinessField);
        }
      query.limit(pageSize2);
      query.skip(skip2);
      if (filterTitle2) {
        query.contains('displayUsername', filterTitle2);
      }
      query.find()
      .then(results => {
        this.setState({
          businesses: results,
          businessesdata: results,
          loading2: false
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        this.setState({ loading2: false });
      });
var query1 = new Parse.Query('_User');
        query1.limit(99999999999999999999999999);
        query1.equalTo("isBusiness", 1);  
        query1.include('Photo');      
        query1.include("profilePictureSmall");
        query1.include("displayName");
        query1.include("recipeRate");
        query1.include("videoRate");
        query1.include("photoRate");
        query1.include("instagram");
        query1.include("facebook");
        query1.include("pinterest");
        query1.include("tiktok");
        query1.include("youtube");
        query1.include("website");
        query1.include("name");
        query1.include("email");
        //query1.descending("createdAt");
        if(sortBusinessOrder==='desc')
        {
             query1.descending(sortBusinessField);
        }else{
             query1.ascending(sortBusinessField);
        }
        //query1.limit(pageSize);
    query1.find()
      .then(resultsdata => {
        this.setState({
          businessesdata: resultsdata,
           totalBusinessCount: resultsdata.length,
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        //this.setState({ loading: false });
      });


  };
   fetchAdminData = () => {

    const { page4, pageSize4,filterTitle4,sortAdminField, sortAdminOrder  } = this.state;
    const skip4 = (page4 - 1) * pageSize4;

     var query = new Parse.Query('_User');
        query.limit(99999999999999999999999999);
        query.equalTo("isAdmin", "1");  
        query.include('Photo');      
        query.include("profilePictureSmall");
        query.include("displayName");  
        query.include("displayUsername");   
        query.include("instagram");
        query.include("facebook");
        query.include("pinterest");
        query.include("tiktok");
        query.include("youtube");
        query.include("website");
        query.include("name");
        query.include("email");
        //query.descending("createdAt");
        if(sortAdminOrder==='desc')
        {
             query.descending(sortAdminField);
        }else{
             query.ascending(sortAdminField);
        } 
        
    query.limit(pageSize4);
    query.skip(skip4);
    if (filterTitle4) {
      query.contains('displayUsername', filterTitle4);
    }
    query.find()
      .then(results => {
        this.setState({
          admins: results,
          adminsdata: results,
          loading4: false
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        this.setState({ loading4: false });
      });
var query1 = new Parse.Query('_User');
        query1.limit(99999999999999999999999999);
        query1.equalTo("isAdmin", "1");  
        query1.include('Photo');      
        query1.include("profilePictureSmall");
        query1.include("displayName");
        query1.include("recipeRate");
        query1.include("videoRate");
        query1.include("photoRate");
        query1.include("instagram");
        query1.include("facebook");
        query1.include("pinterest");
        query1.include("tiktok");
        query1.include("youtube");
        query1.include("website");
        query1.include("name");
        query1.include("email");
        //query1.descending("createdAt");
         if(sortAdminOrder==='desc')
        {
             query1.descending(sortAdminField);
        }else{
             query1.ascending(sortAdminField);
        } 
        //query1.limit(pageSize);
    query1.find()
      .then(resultsdata => {
        this.setState({
          adminsdata: resultsdata,
           totalAdminCount: resultsdata.length,
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        //this.setState({ loading: false });
      });


  };
   fetchCreatorData = () => {
    const { page1, pageSize1,filterTitle1,sortCreatorField, sortCreatorOrder  } = this.state;
    const skip1 = (page1 - 1) * pageSize1;

     var query = new Parse.Query('_User');
        query.limit(99999999999999999999999999);
        query.equalTo("isCreator", 1);  
       // query.include('Photo');      
        query.include("profilePictureSmall");
        query.include("displayName");  
        query.include("displayUsername");   
        query.include("instagram");
        query.include("facebook");
        query.include("pinterest");
        query.include("tiktok");
        query.include("youtube");
        query.include("website");
        query.include("name");
        query.include("email");
        //query.descending("createdAt");
        if(sortCreatorOrder==='desc')
        {
             query.descending(sortCreatorField);
        }else{
             query.ascending(sortCreatorField);
        } 
    query.limit(pageSize1);
    query.skip(skip1);
    if (filterTitle1) {
      query.contains('displayUsername', filterTitle1);
    }
    query.find()
      .then(results => {
        this.setState({
          creators: results,
          creatorsdata: results,
          loading1: false
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        this.setState({ loading1: false });
      });
var query1 = new Parse.Query('_User');
        query1.limit(99999999999999999999999999);
        query1.equalTo("isCreator", 1);  
        query1.include('Photo');      
        query1.include("profilePictureSmall");
        query1.include("displayName");
        query1.include("recipeRate");
        query1.include("videoRate");
        query1.include("photoRate");
        query1.include("instagram");
        query1.include("facebook");
        query1.include("pinterest");
        query1.include("tiktok");
        query1.include("youtube");
        query1.include("website");
        query1.include("name");
        query1.include("email");
        //query1.descending("createdAt");
        if(sortCreatorOrder==='desc')
        {
             query1.descending(sortCreatorField);
        }else{
             query1.ascending(sortCreatorField);
        }
        //query1.limit(pageSize);
    query1.find()
      .then(resultsdata => {
        this.setState({
          creatorsdata: resultsdata,
           totalCreatorCount: resultsdata.length,
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        //this.setState({ loading: false });
      });


  };

  fetchData = () => {
    const { page, pageSize,filterTitle,sortCommunityField, sortCommunityOrder  } = this.state;
    const skip = (page - 1) * pageSize;

     var query = new Parse.Query('_User');
        query.limit(99999999999999999999999999);
        query.equalTo("isCreator", 0);  
        query.equalTo("isBusiness", 0);  
        query.equalTo("isAdmin", "0");  
        query.doesNotExist("isCreator");
        query.doesNotExist("isBusiness");
        query.doesNotExist("isAdmin");
        
        query.include("profilePictureSmall");
        query.include("displayName");  
        query.include("displayUsername");   
        query.include("instagram");
        query.include("facebook");
        query.include("pinterest");
        query.include("tiktok");
        query.include("youtube");
        query.include("website");
        query.include("name");
        query.include("email");
        //query.descending("createdAt");
        if(sortCommunityOrder==='desc')
        {
             query.descending(sortCommunityField);
        }else{
             query.ascending(sortCommunityField);
        } 
        query.limit(pageSize);
        query.skip(skip);
        if (filterTitle) {
          query.contains('displayUsername', filterTitle);
        }
        query.find()
      .then(results => {
        this.setState({
          communities: results,
          communitiesdata: results,
          loading: false
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        this.setState({ loading: false });
      });
var query1 = new Parse.Query('_User');
        query1.limit(99999999999999999999999999);
        query1.equalTo("isCreator", 0);  
        query1.equalTo("isBusiness", 0);  
        query1.equalTo("isAdmin",  "0");  
        query1.doesNotExist("isCreator");
        query1.doesNotExist("isBusiness");
        query1.doesNotExist("isAdmin"); 
        
        query1.include('Photo');      
        query1.include("profilePictureSmall");
        query1.include("displayName");
        query1.include("recipeRate");
        query1.include("videoRate");
        query1.include("photoRate");
        query1.include("instagram");
        query1.include("facebook");
        query1.include("pinterest");
        query1.include("tiktok");
        query1.include("youtube");
        query1.include("website");
        query1.include("name");
        query1.include("email");
        //query1.descending("createdAt");
        if(sortCommunityOrder==='desc')
        {
             query1.descending(sortCommunityField);
        }else{
             query1.ascending(sortCommunityField);
        } 
        //query1.limit(pageSize);
    query1.find()
      .then(resultsdata => {
        this.setState({
          communitiesdata: resultsdata,
           totalCommunitiesCount: resultsdata.length,
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        //this.setState({ loading: false });
      });
  };

  
  socialLinkClass(linkVal)
    {
        if (linkVal && (linkVal != undefined) && (linkVal.length > 1))
        {
            return "sociallink sociallinkitem";
        }
        else
            return "displayNone sociallinkitem";
    }
      formatWebsite(linkVal)
    {
        if ((!linkVal) || (linkVal == undefined))
            return "";

        if (linkVal.includes("http://") || linkVal.includes("https://") || linkVal.includes("Https://") || linkVal.includes("Http://"))
            return linkVal;
        else
            return "https://" + linkVal;
    }

    formatFacebook(linkVal)
    {
        if ((!linkVal) || (linkVal == undefined))
            return "";

        linkVal.replace("@", "");

        linkVal.replace("m.facebook.com", "facebook.com");

        if (linkVal.includes("http://") || linkVal.includes("https://") || linkVal.includes("Https://") || linkVal.includes("Http://"))
            return linkVal;
        else if (linkVal.includes("facebook.com"))
            return "https://" + linkVal;
        else
            return "https://facebook.com/" + linkVal;
    }

    formatPinterest(linkVal)
    {
        if ((!linkVal) || (linkVal == undefined))
            return "";

        linkVal.replace("@", "");
        
        if (linkVal.includes("http://") || linkVal.includes("https://") || linkVal.includes("Https://") || linkVal.includes("Http://"))
            return linkVal;
        else if (linkVal.includes("pinterest.com"))
            return "https://" + linkVal;
        else
            return "https://pinterest.com/" + linkVal;
    }

    formatInstagram(linkVal)
    {
        if ((!linkVal) || (linkVal == undefined))
            return "";

        linkVal.replace("@", "");
        
        if (linkVal.includes("http://") || linkVal.includes("https://") || linkVal.includes("Https://") || linkVal.includes("Http://"))
            return linkVal;
        else if (linkVal.includes("instagram.com"))
            return "https://" + linkVal;
        else
            return "https://instagram.com/" + linkVal;
    }
   renderSocial(creator) {
        var result = ( <div></div>);

        if (!creator)
            return result;

        var instagram = creator.get("instagram");
        var facebook = creator.get("facebook");
        var pinterest = creator.get("pinterest");
        var tiktok = creator.get("tiktok");
        var youtube = creator.get("youtube");
        var website = creator.get("website");

        result = (
          <div className='socialLinkWrp'>
            <div className={this.socialLinkClass(instagram)}>
              <a target="_blank" href={this.formatInstagram(instagram)}>
                <img src="/images/social/create-instagram.svg" width="32" height="32" />
              </a>
            </div>
            <div className={this.socialLinkClass(tiktok)}>
              <a target="_blank" href={this.formatWebsite(tiktok)}>
                <img src="/images/social/create-tiktok.svg" width="32" height="32" />
              </a>
            </div>
            <div className={this.socialLinkClass(youtube)}>
              <a target="_blank" href={this.formatWebsite(youtube)}>
                <img src="/images/social/create-youtube.svg" width="32" height="32" />
              </a>
            </div>
            <div className={this.socialLinkClass(website)}>
              <a target="_blank" href={this.formatWebsite(website)}>
                <img src="/images/social/website.png" width="32" height="32" />
              </a>
            </div>
            <div className={this.socialLinkClass(facebook)}>
              <a target="_blank" href={this.formatFacebook(facebook)}>
                <img src="/images/social/facebook-app.png" width="32" height="32"/>
              </a>
            </div>
          </div>
        );
        return result;
    }
    searchclearCommunity= (event) => {
this.setState({ filterTitle: '' }, () => {
      // Reset page to 1 when filter changes  true
      this.setState({ page: 1, loading: true }, () => {
        this.fetchData();
      });
    });
    }
       searchclearCreator= (event) => {
this.setState({ filterTitle1: '' }, () => {
      // Reset page to 1 when filter changes  true
      this.setState({ page1: 1, loading1: true }, () => {
        this.fetchCreatorData();
      });
    });
    }
        searchclearBusiness= (event) => {
this.setState({ filterTitle2: '' }, () => {
      // Reset page to 1 when filter changes  true
      this.setState({ page2: 1, loading2: true }, () => {
        this.fetchBusinessData();
      });
    });
    }
       searchclearAdmin= (event) => {
this.setState({ filterTitle4: '' }, () => {
      // Reset page to 1 when filter changes  true
      this.setState({ page4: 1, loading4: true }, () => {
        this.fetchAdminData();
      });
    });
    }
     handleFilterChange = (event) => {
    this.setState({ filterTitle: event.target.value }, () => {
      // Reset page to 1 when filter changes  true
      this.setState({ page: 1, loading: true }, () => {
        this.fetchData();
      });
    });
  };
   handleFilterChange1 = (event) => {
    this.setState({ filterTitle1: event.target.value }, () => {
      // Reset page to 1 when filter changes  true
      this.setState({ page1: 1, loading1: true }, () => {
        this.fetchCreatorData();
      });
    });
  };
   handleFilterChange2 = (event) => {
    this.setState({ filterTitle2: event.target.value }, () => {
      // Reset page to 1 when filter changes  true
      this.setState({ page2: 1, loading2: true }, () => {
        this.fetchBusinessData();
      });
    });
  };
   handleFilterChange4 = (event) => {
    this.setState({ filterTitle4: event.target.value }, () => {
      // Reset page to 1 when filter changes  true
      this.setState({ page4: 1, loading4: true }, () => {
        this.fetchAdminData();
      });
    });
  };
 
  render() {
  const { communities, loading, page, pageSize,filterTitle,pagestatus,totalCommunitiesCount,creators, loading1, page1, pageSize1,filterTitle1,totalCreatorCount, loading2, page2, pageSize2,filterTitle2,businesses,totalBusinessCount,loading4, page4, pageSize4,filterTitle4,admins,totalAdminCount,sortAdminOrder,sortAdminField,sortCommunityField, sortCommunityOrder,sortCreatorField, sortCreatorOrder,sortBusinessField, sortBusinessOrder,selectedAdminUsers,selectedBusinessUsers,selectedCreatorUsers,selectedCommunityUsers  } = this.state;
  var self = this;
  //const handleSelectChangeCreator = async  (event, id) => {
 /* const handleUpdate = async () => {
    console.log('handleUpdate');
     try {
      const query = new Parse.Query(Parse.User);
      const user = await query.get('EOtMlN4C5r');
       if (user.getACL().getWriteAccess(Parse.User.current().id)) {

     
       user.set('isCreator', 1);
       console.log('if condition ')

     
      await user.save();
      } else {
        console.log('You do not have permission to update this user.');
      }
    } catch (error) {
      console.log(error.message);
    } finally {

    }
  }*/
  const handleAdminSort = (field) => {
    const { sortAdminField, sortAdminOrder } = this.state;
    const newSortOrder = sortAdminField === field && sortAdminOrder === 'asc' ? 'desc' : 'asc';
    
    this.setState({ sortAdminField: field, sortAdminOrder: newSortOrder }, this.fetchAdminData);
  };
   const handleCommunutySort = (field) => {
    const { sortCommunityField, sortCommunityOrder } = this.state;
    const newSortOrder = sortCommunityField === field && sortCommunityOrder === 'asc' ? 'desc' : 'asc';
    
    this.setState({ sortCommunityField: field, sortCommunityOrder: newSortOrder }, this.fetchData);
  };
   const handleCreatorSort = (field) => {
    const { sortCreatorField, sortCreatorOrder } = this.state;
    const newSortOrder = sortCreatorField === field && sortCreatorOrder === 'asc' ? 'desc' : 'asc';
    
    this.setState({ sortCreatorField: field, sortCreatorOrder: newSortOrder }, this.fetchCreatorData);
  };
  const handleBusinessSort = (field) => {
    const { sortBusinessField, sortBusinessOrder } = this.state;
    const newSortOrder = sortBusinessField === field && sortBusinessOrder === 'asc' ? 'desc' : 'asc';
    
    this.setState({ sortBusinessField: field, sortBusinessOrder: newSortOrder }, this.fetchBusinessData);
  };
 // const handleSelectChangeCreator = (event, id) => {
   const  handleSelectChangeCreator = async (event, id) => {
    const selectedValue = event.target.value;
    
    const currentUser = Parse.User.current();
     const sessionToken = currentUser.getSessionToken();
    if(currentUser)
    {


   /*const responsedata =await  fetch('https://parse.joelskitchen.com/parse/users/'+id, {
          method: 'PUT',
          headers: {
            'X-Parse-Application-Id': 'ze4eQAdmycW7e8MCskILkfGFUNdwYnsKSsZDi0t5',
            'X-Parse-REST-API-Key': 'sDC1XmRXe4bP5i1VqhBhy8cIdiWrBpJmvtmmwvIw',
            'X-Parse-Session-Token': sessionToken,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            'phone': '415-369-6201'
          })
        });
    const updateduser =await responsedata.json();
    console.log(updateduser);*/

    /*  const userQuery = new Parse.Query('_User');
  const userToUpdate = await userQuery.get(id, { useMasterKey: true });

  userToUpdate.set("isCreator", 1);

  try {
    await userToUpdate.save(null, { useMasterKey: true });
    console.log("User updated successfully.");
  } catch (error) {
    throw new Parse.Error(206, "Error while updating user: " + error.message);
  }*/

//handleUpdate();
        /*var query = new Parse.Query('_User');  
        query.equalTo('objectId', id);
        var self = this;
        query.find().then(function(user){
                if(user)
                {
                    let user_ob=user[0];*/


 

 // Create an ACL  firstone
 /*const acl = new Parse.ACL();
  acl.setPublicReadAccess(false);
  acl.setPublicWriteAccess(false);
  acl.setWriteAccess(Parse.User.current(), true); 
  acl.setRoleWriteAccess("Admin", true);

  user_ob.setACL(acl);*/





                    // Check if current user has write access  secondone
             /*const currentUser = Parse.User.current();
             const acl = new Parse.ACL();
              acl.setReadAccess(currentUser, true);
              acl.setWriteAccess(currentUser, true); 

              acl.setWriteAccess(user_ob.id, true);
              user_ob.setACL(acl);
*/



 


/*  const acl = new Parse.ACL();
  acl.setPublicReadAccess(true);
  acl.setPublicWriteAccess(true);
  acl.setWriteAccess(Parse.User.current().id, true); 
  //acl.setRoleWriteAccess("Admin", true);

  user_ob.setACL(acl);


                 


                

                    if(selectedValue==='Creator')
                    {
                        user_ob.set('isCreator', 1);
                        user_ob.set('isBusiness', 0);
                    }
                    if(selectedValue==='Business')
                    {
                        user_ob.set('isBusiness', 1);
                        user_ob.set('isCreator', 0);
                    }
                    if(selectedValue==='Community')
                    {
                        
                        user_ob.set('isBusiness', 0);
                        user_ob.set('isCreator', 0);
                    }
                  
                    
                    user_ob.save().then((updateduser) => {
                             self.setState({creator_update_msg:"User have been updated!"});
                        })*/
let updateData='';
if(selectedValue==='Creator')
  {
      updateData={"isCreator": 1};
  }
  if(selectedValue==='Business')
  {
      updateData={"isBusiness": 1};
  }
  if(selectedValue==='Community')
  {
      updateData={"isCommunity": 1};
  }
  if(selectedValue==='Admin')
  {
      updateData={"isAdmin": "1"};
  }
let userId=id;
/*http://localhost/userdata/updateUser.php*/

    fetch('https://joelpush.5edev.com/userdata/updateUser.php', {
      method: 'POST',      
      body: JSON.stringify({ userId, updateData })
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if(data.success)
        {
             this.setState({creator_update_msg:"User have been updated!"});
              this.fetchData();
              this.fetchCreatorData();
              this.fetchBusinessData();
              this.fetchAdminData();
             /*if(selectedValue==='Creator')
              {
                  this.fetchCreatorData();
              }*/
        }else {
          console.log('Error: ' + data.error);
        }
      })
      .catch((error) => {
        console.error('Error updating user:', error);
      });
/*new code*/

     





                  /*  }
                    })*/
         self.setState({selectedValue:selectedValue});

         setTimeout(() => {
                self.setState({creator_update_msg:null}); 
              }, 15000);
          /*self.fetchData();
          self.fetchCreatorData();
          self.fetchBusinessData();
          self.fetchAdminData();*/
               /*      const query1 = new Parse.Query('_User');
      const user1 =  query1.get(id);
console.log(user1);
        if (user1) {
         user1.set('isBusiness', 1);
         user1.save();
                }*/

            
    }
   
         
  };
 const toggleAdminUserSelection = (userId) => {
    this.setState(prevState => {
      const selectedAdminUsers = prevState.selectedAdminUsers.includes(userId)
        ? prevState.selectedAdminUsers.filter(id => id !== userId)
        : [...prevState.selectedAdminUsers, userId];

      return { selectedAdminUsers };
    });
  }
  const toggleBusinessUserSelection = (userId) => {
    this.setState(prevState => {
      const selectedBusinessUsers = prevState.selectedBusinessUsers.includes(userId)
        ? prevState.selectedBusinessUsers.filter(id => id !== userId)
        : [...prevState.selectedBusinessUsers, userId];

      return { selectedBusinessUsers };
    });
  }
   const toggleCreatorUserSelection = (userId) => {
    this.setState(prevState => {
      const selectedCreatorUsers = prevState.selectedCreatorUsers.includes(userId)
        ? prevState.selectedCreatorUsers.filter(id => id !== userId)
        : [...prevState.selectedCreatorUsers, userId];

      return { selectedCreatorUsers };
    });
  }
    const toggleCommunityUserSelection = (userId) => {
    this.setState(prevState => {
      const selectedCommunityUsers = prevState.selectedCommunityUsers.includes(userId)
        ? prevState.selectedCommunityUsers.filter(id => id !== userId)
        : [...prevState.selectedCommunityUsers, userId];

      return { selectedCommunityUsers };
    });
  }
/*handlePageChangeAdmin= (page4) => {
    this.setState({ page4, loading4: true }, () => {
      this.fetchAdminData();//true
    });
  };*/
 const copyAdminEmailsToClipboard = () => {
    const { admins, selectedAdminUsers } = this.state;
    const emails = admins
      .filter(item => selectedAdminUsers.includes(item.id))
      .map(item => item.get('username'))
      .join('\n');
     if(emails)
     {
      navigator.clipboard.writeText(emails)
      .then(() => {
        //alert('Emails copied to clipboard!');
        $('#admin_account_community_btn').trigger('click');
      })
      .catch(err => {
        console.error('Failed to copy emails: ', err);
      });
    }
  }
   const copyCommunityEmailsToClipboard = () => {
    const { communities, selectedCommunityUsers } = this.state;
    const emails = communities
      .filter(item => selectedCommunityUsers.includes(item.id))
      .map(item => item.get('username'))
      .join('\n');
     if(emails)
     {
       navigator.clipboard.writeText(emails)
      .then(() => {
       // alert('Emails copied to clipboard!');
         $('#admin_account_community_btn').trigger('click');
      })
      .catch(err => {
        console.error('Failed to copy emails: ', err);
      });
     }
     
  }
  

  const copyCreatorEmailsToClipboard = () => {
    const { creators, selectedCreatorUsers } = this.state;
    const emails = creators
      .filter(item => selectedCreatorUsers.includes(item.id))
      .map(item => item.get('username'))
      .join('\n');
    if(emails)
     {
      navigator.clipboard.writeText(emails)
      .then(() => {
        //alert('Emails copied to clipboard!');
         $('#admin_account_community_btn').trigger('click');
      })
      .catch(err => {
        console.error('Failed to copy emails: ', err);
      });
    }
  }
const copyBusinessEmailsToClipboard = () => {
    const { businesses, selectedBusinessUsers } = this.state;
    const emails = businesses
      .filter(item => selectedBusinessUsers.includes(item.id))
      .map(item => item.get('username'))
      .join('\n');
     if(emails)
     {
      navigator.clipboard.writeText(emails)
      .then(() => {
        //alert('Emails copied to clipboard!');
         $('#admin_account_community_btn').trigger('click');
      })
      .catch(err => {
        console.error('Failed to copy emails: ', err);
      });
    }
  }
    return ( 
      <div className=" account_main_top">
        <div className="inner_wrap">
          <div className="table_top_searchbar post_topbar">
            {/*<div className='left-part'>
              
            </div>*/}
            <div className="menu-search-wrap menu-search-mn d-flex flex-row flex-wrap align-items-md-center">
              <div className="search_wrap">
                <form className="form-mn d-flex flex-row flex-wrap">
                  <div className='search_inpt_inner'>
                    {/*onClick={this.searchSuggestion} */}
                    {pagestatus === 1 && 
                      <div>
                        <input className="form-control" type="search" placeholder="Search" aria-label="Search" value={filterTitle} onChange={this.handleFilterChange} />
                        <button type="submit"><img src="/images/search.svg" alt="Search"/></button>

                         {filterTitle? <button type="button" className='close-btn' onClick={this.searchclearCommunity} ><img src="/images/close_small.svg" alt="Close"/></button>:''}
                      </div>
                    }
                    {pagestatus === 2 &&
                      <div>
                        <input className="form-control" type="search" placeholder="Search" aria-label="Search" value={filterTitle1} onChange={this.handleFilterChange1} />
                         <button type="submit"><img src="/images/search.svg" alt="Search"/></button>
                           {filterTitle1? <button type="button" className='close-btn' onClick={this.searchclearCreator} ><img src="/images/close_small.svg" alt="Close"/></button>:''}
                      </div>
                    }
                     {pagestatus === 3 &&
                      <div>
                        <input className="form-control" type="search" placeholder="Search" aria-label="Search" value={filterTitle2} onChange={this.handleFilterChange2} />
                         <button type="submit"><img src="/images/search.svg" alt="Search"/></button>
                          {filterTitle2? <button type="button" className='close-btn' onClick={this.searchclearBusiness} ><img src="/images/close_small.svg" alt="Close"/></button>:''}
                      </div>
                    }

                     {pagestatus === 4 &&
                      <div>
                        <input className="form-control" type="search" placeholder="Search" aria-label="Search" value={filterTitle4} onChange={this.handleFilterChange4} />
                         <button type="submit"><img src="/images/search.svg" alt="Search"/></button>
                          {filterTitle4? <button type="button" className='close-btn' onClick={this.searchclearAdmin} ><img src="/images/close_small.svg" alt="Close"/></button>:''}
                      </div>
                    }
                    {/* <input className="form-control" type="search" placeholder="Search" aria-label="Search"  onChange={this.handleSearch} />*/}
                   
                   
                  </div>
                </form>
              </div>
            </div>
          </div>
          {loading ? 
            (
              <div className='admin_table_loader'>Loading...</div>
            ) : (
              <div className='admin_table_wrapper creatorTable_main_top_wrap adminaccounts'>
              {/*style={{"visibility": "hidden"}} */}
                <div className='tab_Wrap'>
                  <div className={pagestatus === 1?"tab_link topNavActive":"tab_link topNavInactive"} ><label htmlFor='release0'><input  type="radio" style={{"visibility": "hidden"}}  id="release0" name="release"   checked={pagestatus === 1} onClick={(e) => this.radioHandler(1)} />Community Accounts <span className="counter-account">{totalCommunitiesCount? totalCommunitiesCount.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></label></div>
                  <div className={pagestatus === 2?"tab_link topNavActive":"tab_link topNavInactive"} ><label htmlFor='release1'><input type="radio" style={{"visibility": "hidden"}}  name="release"  id="release1"   checked={pagestatus === 2} onClick={(e) => this.radioHandler(2)} />Creator Accounts<span className="counter-account">{totalCreatorCount? totalCreatorCount.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></label></div>
                   <div className={pagestatus === 3?"tab_link topNavActive":"tab_link topNavInactive"} ><label htmlFor='release2'><input type="radio" style={{"visibility": "hidden"}}  name="release"  id="release2"   checked={pagestatus === 3} onClick={(e) => this.radioHandler(3)} />Business Accounts<span className="counter-account">{totalBusinessCount? totalBusinessCount.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></label></div>

                   <div className={pagestatus === 4?"tab_link topNavActive":"tab_link topNavInactive"} ><label htmlFor='release3'><input type="radio" style={{"visibility": "hidden"}}  name="release"  id="release3"   checked={pagestatus === 4} onClick={(e) => this.radioHandler(4)} />Admin Accounts<span className="counter-account">{totalAdminCount? totalAdminCount.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></label></div>
                </div>
                {pagestatus === 1 &&
                  <div className='admin_table_inner'>
                    <p className='profile_message_alert_red entry_msg'>{this.state.creator_update_msg}</p>
                    <div className="admin_table">
                      <table className="creatorTable admincreatortable table-responsive">
                        <thead>
                            <tr>
                                <th>Select</th>
                                <th   className={sortCommunityField === 'displayUsername' && (sortCommunityOrder !== '' ? 'tblh-acnt sort-arrow' : 'tblh-acnt')?"sort-arrow tblh-acnt":"tblh-acnt"}   onClick={(event) => handleCommunutySort('displayUsername')} >Account <span className="updown_arrow">{sortCommunityField === 'displayUsername' && (sortCommunityOrder === 'asc' ? '↑' : '↓')}</span></th>
                                <th className='tblh-type'>Type</th>
                                <th className='tblh-post'>Posts</th>
                                <th className='tblh-engt'>Engagements</th>
                                <th   className={sortCommunityField === 'impr' && (sortCommunityOrder !== '' ? 'tblh-view sort-arrow' : 'tblh-view')?"sort-arrow tblh-view":"tblh-view"}  onClick={(event) => handleCommunutySort('impr')} >Views <span className="updown_arrow">{sortCommunityField === 'impr' && (sortCommunityOrder === 'asc' ? '↑' : '↓')}</span></th>                                  
                                <th className='tblh-flw'>Followers</th>
                                <th   className={sortCommunityField === 'createdAt' && (sortCommunityOrder !== '' ? 'tblh-sign sort-arrow' : 'tblh-sign')?"sort-arrow tblh-sign":"tblh-sign"}  onClick={(event) => handleCommunutySort('createdAt')} >Sign Up <span className="updown_arrow">{sortCommunityField === 'createdAt' && (sortCommunityOrder === 'asc' ? '↑' : '↓')}</span></th>
                                <th   className={sortCommunityField === 'updatedAt' && (sortCommunityOrder !== '' ? 'tblh-lstact sort-arrow' : 'tblh-lstact')?"sort-arrow tblh-lstact":"tblh-lstact"}   onClick={(event) => handleCommunutySort('updatedAt')} >Active <span className="updown_arrow">{sortCommunityField === 'updatedAt' && (sortCommunityOrder === 'asc' ? '↑' : '↓')}</span></th>
                                <th className='tblh-acunt'>Acct ID</th> 
                                <th    className={sortCommunityField === 'username' && (sortCommunityOrder !== '' ? 'tblh-email sort-arrow' : 'tblh-email')?"sort-arrow tblh-email":"tblh-email"}  onClick={(event) => handleCommunutySort('username')} >Email <span className="updown_arrow">{sortCommunityField === 'username' && (sortCommunityOrder === 'asc' ? '↑' : '↓')}</span></th> 
                                <th className='tblh-scllnk'>Social Links</th>
                                
                                
                            </tr>
                        </thead>
                        <tbody>
                          { 
                            communities.map(function (community, i) {
                              //itemUser.fetch();
                              {
                                return (
                                  <tr  key={community.id}>
                                  <td>
                                      <input
                                        type="checkbox"
                                        checked={selectedCommunityUsers.includes(community.id)}
                                        onChange={() => toggleCommunityUserSelection(community.id)}
                                      />
                                        </td>
                                    <td className='tblh-acnt userNamewMedia'>
                                      <div className="user_image">
                                          <a href={community.get("displayUsername")?"https://www.joelskitchen.com/user/"+community.get("displayUsername"):''}>
                                            <span className="thumb"><img class="userProfileImageCreator" src={community != undefined ? (community.get("profilePictureSmall") != undefined ? community.get("profilePictureSmall").url() : "/images/genericuser.png" ) : ""}/></span>
                                            <span className="name">{community.get("displayUsername")?community.get("displayUsername"):''}</span>
                                          </a>
                                      </div>
                                    </td>
                                    <td className='tblh-type'>
                                      {/*  onChange={(event) => handleSelectChangeCreator(event, community.id)}*/}
                                      <select  onChange={(event) => handleSelectChangeCreator(event, community.id)}   style={{'width':'120px'}} className="form-control select-community" value="Community"> 
                                        <option value="Community" selected >Community</option>
                                        <option value="Creator"  >Creator</option>
                                        <option value="Business">Business</option>
                                        <option value="Admin">Admin</option>
                                      </select>

                                    </td>
                                    <td className='tblh-post'><Postcounts postData={community} /></td>
                                    <td className='tblh-engt'>{/*community.get("imprcount")?community.get("imprcount"):'0'*/}<Engagementcounts postDataUser={community} /></td>
                                    <td className='tblh-view'>{community.get("impr")?community.get("impr"):'0'}</td>
                                                                          
                                    <td className='tblh-flw'><Followerscounts postDataUser={community} /></td>
                                    <td className='tblh-sign'>{community.get('createdAt') != undefined ?((community.get('createdAt').getMonth()+1) + "/" + (community.get('createdAt').getDate()) + "/" + community.get('createdAt').getFullYear().toString().substr(-2)):''}</td>

                                    <td className='tblh-lstact'>{community.get('updatedAt') != undefined ?((community.get('updatedAt').getMonth()+1) + "/" + (community.get('updatedAt').getDate()) + "/" + community.get('updatedAt').getFullYear().toString().substr(-2)):''}</td>
                                    <td className='tblh-acunt'>{community.id}</td>
                                    <td className='tblh-email'>
                                      <div className='adminUserData'>
                                        <a href={"mailto:"+community.get("username")}>
                                          {community.get("username")}
                                        </a>
                                      </div>
                                    </td>
                                    <td className='tblh-scllnk'>{self.renderSocial(community)}</td>
                                    
                                    
                                  </tr>
                                );
                              }
                            })
                          }
                        </tbody>
                      </table>    
                      <div className="pagination_bottom">
                        <button
                          onClick={() => this.handlePageChange(page - 1)}
                          disabled={page === 1}
                        >
                          &#10094;
                        </button>
                        <span> Page {page} </span>
                        <button
                          onClick={() => this.handlePageChange(page + 1)}
                          disabled={communities.length < pageSize}
                        >
                          &#10095;
                        </button>
                      </div>
                       <button  className="btn btn-green" onClick={() => copyCommunityEmailsToClipboard()}>Copy Emails</button> 
                    
                    </div>
                  </div>
                }
                {pagestatus === 2 &&
                  <div className='admin_table_inner'>
                    <p className='profile_message_alert_red entry_msg'>{this.state.creator_update_msg}</p>
                    {loading1 ? (
                      <div className='table-loader'>Loading...</div>
                      ) : (
                      <div className="admin_table">
                        <table className="creatorTable admincreatortable table-responsive">
                          <thead>
                              <tr>
                              <th>Select</th>
                                <th  className={sortCreatorField === 'displayUsername' && (sortCreatorOrder !== '' ? 'tblh-acnt sort-arrow' : 'tblh-acnt')?"sort-arrow tblh-acnt":"tblh-acnt"}    onClick={(event) => handleCreatorSort('displayUsername')} >Account <span className="updown_arrow">{sortCreatorField === 'displayUsername' && (sortCreatorOrder === 'asc' ? '↑' : '↓')}</span></th>
                                <th className='tblh-type'>Type</th>
                                <th className='tblh-post'>Posts</th>
                                <th className='tblh-engt'>Engagements</th>
                                <th  className={sortCreatorField === 'impr' && (sortCreatorOrder !== '' ? 'tblh-view sort-arrow' : 'tblh-view')?"sort-arrow tblh-view":"tblh-view"}     onClick={(event) => handleCreatorSort('impr')} >Views <span className="updown_arrow">{sortCreatorField === 'impr' && (sortCreatorOrder === 'asc' ? '↑' : '↓')}</span></th>                                  
                                <th className='tblh-flw'>Followers</th>
                                <th   className={sortCreatorField === 'createdAt' && (sortCreatorOrder !== '' ? 'tblh-sign sort-arrow' : 'tblh-sign')?"sort-arrow tblh-sign":"tblh-sign"}   onClick={(event) => handleCreatorSort('createdAt')}>Sign Up <span className="updown_arrow">{sortCreatorField === 'createdAt' && (sortCreatorOrder === 'asc' ? '↑' : '↓')}</span></th>
                                <th  className={sortCreatorField === 'updatedAt' && (sortCreatorOrder !== '' ? 'tblh-lstact sort-arrow' : 'tblh-lstact')?"sort-arrow tblh-lstact":"tblh-lstact"}   onClick={(event) => handleCreatorSort('updatedAt')}>Active <span className="updown_arrow">{sortCreatorField === 'updatedAt' && (sortCreatorOrder === 'asc' ? '↑' : '↓')}</span></th>
                                <th className='tblh-acunt'>Acct ID</th> 
                                <th  className={sortCreatorField === 'username' && (sortCreatorOrder !== '' ? 'tblh-email sort-arrow' : 'tblh-email')?"sort-arrow tblh-email":"tblh-email"}     onClick={(event) => handleCreatorSort('username')}>Email <span className="updown_arrow">{sortCreatorField === 'username' && (sortCreatorOrder === 'asc' ? '↑' : '↓')}</span></th> 
                                <th className='tblh-scllnk'>Social Links</th>  
                              </tr>
                          </thead>
                          <tbody>
                            { 
                              creators.map(function (creator, i) {
                                //itemUser.fetch();
                                let isCreator=creator.get('isCreator') && creator.get('isCreator')===1?creator.get('isCreator'):'';
                                {
                                  return (
                                    <tr  key={creator.id}>
                                     <td>
                                          <input
                                            type="checkbox"
                                            checked={selectedCreatorUsers.includes(creator.id)}
                                            onChange={() => toggleCreatorUserSelection(creator.id)}
                                          />
                                        </td>
                                      <td className='tblh-acnt userNamewMedia'> 
                                        <div className="user_image">
                                          <a href={creator.get("displayUsername")?"https://www.joelskitchen.com/user/"+creator.get("displayUsername"):''}>
                                            <span className="thumb"><img class="userProfileImageCreator" src={creator != undefined ? (creator.get("profilePictureSmall") != undefined ? creator.get("profilePictureSmall").url() : "/images/genericuser.png" ) : ""}/></span>
                                            <span className="name">{creator.get("displayUsername")?creator.get("displayUsername"):''}</span>
                                          </a>
                                        </div>
                                      </td>
                                      <td className='tblh-type'>
                                        {/*  onChange={(event) => handleSelectChangeCreator(event, creator.id)}*/}
                                        {isCreator==1 && (
                                          <div>
                                            <select  onChange={(event) => handleSelectChangeCreator(event, creator.id)}  style={{'width':'120px'}}  className="form-control select-creator"
                                              value="Creator"
                                            > 
                                              <option value="Community"  >Community</option>
                                              <option value="Creator" selected >Creator</option>
                                              <option value="Business">Business</option>
                                              <option value="Admin">Admin</option>
                                            </select>
                                          </div>
                                        )}
                                      </td>
                                      <td className='tblh-post'><Postcounts postData={creator} /></td>
                                      <td className='tblh-engt'>{/*creator.get("imprcount")?creator.get("imprcount"):'0'*/} <Engagementcounts postDataUser={creator} /></td>
                                      <td className='tblh-view'>{creator.get("impr")?creator.get("impr"):'0'} </td>
                                                                           
                                      <td className='tblh-flw'><Followerscounts postDataUser={creator} /></td>
                                      <td className='tblh-sign'>{creator.get('createdAt') != undefined ?((creator.get('createdAt').getMonth()+1) + "/" + (creator.get('createdAt').getDate()) + "/" + creator.get('createdAt').getFullYear().toString().substr(-2)):''}</td>
                                      <td className='tblh-lstact'>{creator.get('updatedAt') != undefined ?((creator.get('updatedAt').getMonth()+1) + "/" + (creator.get('updatedAt').getDate()) + "/" + creator.get('updatedAt').getFullYear().toString().substr(-2)):''}</td>
                                      <td className='tblh-acunt'>{creator.id}</td>
                                      
                                      <td className='tblh-email'>
                                        <div className='adminUserData'>
                                          <a href={"mailto:"+creator.get("username")}>
                                            {creator.get("username")}
                                          </a>
                                        </div>
                                      </td>
                                      <td className='tblh-scllnk'>{self.renderSocial(creator)}</td>
                                      
                                    </tr>
                                  );
                                }
                              })
                            }
                          </tbody>
                        </table>    
                        <div className="pagination_bottom">
                          <button
                            onClick={() => this.handlePageChange1(page1 - 1)}
                            disabled={page1 === 1}
                          >
                            &#10094;
                          </button>
                          <span> Page {page1} </span>
                          <button
                            onClick={() => this.handlePageChange1(page1 + 1)}
                            disabled={creators.length < pageSize1}
                          >
                            &#10095;
                          </button>
                        </div>
                         <button  className="btn btn-green" onClick={() => copyCreatorEmailsToClipboard()}>Copy Emails</button> 
                      </div>
                      )
                    }
                  </div>
                }
                {pagestatus === 3 &&
                  <div className='admin_table_inner'>
                    <p className='profile_message_alert_red entry_msg'>{this.state.creator_update_msg}</p>
                    {loading2 ? (
                      <div className='table-loader'>Loading...</div>
                      ) : (
                      <div className="admin_table">
                        <table className="creatorTable admincreatortable table-responsive">
                          <thead>
                              <tr>
                               <th>Select</th>
                                <th  className={sortBusinessField === 'displayUsername' && (sortBusinessOrder !== '' ? 'tblh-acnt sort-arrow' : 'tblh-acnt')?"sort-arrow tblh-acnt":"tblh-acnt"}      onClick={(event) => handleBusinessSort('displayUsername')}>Account <span className="updown_arrow">{sortBusinessField === 'displayUsername' && (sortBusinessOrder === 'asc' ? '↑' : '↓')}</span></th>
                                <th className='tblh-type'>Type</th>
                                <th className='tblh-post'>Posts</th>
                                <th className='tblh-engt'>Engagements</th>
                                <th   className={sortBusinessField === 'impr' && (sortBusinessOrder !== '' ? 'tblh-view sort-arrow' : 'tblh-view')?"sort-arrow tblh-view":"tblh-view"}    onClick={(event) => handleBusinessSort('impr')}>Views <span className="updown_arrow">{sortBusinessField === 'impr' && (sortBusinessOrder === 'asc' ? '↑' : '↓')}</span></th>                                  
                                <th className='tblh-flw'>Followers</th>
                                <th className={sortBusinessField === 'createdAt' && (sortBusinessOrder !== '' ? 'tblh-sign sort-arrow' : 'tblh-sign')?"sort-arrow tblh-sign":"tblh-sign"}   onClick={(event) => handleBusinessSort('createdAt')}>Sign Up  <span className="updown_arrow">{sortBusinessField === 'createdAt' && (sortBusinessOrder === 'asc' ? '↑' : '↓')}</span></th>
                                <th className={sortBusinessField === 'updatedAt' && (sortBusinessOrder !== '' ? 'tblh-lstact sort-arrow' : 'tblh-lstact')?"sort-arrow tblh-lstact":"tblh-lstact"}      onClick={(event) => handleBusinessSort('updatedAt')}>Active  <span className="updown_arrow">{sortBusinessField === 'updatedAt' && (sortBusinessOrder === 'asc' ? '↑' : '↓')}</span></th>
                                <th className='tblh-acunt'>Acct ID</th> 
                                <th className={sortBusinessField === 'username' && (sortBusinessOrder !== '' ? 'tblh-email sort-arrow' : 'tblh-email')?"sort-arrow tblh-email":"tblh-email"}     onClick={(event) => handleBusinessSort('username')}>Email <span className="updown_arrow">{sortBusinessField === 'username' && (sortBusinessOrder === 'asc' ? '↑' : '↓')}</span></th> 
                                <th className='tblh-scllnk'>Social Links</th>
                              </tr>
                          </thead>
                          <tbody>
                            { 
                              businesses.map(function (business, i) {
                                //itemUser.fetch();
                                let isBusiness=business.get('isBusiness') && business.get('isBusiness')===1?business.get('isBusiness'):'';
                                {
                                  return (
                                    <tr  key={business.id}>
                                     <td>
                                          <input
                                            type="checkbox"
                                            checked={selectedBusinessUsers.includes(business.id)}
                                            onChange={() => toggleBusinessUserSelection(business.id)}
                                          />
                                        </td>
                                      <td className='tblh-acnt userNamewMedia'>
                                        <div className="user_image">
                                          <a href={business.get("displayUsername")?"https://www.joelskitchen.com/user/"+business.get("displayUsername"):''}>
                                            <span className="thumb"><img class="userProfileImageCreator" src={business != undefined ? (business.get("profilePictureSmall") != undefined ? business.get("profilePictureSmall").url() : "/images/genericuser.png" ) : ""}/></span>
                                            <span className="name">{business.get("displayUsername")?business.get("displayUsername"):''}</span>
                                          </a>
                                        </div>
                                      </td>
                                      <td className='tblh-type'>
                                      {/*  onChange={(event) => handleSelectChangeCreator(event, business.id)}*/}
                                     {/* {isBusiness==1 && (*/}<div>  <select  onChange={(event) => handleSelectChangeCreator(event, business.id)} style={{'width':'120px'}} className="form-control select-business"
                                          value="Business"
                                        
                                        > 
                                          <option value="Community"  >Community</option>
                                          <option value="Creator"  >Creator</option>
                                          <option value="Business" selected>Business</option>
                                          <option value="Admin">Admin</option>
                                        </select></div>{/*)}*/}

                                      </td>
                                      <td className='tblh-post'><Postcounts postData={business} /></td>
                                      <td className='tblh-engt'>{/*{business.get("imprcount")?business.get("imprcount"):'0'}*/}  <Engagementcounts postDataUser={business} /></td>
                                      <td className='tblh-view'>{business.get("impr")?business.get("impr"):'0'} </td>
                                                                           
                                      <td className='tblh-flw'><Followerscounts postDataUser={business} /></td>
                                      <td className='tblh-sign'>{business.get('createdAt') != undefined ?((business.get('createdAt').getMonth()+1) + "/" + (business.get('createdAt').getDate()) + "/" + business.get('createdAt').getFullYear().toString().substr(-2)):''}</td>
                                      <td className='tblh-lstact'>{business.get('updatedAt') != undefined ?((business.get('updatedAt').getMonth()+1) + "/" + (business.get('updatedAt').getDate()) + "/" + business.get('updatedAt').getFullYear().toString().substr(-2)):''}</td>
                                        <td>{business.id}</td>
                                      
                                      <td className='tblh-email'>
                                        <div className='adminUserData'>
                                          <a href={"mailto:"+business.get("username")}>
                                            {business.get("username")}
                                          </a>
                                        </div>
                                      </td>
                                      <td className='tblh-scllnk'>{self.renderSocial(business)}</td>
                                    
                                    </tr>
                                  );
                                }
                              })
                            }
                          </tbody>
                        </table>    
                        <div className="pagination_bottom">
                          <button
                            onClick={() => this.handlePageChangeBusiness(page2 - 1)}
                            disabled={page2 === 1}
                          >
                            &#10094;
                          </button>
                          <span> Page {page2} </span>
                          <button
                            onClick={() => this.handlePageChangeBusiness(page2 + 1)}
                            disabled={businesses.length < pageSize2}
                          >
                            &#10095;
                          </button>
                        </div>
                        <button  className="btn btn-green" onClick={() => copyBusinessEmailsToClipboard()}>Copy Emails</button> 
                      </div>
                      )
                    }
                  </div>
                }

                {pagestatus === 4 &&
                  <div className='admin_table_inner'>
                    <p className='profile_message_alert_red entry_msg'>{this.state.creator_update_msg}</p>
                    {loading4 ? (
                      <div className='table-loader'>Loading...</div>
                      ) : (
                      <div className="admin_table">
                        <table className="creatorTable admincreatortable table-responsive">
                          <thead>
                              <tr >
                                <th>Select</th>
                                <th  className={sortAdminField === 'displayUsername' && (sortAdminOrder !== '' ? 'tblh-email tblh-acnt' : 'tblh-acnt')?"sort-arrow tblh-acnt":"tblh-acnt"}   onClick={(event) => handleAdminSort('displayUsername')} >Account  <span className="updown_arrow">{sortAdminField === 'displayUsername' && (sortAdminOrder === 'asc' ? '↑' : '↓')}</span></th>
                                <th className='tblh-type'>Type</th>
                                <th className='tblh-post'>Posts</th>
                                <th className='tblh-engt'>Engagements</th>
                                <th className={sortAdminField === 'impr' && (sortAdminOrder !== '' ? 'sort-arrow tblh-view' : 'tblh-view')?"sort-arrow tblh-view":"tblh-view"}   onClick={(event) => handleAdminSort('impr')}>Views <span className="updown_arrow">{sortAdminField === 'impr' && (sortAdminOrder === 'asc' ? '↑' : '↓')}</span></th>                                  
                                <th className='tblh-flw'>Followers</th>
                                <th   className={sortAdminField === 'createdAt' && (sortAdminOrder !== '' ? 'sort-arrow tblh-sign' : 'tblh-sign')?"sort-arrow tblh-sign":"tblh-sign"}   onClick={(event) => handleAdminSort('createdAt')} >Sign Up  <span className="updown_arrow">{sortAdminField === 'createdAt' && (sortAdminOrder === 'asc' ? '↑' : '↓')}</span></th>
                                <th   className={sortAdminField === 'updatedAt' && (sortAdminOrder !== '' ? 'sort-arrow tblh-lstact' : 'tblh-lstact')?"sort-arrow tblh-lstact":"tblh-lstact"}   onClick={(event) => handleAdminSort('updatedAt')} >Active <span className="updown_arrow">{sortAdminField === 'updatedAt' && (sortAdminOrder === 'asc' ? '↑' : '↓')}</span></th>
                                <th className='tblh-acunt' >Acct ID </th> 
                                <th className={sortAdminField === 'username' && (sortAdminOrder !== '' ? 'sort-arrow tblh-email' : 'tblh-email')?"sort-arrow tblh-email":"tblh-email"}   onClick={(event) => handleAdminSort('username')} >Email <span className="updown_arrow">{sortAdminField === 'username' && (sortAdminOrder === 'asc' ? '↑' : '↓')}</span></th> 
                                <th className='tblh-scllnk'>Social Links</th> 
                              </tr>
                          </thead>
                          <tbody>
                            { 
                              admins.map(function (admin, i) {
                                //itemUser.fetch();
                                let isAdmin=admin.get('isAdmin') && admin.get('isAdmin')==="1"?admin.get('isAdmin'):'';
                                {
                                  return (
                                    <tr  key={admin.id}>
                                    <td>
                                          <input
                                            type="checkbox"
                                            checked={selectedAdminUsers.includes(admin.id)}
                                            onChange={() => toggleAdminUserSelection(admin.id)}
                                          />
                                        </td>
                                      <td className='tblh-acnt userNamewMedia'>
                                        <div className="user_image">
                                          <a href={admin.get("displayUsername")?"https://www.joelskitchen.com/user/"+admin.get("displayUsername"):''}>
                                            <span className="thumb"><img class="userProfileImageCreator" src={admin != undefined ? (admin.get("profilePictureSmall") != undefined ? admin.get("profilePictureSmall").url() : "/images/genericuser.png" ) : ""}/></span>
                                            <span className="name">{admin.get("displayUsername")?admin.get("displayUsername"):''}</span>
                                          </a>
                                        </div>
                                      </td>
                                      <td className='tblh-type'>
                                      {/*  onChange={(event) => handleSelectChangeCreator(event, business.id)}*/}
                                      <div>  <select   onChange={(event) => handleSelectChangeCreator(event, admin.id)}  style={{'width':'120px'}} className="form-control select-business"
                                          value="Admin"
                                        
                                        > 
                                          <option value="Community"  >Community</option>
                                          <option value="Creator"  >Creator</option>
                                          <option value="Business">Business</option>
                                          <option value="Admin" selected>Admin</option>
                                        </select></div>

                                      </td>
                                      <td className='tblh-post'><Postcounts postData={admin} /></td>
                                      <td className='tblh-engt'>{/*{business.get("imprcount")?business.get("imprcount"):'0'}*/}  <Engagementcounts postDataUser={admin} /></td>
                                      <td className='tblh-view'>{admin.get("impr")?admin.get("impr"):'0'} </td>
                                                                           
                                      <td className='tblh-flw'><Followerscounts postDataUser={admin} /></td>
                                      <td className='tblh-sign'>{admin.get('createdAt') != undefined ?((admin.get('createdAt').getMonth()+1) + "/" + (admin.get('createdAt').getDate()) + "/" + admin.get('createdAt').getFullYear().toString().substr(-2)):''}</td>
                                      <td className='tblh-lstact'>{admin.get('updatedAt') != undefined ?((admin.get('updatedAt').getMonth()+1) + "/" + (admin.get('updatedAt').getDate()) + "/" + admin.get('updatedAt').getFullYear().toString().substr(-2)):''}</td>
                                      <td>{admin.id}</td>
                                      
                                      <td className='tblh-email'>
                                        <div className='adminUserData'>
                                          <a href={"mailto:"+admin.get("username")}>
                                            {admin.get("username")}
                                          </a>
                                        </div>
                                      </td>
                                      <td className='tblh-scllnk'>{self.renderSocial(admin)}</td>
                                      
                                    </tr>
                                  );
                                }
                              })
                            }
                          </tbody>
                        </table>    
                        <div className="pagination_bottom">
                          <button
                            onClick={() => this.handlePageChangeAdmin(page4 - 1)}
                            disabled={page4 === 1}
                          >
                            &#10094;
                          </button>
                          <span> Page {page4} </span>
                          <button
                            onClick={() => this.handlePageChangeAdmin(page4 + 1)}
                            disabled={admins.length < pageSize4}
                          >
                            &#10095;
                          </button>
                        </div>
                         <button  className="btn btn-green" onClick={() => copyAdminEmailsToClipboard()}>Copy Emails</button> 
                      </div> 
                      )
                    }
                  </div>
                }
              </div>
            )
          }
        </div>

        <button href='javascript:void(0);' id="admin_account_community_btn" type="button" data-toggle="modal" data-target="#admin_account_community"  style={{display:"none"}} >submit</button>
        <div className="modal fade post_modal post_modal_overlay createPostCloseModal admin_account_community" id="admin_account_community" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="createPostCloseTitle" aria-hidden="true">
          <div className='bg_overlay'></div>
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className='inner text-center'>
                  <div className='title-main'>
                    <h6>Emails copied to clipboard!</h6>                    
                  </div>
                  <div className='action-part'>
                    <a href='javascript:void(0);' className='btn cancel  btn-post-cancel'  onClick={this.checkCancelBtn} data-dismiss="modal" aria-label="Close" title='Close'>Close</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>




      </div>
    );
  }
}
export default AdminAccounts;