import React, { useState, useRef,Component } from 'react';
import Parse from 'parse';
import Textarea from 'react-textarea-autosize';
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import Dropdown from 'react-dropdown'
import $ from "jquery";
const createDropdownOptions = [
 "Challenge",
 "Collaboration" 
]
class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
            selectedNewCreateType: "Challenge",
            email: '',
            password: '',
            login_message_error:null,
        };


    this._onSelectNewOption = this._onSelectNewOption.bind(this);
    this.handleNewOption = this.handleNewOption.bind(this);
    this.handleUsernameLoginChange = this.handleUsernameLoginChange.bind(this);
    this.handlePasswordLoginChange = this.handlePasswordLoginChange.bind(this);
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);

  }

  handleUsernameLoginChange(event) {
        this.setState({email: event.target.value});
    }

  handlePasswordLoginChange(event) {
        this.setState({password: event.target.value});
    }
    handleLoginSubmit(event) {
        event.preventDefault();
        var self = this;
        Parse.User.logIn(this.state.email.toLowerCase(), this.state.password, {
          success: user => {
                var msg = 'User logged in successful with username: ' + user.get("username") + ' and email: ' + user.get("email");
                this.setState({message: msg});
                this.forceUpdate();
               this.setState({loggedIn: true});
                window.location.reload(true);
          },
          error: (user, error) => {
                var msg = ("The login failed with error: " + error.code + " " + error.message);
                 self.setState({login_message_error:error.message});  
               setTimeout(() => {
                  self.setState({login_message_error:null}); 
                }, 3000);
               // this.setState({message: msg});
                this.forceUpdate();
               this.setState({loggedIn: false});
                //window.location.reload(true);

          }, 
        });
        let install = new Parse.Installation();
        install.set("deviceType", navigator.userAgent);

        install.save().then((resp) => {
          console.log('Created install object', resp);

          this.setState({
            result: 'New object created with objectId: ' + resp.id
          })
        }, err => {
          console.log('Error creating install object', err);

          this.setState({
            result: 'Failed to create new object, with error code: ' + err.message
          })
        });
    }

   _onSelectNewOption(option) {
        console.log('You selected ', option.label)
        this.setState({selectedNewCreateType: option.label})
    }
    handleNewOption(event) {
        event.preventDefault();
        if(this.state.selectedNewCreateType==='Challenge')
        {
            window.location.href = "/create-challenge";
        }
        if(this.state.selectedNewCreateType==='Collaboration')
        {
           $('.createNewBtnPopup').trigger('click');
        }
      }

  render() {
    
    /*  | <a href="http://www.joelskitchen.com/terms">Terms Of Use</a> | <a href="http://www.joelskitchen.com/privacy">Privacy Policy</a> */

    return (
      <React.Fragment>
        <div className="footer">
          <center>
            <br/>
            <hr/>
            <div className="social">
              <a href="https://www.facebook.com/pages/Dirty-Ones/162256920574121"><img src="/images/header/fb.png"/></a>&nbsp; &nbsp;
              <a href="http://instagram.com/joels_kitchen"><img src="/images/header/insta.png"/></a>
            </div>
            <br/>
            <p>&copy; Joel's Kitchen | <a href="mailto:joel@joelskitchen.com">Contact</a></p>
          </center>
        </div>
        <div className="modal fade post_modal post_modal_overlay challengeModal createChallengeModal" id="createChallengeModal" data-backdrop="true" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="createPostCloseTitle" aria-hidden="true">
          <div className='bg_overlay'></div>
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="top-part">
                <h5 className="modal-title">Challenge Details</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <img src="/images/close.svg" alt="Close"/>
                </button>
              </div>
              <div className="modal-body">
                <div className='chlng-box'>
                  <h6>Photo</h6>
                  <p>Your photo will show creators the type of content you want for your challenge.</p>
                </div>
                <div className='chlng-box'>
                  <h6>Title</h6>
                  <p>This is how we will list your challenge.  Keep it short and sweet.</p>
                </div>
                <div className='chlng-box'>
                  <h6>Hashtag</h6>
                  <p>You have the option to include a hashtag for your challenge. While we'll organize posts within the challenge, hashtags provide an opportunity to populate your challenge with existing content. It's like giving your challenge a grand kickoff. If you prefer to exclusively showcase your community's posts within your challenge, you can simply skip the hashtag.</p>
                </div>
                <div className='chlng-box'>
                  <h6>Creative Direction</h6>
                  <p>Your creative direction serves as a guide for creators and their content. The greater freedom you give, the higher the participation.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade post_modal post_modal_overlay challengeModal challengeGoalsModal" id="challengeGoalsModal" data-backdrop="true" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="createPostCloseTitle" aria-hidden="true">
          <div className='bg_overlay'></div>
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="top-part">
                <h5 className="modal-title">Challenge Goals</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <img src="/images/close.svg" alt="Close"/>
                </button>
              </div>
              <div className="modal-body">
                <div className='chlng-box'>
                  <h6>More Creators</h6>
                  <p>We’ll help you connect with the most trusted creators on social media. Get started meeting creator that are a perfect fit for growing your brand and selling your products.</p>
                </div>
                <div className='chlng-box'>
                  <h6>More Content</h6>
                  <p>Get a library of high quality content for your brand.  Perfect for setting up your digital marketing campaigns.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade post_modal post_modal_overlay budgetDurationModal" id="budgetDurationModal" data-backdrop="true" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="createPostCloseTitle" aria-hidden="true">
          <div className='bg_overlay'></div>
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Budget & Duration</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div className='chlng-box'>
                  <h6>More Creators</h6>
                  <p>We'll help you connect with the most trusted creators on social media. Get started meeting creator that are a perfect fit for growing your brand and selling your products.</p>
                </div>
                <div className='chlng-box'>
                  <h6>More Content</h6>
                  <p>Get a library of high quality content for your brand. Perfect for setting up your digital marketing campaigns.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade post_modal post_modal_overlay addPaymentInfoModal" id="addPaymentInfoModal" data-backdrop="true" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="createPostCloseTitle" aria-hidden="true">
          <div className='bg_overlay'></div>
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Add Payment Information</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <aside className='card-wrap contact-info'>
                  <div className='title-wrap'>
                    <h3>Contact Information</h3>
                  </div>
                  <div className='frm-wrapper'>
                    <div className='frm-grp'>
                      <label className='frm-lbl'>First Name</label>
                      <input type="text" placeholder='First Name' name="fname" className="frm-cntrl" />
                    </div>
                    <div className='frm-grp'>
                      <label className='frm-lbl'>Last Name</label>
                      <input type="text" placeholder='Last Name' name="lname" className="frm-cntrl" />
                    </div>
                    <div className='frm-grp'>
                      <label className='frm-lbl'>Business Name (optional)</label>
                      <input type="text" placeholder='Business Name' name="bname" className="frm-cntrl" />
                    </div>
                    <div className='frm-grp'>
                      <label className='frm-lbl'>Phone number</label>
                      <input type="text" placeholder='First Name' name="fname" className="frm-cntrl" />
                    </div>
                  </div>
                </aside>
                <aside className='card-wrap billing-add'>
                  <div className='title-wrap'>
                    <h3>Billing Address</h3>
                  </div>
                  <div className='frm-wrapper'>
                    <div className='frm-grp stadd stadd1'>
                      <label className='frm-lbl'>Street address 1</label>
                      <input type="text" placeholder='Street address 1' name="stadd1" className="frm-cntrl" />
                    </div>
                    <div className='frm-grp stadd stadd2'>
                      <label className='frm-lbl'>Street address 2</label>
                      <input type="text" placeholder='Street address 2' name="stadd2" className="frm-cntrl" />
                    </div>
                    <div className='frm-grp city'>
                      <label className='frm-lbl'>City </label>
                      <input type="text" placeholder='City ' name="city" className="frm-cntrl" />
                    </div>
                    <div className='frm-grp state'>
                      <input type="text" placeholder='State ' name="state" className="frm-cntrl" />
                    </div>
                    <div className='frm-grp zipcode'>
                      <label className='frm-lbl'>Zip Code</label>
                      <input type="text" placeholder='Zip Code' name="zipcode" className="frm-cntrl" />
                    </div>
                  </div>
                </aside>
                <aside className='card-wrap card-detail'>
                  <div className='title-wrap'>
                    <h3>Card Details</h3>
                    <ul>
                      <li><a href='javascript:void(0)'><img src='/images/mastercard_payment.svg' alt='Mastercard' /></a></li>
                      <li><a href='javascript:void(0)'><img src='/images/visa_payment.svg' alt='Visa' /></a></li>
                      <li><a href='javascript:void(0)'><img src='/images/amex_payment.svg' alt='Amex' /></a></li>
                      <li><a href='javascript:void(0)'><img src='/images/discover_payment.svg' alt='Discover' /></a></li>
                    </ul>
                  </div>
                  <div className='frm-wrapper'>
                    <div className='frm-grp card-name'>
                      <label className='frm-lbl'>Name on card</label>
                      <input type="text" placeholder='Name on card' name="cardname" className="frm-cntrl" />
                    </div>
                    <div className='frm-grp'>
                      <label className='frm-lbl'>Card number</label>
                      <input type="text" placeholder='Card number' name="cardnumber" className="frm-cntrl" />
                    </div>
                    <div className='frm-grp expdate'>
                      <label className='frm-lbl'>Expiration Date</label>
                      <input type="text" placeholder='MM/YY' name="expdate" className="frm-cntrl" />
                    </div>
                    <div className='frm-grp cardcvv'>
                      <input type="text" placeholder='CVV' name="cardcvv" className="frm-cntrl" />
                    </div>
                    <div className='frm-grp paymnt-terms'>
                      <img src='./images/lock.svg' alt="Lock"/>
                      <label className='frm-lbl'>Your payment methods are saved and stored securely. <a href="javascript:void(0);" title='Terms apply'>Terms apply</a></label>
                    </div>
                    <div class="action cta_btn third-btn">
                      <button class="submit">Save</button>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>

        {/* Login Modal */}
        <div className="modal fade post_modal ragister_login_model createPostCloseModal" id="loginModal" data-backdrop="static" data-keyboard="true" tabIndex="-1" role="dialog" aria-labelledby="createPostCloseTitle" aria-hidden="true">
          <div className='bg_overlay'></div>
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className='action-part'>
                  <a href='javascript:void(0);' className='btn cancel close btn-post-cancel'  data-dismiss="modal" aria-label="Close" title='Cancel'>
                    <img src="/images/close.svg" alt="Close"/>
                  </a>
                </div>
                <div className='inner'>
                  <div className="logo_login_main">
                    <div className="logo">
                      <a id="logo" rel="home" href="/">
                        <img src="/images/JKCM_Logo.png" alt="Joel's Kitchen Creator Marketplace" className='dt_logo'/>
                        {/*<span className='divider'></span>
                        <img src="/images/crt_mrk_plc.png" alt="Creator Marketplace" className='txt_logo'/>*/}
                      </a>
                    </div>
                    <div className="form_midddle">                      
                      <form className="logiN_form"  onSubmit={this.handleLoginSubmit}>
                        <label className='frm-error'>{this.state.login_message_error}</label>
                        <div className="input"><input type="text" class="form-control" placeholder="Email" required name="username" onChange={this.handleUsernameLoginChange} /></div>
                        <div className="input"><input type="password" class="form-control" placeholder="Password" required name="password"  onChange={this.handlePasswordLoginChange}/></div>
                        <div className='frm-lbl text-right'>
                          <a href="javascript:;" className="forgot_password" >forgot password?</a>
                        </div>                    
                        <div className='secondary-btn btn-wrp cta_btn '>
                          <button className=" submit_button_ragister" type="submit">Log in</button>
                        </div>                      
                      </form>
                    </div>
                    <div className="logiN_singUp">
                      Don’t have an account? {/*<a href="javascript:;" type="button" class="btn" data-toggle="modal" data-target="#ragisterModal" id="ragister_popup_btn">Sign up</a> */} <a href='javascript:void(0);' type="button" class="btn" id="ragister_popup_btn">Sign up</a>
                    </div>                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Registration Modal */}
        <div className="modal fade post_modal ragister_login_model createPostCloseModal" id="ragisterModal" data-backdrop="static" data-keyboard="true" tabIndex="-1" role="dialog" aria-labelledby="createPostCloseTitle" aria-hidden="true">
          <div className='bg_overlay'></div>
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className='action-part'>
                  <a href='javascript:void(0);' className='btn cancel close btn-post-cancel'  data-dismiss="modal" aria-label="Close" title='Cancel'>
                    <img src="/images/close.svg" alt="Close"/>
                  </a>
                </div>
                <div className='inner text-center'>
                  <div className="logo_login_main">
                    <div className="form_midddle">
                      <div className="logo">
                        <a id="logo" rel="home" href="/">
                          <img src="/images/JKCM_Logo.png" alt="Joel's Kitchen Creator Marketplace" className='dt_logo'/>
                          {/*<span className='divider'></span>
                          <img src="/images/crt_mrk_plc.png" alt="Creator Marketplace" className='txt_logo'/>*/}
                        </a>
                      </div>
                      <form className="logiN_form">
                        <div className="input"><input type="text" class="form-control" placeholder="Email" required="" /></div>
                        <div className="password"><input type="password" class="form-control" placeholder="Password" required="" /></div>
                        <a href="javascript:;" className="forgot_password" >&nbsp;</a>
                        <button className="btn btn-lg btn-primary btn-block signUp submit_button_ragister " type="submit">Sign up</button>
                      </form>
                    </div>
                    <div className="logiN_singUp">
                      Don’t have an account? <a href="javascript:;" type="button" class="btn" data-toggle="modal" data-target="#loginModal" id="ragister_popup_btn">Log in</a> 
                    </div>                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

       {/* <div className="modal fade post_modal post_modal_overlay createPostCloseModal chnlgPostConfrModal" id="chnlgPostConfrModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="createPostCloseTitle" aria-hidden="true">
          <div className='bg_overlay'></div>
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className='inner text-center'>
                  <div className='title-main'>
                    <h6>Thank you for submitting your challenge!</h6>
                    <p>Joel’s Kitchen will contact you within 24 hours to discuss budget and payment. </p>
                  </div>
                  <div className='action-part'>
                    <a href='javascript:void(0);' className='btn cancel  btn-post-cancel'  onClick={this.checkCancel} data-dismiss="modal" aria-label="Close" title='Cancel'>Close</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>*/}
         <button href='javascript:void(0);' id="new_open_popup" type="button" data-toggle="modal" data-target="#newOpenpopup"  style={{display:"none"}} >New click</button>
        <div className="modal fade post_modal post_modal_overlay createPostCloseModal chnlgPostConfrModal" id="newOpenpopup" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="createPostCloseTitle" aria-hidden="true">
          <div className='bg_overlay'></div>
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className='inner text-center'>
                  <div className='title-main'>
                   
                    <p><div class="createNewDropdown"   style={{width:"auto"}}><Dropdown options={createDropdownOptions} onChange={this._onSelectNewOption} value="Challenge" placeholder="new" /> </div> &nbsp;
                    <div class="createNewBtnWrapper">&nbsp; <button className="btn btn-lg btn-primary btn-block createNewBtn" onClick={this.handleNewOption}>New</button>
                     </div>
                    </p>
                  </div>
                  <div className='action-part'>
                    <a href='javascript:void(0);' className='btn cancel  btn-post-cancel'  onClick={this.checkCancel} data-dismiss="modal" aria-label="Close" title='Cancel'>Close</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Footer;
