import '../global.js'
import Parse from 'parse';
import React from 'react';

class Engagementcounts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            engagementcount:0,
        };
        var activityquery = new Parse.Query('Activity');       
        //followersquery.include("user");     
        activityquery.limit(99999999999999999999999999);   
        activityquery.include("toUser");
        activityquery.equalTo("type", "comment");
        activityquery.equalTo("toUser",props.postDataUser);
        var self = this;
        activityquery.find().then(function(engagementresult){
            if(engagementresult){
                 self.setState({engagementcount: engagementresult.length});
                 self.setState({engagement: engagementresult});
            }
        })
    }

    render() {
        var engagementcount=this.state.engagementcount;
        let valueAdded =engagementcount;
           if(this.state.engagement && this.state.engagement.length>0  )
           {
            
            /*for (var i = 0; i < this.state.engagement.length; i++) {
                 var activity = this.state.engagement[i];  
                 let likeCount=activity.get("likeCount") && activity.get("likeCount")!==undefined?activity.get("likeCount"):0;  
                 
                 if(activity.get("likeCount")!=='0' || activity.get("likeCount")!==undefined)
                 {
                     valueAdded += activity.get("likeCount")!==0 || activity.get("likeCount")!==undefined;
                 }               

            }*/
            for (const activity of this.state.engagement) {
              const likeCount = activity.get("likeCount") || 0;
              valueAdded += likeCount;
            }
        }
        var result = (<>{valueAdded} </> );            
        return result;
    }


}

export default Engagementcounts;