import React, { Component } from 'react';
import Parse from 'parse';
import $ from "jquery";
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import SaveNotification from "./saveNotification.jsx";
const stripePromise = loadStripe("pk_test_51N1Iw3F2uVaAxW23DxC7agGfTzAW2GmNeWovzSsHYi2laozcLAIdliI06EIKpXPS34FaaOGTyTYqrHqhqrm19MKi001fafzmiU");
// test key  loadStripe stripeSecretKey   pk_test_51N1Iw3F2uVaAxW23DxC7agGfTzAW2GmNeWovzSsHYi2laozcLAIdliI06EIKpXPS34FaaOGTyTYqrHqhqrm19MKi001fafzmiU
//test clientSecret  sk_test_51N1Iw3F2uVaAxW23VoHgfbi2ikYGzN6W4g3QOlmdu9SMRaRyF4ytRU180W4PMnnSK3UEy22EgSVTfs9Lki7D4Dgb00bVw8eqIO 
//live key loadStripe stripeSecretKey  pk_live_51N1Iw3F2uVaAxW230PuXQFIgkR1TBhgLbB4mflAqFqZTT8Q1D9StAfCQgz22ZTkdsMSB7wdgLg9M6SIBjfk1EVKn00HeKUe1XS
//live clientSecret  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
class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    const queryString = window.location.href;
     var challenge_id = queryString.split("/")[4]; 
     this.checkChallengeAvailable(challenge_id);
     this.challenge_id=challenge_id;
    this.state = {
      email: '',
      name: '',
      amount: '',
      clientSecret: "sk_test_51N1Iw3F2uVaAxW23VoHgfbi2ikYGzN6W4g3QOlmdu9SMRaRyF4ytRU180W4PMnnSK3UEy22EgSVTfs9Lki7D4Dgb00bVw8eqIO",
      clientSecrets:null,
      error_message:'',
      stripeSecretKey:"pk_test_51N1Iw3F2uVaAxW23DxC7agGfTzAW2GmNeWovzSsHYi2laozcLAIdliI06EIKpXPS34FaaOGTyTYqrHqhqrm19MKi001fafzmiU",
      prize:'',
      challengeAmount:'',
      totalCost:'',
      caption:null,
      name:null,
      endDate:null,
      username:null,
      lastinvoiceNumber:1,
      isPaid:0,
      loading:false,
      challengemainid:this.challenge_id,
      isNotificationOpen:false,
      post_Notification_Id:'',
      post_Notification_Msg:'',
      post_Notification_Comment:'',
    };    
     this.checkInvoiceNumber();
  } 

  toggleNotification = (postid,msg,comment) => {   console.log('toggleNotification'+postid);
      var self = this;
        self.setState(prevState => ({
          isNotificationOpen: !prevState.isNotificationOpen,
          post_Notification_Id: postid,
          post_Notification_Msg: msg,
          post_Notification_Comment:comment  
        }));
        //self.setState({ post_Notification_Id: postid });        
      };
   checkInvoiceNumber() {
            var self = this;  
            const ClassObject = Parse.Object.extend('Photo'); 
            const queryjob = new Parse.Query(ClassObject);
            queryjob.descending('invoiceNumber');
            queryjob.limit(1);

            queryjob.find().then(function(queryrresult){
                if(queryrresult){     
                let queryrresults= queryrresult[0]; 
                let invoiceNumber=queryrresult[0].get('invoiceNumber')!==undefined?queryrresult[0].get('invoiceNumber')+1:1;  
                self.setState({lastinvoiceNumber:invoiceNumber});
                /*queryrresults.set('invoiceNumber',invoiceNumber);
            
                queryrresults.save().then((photodata) => {
                });*/
                }
            })
    }
    checkChallengeAvailable(challenge_id) {
      var query = new Parse.Query('Photo');
      query.equalTo("objectId", challenge_id);
      query.include('user');
      var self = this;
        query.first().then(function(result){
            if(result){
                let challengeStatus=result.get('challengeStatus');
                let challengeTitle=result.get('challengeTitle');
                let businessAccount=result.get('businessAccount');
                let prize=result.get('prize');
                let challengeAmount=result.get('challengeAmount');
                let name=result.get('user').get('displayName')?result.get('user').get('displayName'):result.get('user').get('username');
                let endDate=result.get('endDate')?result.get('endDate'):'';
                let totalCost = result.get('totalCost');
                let caption=result.get('caption');
                let username=result.get('user').get('username')?result.get('user').get('username'):'';
                //let isPaid = result.get('isPaid')?result.get('isPaid'):'';
                
                if(prize!=='')
                {
                     self.setState({prize: prize});
                }
               
                if(challengeAmount!=='')
                {
                    self.setState({challengeAmount: challengeAmount});
                }
                if(totalCost!=='')
                {
                    
                    self.setState({totalCost: totalCost});
                    self.setState({amount: totalCost});
                }
               
                if(caption!=='')
                {
                     self.setState({caption: caption});
                }
                if(name!=='')
                {
                     self.setState({name: name});
                }
                if(username!=='')
                {
                     self.setState({username: username});
                }
                if(endDate!=='')
                {
                     self.setState({endDate: endDate});
                }
                
                //Your card number is incomplete.
                
              
              
            }else{
                window.location.href = "/business/challenges";
            }
        })
    }

  handleAmountChange = (event) => {
    this.setState({ amount: event.target.value });
    this.setState({totalCost: event.target.value});
  };


 /* async createPaymentIntent() {
    const stripeSecretKey = this.state.stripeSecretKey;

    const response = await fetch('https://api.stripe.com/v1/payment_intents', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${stripeSecretKey}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        amount: this.state.amount+'00',
        currency: 'usd',
      }),
    });
    const paymentIntent = await response.json();
    this.setState({ clientSecrets: paymentIntent.client_secret });
  }*/

  handleSubmit = async (event) => {
    this.setState({ loading: true });
    event.preventDefault();
    const { stripe, elements } = this.props;
    var challengemainid=this.state.challengemainid;

    if (!stripe || !elements) {
      return;
    }

  const stripeSecretKey = this.state.clientSecret;

    const response = await fetch('https://api.stripe.com/v1/payment_intents', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${stripeSecretKey}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        amount: this.state.amount+'00',
        currency: 'usd',
        //payment_method_types: ['card'],
        //metadata
       
      }),
    });
    const paymentIntents = await response.json();
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);

    const { error, paymentIntent } = await stripe.confirmCardPayment(paymentIntents.client_secret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: this.state.name,
          /*email: this.state.email,*/
        },
        /*metadata:{
           challenge_amount:  this.state.challengeAmount,
           total_cost: this.state.totalCost,
           caption: this.state.caption,
           name: this.state.name,
           end_date: this.state.endDate
        }*/
      },
    });

    if (error) {
        //console.log('[error]', error);    
         this.setState({ loading: false });  
        this.setState({ error_message: error.message });
        //console.log(error.message);
        setTimeout(() => {
           this.setState({ error_message: '' });
        }, 10000);
    } else if (paymentIntent.status === 'succeeded') {
      //console.log('Payment successful');
        this.setState({ loading: true });   

      var query = new Parse.Query('Photo');
      query.equalTo("objectId", this.challenge_id);
      query.include('user');
      let lastinvoiceNumber=this.state.lastinvoiceNumber;
      var self = this;
        query.first().then(function(result){
            if(result){

                let challengeStatus=result.get('challengeStatus');
                let challengeTitle=result.get('challengeTitle');
                let businessAccount=result.get('businessAccount');
                let prize=result.get('prize');
                let challengeAmount=result.get('challengeAmount');
                let name=result.get('user').get('displayName')?result.get('user').get('displayName'):result.get('user').get('username');
                let endDate=result.get('endDate')?result.get('endDate'):'';
                let totalCost = result.get('totalCost');
                let caption=result.get('caption');
               
                result.set("challengeStatus", "Active");    
                result.set("isPaid", "1"); 
                result.set('invoiceNumber', lastinvoiceNumber);
                //payment
                var Payment = Parse.Object.extend("Payment");
                var payment = new Payment();
                let currentUser = Parse.User.current(); 
                let currentUser_id=currentUser.id; 
                payment.set('challenge_amount',challengeAmount);
                payment.set('prize_amount',prize);
                payment.set('total_amount',totalCost);
                payment.set('pay_to','Joel`s Kitchen');
                payment.set('user',currentUser);
                
                payment.set('photo', result);
                payment.set('due_date', endDate);
                payment.set('payment_id', paymentIntent.id);
                 

                payment.save().then((paymentFile) => {

                    

                }).catch((error) => {
                }); 
                 result.set("payment", payment); 

                
                 result.save().then((photodata) => {
                    self.toggleNotification(challengemainid,'Your challenge is approved','');
                    self.setState({isNotificationOpen:false,post_Notification_Id:'',post_Notification_Msg:'',post_Notification_Comment:''});
                    self.toggleNotification(challengemainid,'created a challenge','');
                    self.setState({isNotificationOpen:false,post_Notification_Id:'',post_Notification_Msg:'',post_Notification_Comment:''});
                 })   
                  self.setState({lastinvoiceNumber: lastinvoiceNumber});
            }
        })

      $('.payment_card.payment_page').attr("style", "display: none");
      $('.payment_card.success_page').attr("style", "display: block");
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const {totalCost, challengeAmount, prize,  name,endDate,username,amount,isLoading,lastinvoiceNumber,loading } = this.state;
    var isNotificationOpen=this.state.isNotificationOpen; 
    var post_Notification_Ids=this.state.post_Notification_Id; 
    var post_Notification_Msg=this.state.post_Notification_Msg; 
    var post_Notification_Comment=this.state.post_Notification_Comment;
    return (
     <div className="payment_page_main">  {isNotificationOpen?<SaveNotification   isOpen={isNotificationOpen}  postNotificationid={post_Notification_Ids} postMsg={post_Notification_Msg} postPhotoid={post_Notification_Ids} postActivityComment={post_Notification_Comment}  />:""}
{/* test */}
        <div className='payment_mn'>
            <div className='payment_card payment_page' style={{'margin-bottom':'50px'}}>
            {loading && <div className="loading_img"  style={{'position': "absolute", 'top': "50%", 'left': "50%",'transform':"translate(-50%, -50%)"}}   ><img src="/images/loader.gif" /></div>}
                <div className='inner'>
                    <div className='strp-paymnt-page chlng-paymnt-decline-page' >
                        <div className='top_part'>
                        <div class="pytm_tlt">

                        <h2>{this.state.error_message} </h2>
                        </div>
                            <div className='pytm_amunt'>
                
                                <label>Due now <span>${/*1,500*/}{totalCost? totalCost.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></label>
                            </div>
                        </div>
                        <div className='middle_part'>   
                            <aside className='box'>
                                <h4>Your challenge is ready for payment!</h4>
                                <ul >
                                    <li><label className='title'>Challenge Amount</label><span className='value'>${/*1,000.00*/}{challengeAmount? challengeAmount.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></li>
                                    <li><label className='title'>Prize Amount</label><span className='value'>${/*500.00*/}{prize? prize.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></li>
                                    <li className='total'><label className='title'>Total</label><span className='value'>${/*1,500.00*/}{totalCost? totalCost.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></li>
                                </ul>
                            </aside>
                            <aside className='box'>
                                <h4>Payment Details</h4>
                                <ul>
                                    <li><label className='title'>Pay to</label><span className='value'>Joel’s Kitchen</span></li>
                                    <li><label className='title'>Customer Name</label><span className='value'>{name}</span></li>
                                    <li><label className='title'>Invoice number</label><span className='value'>{lastinvoiceNumber}</span></li>
                                    <li><label className='title'>Due date</label><span className='value'>{endDate}</span></li>
                                </ul>
                            </aside>
                        </div>
                        <div className='bottom_part'>
                            <div className='action_mn'>
                                
<form onSubmit={this.handleSubmit}>
<label>
<input
type="text"
    style={{'display':'none'}}
value={name}      
placeholder="Name"
/>
<input
type="text"
value={username}      
    style={{'display':'none'}}
placeholder="username"
/>
<input
    type="number"
    value={amount}
    style={{'display':'none'}}
    onChange={this.handleAmountChange}
    disabled={isLoading}
/>
</label>
<CardElement />
<button type="submit" disabled={!this.props.stripe}>
{isLoading ? 'Processing…' : <a ><img src="/images/pymt-lock.svg" alt="Payment Lock"/>Pay ${amount? amount.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''} Now</a>}
</button>
</form>
                            </div>
                            <div className='strip_mn'>
                                <img src="/images/pymt-strip.png" alt="Powered by stripe"/>
                            </div>
                            <p>Questions? <a href='javascript:void(0);' title='Contact us'>Contact us.</a></p>
                        </div>
                    </div>
                    <div className='chlng-paymnt-accunt-page success_payment' style={{"display":"none"}}>
                        <div className='top_part'>
                            <div className='pytm_tlt'>
                                <h2>Thank you for your Payment!</h2>
                            </div>
                        </div>
                        <div className='middle_part'>
                            <aside className='box'>
                                <h4>Payment Amount</h4>
                                <div className='top_part pytm_amunt'>
                                    <label><span>${/*1,500*/}{totalCost? totalCost.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></label>
                                </div>
                                    <ul >
                                    <li><label className='title'>Challenge Amount</label><span className='value'>${/*1,000.00*/}{challengeAmount? challengeAmount.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></li>
                                    <li><label className='title'>Prize Amount</label><span className='value'>${/*500.00*/}{prize? prize.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></li>
                                    <li className='total'><label className='title'>Total</label><span className='value'>${/*1,500.00*/}{totalCost? totalCost.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></li>
                                </ul>
                            </aside>
                            <aside className='box'>
                                <h4>Payment Details</h4>
                                    <ul>
                                    <li><label className='title'>Pay to</label><span className='value'>Joel’s Kitchen</span></li>
                                    <li><label className='title'>Customer Name</label><span className='value'>{name}</span></li>
                                    <li><label className='title'>Invoice number</label><span className='value'>{lastinvoiceNumber}</span></li>
                                    <li><label className='title'>Due date</label><span className='value'>{endDate}</span></li>
                                </ul>
                            </aside>
                        </div>
                        <div className='bottom_part'>
                            <div className='action_mn'>
                                <div className='cls-btn'>
                                    <a href='/business/challenges' title='Close Page'>Close Page</a>
                                </div>
                                <div className='takeme-btn'>
                                    <a href='/business/challenges' title='Take me to Joel’s Kitchen'>Take me to Joel’s Kitchen</a>
                                </div>
                            </div>
                            <p>Questions? <a href='javascript:void(0);' title='Contact us'>Contact us.</a></p>
                        </div>
                    </div>
                    <div className='chlng-paymnt-decline-page' style={{"display":"none"}}>
                        <div className='top_part'>
                            <div className='pytm_tlt'>
                                <h2>Thank you for your Payment!</h2>
                            </div>
                            <div className='pytm_amunt' style={{"display":"none"}}>
                                <label>Due now <span>${/*1,500*/}{totalCost? totalCost.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></label>
                            </div>
                        </div>
                        <div className='middle_part'>   

                            <aside className='box'>
                                <h4>Your challenge is ready for payment!</h4>
                                <ul >
                                    <li><label className='title'>Challenge Amount</label><span className='value'>$1,000.00</span></li>
                                    <li><label className='title'>Prize Amount</label><span className='value'>$500.00</span></li>
                                    <li className='total'><label className='title'>Total</label><span className='value'>$1,500.00</span></li>
                                </ul>
                            </aside>
                            <aside className='box'>
                                <h4>Payment Details</h4>
                                <ul>
                                    <li><label className='title'>Pay to</label><span className='value'>Joel’s Kitchen</span></li>
                                    <li><label className='title'>Customer Name</label><span className='value'>Bob’s Red Mill</span></li>
                                    <li><label className='title'>Invoice number</label><span className='value'>{lastinvoiceNumber}</span></li>
                                    <li><label className='title'>Due date</label><span className='value'>May 24, 2024</span></li>
                                </ul>
                            </aside>


                        </div>

                        <div className='bottom_part'>

                            <div className='action_mn'>

                                <a href='javascript:void(0);'><img src="/images/pymt-lock.svg" alt="Payment Lock"/>Pay $1,500 Now</a>




                            </div>
                            <div className='strip_mn'>
                                <img src="/images/pymt-strip.png" alt="Powered by stripe"/>
                            </div>
                            <p>Questions? <a href='javascript:void(0);' title='Contact us'>Contact us.</a></p>
                        </div>
                    </div>





                </div>
            </div>
            <div className='payment_card success_page' style={{"display":"none","margin-bottom":"50px"}}>
                <div className='inner'>
                    <div className='strp-paymnt-page' style={{"display":"none"}}>
                        <div className='top_part'>
                            <div className='pytm_tlt'>
                                <h2>Thank you for your Payment!</h2>
                            </div>
                            <div className='pytm_amunt' style={{"display":"none"}}>
                                <label>Due now <span>${totalCost? totalCost.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></label>
                            </div>
                        </div>
                        <div className='middle_part'>   
                            <aside className='box'>
                                <h4>Your challenge is ready for payment!</h4>
                                    <ul >
                                    <li><label className='title'>Challenge Amount</label><span className='value'>${/*1,000.00*/}{challengeAmount? challengeAmount.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></li>
                                    <li><label className='title'>Prize Amount</label><span className='value'>${/*500.00*/}{prize? prize.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></li>
                                    <li className='total'><label className='title'>Total</label><span className='value'>${/*1,500.00*/}{totalCost? totalCost.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></li>
                                </ul>
                            </aside>
                            <aside className='box'>
                                <h4>Payment Details</h4>
                                <ul>
                                    <li><label className='title'>Pay to</label><span className='value'>Joel’s Kitchen</span></li>
                                    <li><label className='title'>Customer Name</label><span className='value'>{name}</span></li>
                                    <li><label className='title'>Invoice number</label><span className='value'>{lastinvoiceNumber}</span></li>
                                    <li><label className='title'>Due date</label><span className='value'>{endDate}</span></li>
                                </ul>
                            </aside>
                        </div>
                        <div className='bottom_part'>
                            <div className='action_mn'>
                                <a href='javascript:void(0);'><img src="/images/pymt-lock.svg" alt="Payment Lock"/>Pay $1,500 Now</a>
                            </div>
                            <div className='strip_mn'>
                                <img src="/images/pymt-strip.png" alt="Powered by stripe"/>
                            </div>
                            <p>Questions? <a href='javascript:void(0);' title='Contact us'>Contact us.</a></p>
                        </div>
                    </div>
                    <div className='chlng-paymnt-accunt-page' >
                        <div className='top_part'>
                            <div className='pytm_tlt'>
                                <h2>Thank you for your Payment!</h2>
                            </div>
                        </div>
                        <div className='middle_part'>
                            <aside className='box'>
                                <h4>Payment Amount</h4>
                                <div className='top_part pytm_amunt'>
                                    <label><span>${totalCost? totalCost.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></label>
                                </div>
                                <ul style={{"display":"none"}}>
                                        <li><label className='title'>Challenge Amount</label><span className='value'>${/*1,000.00*/}{challengeAmount? challengeAmount.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></li>
                                    <li><label className='title'>Prize Amount</label><span className='value'>${/*500.00*/}{prize? prize.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></li>
                                    <li className='total'><label className='title'>Total</label><span className='value'>${/*1,500.00*/}{totalCost? totalCost.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></li>
                                </ul>
                            </aside>
                            <aside className='box'>
                                <h4>Payment Details</h4>
                                <ul>
                                    <li><label className='title'>Pay to</label><span className='value'>Joel’s Kitchen</span></li>
                                    <li><label className='title'>Customer Name</label><span className='value'>{name}</span></li>
                                    <li><label className='title'>Invoice number</label><span className='value'>{lastinvoiceNumber}</span></li>
                                    <li><label className='title'>Due date</label><span className='value'>{endDate}</span></li>
                                </ul>
                            </aside>
                        </div>
                        <div className='bottom_part'>
                            <div className='action_mn'>
                                <div className='cls-btn'>
                                    <a href='/business/challenges' title='Close Page'>Close Page</a>
                                </div>
                                <div className='takeme-btn'>
                                    <a href='/business/challenges' title='Take me to Joel’s Kitchen'>Take me to Joel’s Kitchen</a>
                                </div>
                            </div>
                            <p>Questions? <a href='javascript:void(0);' title='Contact us'>Contact us.</a></p>
                        </div>
                    </div>
                    <div className='chlng-paymnt-decline-page' style={{"display":"none"}}>
                        <div className='top_part'>
                            <div className='pytm_tlt'>
                                <h2>Thank you for your Payment!</h2>
                            </div>
                            <div className='pytm_amunt' style={{"display":"none"}}>
                                <label>Due now <span>${totalCost? totalCost.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></label>
                            </div>
                        </div>
                        <div className='middle_part'>   
                            <aside className='box'>
                                <h4>Your challenge is ready for payment!</h4>
                                <ul >
                                    <li><label className='title'>Challenge Amount</label><span className='value'>${/*1,000.00*/}{challengeAmount? challengeAmount.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></li>
                                    <li><label className='title'>Prize Amount</label><span className='value'>${/*500.00*/}{prize? prize.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></li>
                                    <li className='total'><label className='title'>Total</label><span className='value'>${/*1,500.00*/}{totalCost? totalCost.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></li>
                                </ul>
                            </aside>
                            <aside className='box'>
                                <h4>Payment Details</h4>
                                <ul>
                                    <li><label className='title'>Pay to</label><span className='value'>Joel’s Kitchen</span></li>
                                    <li><label className='title'>Customer Name</label><span className='value'>{name}</span></li>
                                    <li><label className='title'>Invoice number</label><span className='value'>{lastinvoiceNumber}</span></li>
                                    <li><label className='title'>Due date</label><span className='value'>{endDate}</span></li>
                                </ul>
                            </aside>
                        </div>
                        <div className='bottom_part'>
                            <div className='action_mn'>
                                <a href='javascript:void(0);'><img src="/images/pymt-lock.svg" alt="Payment Lock"/>Pay {totalCost? totalCost.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''} Now</a>
                            </div>
                            <div className='strip_mn'>
                                <img src="/images/pymt-strip.png" alt="Powered by stripe"/>
                            </div>
                            <p>Questions? <a href='javascript:void(0);' title='Contact us'>Contact us.</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='payment_card failed_page' style={{"display":"none","margin-bottom":"50px"}}>
                <div className='inner'>
                    <div className='strp-paymnt-page' style={{"display":"none"}}>
                        <div className='top_part'>
                            <div className='pytm_tlt'>
                                <h2>Your payment has been declined. Please try again. </h2>
                            </div>
                            <div className='pytm_amunt' style={{"display":"none"}}>
                                <label>Due now <span>${totalCost? totalCost.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></label>
                            </div>
                        </div>
                        <div className='middle_part'>   
                            <aside className='box'>
                                <h4>Your challenge is ready for payment!</h4>
                                <ul >
                                    <li><label className='title'>Challenge Amount</label><span className='value'>${/*1,000.00*/}{challengeAmount? challengeAmount.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></li>
                                    <li><label className='title'>Prize Amount</label><span className='value'>${/*500.00*/}{prize? prize.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></li>
                                    <li className='total'><label className='title'>Total</label><span className='value'>${/*1,500.00*/}{totalCost? totalCost.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></li>
                                </ul>
                            </aside>
                            <aside className='box'>
                                <h4>Payment Details</h4>
                                    <ul>
                                    <li><label className='title'>Pay to</label><span className='value'>Joel’s Kitchen</span></li>
                                    <li><label className='title'>Customer Name</label><span className='value'>{name}</span></li>
                                    <li><label className='title'>Invoice number</label><span className='value'>{lastinvoiceNumber}</span></li>
                                    <li><label className='title'>Due date</label><span className='value'>{endDate}</span></li>
                                </ul>
                            </aside>
                        </div>
                        <div className='bottom_part'>
                            <div className='action_mn'>
                                <a href='javascript:void(0);'><img src="/images/pymt-lock.svg" alt="Payment Lock"/>Pay ${totalCost? totalCost.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''} Now</a>
                            </div>
                            <div className='strip_mn'>
                                <img src="/images/pymt-strip.png" alt="Powered by stripe"/>
                            </div>
                            <p>Questions? <a href='javascript:void(0);' title='Contact us'>Contact us.</a></p>
                        </div>
                    </div>



                    <div className='chlng-paymnt-accunt-page failed_payment' style={{"display":"none"}} >
                        <div className='top_part'>
                            <div className='pytm_tlt'>
                                <h2>Thank you for your Payment!</h2>
                            </div>
                        </div>
                        <div className='middle_part'>
                            <aside className='box'>
                                <h4>Payment Amount</h4>
                                <div className='top_part pytm_amunt'>
                                    <label><span>${/*1,500*/}{totalCost? totalCost.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></label>
                                </div>
                                <ul >
                                    <li><label className='title'>Challenge Amount</label><span className='value'>${/*1,000.00*/}{challengeAmount? challengeAmount.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></li>
                                    <li><label className='title'>Prize Amount</label><span className='value'>${/*500.00*/}{prize? prize.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></li>
                                    <li className='total'><label className='title'>Total</label><span className='value'>${/*1,500.00*/}{totalCost? totalCost.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></li>
                                </ul>
                            </aside>
                            <aside className='box'>
                                <h4>Payment Details</h4>
                                <ul>
                                    <li><label className='title'>Pay to</label><span className='value'>Joel’s Kitchen</span></li>
                                    <li><label className='title'>Customer Name</label><span className='value'>{name}</span></li>
                                    <li><label className='title'>Invoice number</label><span className='value'>{lastinvoiceNumber}</span></li>
                                    <li><label className='title'>Due date</label><span className='value'>{endDate}</span></li>
                                </ul>
                            </aside>
                        </div>
                        <div className='bottom_part'>
                            <div className='action_mn'>
                                <div className='cls-btn'>
                                    <a href='/business/challenges' title='Close Page'>Close Page</a>
                                </div>
                                <div className='takeme-btn'>
                                    <a href='/business/challenges' title='Take me to Joel’s Kitchen'>Take me to Joel’s Kitchen</a>
                                </div>
                            </div>
                            <p>Questions? <a href='javascript:void(0);' title='Contact us'>Contact us.</a></p>
                        </div>
                    </div>
                    <div className='chlng-paymnt-decline-page' >
                        <div className='top_part'>
                            <div className='pytm_tlt'>
                                <h2>Your payment has been declined. <br />Please try again. </h2>
                            </div>
                            <div className='pytm_amunt' style={{"display":"none"}}>
                                <label>Due now <span>${totalCost? totalCost.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></label>
                            </div>
                        </div>
                        <div className='middle_part'>   
                            <aside className='box'>
                                <h4>Your challenge is ready for payment!</h4>
                                <ul >
                                    <li><label className='title'>Challenge Amount</label><span className='value'>${/*1,000.00*/}{challengeAmount? challengeAmount.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></li>
                                    <li><label className='title'>Prize Amount</label><span className='value'>${/*500.00*/}{prize? prize.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></li>
                                    <li className='total'><label className='title'>Total</label><span className='value'>${/*1,500.00*/}{totalCost? totalCost.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></li>
                                </ul>
                            </aside>
                            <aside className='box'>
                                <h4>Payment Details</h4>
                                <ul>
                                    <li><label className='title'>Pay to</label><span className='value'>Joel’s Kitchen</span></li>
                                    <li><label className='title'>Customer Name</label><span className='value'>{name}</span></li>
                                    <li><label className='title'>Invoice number</label><span className='value'>{lastinvoiceNumber}</span></li>
                                    <li><label className='title'>Due date</label><span className='value'>{endDate}</span></li>
                                </ul>
                            </aside>
                        </div>
                        <div className='bottom_part'>
                            <div className='action_mn'>
                                <a href='javascript:void(0);'><img src="/images/pymt-lock.svg" alt="Payment Lock"/>Pay $1,500 Now</a>


                            </div>
                            <div className='strip_mn'>
                                <img src="/images/pymt-strip.png" alt="Powered by stripe"/>
                            </div>
                            <p>Questions? <a href='javascript:void(0);' title='Contact us'>Contact us.</a></p>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

{/*test 1*/}

    
    </div>
    );
  }
}

const InjectedCheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  return <CheckoutForm stripe={stripe} elements={elements} />;
};

const Makepayment = () => {
  return (
    <Elements stripe={stripePromise}>
      <InjectedCheckoutForm />
    </Elements>
  );
};

export default Makepayment;