import '../global.js'
import React, { useState, useRef } from 'react';
import Parse from 'parse';
import ReactTable from "react-table-v6";
import 'react-table-v6/react-table.css'
import Postcounts from "./postcounts.jsx";
class Custompagination extends React.Component {
    constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      page: 0,
      pageSize: 10,
      reactTableData: [],
    };
  }

  
  renderPostCount(creator)
    {
        const querydata = new Parse.Query('Photo');
        querydata.equalTo("user", creator);
        let postuser_id=creator.id;
        console.log('creator.id'+creator.id);
        querydata.find().then(function(result){
            // console.log('result result');
            // console.log(result);
            return result.length;
            // console.log(result.length+'creator'+postuser_id);
            // $('#postid_'+postuser_id).html(result.length);

        }
        )
    }
  

   componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    // Simulate an async data fetch
   // setTimeout(() => {
        var query = new Parse.Query('_User');
        query.limit(99999999999999999999999999);
        query.equalTo("isCreator", 1);  
        query.include('Photo');      
        query.include("profilePictureSmall");
        query.include("displayName");
        query.include("recipeRate");
        query.include("videoRate");
        query.include("photoRate");
        query.include("instagram");
        query.include("facebook");
        query.include("pinterest");
        query.include("tiktok");
        query.include("youtube");
        query.include("website");
        query.include("name");
        query.include("email");
        query.include("userStats");
        
        query.include("userStats.instaFollowers");
        query.descending("createdAt");

        var self = this;


        query.find().then(function(result){
            if(result){


                var query = new Parse.Query('RecipeV2');
                {
                   // self.setState({recipes: result});

                    //self.reactTableData = [];


                    var newReactTableData = [];
                    var userEmailArr = [];
                    var theUserEmails = "";

                    for (var i = 0; i < result.length; i++) {

                        var user = result[i];
                        var wrapper = {};

                        wrapper.pic = user.get("profilePictureSmall");
                        wrapper.thisUserAccount=user;
                        wrapper.postsViews=user.get("imprcount")?user.get("imprcount"):'0';
                        wrapper.engagementsPosts=user.get("impr")?user.get("impr"):'0';

                          var followerProps = {
                                    followerCount: user.get("userStats") ? user.get("userStats").get("instaFollowers") : "",
                                    followerChange: user.get("userStats") ? user.get("userStats").get("instaFollowersChange") : "",
                                    abbrev: false
                                }
                        var followerPropcount= '';  //self.renderFollowerCount(followerProps);
                       // wrapper.followersPosts=followerPropcount;
                        var sociallinks='';//self.renderSocial(user);
                        wrapper.socialLinks=sociallinks;
                        var useremail=user.get("username");
                       
                        wrapper.createrEmail=useremail;

                        var postCounts='';//self.renderPostCount(user);
                        const userPosts =self.renderPostCount(user);; 

                         var posts_query = new Parse.Query('Photo');
       // posts_query.equalTo('_p_user', "_User$"+user.id);
        posts_query.equalTo('user', user);
        posts_query.count({
          success: function(count) {
            console.log(count+'count');
          wrapper.followersPosts=count;
          },
          error: function(error) {
            // The request failed
          }
        });
                         wrapper.posts=user;


                        var username = user.get("displayUsername");
                        var name = user.get("displayName");
                        var bio = user.get("bio");
                        var website = user.get("website");
                        var email = user.get("username");

                        if (bio && (bio.length > 64))
                        {
                            bio = bio.slice(0, 64);
                            bio = bio + "...";
                        }
                        
                        wrapper.username = username;
                        wrapper.name = name;
                        wrapper.bio = bio;

                        wrapper.website = website;
                        wrapper.city = user.get("city");;
                        wrapper.email = email;

                        wrapper.instagram = user.get("instagram");
                        wrapper.tiktok = user.get("tiktok");
                        wrapper.youtube = user.get("youtube");

                       
                        wrapper.lastModified = user.get("updatedAt");
                        wrapper.createdAt = user.get("createdAt");
                        wrapper.startDate = user.get("startDate");
                        wrapper.endDate = user.get("endDate");
                        wrapper.createdAtStr = ((wrapper.createdAt.getMonth()) + "/" + (wrapper.createdAt.getDate()) + "/" + (wrapper.createdAt.getFullYear() % 100));
                        wrapper.lastModifiedStr = ((wrapper.lastModified.getMonth()) + "/" + (wrapper.lastModified.getDate()) + "/" + (wrapper.lastModified.getFullYear() % 100) );
                    
                        
                        const wrapperCopy = wrapper;
                        var shouldAdd;
  
                        shouldAdd = true; 


                        if (shouldAdd)
                        {
                     
                            self.state.reactTableData.push(wrapperCopy);
                                
                            newReactTableData.push(wrapper);
                            // },1000);
                        }

                        const copy = self.state.reactTableData;

                     

                        if ((email) && (email.includes("@")))
                           userEmailArr.push(email);
                    };

                    self.setState({reactTableData:newReactTableData});
 
                     

                    /*userEmailArr.sort(); //alphabetical

                    for (var i = 0; i < userEmailArr.length; i++)
                    {
                        theUserEmails = theUserEmails + userEmailArr[i] + "\r\n";
                       
                    }*/

                    //self.setState({userEmails:theUserEmails});

                }
             
            } else {
                console.log("No recipes found");
               // promise.resolve(null);
            }
        }, function(error){
                console.error("Error searching for recipes  Error: " + error);
                //promise.error(error);
        });
      const  data=this.state.reactTableData;
      this.setState({data, loading: false });
    //}, 2000);
  }

  handlePageChange = (pageIndex) => {
    this.setState({ page: pageIndex }, () => {
      this.fetchData();
    });
  };

  render() {
    const { data, loading, page, pageSize } = this.state;
   

    var columns = [
       {
            Header: 'Collaborate',
            width: 120,
            //accessor: 'recipe', // String-based value accessors!
            id: 'thisUser',
            accessor: 'thisUser',
            Cell: props => <div class="chlngThumb">
                <input type="checkbox" name="checkbox" id="checkbox_id" value="value" />
                <label htmlFor="checkbox_id"></label>
            </div>
          },{
            Header: 'Account',
            width: 190,
            //accessor: 'recipe', // String-based value accessors!
            id: 'thisUserAccount',
            accessor: 'thisUserAccount',
            Cell: props => <div><div class="chlngThumb"><a href='javascript:void(0);'><img class="userProfileImageCreator" src={props.value != undefined ?
                        (props.value.get("profilePictureSmall") != undefined ? props.value.get("profilePictureSmall").url() : "/images/genericuser.png" ) : ""}/><span>creatoraccount</span></a></div><span><a href={props.value.get("displayUsername")?"https://www.joelskitchen.com/user/"+props.value.get("displayUsername"):''}>{props.value.get("displayUsername")?props.value.get("displayUsername"):''}</a></span></div>
          }, {
            Header: 'Posts',
            width: 100,
            //accessor: 'recipe', // String-based value accessors!
            id: 'posts',
            accessor: 'posts' , // Custom value accessors! Followerscounts
            Cell: props =><div className='adminUserData'><Postcounts postData={props.value} /></div>
          }, {
            Header: 'Views',
            width: 100,
            //accessor: 'recipe', // String-based value accessors!
            id: 'postsViews',
            accessor: 'postsViews' , // Custom value accessors!
            Cell: props =><div className='adminUserData'>{props.value}</div> 
          },{
            Header: 'Engagements',
            width: 110,
            //accessor: 'recipe', // String-based value accessors!
            id: 'engagementsPosts',
            accessor: 'engagementsPosts' , // Custom value accessors!
            Cell: props =><div className='adminUserData'>{props.value}</div>
          }, {
            Header: 'Followers',
            width: 100,
            //accessor: 'recipe', // String-based value accessors!
            id: 'followersPosts',
            accessor: 'followersPosts' , // Custom value accessors!
            Cell: props =><div className='adminUserData'>{props.value}{/*<Followerscounts postDataUser={props.value} />*/}{/*{props.value}*/}</div>
        }, {
            Header: 'Social Link',
            width: 110,
            //accessor: 'recipe', // String-based value accessors!
            id: 'socialLinks',
            accessor: 'socialLinks' , // Custom value accessors!
            Cell: props => <div className='adminUserData'>{props.value}</div>
          }, {
            Header: 'Email',
            width: 220,
            //accessor: 'recipe', // String-based value accessors!
            id: 'createrEmail',
            accessor: 'createrEmail' , // Custom value accessors!
            Cell: props => <div className='adminUserData'><a href={"mailto:"+props.value}>{props.value} <img src="images/social/website.png" width="20" height="20"></img><i class="bi bi-envelope"></i></a></div>
          }];

    return (
      <div>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <ReactTable
            data={data}
            columns={columns}
            manual
            defaultPageSize={10}
            className="-striped -highlight"
            page={page}
            onPageChange={this.handlePageChange}
          />
        )}
      </div>
    );
  }
}

export default Custompagination;