import '../global.js'
import React, { Component } from 'react';
import Parse from 'parse';
import ReactTable from "react-table-v6";

class Creatordirectorytable extends React.Component {
    constructor(props) {
      super(props);
      this.state = { };
      let currentUser = Parse.User.current();   
        
        if(!currentUser || (currentUser.get('isAdmin')!==undefined && currentUser.get('isAdmin')!=="1"))
        {
            window.location.href = "/";
        }
    }
    render() {
      return (
        <div className="account_main_top kpi-listing">
          <div className="inner_wrap">
            <div className="table_top_searchbar post_topbar">
              <div className='left-part'>
                <h2>Creator Directory</h2> 
              </div>
            </div>
            
            
            <div className='admin_table_wrapper'>
              <div className='admin_table_inner'>
              <div class="admin_table">
                <table class="creatorTable table-responsive">
                  <thead>
                    <tr>
                    <th class="picture">Picture</th>
                    <th class="views">Title</th>
                    <th class="status">Status</th>
                    <th class="business">Business Account</th>
                    <th class="engagements">Last Modified</th>
                    <th class="platforms">Start Date</th>
                    <th class="creator">End Date</th>
                    <th class="job">Job Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="user_image">
                          <a href="javascript:void(0);">
                            <span class="thumb">
                              <img class="userProfileImageCreator" src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/4ff7b2c290ad4bb8ef4dd0ed1b53cee9_image.jpg" />
                            </span>
                          </a>
                        </div>
                      </td>
                      <td><a href="https://www.joelskitchen.com/user/direliw418_dev">direliw418_dev</a></td>
                      <td class="status">
                        <div class="adminUserData"><span class="stat-badges st-complete">Complete</span></div>
                      </td>
                      <td><a href="https://www.joelskitchen.com/user/joelskitchen">joelskitchen</a></td>
                      <td class="postedon">7/7/24</td>
                      <td class="postedon">7/7/24</td>
                      <td class="postedon">31/7/24</td>                      
                      <td>123</td>
                    </tr>
                    <tr>
                      <td>
                        <div class="user_image">
                          <a href="javascript:void(0);">
                            <span class="thumb">
                              <img class="userProfileImageCreator" src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/4ff7b2c290ad4bb8ef4dd0ed1b53cee9_image.jpg" />
                            </span>
                          </a>
                        </div>
                      </td>
                      <td><a href="https://www.joelskitchen.com/user/direliw418_dev">direliw418_dev</a></td>
                      <td class="status">
                        <div class="adminUserData"><span class="stat-badges st-pending">Pending</span></div>
                      </td>
                      <td><a href="https://www.joelskitchen.com/user/joelskitchen">joelskitchen</a></td>
                      <td class="postedon">7/7/24</td>
                      <td class="postedon">7/7/24</td>
                      <td class="postedon">31/7/24</td>                      
                      <td>123</td>
                    </tr>
                    <tr>
                      <td>
                        <div class="user_image">
                          <a href="javascript:void(0);">
                            <span class="thumb">
                              <img class="userProfileImageCreator" src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/4ff7b2c290ad4bb8ef4dd0ed1b53cee9_image.jpg" />
                            </span>
                          </a>
                        </div>
                      </td>
                      <td><a href="https://www.joelskitchen.com/user/direliw418_dev">direliw418_dev</a></td>
                      <td class="status">
                        <div class="adminUserData"><span class="stat-badges st-complete">Complete</span></div>
                      </td>
                      <td><a href="https://www.joelskitchen.com/user/joelskitchen">joelskitchen</a></td>
                      <td class="postedon">7/7/24</td>
                      <td class="postedon">7/7/24</td>
                      <td class="postedon">31/7/24</td>                      
                      <td>123</td>
                    </tr>
                    <tr>
                      <td>
                        <div class="user_image">
                          <a href="javascript:void(0);">
                            <span class="thumb">
                              <img class="userProfileImageCreator" src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/4ff7b2c290ad4bb8ef4dd0ed1b53cee9_image.jpg" />
                            </span>
                          </a>
                        </div>
                      </td>
                      <td><a href="https://www.joelskitchen.com/user/direliw418_dev">direliw418_dev</a></td>
                      <td class="status">
                        <div class="adminUserData"><span class="stat-badges st-pending">Pending</span></div>
                      </td>
                      <td><a href="https://www.joelskitchen.com/user/joelskitchen">joelskitchen</a></td>
                      <td class="postedon">7/7/24</td>
                      <td class="postedon">7/7/24</td>
                      <td class="postedon">31/7/24</td>                      
                      <td>123</td>
                    </tr>
                    <tr>
                      <td>
                        <div class="user_image">
                          <a href="javascript:void(0);">
                            <span class="thumb">
                              <img class="userProfileImageCreator" src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/4ff7b2c290ad4bb8ef4dd0ed1b53cee9_image.jpg" />
                            </span>
                          </a>
                        </div>
                      </td>
                      <td><a href="https://www.joelskitchen.com/user/direliw418_dev">direliw418_dev</a></td>
                      <td class="status">
                        <div class="adminUserData"><span class="stat-badges st-complete">Complete</span></div>
                      </td>
                      <td><a href="https://www.joelskitchen.com/user/joelskitchen">joelskitchen</a></td>
                      <td class="postedon">7/7/24</td>
                      <td class="postedon">7/7/24</td>
                      <td class="postedon">31/7/24</td>                      
                      <td>123</td>
                    </tr>
                    <tr>
                      <td>
                        <div class="user_image">
                          <a href="javascript:void(0);">
                            <span class="thumb">
                              <img class="userProfileImageCreator" src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/4ff7b2c290ad4bb8ef4dd0ed1b53cee9_image.jpg" />
                            </span>
                          </a>
                        </div>
                      </td>
                      <td><a href="https://www.joelskitchen.com/user/direliw418_dev">direliw418_dev</a></td>
                      <td class="status">
                        <div class="adminUserData"><span class="stat-badges st-pending">Pending</span></div>
                      </td>
                      <td><a href="https://www.joelskitchen.com/user/joelskitchen">joelskitchen</a></td>
                      <td class="postedon">7/7/24</td>
                      <td class="postedon">7/7/24</td>
                      <td class="postedon">31/7/24</td>                      
                      <td>123</td>
                    </tr>
                    <tr>
                      <td>
                        <div class="user_image">
                          <a href="javascript:void(0);">
                            <span class="thumb">
                              <img class="userProfileImageCreator" src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/4ff7b2c290ad4bb8ef4dd0ed1b53cee9_image.jpg" />
                            </span>
                          </a>
                        </div>
                      </td>
                      <td><a href="https://www.joelskitchen.com/user/direliw418_dev">direliw418_dev</a></td>
                      <td class="status">
                        <div class="adminUserData"><span class="stat-badges st-pending">Pending</span></div>
                      </td>
                      <td><a href="https://www.joelskitchen.com/user/joelskitchen">joelskitchen</a></td>
                      <td class="postedon">7/7/24</td>
                      <td class="postedon">7/7/24</td>
                      <td class="postedon">31/7/24</td>                      
                      <td>123</td>
                    </tr>
                  </tbody>
                </table>
                <div class="pagination_bottom">
                  <button disabled="">❮</button>
                  <span> Page 1 </span>
                  <button>❯</button>
                </div>
              </div>
              </div>
            </div>
            
          </div>
        </div>
      );
    }
}


export default Creatordirectorytable;