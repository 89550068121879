import Parse from 'parse';
import React from 'react';
import $ from "jquery";
class saveNotification extends React.Component {
    constructor(props) {
        super(props);
        const currentUser = Parse.User.current();
        let postNotificationid=props.postNotificationid;
        let postPhotoid=props.postPhotoid;
        let postMsg=props.postMsg;
        let communityComment='';
        let comment='';
        let postActivityComment=props.postActivityComment;
        
        if (currentUser) { 

            var query = new Parse.Query('Photo');  
            query.equalTo('objectId', postPhotoid);
            query.include("user");
            query.find().then(function(photo){
                    if(photo)
                    {
                         let photo_ob=photo[0];
                         var photoid= photo_ob.id;
                         var photouser= photo_ob.get('user').id;
                         var Notification = Parse.Object.extend("Notification");
                         var notification = new Notification();
                         if(postMsg==='tagged you in a post')
                            {
                                communityComment=photo_ob.get('user').get("displayUsername")+"'s tagged you in a post";
                                comment="tagged you in a post";  
                                let captiondata=photo_ob.get('caption');
                                let captionusernameupdated='';
                                let captionusername='';
                                captionusername = captiondata.match(/@\w+/);
                                 if (captionusername) {
                                  captionusernameupdated=captionusername[0].substr(1);
                                } 
                               
                               // notification.set('notifiedUser', photo_ob.get('user'));
                                var userquery = new Parse.Query('User');       
                                userquery.equalTo("displayUsername", captionusernameupdated);
                                var self = this;
                                userquery.first().then(function(result){
                                    if(result){ 
                                         notification.set('notifiedUser', result);

                                               let currentUser = Parse.User.current(); 
                                                let currentUser_id=currentUser.id; 
                                                notification.set('viewed', 0);
                                                const fromUserThumb = currentUser.get('profilePictureSmall');
                                                const thumbnail = photo_ob.get('thumbnail');
                                                if(thumbnail!=undefined)
                                                 { 
                                                      notification.set('thumbnail', thumbnail);
                                                 }
                                                 
                                                
                                                notification.set('commentingUser', currentUser);
                                                if(fromUserThumb!=undefined)
                                                 { 
                                                      notification.set('profilePictureSmall', fromUserThumb);
                                                 }
                                                notification.set('photo', photo_ob);
                                                notification.set("_wperm", {
                                                  "*": {
                                                    "read": true,
                                                    "write": true 
                                                  }
                                                });
                                                 notification.set("_rperm", {
                                                  "*": {
                                                   "read": true,
                                                    "write": true 
                                                  }
                                                });
                                                 notification.set("_acl", {
                                                  "*": {
                                                    "read": true,
                                                    "write": true 
                                                  }
                                                });
                                                var acl = new Parse.ACL();
                                                acl.setPublicReadAccess(true);
                                                acl.setPublicWriteAccess(true);
                                                acl.setWriteAccess(currentUser, true); 
                                                acl.setReadAccess(currentUser, true); 
                                                notification.setACL(acl);
                                                notification.set('communityComment', communityComment);
                                                notification.set('comment', comment);
                                                notification.set('index', 0);  
                                                notification.set('webviewed', 1);             

                                                notification.save().then((likeSaved) => {
                                                     console.log('notification saved');
                                                     console.log(likeSaved);
                                                })
                                    }
                                    })    
                           

                           }else  {


                          let currentUser = Parse.User.current(); 
                          let mobile_notification_text='';
                          let mobile_notification_user='';
                          let mobile_notification_user_token='';

                           if(postMsg==='liked photo')
                            {
                                communityComment="liked "+photo_ob.get('user').get("displayUsername")+"'s post.";
                                comment="liked your post.";  
                                notification.set('notifiedUser', photo_ob.get('user'));
                                notification.set('commentingUser', currentUser);    
                                mobile_notification_text=currentUser.get("displayUsername")+' liked your post.';   
                                 mobile_notification_user=currentUser.get("displayUsername")?currentUser.get("displayUsername"):currentUser.get("displayName");
                                mobile_notification_user_token=photo_ob.get('user').get("FCMDeviceToken")?photo_ob.get('user').get("FCMDeviceToken"):'';                        
                            }else if(postMsg==='liked comment')
                            {
                                communityComment="liked "+photo_ob.get('user').get("displayUsername")+"'s post.";
                                comment="liked your comment: “"+ postActivityComment.get('content')+"”";//"liked your comment";    
                                notification.set('activity', postActivityComment);
                                notification.set('notifiedUser', postActivityComment.get('fromUser'));
                                notification.set('commentingUser', currentUser);
                                mobile_notification_text=currentUser.get("displayUsername")+" liked your comment: “"+ postActivityComment.get('content')+"”";; 
                                 mobile_notification_user=currentUser.get("displayUsername")?currentUser.get("displayUsername"):currentUser.get("displayName");
                                mobile_notification_user_token=postActivityComment.get('fromUser').get("FCMDeviceToken")?postActivityComment.get('fromUser').get("FCMDeviceToken"):'';
                            }else if(postMsg==='commented post')
                            {
                                communityComment="commented "+photo_ob.get('user').get("displayUsername")+"'s post.";
                                comment=postActivityComment.get('content');//"commented your post";    
                                notification.set('activity', postActivityComment);
                                notification.set('notifiedUser', photo_ob.get('user'));
                                notification.set('commentingUser', currentUser);
                                mobile_notification_text=currentUser.get("displayUsername")+" commented on your post: " +" “"+postActivityComment.get('content')+'” ';
                                mobile_notification_user=currentUser.get("displayUsername")?currentUser.get("displayUsername"):currentUser.get("displayName");
                                mobile_notification_user_token=photo_ob.get('user').get("FCMDeviceToken")?photo_ob.get('user').get("FCMDeviceToken"):'';
                            }else if(postMsg==='saved photo')
                            {
                                communityComment="liked "+photo_ob.get('user').get("displayUsername")+"'s post.";
                                comment="saved your post.";  
                                notification.set('notifiedUser', photo_ob.get('user'));
                                notification.set('commentingUser', currentUser);
                                mobile_notification_text=currentUser.get("displayUsername")+" saved your post.";                               
                                mobile_notification_user=currentUser.get("displayUsername")?currentUser.get("displayUsername"):currentUser.get("displayName");
                                mobile_notification_user_token=photo_ob.get('user').get("FCMDeviceToken")?photo_ob.get('user').get("FCMDeviceToken"):'';
                            }else if(postMsg==='was awarded winner of the challenge')
                            {
                                communityComment=photo_ob.get('user').get("displayUsername")+"'s was awarded winner of the challenge";
                                comment="was awarded winner of the challenge";  
                                notification.set('notifiedUser', photo_ob.get('user'));
                                notification.set('challenge', 1);
                                notification.set('global', 1);                               
                                //notification.set('commentingUser', currentUser);
                                notification.set('commentingUser', photo_ob.get('user'));
                                mobile_notification_user=photo_ob.get('user').get("displayUsername")?photo_ob.get('user').get("displayUsername"):photo_ob.get('user').get("displayName");
                                mobile_notification_user_token=photo_ob.get('user').get("FCMDeviceToken")?photo_ob.get('user').get("FCMDeviceToken"):'';
                                mobile_notification_text=photo_ob.get('user').get("displayUsername")+" was awarded winner of the challenge";

                            }else if(postMsg==='You was awarded winner of the challenge')
                            {
                                communityComment="You was awarded winner of the challenge "+photo_ob.get('user').get("displayUsername")+"'s ";
                                comment="You was awarded winner of the challenge";  
                                notification.set('notifiedUser', photo_ob.get('user'));
                                notification.set('challenge', 1);
                              
                               // notification.set('commentingUser', currentUser);
                                notification.set('commentingUser',  photo_ob.get('user'));
                                mobile_notification_user=photo_ob.get('user').get("displayUsername")?photo_ob.get('user').get("displayUsername"):photo_ob.get('user').get("displayName");
                                mobile_notification_user_token=photo_ob.get('user').get("FCMDeviceToken")?photo_ob.get('user').get("FCMDeviceToken"):'';
                                mobile_notification_text="You was awarded winner of the challenge";

                            }else if(postMsg==='created a challenge')
                            {
                               
                                communityComment=photo_ob.get('user').get("displayUsername")+"'s challenge became active!";
                                comment="challenge became active!";  
                               // notification.set('notifiedUser', photo_ob.get('user'));
                                notification.set('notifiedUser', currentUser);
                                notification.set('challenge', 1);
                                notification.set('global', 1);
                                notification.set('commentingUser', photo_ob.get('user'));
                                mobile_notification_text=photo_ob.get('user').get("displayUsername")+"'s challenge became active!";
                                mobile_notification_user=photo_ob.get('user').get("displayUsername")?photo_ob.get('user').get("displayUsername"):photo_ob.get('user').get("displayName");
                                mobile_notification_user_token=photo_ob.get('user').get("FCMDeviceToken")?photo_ob.get('user').get("FCMDeviceToken"):'';
                              
                            }else if(postMsg==='Thank you for submitting your challenge. It is currently pending review.')
                            {
                                communityComment="Thank you for submitting your challenge. It is currently pending review. "+photo_ob.get('user').get("displayUsername")+"'s ";
                                comment="Thank you for submitting your challenge. It is currently pending review.";  
                                notification.set('notifiedUser', photo_ob.get('user'));
                                notification.set('challenge', 1);
                                notification.set('commentingUser', currentUser);
                                 mobile_notification_text="Thank you for submitting your challenge. It is currently pending review.";
                                 mobile_notification_user=photo_ob.get('user').get("displayUsername")?photo_ob.get('user').get("displayUsername"):photo_ob.get('user').get("displayName");
                                mobile_notification_user_token=photo_ob.get('user').get("FCMDeviceToken")?photo_ob.get('user').get("FCMDeviceToken"):'';

                            }else if(postMsg==='Your challenge is approved')
                            {
                                communityComment="Your challenge is approved!";
                                comment="Your challenge is approved!";  
                                notification.set('notifiedUser', photo_ob.get('user'));
                                notification.set('challenge', 1);
                                notification.set('commentingUser', currentUser);
                                mobile_notification_text="Your challenge is approved!";
                                mobile_notification_user=photo_ob.get('user').get("displayUsername")?photo_ob.get('user').get("displayUsername"):photo_ob.get('user').get("displayName");
                                mobile_notification_user_token=photo_ob.get('user').get("FCMDeviceToken")?photo_ob.get('user').get("FCMDeviceToken"):'';

                            }else if(postMsg==='posted to your challenge')
                            {
                                communityComment=currentUser.get("displayUsername")+"'s posted to your challenge ";
                                comment="posted to your challenge.";  
                                notification.set('notifiedUser', photo_ob.get('challenge').get('user'));
                                notification.set('commentingUser', currentUser);
                                mobile_notification_text=currentUser.get("displayUsername")+" posted to your challenge ";   
                                mobile_notification_user=currentUser.get("displayUsername")?currentUser.get("displayUsername"):currentUser.get("displayName");
                                mobile_notification_user_token=photo_ob.get('challenge').get('user').get("FCMDeviceToken")?photo_ob.get('challenge').get('user').get("FCMDeviceToken"):'';                              
                            }

                           if(postMsg==='created a challenge')
                            {

//single notification send
let currentUser_id=currentUser.id; 
    notification.set('viewed', 0);
    const fromUserThumb = currentUser.get('profilePictureSmall');
    const thumbnail = photo_ob.get('thumbnail');
    if(thumbnail!=undefined)
     { 
          notification.set('thumbnail', thumbnail);
     }
     
    
    
    if(fromUserThumb!=undefined)
     { 
          notification.set('profilePictureSmall', fromUserThumb);
     }
    notification.set('photo', photo_ob);
    notification.set("_wperm", {
      "*": {
        "read": true,
        "write": true 
      }
    });
     notification.set("_rperm", {
      "*": {
       "read": true,
        "write": true 
      }
    });
     notification.set("_acl", {
      "*": {
        "read": true,
        "write": true 
      }
    });
    var acl = new Parse.ACL();
    acl.setPublicReadAccess(true);
    acl.setPublicWriteAccess(true);
    acl.setWriteAccess(currentUser, true); 
    acl.setReadAccess(currentUser, true); 
    notification.setACL(acl);
    notification.set('communityComment', communityComment);
    notification.set('comment', comment);
    notification.set('index', 0);    
    notification.set('webviewed', 1);    
    notification.save().then((likeSaved) => {
         console.log('notification saved');
         console.log(likeSaved);
    }) 
    //send notification
    
    //send notification
//single notification send


    var userquery = new Parse.Query('User');    
    userquery.limit(99999999999);  
    userquery.notEqualTo("displayUsername", currentUser.get("displayUsername"));
    userquery.notEqualTo("objectId", photo_ob.get('user').id);
    
    userquery.exists("FCMDeviceToken");   
    let allusers=[];                            
    userquery.find().then(function(result){
    if(result)
    {
    for (const matchusername of result) 
         { 
        let mobile_notification_user_token=matchusername.get("FCMDeviceToken")?matchusername.get("FCMDeviceToken"):'';
        if(mobile_notification_user_token!=='')
        {
           allusers.push(mobile_notification_user_token);
        }
                              
            }
        }
            if(allusers)
            {
                  var settings = {
                      "url": "https://joelpush.5edev.com/api/send-multiple-notifications",
                      "method": "POST",
                      "timeout": 0,
                      "headers": {
                        "Content-Type": "application/json"
                     
                      },
                      "data": JSON.stringify({
                        "notification": {
                          "badge": 1,
                          "body": mobile_notification_text,
                          "sound": "default",
                          "title": 'Joel’s Kitchen'
                        },
                        "priority": "high",
                        "device_tokens": allusers 
                      }),
                    };
                    if(allusers)
                    {
                      $.ajax(settings).done(function (response) {
                          console.log(response);
                        });  
                    }

            }
        
    })

                            }else if(postMsg==='was awarded winner of the challenge')
                            {

//single notification send
let currentUser_id=currentUser.id; 
    notification.set('viewed', 0);
    const fromUserThumb = currentUser.get('profilePictureSmall');
    const thumbnail = photo_ob.get('thumbnail');
    if(thumbnail!=undefined)
     { 
          notification.set('thumbnail', thumbnail);
     }
     
    if(fromUserThumb!=undefined)
     { 
          notification.set('profilePictureSmall', fromUserThumb);
     }
    notification.set('photo', photo_ob);
    notification.set("_wperm", {
      "*": {
        "read": true,
        "write": true 
      }
    });
     notification.set("_rperm", {
      "*": {
       "read": true,
        "write": true 
      }
    });
     notification.set("_acl", {
      "*": {
        "read": true,
        "write": true 
      }
    });
    var acl = new Parse.ACL();
    acl.setPublicReadAccess(true);
    acl.setPublicWriteAccess(true);
    acl.setWriteAccess(currentUser, true); 
    acl.setReadAccess(currentUser, true); 
    notification.setACL(acl);
    notification.set('communityComment', communityComment);
    notification.set('comment', comment);
    notification.set('index', 0);    
    notification.set('webviewed', 1); 
    notification.save().then((likeSaved) => {
         console.log('notification saved');
         console.log(likeSaved);
    }) 

//single notification send


    var userquery = new Parse.Query('User');    
    userquery.limit(99999999999);  
    userquery.notEqualTo("displayUsername", currentUser.get("displayUsername"));
    userquery.exists("FCMDeviceToken");   
    let allusers=[];                            
    userquery.find().then(function(result){
    if(result)
    {
    for (const matchusername of result) 
         { 
        let mobile_notification_user_token=matchusername.get("FCMDeviceToken")?matchusername.get("FCMDeviceToken"):'';
        if(mobile_notification_user_token!=='')
        {
           allusers.push(mobile_notification_user_token);
        }
                              
            }
        }
            if(allusers)
            {
               
                  //send notification    
                      var settings = {
                      "url": "https://joelpush.5edev.com/api/send-multiple-notifications",
                      "method": "POST",
                      "timeout": 0,
                      "headers": {
                        "Content-Type": "application/json",                      
                      },
                      "data": JSON.stringify({
                        "notification": {
                          "badge": 1,
                          "body": mobile_notification_text,
                          "sound": "default",
                          "title": 'Joel’s Kitchen'
                        },
                        "priority": "high",
                        "device_tokens": allusers 
                      }),
                    };

                    if(allusers)
                    {
                      $.ajax(settings).done(function (response) {
                          console.log(response);
                        }); 
                    }

            }
        
    })

                            

                            }
                            else{
  
                            let currentUser_id=currentUser.id; 
                            notification.set('viewed', 0);
                            const fromUserThumb = currentUser.get('profilePictureSmall');
                            const thumbnail = photo_ob.get('thumbnail');
                            if(thumbnail!=undefined)
                             { 
                                  notification.set('thumbnail', thumbnail);
                             }
                             
                            
                            
                            if(fromUserThumb!=undefined)
                             { 
                                  notification.set('profilePictureSmall', fromUserThumb);
                             }
                            notification.set('photo', photo_ob);
                            notification.set("_wperm", {
                              "*": {
                                "read": true,
                                "write": true 
                              }
                            });
                             notification.set("_rperm", {
                              "*": {
                               "read": true,
                                "write": true 
                              }
                            });
                             notification.set("_acl", {
                              "*": {
                                "read": true,
                                "write": true 
                              }
                            });
                            var acl = new Parse.ACL();
                            acl.setPublicReadAccess(true);
                            acl.setPublicWriteAccess(true);
                            acl.setWriteAccess(currentUser, true); 
                            acl.setReadAccess(currentUser, true); 
                            notification.setACL(acl);
                            notification.set('communityComment', communityComment);
                            notification.set('comment', comment);
                            notification.set('index', 0);    
                            notification.set('webviewed', 1);           

                            notification.save().then((likeSaved) => {
                                 console.log('notification saved');
                                 console.log(likeSaved);
                            }) 
                            //send notification
                            var settings = {
                              "url": "https://joelpush.5edev.com/api/send-push-notification",
                              "method": "POST",
                              "timeout": 0,
                              "headers": {
                                "Content-Type": "application/json"
                              },
                              "data": JSON.stringify({
                                "notification": {
                                  "badge": 1,
                                  "body": mobile_notification_text,
                                  "sound": "default",
                                  "title": 'Joel’s Kitchen'
                                },
                                "priority": "high",
                                "device_token": mobile_notification_user_token
                              }),
                            };
                            if(mobile_notification_user_token!=='')
                            { 
                              $.ajax(settings).done(function (response) {
                                  console.log(response);
                                });  
                            }
                            //send notification

                            }
      
                           
                     }

                     }
                 })

      }
    }
    render() {
        return '';
    }
}
export default saveNotification;