import '../global.js'
import React, { Component } from 'react';
import Parse from 'parse';
import ReactTable from "react-table-v6";
import 'react-table-v6/react-table.css'
//import Postcounts from "./postcounts.jsx";
import Postcounts from "./postcounts.jsx";
import Followerscounts from "./followerscounts.jsx";
class Allcreatorsnew extends React.Component {
    constructor(props) {
    super(props);
    this.state = {
      users: [],
      loading: true,
      page: 1,
      pageSize: 100,
      total: 0,      
      filterTitle: '',
      sortCreatorField: 'displayUsername',
      sortCreatorOrder: 'asc',
    };
    this.handleSearch = this.handleSearch.bind(this);
    let currentUser = Parse.User.current();   
        
        if(!currentUser || (currentUser.get('isAdmin')!==undefined && currentUser.get('isAdmin')!=="1"))
        {
            window.location.href = "/";
        }
  }
   handleSearch = (event) => {
        const searchTerm = event.target.value;
        let reactTableData= this.state.users;
        let searchreactTableData= this.state.usersdata;
        var self = this;
        if(event.target.value)
        {
            //this.getPostAux(searchTerm);
             const newreactTableData = searchreactTableData.filter(item => 
             //console.log(item.get('name'))       
              item.get('displayName').toLowerCase().includes(searchTerm.toLowerCase())
            );
              self.setState({users:newreactTableData});
        }else{
             this.fetchData();
        }
    }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { page, pageSize,filterTitle,sortCreatorField, sortCreatorOrder  } = this.state;
    const skip = (page - 1) * pageSize;

     var query = new Parse.Query('_User');
        query.limit(99999999999999999999999999);
        query.equalTo("isCreator", 1);  
       // query.include('Photo');      
        query.include("profilePictureSmall");
        query.include("displayName");  
        query.include("displayUsername");   
        query.include("instagram");
        query.include("facebook");
        query.include("pinterest");
        query.include("tiktok");
        query.include("youtube");
        query.include("website");
        query.include("name");
        query.include("email");
        //query.descending("createdAt");
        if(sortCreatorOrder==='desc')
        {
             query.descending(sortCreatorField);
        }else{
             query.ascending(sortCreatorField);
        } 
    query.limit(pageSize);
    query.skip(skip);
    if (filterTitle) {
      query.contains('displayUsername', filterTitle);
    }
    query.find()
      .then(results => {
        this.setState({
          users: results,
          usersdata: results,
          loading: false
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        this.setState({ loading: false });
      });
var query1 = new Parse.Query('_User');
        query1.limit(99999999999999999999999999);
        query1.equalTo("isCreator", 1);  
        query1.include('Photo');      
        query1.include("profilePictureSmall");
        query1.include("displayName");
        query1.include("recipeRate");
        query1.include("videoRate");
        query1.include("photoRate");
        query1.include("instagram");
        query1.include("facebook");
        query1.include("pinterest");
        query1.include("tiktok");
        query1.include("youtube");
        query1.include("website");
        query1.include("name");
        query1.include("email");
       // query1.descending("createdAt");
        if(sortCreatorOrder==='desc')
        {
             query1.descending(sortCreatorField);
        }else{
             query1.ascending(sortCreatorField);
        } 
        //query1.limit(pageSize);
    query1.find()
      .then(resultsdata => {
        this.setState({
          usersdata: resultsdata,
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        //this.setState({ loading: false });
      });


  };

  handlePageChange = (page) => {
    this.setState({ page, loading: true }, () => {
      this.fetchData();//true
    });
  };
handleFilterChange1 = (event) => {
    this.setState({ filterTitle: event.target.value }, () => {
      // Reset page to 1 when filter changes  true
      this.setState({ page: 1, loading: true }, () => {
        this.fetchData();
      });
    });
  };

  socialLinkClass(linkVal)
    {
        if (linkVal && (linkVal != undefined) && (linkVal.length > 1))
        {
            return "sociallink";
        }
        else
            return "displayNone";
    }
      formatWebsite(linkVal)
    {
        if ((!linkVal) || (linkVal == undefined))
            return "";

        if (linkVal.includes("http://") || linkVal.includes("https://") || linkVal.includes("Https://") || linkVal.includes("Http://"))
            return linkVal;
        else
            return "https://" + linkVal;
    }

    formatFacebook(linkVal)
    {
        if ((!linkVal) || (linkVal == undefined))
            return "";

        linkVal.replace("@", "");

        linkVal.replace("m.facebook.com", "facebook.com");

        if (linkVal.includes("http://") || linkVal.includes("https://") || linkVal.includes("Https://") || linkVal.includes("Http://"))
            return linkVal;
        else if (linkVal.includes("facebook.com"))
            return "https://" + linkVal;
        else
            return "https://facebook.com/" + linkVal;
    }

    formatPinterest(linkVal)
    {
        if ((!linkVal) || (linkVal == undefined))
            return "";

        linkVal.replace("@", "");
        
        if (linkVal.includes("http://") || linkVal.includes("https://") || linkVal.includes("Https://") || linkVal.includes("Http://"))
            return linkVal;
        else if (linkVal.includes("pinterest.com"))
            return "https://" + linkVal;
        else
            return "https://pinterest.com/" + linkVal;
    }

    formatInstagram(linkVal)
    {
        if ((!linkVal) || (linkVal == undefined))
            return "";

        linkVal.replace("@", "");
        
        if (linkVal.includes("http://") || linkVal.includes("https://") || linkVal.includes("Https://") || linkVal.includes("Http://"))
            return linkVal;
        else
            return "https://instagram.com/" + linkVal;
    }
   renderSocial(creator) {
        var result = ( <div> </div>
            );

        if (!creator)
            return result;

        var instagram = creator.get("instagram");
        var facebook = creator.get("facebook");
        var pinterest = creator.get("pinterest");
        var tiktok = creator.get("tiktok");
        var youtube = creator.get("youtube");
        var website = creator.get("website");

        result = (
          <div className='socialLinkWrp'>
            <div class={this.socialLinkClass(instagram)}>
              <a target="_blank" href={this.formatInstagram(instagram)}>
                <img src="/images/social/create-instagram.svg" width="32" height="32" />
              </a>
            </div>
            <div class={this.socialLinkClass(tiktok)}>
              <a target="_blank" href={this.formatWebsite(tiktok)}>
                <img src="/images/social/create-tiktok.svg" width="32" height="32" />
              </a>
            </div>
            <div class={this.socialLinkClass(youtube)}>
              <a target="_blank" href={this.formatWebsite(youtube)}>
                <img src="/images/social/create-youtube.svg" width="32" height="32" />
              </a>
            </div>
            <div class={this.socialLinkClass(website)}>
              <a target="_blank" href={this.formatWebsite(website)}>
                <img src="/images/social/create-website.svg" width="32" height="32" />
              </a>
            </div>
            <div className={this.socialLinkClass(facebook)}>
              <a target="_blank" href={this.formatFacebook(facebook)}>
                <img src="/images/social/facebook-app.png" width="32" height="32"/>
              </a>
            </div>
          </div>
            );
        return result;
    }
     handleFilterChange = (event) => {
    this.setState({ filterTitle: event.target.value }, () => {
      // Reset page to 1 when filter changes  true
      this.setState({ page: 1, loading: true }, () => {
        this.fetchData();
      });
    });
  };
  render() {
    const { users, loading, page, pageSize,filterTitle,sortCreatorField, sortCreatorOrder } = this.state;
var self = this;

const handleCreatorSort = (field) => {
    const { sortCreatorField, sortCreatorOrder } = this.state;
    const newSortOrder = sortCreatorField === field && sortCreatorOrder === 'asc' ? 'desc' : 'asc';
    
    this.setState({ sortCreatorField: field, sortCreatorOrder: newSortOrder }, this.fetchData);
  };
    return (
      <div className="account_main_top">
        <div className="inner_wrap">
          <div className="table_top_searchbar post_topbar">
            <div className='left-part'>
                <a href="javascript:void(0);">Invite</a>
                <h2>Invite creators to launch a campaign for your brand.</h2>
            </div>
            <div className="menu-search-wrap menu-search-mn d-flex flex-row flex-wrap align-items-md-center">
              <div className="search_wrap">
                <form className="form-mn d-flex flex-row flex-wrap">
                  <div className='search_inpt_inner'>
                    {/*onClick={this.searchSuggestion} */}
                    <input className="form-control" type="search" placeholder="Search" aria-label="Search" value={filterTitle} onChange={this.handleFilterChange} />
                    {/*    <input className="form-control" type="search" placeholder="Search" aria-label="Search"  onChange={this.handleSearch} />*/}
                    <button type="submit"><img src="/images/search.svg" alt="Search"/></button>
                    <button type="button" className='close-btn hide' onClick={this.searchSuggestionCloase} ><img src="/images/close_small.svg" alt="Close"/></button>
                  </div>
                </form>
              </div>
            </div>
          </div> 
          {
            loading ? (
              <div className='admin_table_loader'>Loading...</div>
            ) : (
              <div className='admin_table_wrapper'>
                <div className="admin_table_inner creatorTable_main_top">
                  <div className="admin_table">
                    <table className="creatorTable table-responsive">
                      <thead>
                        <tr>
                          <th>Collaborate</th>
                          <th className={sortCreatorField === 'displayUsername' && (sortCreatorField !== '' ? ' sort-arrow' : '')?"sort-arrow":""}   onClick={(event) => handleCreatorSort('displayUsername')} >Account <span className="updown_arrow">{sortCreatorField === 'displayUsername' && (sortCreatorOrder === 'asc' ? '↑' : '↓')}</span></th>
                          <th>Posts</th>
                          <th  className={sortCreatorField === 'imprcount' && (sortCreatorField !== '' ? ' sort-arrow' : '')?"sort-arrow":""}    onClick={(event) => handleCreatorSort('imprcount')} >Views  <span className="updown_arrow">{sortCreatorField === 'imprcount' && (sortCreatorOrder === 'asc' ? '↑' : '↓')}</span></th>
                          <th  className={sortCreatorField === 'impr' && (sortCreatorField !== '' ? ' sort-arrow' : '')?"sort-arrow":""}   onClick={(event) => handleCreatorSort('impr')} >Engagements  <span className="updown_arrow">{sortCreatorField === 'impr' && (sortCreatorOrder === 'asc' ? '↑' : '↓')}</span></th>
                          <th>Followers</th>
                          <th>Social Link</th>
                          <th  className={sortCreatorField === 'username' && (sortCreatorField !== '' ? ' sort-arrow' : '')?"sort-arrow":""}  onClick={(event) => handleCreatorSort('username')}>Email <span className="updown_arrow">{sortCreatorField === 'username' && (sortCreatorOrder === 'asc' ? '↑' : '↓')}</span></th>
                        </tr>
                      </thead>
                      <tbody>
                        { users.map(function (user, i) {
                          //itemUser.fetch();
                          {
                            return (
                              <tr>
                                <td className='colbrte-check'>
                                  <div class="admin_check_radio">
                                    <label htmlFor="checkbox_id">
                                      <input type="checkbox" name="checkbox" id="checkbox_id" value="value" />
                                      <span></span>
                                    </label>
                                  </div>
                                </td>
                                <td className='userNamewMedia'>
                                  <div className="user_image">
                                    <a href={user.get("displayUsername")?"https://www.joelskitchen.com/user/"+user.get("displayUsername"):''}>
                                      <span className="thumb"><img class="userProfileImageCreator" src={user != undefined ?(user.get("profilePictureSmall") != undefined ? user.get("profilePictureSmall").url() : "/images/genericuser.png" ) : ""}/></span>
                                      <span className="name">{user.get("displayUsername")?user.get("displayUsername"):''}</span>
                                    </a>
                                  </div>
                                </td>
                                <td className="font-size-12"><Postcounts postData={user} /></td>
                                <td className="font-size-12">{user.get("imprcount")?user.get("imprcount"):'0'}</td>
                                <td className="font-size-13">{user.get("impr")?user.get("impr"):'0'}</td>
                                <td className="font-size-13"><Followerscounts postDataUser={user} /></td>
                                <td className='platforms'>{self.renderSocial(user)}</td>
                                <td className='user_email'><a href={"mailto:"+user.get("username")}>{user.get("username")} <img src="/images/social/create-mail.svg" width="20" height="20" /></a></td>
                              </tr>
                            );
                          }
                        })}
                      </tbody>
                    </table>           
                    <div className="pagination_bottom">
                      <button
                        onClick={() => this.handlePageChange(page - 1)}
                        disabled={page === 1}
                      >
                        &#10094;
                      </button>
                      <span> Page {page} </span>
                      <button
                        onClick={() => this.handlePageChange(page + 1)}
                        disabled={users.length < pageSize}
                      >
                        &#10095;
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}
export default Allcreatorsnew;