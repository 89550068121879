import '../global.js'
import React, { Component } from 'react';
import Parse from 'parse';

class AdminkpiGraph extends React.Component {
    constructor(props) {
      super(props);
      this.state = { };
      let currentUser = Parse.User.current();   
        
        if(!currentUser || (currentUser.get('isAdmin')!==undefined && currentUser.get('isAdmin')!=="1"))
        {
            window.location.href = "/";
        }
    }
    render() {
      return (
        <div className="account_main_top kpi-garph">
          <div className="inner_wrap">
            <div className="table_top_searchbar post_topbar">
              <div className='left-part'>
                <h2>KPIs</h2> 
              </div>
              <div className="menu-search-wrap menu-search-mn d-flex flex-row flex-wrap align-items-md-center">
                <div className="search_wrap">
                  <form className="form-mn d-flex flex-row flex-wrap">
                    <div className='search_inpt_inner'>
                      <input className="form-control" placeholder="Search" aria-label="Search" type="text" />
                      <button type="submit"><img src="/images/search.svg" alt="Search"/></button>
                      <button type="button" className='close-btn hide' ><img src="/images/close_small.svg" alt="Close"/></button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className='admin_table_wrapper kpi-table'>
              <div className='admin_table_inner'>
                <div className="admin_table">
                  <div className='top-part'>
                    <div className='card-list'>
                      <div className='cards'>
                        <h3 className='name'>Total Sign Ups</h3>
                        <div className='count'>
                          <span className='number'>7,265</span>
                          <span className='percentage'><small>+11.02%</small><img src="/images/arrow-rise.svg" alt="Up"/></span>
                        </div>
                      </div>
                      <div className='cards'>
                        <h3 className='name'>Daily Active Users</h3>
                        <div className='count'>
                          <span className='number'>3,671</span>
                          <span className='percentage'><small>-0.03%</small><img src="/images/arrow-down.svg" alt="Up"/></span>
                        </div>
                      </div>
                      <div className='cards'>
                        <h3 className='name'>Monthly Active Users</h3>
                        <div className='count'>
                          <span className='number'>156</span>
                          <span className='percentage'><small>+15.03%</small><img src="/images/arrow-rise.svg" alt="Up"/></span>
                        </div>
                      </div>
                      <div className='cards'>
                        <h3 className='name'>Posts</h3>
                        <div className='count'>
                          <span className='number'>2,318</span>
                          <span className='percentage'><small>+6.08%</small><img src="/images/arrow-rise.svg" alt="Up"/></span>
                        </div>
                      </div>  
                    </div>
                    <div className='challenge-trafic'>
                      <div className='challenges cards'>
                        <div className='inner'>
                          <div className='title-part'>
                            <h4>Total Challenges   Total Entries</h4>
                            <label className='duration'>
                              <span className='current'>This Year</span>
                              <span className='prev'>Last Year</span>  
                            </label>
                          </div>
                        </div>
                      </div>  
                      <div className='traffic cards'>
                        <div className='inner'>
                          <div className='title-part'>
                            <h4>Traffic by Page</h4>
                          </div>
                        </div>
                      </div>  
                    </div>
                  </div>
                  <div className='bottom-part'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
}
export default AdminkpiGraph;