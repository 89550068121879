import React, { Component } from 'react';
import Parse from 'parse';
import $ from "jquery";
import './payment.css';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
const stripePromise = loadStripe('pk_test_51N1Iw3F2uVaAxW23DxC7agGfTzAW2GmNeWovzSsHYi2laozcLAIdliI06EIKpXPS34FaaOGTyTYqrHqhqrm19MKi001fafzmiU');
export default class Payments extends Component {
    render() {
        return (
            <React.Fragment>

                <div className='payment_mn'>
                    <div className='payment_card' style={{"margin-bottom":"50px"}}>
                        <div className='inner'>
                            <div className='strp-paymnt-page' >
                                <div className='top_part'>
                                    <div className='pytm_amunt'>
                                {/*    <stripe-buy-button
      buy-button-id="buy_btn_1PVTJDF2uVaAxW2344UusWB5"
      publishable-key="pk_test_51N1Iw3F2uVaAxW23DxC7agGfTzAW2GmNeWovzSsHYi2laozcLAIdliI06EIKpXPS34FaaOGTyTYqrHqhqrm19MKi001fafzmiU"
      customer-session-client-secret="sk_test_51N1Iw3F2uVaAxW23VoHgfbi2ikYGzN6W4g3QOlmdu9SMRaRyF4ytRU180W4PMnnSK3UEy22EgSVTfs9Lki7D4Dgb00bVw8eqIO"
    >
    </stripe-buy-button>
    */
    }
                                        <label>Due now <span>$1,500</span></label>
                                    </div>
                                </div>
                                <div className='middle_part'>   
                                    <aside className='box'>
                                        <h4>Your challenge is ready for payment!</h4>
                                        <ul >
                                            <li><label className='title'>Challenge Amount</label><span className='value'>$1,000.00</span></li>
                                            <li><label className='title'>Prize Amount</label><span className='value'>$500.00</span></li>
                                            <li className='total'><label className='title'>Total</label><span className='value'>$1,500.00</span></li>
                                        </ul>
                                    </aside>
                                    <aside className='box'>
                                        <h4>Payment Details</h4>
                                        <ul>
                                            <li><label className='title'>Pay to</label><span className='value'>Joel’s Kitchen</span></li>
                                            <li><label className='title'>Customer Name</label><span className='value'>Bob’s Red Mill</span></li>
                                            <li><label className='title'>Invoice number</label><span className='value'>1028</span></li>
                                            <li><label className='title'>Due date</label><span className='value'>May 24, 2024</span></li>
                                        </ul>
                                    </aside>
                                </div>
                                <div className='bottom_part'>
                                    <div className='action_mn'>
                                        <a href='javascript:void(0);'><img src="/images/pymt-lock.svg" alt="Payment Lock"/>Pay $1,500 Now</a>
                                    </div>
                                    <div className='strip_mn'>
                                        <img src="/images/pymt-strip.png" alt="Powered by stripe"/>
                                    </div>
                                    <p>Questions? <a href='javascript:void(0);' title='Contact us'>Contact us.</a></p>
                                </div>
                            </div>
                            <div className='chlng-paymnt-accunt-page' style={{"display":"none"}}>
                                <div className='top_part'>
                                    <div className='pytm_tlt'>
                                        <h2>Thank you for your Payment!</h2>
                                    </div>
                                </div>
                                <div className='middle_part'>
                                    <aside className='box'>
                                        <h4>Payment Amount</h4>
                                        <div className='top_part pytm_amunt'>
                                            <label><span>$1,500</span></label>
                                        </div>
                                        <ul style={{"display":"none"}}>
                                            <li><label className='title'>Challenge Amount</label><span className='value'>$1,000.00</span></li>
                                            <li><label className='title'>Prize Amount</label><span className='value'>$500.00</span></li>
                                            <li className='total'><label className='title'>Total</label><span className='value'>$1,500.00</span></li>
                                        </ul>
                                    </aside>
                                    <aside className='box'>
                                        <h4>Payment Details</h4>
                                        <ul>
                                            <li><label className='title'>Pay to</label><span className='value'>Joel’s Kitchen</span></li>
                                            <li><label className='title'>Customer Name</label><span className='value'>Bob’s Red Mill</span></li>
                                            <li><label className='title'>Invoice number</label><span className='value'>1028</span></li>
                                            <li><label className='title'>Due date</label><span className='value'>May 24, 2024</span></li>
                                        </ul>
                                    </aside>
                                </div>
                                <div className='bottom_part'>
                                    <div className='action_mn'>
                                        <div className='cls-btn'>
                                            <a href='javascript:void(0);' title='Close Page'>Close Page</a>
                                        </div>
                                        <div className='takeme-btn'>
                                            <a href='javascript:void(0);' title='Take me to Joel’s Kitchen'>Take me to Joel’s Kitchen</a>
                                        </div>
                                    </div>
                                    <p>Questions? <a href='javascript:void(0);' title='Contact us'>Contact us.</a></p>
                                </div>
                            </div>
                            <div className='chlng-paymnt-decline-page' style={{"display":"none"}}>
                                <div className='top_part'>
                                    <div className='pytm_tlt'>
                                        <h2>Thank you for your Payment!</h2>
                                    </div>
                                    <div className='pytm_amunt' style={{"display":"none"}}>
                                        <label>Due now <span>$1,500</span></label>
                                    </div>
                                </div>
                                <div className='middle_part'>   
                                    <aside className='box'>
                                        <h4>Your challenge is ready for payment!</h4>
                                        <ul >
                                            <li><label className='title'>Challenge Amount</label><span className='value'>$1,000.00</span></li>
                                            <li><label className='title'>Prize Amount</label><span className='value'>$500.00</span></li>
                                            <li className='total'><label className='title'>Total</label><span className='value'>$1,500.00</span></li>
                                        </ul>
                                    </aside>
                                    <aside className='box'>
                                        <h4>Payment Details</h4>
                                        <ul>
                                            <li><label className='title'>Pay to</label><span className='value'>Joel’s Kitchen</span></li>
                                            <li><label className='title'>Customer Name</label><span className='value'>Bob’s Red Mill</span></li>
                                            <li><label className='title'>Invoice number</label><span className='value'>1028</span></li>
                                            <li><label className='title'>Due date</label><span className='value'>May 24, 2024</span></li>
                                        </ul>
                                    </aside>
                                </div>
                                <div className='bottom_part'>
                                    <div className='action_mn'>
                                        <a href='javascript:void(0);'><img src="/images/pymt-lock.svg" alt="Payment Lock"/>Pay $1,500 Now</a>
                                    </div>
                                    <div className='strip_mn'>
                                        <img src="/images/pymt-strip.png" alt="Powered by stripe"/>
                                    </div>
                                    <p>Questions? <a href='javascript:void(0);' title='Contact us'>Contact us.</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                   {/*<div className='payment_card' style={{"display":"none","margin-bottom":"50px"}}>
                        <div className='inner'>
                            <div className='strp-paymnt-page' style={{"display":"none"}}>
                                <div className='top_part'>
                                    <div className='pytm_tlt'>
                                        <h2>Thank you for your Payment!</h2>
                                    </div>
                                    <div className='pytm_amunt' style={{"display":"none"}}>
                                        <label>Due now <span>$1,500</span></label>
                                    </div>
                                </div>
                                <div className='middle_part'>   
                                    <aside className='box'>
                                        <h4>Your challenge is ready for payment!</h4>
                                        <ul >
                                            <li><label className='title'>Challenge Amount</label><span className='value'>$1,000.00</span></li>
                                            <li><label className='title'>Prize Amount</label><span className='value'>$500.00</span></li>
                                            <li className='total'><label className='title'>Total</label><span className='value'>$1,500.00</span></li>
                                        </ul>
                                    </aside>
                                    <aside className='box'>
                                        <h4>Payment Details</h4>
                                        <ul>
                                            <li><label className='title'>Pay to</label><span className='value'>Joel’s Kitchen</span></li>
                                            <li><label className='title'>Customer Name</label><span className='value'>Bob’s Red Mill</span></li>
                                            <li><label className='title'>Invoice number</label><span className='value'>1028</span></li>
                                            <li><label className='title'>Due date</label><span className='value'>May 24, 2024</span></li>
                                        </ul>
                                    </aside>
                                </div>
                                <div className='bottom_part'>
                                    <div className='action_mn'>
                                        <a href='javascript:void(0);'><img src="/images/pymt-lock.svg" alt="Payment Lock"/>Pay $1,500 Now</a>
                                    </div>
                                    <div className='strip_mn'>
                                        <img src="/images/pymt-strip.png" alt="Powered by stripe"/>
                                    </div>
                                    <p>Questions? <a href='javascript:void(0);' title='Contact us'>Contact us.</a></p>
                                </div>
                            </div>
                            <div className='chlng-paymnt-accunt-page' >
                                <div className='top_part'>
                                    <div className='pytm_tlt'>
                                        <h2>Thank you for your Payment!</h2>
                                    </div>
                                </div>
                                <div className='middle_part'>
                                    <aside className='box'>
                                        <h4>Payment Amount</h4>
                                        <div className='top_part pytm_amunt'>
                                            <label><span>$1,500</span></label>
                                        </div>
                                        <ul style={{"display":"none"}}>
                                            <li><label className='title'>Challenge Amount</label><span className='value'>$1,000.00</span></li>
                                            <li><label className='title'>Prize Amount</label><span className='value'>$500.00</span></li>
                                            <li className='total'><label className='title'>Total</label><span className='value'>$1,500.00</span></li>
                                        </ul>
                                    </aside>
                                    <aside className='box'>
                                        <h4>Payment Details</h4>
                                        <ul>
                                            <li><label className='title'>Pay to</label><span className='value'>Joel’s Kitchen</span></li>
                                            <li><label className='title'>Customer Name</label><span className='value'>Bob’s Red Mill</span></li>
                                            <li><label className='title'>Invoice number</label><span className='value'>1028</span></li>
                                            <li><label className='title'>Due date</label><span className='value'>May 24, 2024</span></li>
                                        </ul>
                                    </aside>
                                </div>
                                <div className='bottom_part'>
                                    <div className='action_mn'>
                                        <div className='cls-btn'>
                                            <a href='javascript:void(0);' title='Close Page'>Close Page</a>
                                        </div>
                                        <div className='takeme-btn'>
                                            <a href='javascript:void(0);' title='Take me to Joel’s Kitchen'>Take me to Joel’s Kitchen</a>
                                        </div>
                                    </div>
                                    <p>Questions? <a href='javascript:void(0);' title='Contact us'>Contact us.</a></p>
                                </div>
                            </div>
                            <div className='chlng-paymnt-decline-page' style={{"display":"none"}}>
                                <div className='top_part'>
                                    <div className='pytm_tlt'>
                                        <h2>Thank you for your Payment!</h2>
                                    </div>
                                    <div className='pytm_amunt' style={{"display":"none"}}>
                                        <label>Due now <span>$1,500</span></label>
                                    </div>
                                </div>
                                <div className='middle_part'>   
                                    <aside className='box'>
                                        <h4>Your challenge is ready for payment!</h4>
                                        <ul >
                                            <li><label className='title'>Challenge Amount</label><span className='value'>$1,000.00</span></li>
                                            <li><label className='title'>Prize Amount</label><span className='value'>$500.00</span></li>
                                            <li className='total'><label className='title'>Total</label><span className='value'>$1,500.00</span></li>
                                        </ul>
                                    </aside>
                                    <aside className='box'>
                                        <h4>Payment Details</h4>
                                        <ul>
                                            <li><label className='title'>Pay to</label><span className='value'>Joel’s Kitchen</span></li>
                                            <li><label className='title'>Customer Name</label><span className='value'>Bob’s Red Mill</span></li>
                                            <li><label className='title'>Invoice number</label><span className='value'>1028</span></li>
                                            <li><label className='title'>Due date</label><span className='value'>May 24, 2024</span></li>
                                        </ul>
                                    </aside>
                                </div>
                                <div className='bottom_part'>
                                    <div className='action_mn'>
                                        <a href='javascript:void(0);'><img src="/images/pymt-lock.svg" alt="Payment Lock"/>Pay $1,500 Now</a>
                                    </div>
                                    <div className='strip_mn'>
                                        <img src="/images/pymt-strip.png" alt="Powered by stripe"/>
                                    </div>
                                    <p>Questions? <a href='javascript:void(0);' title='Contact us'>Contact us.</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='payment_card' style={{"display":"none","margin-bottom":"50px"}}>
                        <div className='inner'>
                            <div className='strp-paymnt-page' style={{"display":"none"}}>
                                <div className='top_part'>
                                    <div className='pytm_tlt'>
                                        <h2>Your payment has been declined. Please try again. </h2>
                                    </div>
                                    <div className='pytm_amunt' style={{"display":"none"}}>
                                        <label>Due now <span>$1,500</span></label>
                                    </div>
                                </div>
                                <div className='middle_part'>   
                                    <aside className='box'>
                                        <h4>Your challenge is ready for payment!</h4>
                                        <ul >
                                            <li><label className='title'>Challenge Amount</label><span className='value'>$1,000.00</span></li>
                                            <li><label className='title'>Prize Amount</label><span className='value'>$500.00</span></li>
                                            <li className='total'><label className='title'>Total</label><span className='value'>$1,500.00</span></li>
                                        </ul>
                                    </aside>
                                    <aside className='box'>
                                        <h4>Payment Details</h4>
                                        <ul>
                                            <li><label className='title'>Pay to</label><span className='value'>Joel’s Kitchen</span></li>
                                            <li><label className='title'>Customer Name</label><span className='value'>Bob’s Red Mill</span></li>
                                            <li><label className='title'>Invoice number</label><span className='value'>1028</span></li>
                                            <li><label className='title'>Due date</label><span className='value'>May 24, 2024</span></li>
                                        </ul>
                                    </aside>
                                </div>
                                <div className='bottom_part'>
                                    <div className='action_mn'>
                                        <a href='javascript:void(0);'><img src="/images/pymt-lock.svg" alt="Payment Lock"/>Pay $1,500 Now</a>
                                    </div>
                                    <div className='strip_mn'>
                                        <img src="/images/pymt-strip.png" alt="Powered by stripe"/>
                                    </div>
                                    <p>Questions? <a href='javascript:void(0);' title='Contact us'>Contact us.</a></p>
                                </div>
                            </div>
                            <div className='chlng-paymnt-accunt-page' style={{"display":"none"}} >
                                <div className='top_part'>
                                    <div className='pytm_tlt'>
                                        <h2>Thank you for your Payment!</h2>
                                    </div>
                                </div>
                                <div className='middle_part'>
                                    <aside className='box'>
                                        <h4>Payment Amount</h4>
                                        <div className='top_part pytm_amunt'>
                                            <label><span>$1,500</span></label>
                                        </div>
                                        <ul style={{"display":"none"}}>
                                            <li><label className='title'>Challenge Amount</label><span className='value'>$1,000.00</span></li>
                                            <li><label className='title'>Prize Amount</label><span className='value'>$500.00</span></li>
                                            <li className='total'><label className='title'>Total</label><span className='value'>$1,500.00</span></li>
                                        </ul>
                                    </aside>
                                    <aside className='box'>
                                        <h4>Payment Details</h4>
                                        <ul>
                                            <li><label className='title'>Pay to</label><span className='value'>Joel’s Kitchen</span></li>
                                            <li><label className='title'>Customer Name</label><span className='value'>Bob’s Red Mill</span></li>
                                            <li><label className='title'>Invoice number</label><span className='value'>1028</span></li>
                                            <li><label className='title'>Due date</label><span className='value'>May 24, 2024</span></li>
                                        </ul>
                                    </aside>
                                </div>
                                <div className='bottom_part'>
                                    <div className='action_mn'>
                                        <div className='cls-btn'>
                                            <a href='javascript:void(0);' title='Close Page'>Close Page</a>
                                        </div>
                                        <div className='takeme-btn'>
                                            <a href='javascript:void(0);' title='Take me to Joel’s Kitchen'>Take me to Joel’s Kitchen</a>
                                        </div>
                                    </div>
                                    <p>Questions? <a href='javascript:void(0);' title='Contact us'>Contact us.</a></p>
                                </div>
                            </div>
                            <div className='chlng-paymnt-decline-page' >
                                <div className='top_part'>
                                    <div className='pytm_tlt'>
                                        <h2>Your payment has been declined. <br />Please try again. </h2>
                                    </div>
                                    <div className='pytm_amunt' style={{"display":"none"}}>
                                        <label>Due now <span>$1,500</span></label>
                                    </div>
                                </div>
                                <div className='middle_part'>   
                                    <aside className='box'>
                                        <h4>Your challenge is ready for payment!</h4>
                                        <ul >
                                            <li><label className='title'>Challenge Amount</label><span className='value'>$1,000.00</span></li>
                                            <li><label className='title'>Prize Amount</label><span className='value'>$500.00</span></li>
                                            <li className='total'><label className='title'>Total</label><span className='value'>$1,500.00</span></li>
                                        </ul>
                                    </aside>
                                    <aside className='box'>
                                        <h4>Payment Details</h4>
                                        <ul>
                                            <li><label className='title'>Pay to</label><span className='value'>Joel’s Kitchen</span></li>
                                            <li><label className='title'>Customer Name</label><span className='value'>Bob’s Red Mill</span></li>
                                            <li><label className='title'>Invoice number</label><span className='value'>1028</span></li>
                                            <li><label className='title'>Due date</label><span className='value'>May 24, 2024</span></li>
                                        </ul>
                                    </aside>
                                </div>
                                <div className='bottom_part'>
                                    <div className='action_mn'>
                                        <a href='javascript:void(0);'><img src="/images/pymt-lock.svg" alt="Payment Lock"/>Pay $1,500 Now</a>
                                    </div>
                                    <div className='strip_mn'>
                                        <img src="/images/pymt-strip.png" alt="Powered by stripe"/>
                                    </div>
                                    <p>Questions? <a href='javascript:void(0);' title='Contact us'>Contact us.</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                */} 
                </div>
            </React.Fragment>
        )
    }
}