import './global.js'
import React, { Component } from 'react';
import Parse from 'parse';
import Home from "./components/home";
import Login from "./components/login";
import Footer from "./footer.jsx";
import RecipeList from "./components/recipelist";
import ProductList from "./components/productlist";
import CreatorList from "./components/creatorlist";
import ReactGA from 'react-ga';
import { ReactNotifications, Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

import './App.css';
//import './Dropdown.css';

class AppTop extends Component {

  
  constructor(props) {
    super(props);

    // TODO don't forget to add your app and js ids
    
    Parse.initialize("ze4eQAdmycW7e8MCskILkfGFUNdwYnsKSsZDi0t5", "sDC1XmRXe4bP5i1VqhBhy8cIdiWrBpJmvtmmwvIw"); //PASTE HERE YOUR Back4App APPLICATION ID AND YOUR JavaScript KEY
    Parse.serverURL = "https://parse.joelskitchen.com/parse";
    //Parse.Cloud.useMasterKey();

    console.log("Init GA");
    ReactGA.initialize('UA-60526992-2');
    ReactGA.pageview(window.location.pathname + window.location.search);
    let currentUser = Parse.User.current(); 
    let displayUsername='';
    let getUsername='';
    let getUseremail='';
    if(currentUser)
    {
       displayUsername = currentUser.get("displayUsername");
       getUsername = currentUser.get("username");
       getUseremail = currentUser.get("email");
    }    
    this.usernameParam = displayUsername;
    this.state = {
            getUsername: getUsername,
            getUseremail:getUseremail,
            userImage: null,
            adminlogo:false
        };
    this.queryForUserRecordData();
    this.handleLogoutfunc = this.handleLogoutfunc.bind(this);
    var url = window.location.href;
     if(url.includes('/admin'))
    {

      this.state.adminlogo = true;
      console.log('admin page dd')
    }

  }
     handleLogoutfunc(event) {
        event.preventDefault();
        Parse.User.logOut().then(() => {
            this.forceUpdate();
            this.setState({loggedIn:false});
            var currentUser = Parse.User.current();
            setTimeout(function(){
                window.location.reload(true); 
            },2000); 
        });
    }
  queryForUserRecordData() {
        var query = new Parse.Query('User');       
        query.equalTo("displayUsername", this.usernameParam);
        query.include("image");
        query.include("thumbnail");   
        var self = this;
        query.first().then(function(result){
            if(result){       
               self.setState({userImage: result.get("profilePictureSmall").url()});
            } else {

            }
        }, function(error){
                console.error("Error loading post Error: " + error);
                //promise.error(error);
        });
    }
  

  render() {
    var currentUser = Parse.User.current();
    var fetchUsername = this.state.getUsername;
    var fetchEmail = this.state.getUseremail;
    var loggedin='';
    if(currentUser)
    {
      loggedin=currentUser.get('username');
    }
    var adminlogo=this.state.adminlogo;
    
    return (
        <>
          <ReactNotifications />
          <header className="header-mn1 header-mn"> 
            <nav id="navbarnew" className="navbar">
              <div className="container">
                <div className="inner d-flex flex-row flex-wrap align-items-md-center align-items-center justify-content-between ">
                  <div className="logo">
                    <a id="logo" rel="home" href="/">{adminlogo===true?  <img src="/images/logo-dashboard.png" alt="Joel's Kitchen" className='dt_logo'/>:<img src="/images/JKCM_Logo.png" alt="Joel's Kitchen" className='dt_logo'/>}
                    {/*  <img src="/images/logo-dashboard.png" alt="Joel's Kitchen" className='dt_logo'/>*/}


                      {/* <span className='divider'></span>
                      <img src="/images/crt_mrk_plc.png" alt="Creator Marketplace" className='txt_logo'/> */}
                    </a>
                  </div>          
                    {/* <div class="logo_nav">
                    <a id="logo" rel="home" href="https://www.joelskitchen.com/">
                    <img src="/images/logo.png" width="175" height="auto"/></a>
                    </div>*/}
                  <div className='menu-search-wrap menu-search-social d-flex flex-row flex-wrap align-items-md-center'>
                    <div className="menu_nav d-flex flex-row flex-wrap">
                        {loggedin && fetchUsername?  <div className="signup_nav user_prfl_nav btn-group">               
                          <button type="button" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {this.state.userImage!==null?
                              <img height="50" width="50" className="userProfileImageLarge"  src={this.state.userImage}  alt={fetchUsername} />
                              :<img height="50" width="50"  src="/images/default_userpic0.png" alt="Profile Picture" />
                            }
                          </button>
                          <div className="dropdown-menu dropdown-menu-right">
                            <button className="dropdown-item" onClick={this.handleLogoutfunc} type="button">Log Out</button>
                          </div>
                      
                          <button id='handlemenu'  style={{display:"none"}}   className='btn btn-primary' onClick={this.selectSection}>menu</button>
                        </div>

                        : <div className="signup_nav cta_btn d-flex flex-row flex-wrap">
                          <div className='third-btn btn-wrp'>
                            <button type="button" className='btn' data-toggle="modal" data-target="#loginModal"  id="login_popup_btn">Log in</button>
                          </div>
                        </div>}
                    </div>
                  </div>

                                    
                    {/*        <div class="business_nav">
                  <a href="/business/"><b>Business</b></a>
                  </div>

                  <div class="create_nav">
                  <a href="https://create.joelskitchen.com"><b>Create</b></a>
                  </div>

                  <div class="signup_nav">
                  <a href="https://apps.apple.com/us/app/joels-kitchen/id1151259671"><b>Sign Up</b></a>
                  </div>
                        */} 
              
            </div>
              </div>
            </nav>
          </header>
        </>
    );
  }
}

export default AppTop;
