import '../global.js'
import Parse from 'parse';
import React from 'react';

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            rememberMe: false,
            message: '',
            loggedIn: true,
        };

        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleRememberMeClick = this.handleRememberMeClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleRememberMeClick() {
        this.setState({rememberMe: !this.state.rememberMe});
    }

    handleUsernameChange(event) {
        this.setState({email: event.target.value});
    }

    handlePasswordChange(event) {
        this.setState({password: event.target.value});
    }

    handleLogout(event) {
        event.preventDefault();
        Parse.User.logOut().then(() => {
            this.forceUpdate();
            this.setState({loggedIn:false});
            var currentUser = Parse.User.current();

            setTimeout(function(){
                window.location.reload(true); 
            },2000); 
        });
    }


    handleSubmit(event) {
        event.preventDefault();
/*
        const user = new Parse.User();
        user.set("username", this.state.email);
        user.set("email", this.state.email);
        user.set("password", this.state.password);
        user.set("rememberMe", this.state.rememberMe);
*/
         Parse.User.logIn(this.state.email.toLowerCase(), this.state.password, {
          success: user => {
            
                
                var msg = 'User logged in successful with username: ' + user.get("username") + ' and email: ' + user.get("email");
                this.setState({message: msg});
                this.forceUpdate();
               this.setState({loggedIn: true});
                console.log(msg);
                window.location.reload(true);
          },
          error: (user, error) => {
                var msg = ("The login failed with error: " + error.code + " " + error.message);
                this.setState({message: msg});
                this.forceUpdate();
               this.setState({loggedIn: false});
                console.log(msg);
                window.location.reload(true);

          }, 
        });



        let install = new Parse.Installation();
        install.set("deviceType", navigator.userAgent);

        install.save().then((resp) => {
          console.log('Created install object', resp);

          this.setState({
            result: 'New object created with objectId: ' + resp.id
          })
        }, err => {
          console.log('Error creating install object', err);

          this.setState({
            result: 'Failed to create new object, with error code: ' + err.message
          })
        });
    }

    render() {
        var result = (
            <div className="container">
               {/* <form className="form-signup" onSubmit={this.handleSubmit}>
                    <br/><br/>
                    <h4 className="form-signup-heading">Please log in to your Joel's account.</h4>
                    <input type="text" className="form-control" placeholder="Email address" required
                           autoFocus onChange={this.handleUsernameChange}/>
                    <input type="text" className="form-control" placeholder="Password" required
                           onChange={this.handlePasswordChange}/>
                           <br/>
                           

                    <button className="btn btn-lg btn-primary btn-block" type="submit">Login</button>
                    <br/>
                    <h4>{this.state.message}</h4>
                </form>*/}
            </div>

        );
        var user = Parse.User.current();
        var gray = "#888888";
        if (user != null)
        {
            var email = user.get("displayName");
            var isAdmin = user.get("isAdmin");
            if (isAdmin!==undefined && isAdmin==="1")
            {
                result = (
                    <div className="container">
                        <form className="form-signup" onSubmit={this.handleSubmit}>Logged in as {email} <span class="admin_text">[ADMIN]</span> <a onClick={this.handleLogout}>(Logout?)</a></form>
                    </div>
                );

            }else
            {
                result = (
                    <div className="container">
                        <form className="form-signup" onSubmit={this.handleSubmit}>Logged in as {email} <a onClick={this.handleLogout}>(Logout?)</a></form>
                    </div>
                );
            }

            /*if (!isAdmin)
            {
                result = (
                    <div className="container">
                        <form className="form-signup" onSubmit={this.handleSubmit}>Logged in as {email} <a onClick={this.handleLogout}>(Logout?)</a></form>
                    </div>
                );
            }
            else
            {
                result = (
                    <div className="container">
                        <form className="form-signup" onSubmit={this.handleSubmit}>Logged in as {email} <span class="admin_text">[ADMIN]</span> <a onClick={this.handleLogout}>(Logout?)</a></form>
                    </div>
                );
            }*/
        }
        return result;
        /* 
                    <label className="checkbox">
                        <input type="checkbox" value="remember-me" onChange={this.handleRememberMeClick}/>
                        &nbsp; Remember me
                    </label>
                    */

    }


}

export default Login;