
import Parse from 'parse';
import React from 'react';

import './creatorlist.css';

class CreatorList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            creators: {},
            width: 0, height: 0,
        };

        this.getCreators = this.getCreators.bind(this);
        this.getCreatorsAux = this.getCreatorsAux.bind(this);

        this.getCreatorsAux();

        global.productList = this;

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);


        var query = new Parse.Query('GlobalStats');
        //query.equalTo("id", "tRYoc3dDiZ");
       
        var self = this;
        console.log("GLOBALSTATS");
        query.find().then(function(result){
            if(result){
                self.setState({globalStats: result[0]});
                console.log("GLOBALSTATS<><>:");
                console.log(result);
            // If result was defined, the object with this objectID was found
                //promise.resolve(result);
            } else {
                console.log("No GLOBALSTATS found");
               // promise.resolve(null);
            }
        }, function(error){
                console.error("Error searching for GLOBALSTATS  Error: " + error);
                //promise.error(error);
        });
    }

    componentDidMount() {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }


    getCreatorsAux() {
        console.log('Getting Creators for current user');


        var isAdmin = false;
        if (Parse.User.current())
            isAdmin = Parse.User.current().get("isAdmin");

        var query = new Parse.Query('_User');
       
       //TEMP -- see all Products
        //if (!isAdmin)
        //    query.equalTo("user", Parse.User.current());

        //query.ascending("name");
        query.limit(500);

        query.equalTo("isCreator", 1);
        query.include("profilePictureSmall");
        query.include("displayName");
        query.include("recipeRate");
        query.include("videoRate");
        query.include("photoRate");
        query.include("instagram");
        query.include("facebook");
        query.include("pinterest");
        query.include("tiktok");
        query.include("youtube");
        query.include("website");
        query.include("name");
        query.include("userStats");
        query.ascending("displayUsername");

        var self = this;

        query.find().then(function(result){
            if(result){
                console.log(result);
                self.setState({creators: result});
                console.log(self.state.creators);
                // If result was defined, the object with this objectID was found
                //promise.resolve(result);
            } else {
                console.log("No Creators found");
               // promise.resolve(null);
            }
        }, function(error){
                console.error("Error searching for Creators  Error: " + error);
                //promise.error(error);
        });

    }

    getCreators(event) {
        event.preventDefault();
        this.getCreators();
    }


    formatCount(count)
    {
        if ((!count) || (count == undefined))
            return "";
        
        if (count > 1000000)
        {
            let dig1 = Math.floor(count / 1000000);
            let dig2 = Math.floor(count / 100000)-(dig1 * 10);
            if (dig2 == 0)
                return dig1 + "m";
            return dig1 + "." + dig2 + "m";
        }
        else if (count > 1000)
        {
            let dig1 = Math.floor(count / 1000);
            let dig2 = Math.floor(count / 100)-(dig1 * 10);
            if (dig2 == 0)
                return dig1 + "k";
            return dig1 + "." + dig2 + "k";
        }
        return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    formatCountNoAbbrev(count)
    {
        if ((!count) || (count == undefined))
            return "";
        
        return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    
    formatRate(rate)
    {
        if ((!rate) || (rate == undefined))
            return "";
        
        if (rate.includes("$"))
            return rate;
        else
            return "$" + rate;
    }

    formatSocialLink(rate)
    {
        if ((!rate) || (rate == undefined))
            return "";
        
        if (rate.includes("$"))
            return rate;
        else
            return "$" + rate;
    }

    socialLinkClass(linkVal)
    {
        if (linkVal && (linkVal != undefined) && (linkVal.length > 1))
        {
            return "sociallink";
        }
        else
            return "displayNone";
    }

    formatWebsite(linkVal)
    {
        if ((!linkVal) || (linkVal == undefined))
            return "";

        if (linkVal.includes("http://") || linkVal.includes("https://") || linkVal.includes("Https://") || linkVal.includes("Http://"))
            return linkVal;
        else
            return "https://" + linkVal;
    }

    formatFacebook(linkVal)
    {
        if ((!linkVal) || (linkVal == undefined))
            return "";

        linkVal.replace("@", "");

        linkVal.replace("m.facebook.com", "facebook.com");

        if (linkVal.includes("http://") || linkVal.includes("https://") || linkVal.includes("Https://") || linkVal.includes("Http://"))
            return linkVal;
        else if (linkVal.includes("facebook.com"))
            return "https://" + linkVal;
        else
            return "https://facebook.com/" + linkVal;
    }

    formatPinterest(linkVal)
    {
        if ((!linkVal) || (linkVal == undefined))
            return "";

        linkVal.replace("@", "");
        
        if (linkVal.includes("http://") || linkVal.includes("https://") || linkVal.includes("Https://") || linkVal.includes("Http://"))
            return linkVal;
        else if (linkVal.includes("pinterest.com"))
            return "https://" + linkVal;
        else
            return "https://pinterest.com/" + linkVal;
    }

    formatInstagram(linkVal)
    {
        if ((!linkVal) || (linkVal == undefined))
            return "";

        linkVal.replace("@", "");
        
        if (linkVal.includes("http://") || linkVal.includes("https://") || linkVal.includes("Https://") || linkVal.includes("Http://"))
            return linkVal;
        else
            return "https://instagram.com/" + linkVal;
    }

    renderFollowerCount(followerProps) //followerCount, followerChange, abbrev)
    {  
        var followerCount = followerProps.followerCount;
        var followerChange = followerProps.followerChange;
        var abbrev = followerProps.abbrev;

        if (followerCount == undefined)
            followerCount = 0;
        if (followerChange == undefined)
            followerChange = 0;

        var count = abbrev ? this.formatCount(followerCount) : this.formatCountNoAbbrev(followerCount);
        var countChangeClass = followerChange >= 0 ? "followerChangeUp" : "followerChangeDown";

        if (followerChange >= 0)
            followerChange = "+" + followerChange;

        var result = (
            <div>
            {count}
            <div class={countChangeClass}>
             &nbsp;
            </div>
            </div>
        );

        return result;
    }

    renderSocial(creator) {
        var result = ( <div> </div>
            );

        var instagram = creator.get("instagram");
        var facebook = creator.get("facebook");
        var pinterest = creator.get("pinterest");
        var tiktok = creator.get("tiktok");
        var youtube = creator.get("youtube");
        var website = creator.get("website");

        result = (
            <div>

            <div class={this.socialLinkClass(instagram)}>

            <a href={this.formatInstagram(instagram)}>
            <img src="images/social/instagram.png" width="32" height="32"></img>
            </a>

            </div>
            <div class={this.socialLinkClass(tiktok)}>

            <a href={this.formatWebsite(tiktok)}>
            <img src="images/social/tiktok.png" width="32" height="32"></img>
            </a>

            </div>
            <div class={this.socialLinkClass(youtube)}>
            
            <a href={this.formatWebsite(youtube)}>
            <img src="images/social/youtube.png" width="32" height="32"></img>
            </a>

            </div>
            <div class={this.socialLinkClass(website)}>

            <a href={this.formatWebsite(website)}>
            <img src="images/social/website.png" width="32" height="32"></img>
            </a>

            </div>
            </div>
            );
        return result;
    }

    render() {
        //console.log("Rendering product list");
        var result = (
            <div className="container">
            </div>

        );

        var user = Parse.User.current();
        if (user)
            user.fetch();
        if (!user)
            return result;

        if (1)
        {
            let creators = (this.state.creators.length !== undefined) ? this.state.creators : [];
            //console.log("Products length " + products.length);

            var self = this;

           // if (this.state.curProduct != null)
            if (0) 
            {
                
            }
            else if (1)
            {
                var lastUpdated = this.state.globalStats ? this.state.globalStats.get("lastCreatorDirUpdate") : "";

                console.log("Render: globalstats are " +  this.state.globalStats);

                if (this.state.width > 1000)
                {
                     result =  (
                         <div className="container" key="-1">
                         <div class="joelSectionHeader" width="100%">
                         <h3>Creator Directory</h3>
                         </div>
                         <div class="creatorDirLastUpdate">Last update was: {lastUpdated}</div><br/>
                         <table class="creatorTable">
                        <thead>
                            <tr>
                                <th>Creator</th> 
                                <th>
                                    <img src="images/social/joels.png" width="32" height="32"></img>
                                    &nbsp;Post Rate
                                </th>
                                <th>
                                    <img src="images/social/joels.png" width="32" height="32"></img>
                                    &nbsp;Recipe Rate
                                </th>
                                <th>
                                    <img src="images/social/instagram.png" width="32" height="32"></img>
                                    &nbsp;Followers
                                </th>
                                <th>Social Links</th>
                            </tr>
                        </thead>
                        <tbody>
                         { creators.map(function (creator, i) {

                                //itemUser.fetch();
                                var itemUsername = creator.get("displayUsername");

                                var followerProps = {
                                    followerCount: creator.get("userStats") ? creator.get("userStats").get("instaFollowers") : "",
                                    followerChange: creator.get("userStats") ? creator.get("userStats").get("instaFollowersChange") : "",
                                    abbrev: false
                                }

                                {
                                     return (
                                        <tr>
                                        <td><a href={"https://www.joelskitchen.com/user/" + itemUsername}>
                                        <div class="creatorUsername">
                                            <img class="userProfileImageCreator" src={creator?creator.get("profilePictureSmall")?creator.get("profilePictureSmall").url():'':''}/>
                                            &nbsp; {itemUsername}
                                            
                                        </div></a>
                                        </td>

                                        <td>{self.formatRate(creator.get("photoRate"))}</td>

                                        <td>{self.formatRate(creator.get("recipeRate"))}</td>

                                        <td>
                                            {self.renderFollowerCount(followerProps)}</td>
                                        <td>{self.renderSocial(creator)}</td>
                                       </tr>
                                    );
        /*
                                    return (
                                       <div class="productListEntry" key={i}> <a class="itemLink" onClick={() => self.selectProduct(product)} >{product.get("name")}</a> by {itemUsername}</div>
                                    );
                                    */
                                }

                            })}

                        </tbody>
                        </table><br/>
                        <center>
                        
                        <div class="emailTeam">
                            <a href="mailto:joel@joelskitchen.com"><span class="emailTeamEmail">Email</span>&nbsp;
                            <span class="emailTeamSecondPart">for a full list of rates & analytics from Joel's Kitchen.</span></a>
                        </div><br/>
                        </center>
                     </div>
                      );
                }
                else //mobile
                {
                    result =  (
                         <div className="container" key="-1">
                         <div class="joelSectionHeader" width="100%">
                         <h3>Creator Directory</h3>
                         </div>
                         <div class="creatorDirLastUpdate">Last update was: {lastUpdated}</div><br/>
                         <table class="creatorTable">
                        <thead>
                            <tr>
                                <th>Creator</th> 

                                <th>
                                    <img src="images/social/joels.png" width="32" height="32"></img>
                                    <br/>Post Rate
                                </th>
                                <th>
                                    <img src="images/social/instagram.png" width="32" height="32"></img>
                                    <br/>Followers
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                         { creators.map(function (creator, i) {

                                //itemUser.fetch();
                                var itemUsername = creator.get("displayUsername");
                                var followerProps = {
                                    followerCount: creator.get("userStats") ? creator.get("userStats").get("instaFollowers") : "",
                                    followerChange: creator.get("userStats") ? creator.get("userStats").get("instaFollowersChange") : "",
                                    abbrev: true
                                }

                                {
                                     return (
                                        <tr>
                                        <td><a href={"https://www.joelskitchen.com/user/" + itemUsername}>
                                        {itemUsername}
                                        </a>
                                        </td>
                                        
                                        <td>{self.formatRate(creator.get("photoRate"))}</td>
                                        <td>
                                            {self.renderFollowerCount(followerProps)}</td>
                                       </tr>
                                    );
        /*
                                    return (
                                       <div class="productListEntry" key={i}> <a class="itemLink" onClick={() => self.selectProduct(product)} >{product.get("name")}</a> by {itemUsername}</div>
                                    );
                                    */
                                }

                            })}

                        </tbody>
                        </table><br/>
                        <center>
                        <div class="emailTeam">
                            <a href="mailto:joel@joelskitchen.com"><span class="emailTeamEmail">Email</span></a>&nbsp;
                            <span class="emailTeamSecondPart">for a full list of rates & analytics from Joel's Kitchen.</span>
                        </div><br/>
                        </center>
                     </div>
                      );
                }
                }

         /*

                  { self.state.ingredTypes.map(function (ingredType, i) {
                        return (
                           <span key={i+100}><h4> {ingredType}  <a onClick={() => self.handleAdd(i)} >Add</a></h4></span>

                           { self.state.ingredArr[i].map(function (ingred, j) {
                                return (
                                   <span key={(i*j)+100}><h4> {ingred}  <a onClick={() => self.handleDelete(i,j))} >Delete</a></h4></span>
                                );
                            })}
                        );
                    })}
                    */
/*
            result =  (
                 <div className="container" key="-1">
                 <h3>My Products</h3>
                 {buffer}
                 <span >Test<a onClick={this.selectProduct}>(selectProduct?)</a></span>
                 <br/>
                <form className="form-signup" onSubmit={this.handleNew}>
                    <button className="btn btn-lg btn-primary btn-block" type="submit">New Product</button>
                </form>
                <span><a onClick={this.selectProduct}>
                       TEST
                    </a></span>
             </div>
              );
              */

/*
  <li>
                  <div className='view'>
                    <label>{product.title}</label>
                    <button className="destroy" onClick={this.deleteTodo.bind(null, todo)} />
                  </div>
                </li>

            result = (
                 <div className="container">
                 <h3>My Products</h3>
                 <br/>
                <form className="form-signup" onSubmit={this.handleNew}>
                    <br/><br/>
                    <button className="btn btn-lg btn-primary btn-block" type="submit">New Product</button>
                </form>
            </div>
            );


/*
            return (
               <span key={i+100}><h4> {ingredType}  <a onClick={() => self.handleAdd(i)} >Add</a></h4></span>

               { self.state.ingredArr[i].map(function (ingred, j) {
                    return (
                       <span key={(i*j)+100}><h4> {ingred}  <a onClick={() => self.handleDelete(i,j))} >Delete</a></h4></span>
                    );
                })}
            );*/


        }
        return result;
        /* 
                    <label className="checkbox">
                        <input type="checkbox" value="remember-me" onChange={this.handleRememberMeClick}/>
                        &nbsp; Remember me
                    </label>
                    */

    }


}

export default CreatorList;