import React, { Component } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './App.css';
import StripeCheckoutComponent from './StripeCheckoutComponent';

// Initialize Stripe
const stripePromise = loadStripe('pk_test_51N1Iw3F2uVaAxW23DxC7agGfTzAW2GmNeWovzSsHYi2laozcLAIdliI06EIKpXPS34FaaOGTyTYqrHqhqrm19MKi001fafzmiU');

class Stripepayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: '',
      isLoading: false,
      paymentResult: null,
    };
  }

  handleAmountChange = (event) => {
    this.setState({ amount: event.target.value });
  };


  render() {
    return (
      <div className="App">
        <h1>Stripe Payment Integration</h1>


        <Elements stripe={stripePromise}  >
          <CheckoutForm
            amount={this.state.amount}
            handleAmountChange={this.handleAmountChange}
            isLoading={this.state.isLoading}
            setLoading={(isLoading) => this.setState({ isLoading })}
            setPaymentResult={(paymentResult) => this.setState({ paymentResult })}
          />
        </Elements>
        {this.state.paymentResult && (
          <div>
            <h2>Payment Result</h2>
            <pre>{JSON.stringify(this.state.paymentResult, null, 2)}</pre>
          </div>
        )}
      </div>
    );
  }
}

const CheckoutForm = ({ amount, handleAmountChange, isLoading, setLoading, setPaymentResult }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
           "name": "azhar",
           "email": "azhar@5edev.com"
       },
    });

    if (error) {
      console.error(error);
      setPaymentResult({ error });
      setLoading(false);
      return;
    }

    // Here, you would send the paymentMethod.id to your server to create a payment intent
    // For the purpose of this example, we'll just log it
    console.log('PaymentMethod:', paymentMethod);

    // Simulate server response
    setTimeout(() => {
      setPaymentResult({ success: true, paymentMethod });
      setLoading(false);
    }, 1000);
  };

  return (<div> <p><br/><br/><br/><br/><br/><br/><br/></p>
    <StripeCheckoutComponent />
   afaff <form onSubmit={handleSubmit}>
   

      <label>
        Amount
        <input
          type="number"
          value={amount}
          onChange={handleAmountChange}
          disabled={isLoading}
        />
      </label>
      <CardElement />
      <button type="submit" disabled={!stripe || isLoading}>
        {isLoading ? 'Processing…' : 'Pay'}
      </button>
    </form></div>
  );
};

export default Stripepayment;