import '../global.js'
import React, { Component } from 'react';
import Parse from 'parse';
import ReactTable from "react-table-v6";
import 'react-table-v6/react-table.css'
import Postcounts from "./postcounts.jsx";
import Challengeentries from "./Challengeentries.jsx";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import Dropdown from 'react-dropdown';
import Followerscounts from "./followerscounts.jsx";
//import Challengecheck from "./Challengecheck.jsx";
//import 'bootstrap/dist/css/bootstrap.css';
import Engagementcounts from "./engagementcounts.jsx";
import SaveNotification from "../saveNotification.jsx";
 function time_ago(time) {

    switch (typeof time) {
      case 'number':
        break;
      case 'string':
        time = +new Date(time);
        break;
      case 'object':
        if (time.constructor === Date) time = time.getTime();
        break;
      default:
        time = +new Date();
    }
    var time_formats = [
      [60, 's', 1], // 60
      [120, '1m', '1 minute from now'], // 60*2
      [3600, 'm', 60], // 60*60, 60
      [7200, '1h', '1 hour from now'], // 60*60*2
      [86400, 'h', 3600], // 60*60*24, 60*60
      [172800, '1d', 'Tomorrow'], // 60*60*24*2
      [604800, 'd', 86400], // 60*60*24*7, 60*60*24
      [1209600, '1w', 'Next week'], // 60*60*24*7*4*2
      [2419200, 'w', 604800], // 60*60*24*7*4, 60*60*24*7
      [4838400, '1m', 'Next month'], // 60*60*24*7*4*2
      [29030400, 'm', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
      [58060800, '1y', 'Next year'], // 60*60*24*7*4*12*2
      [2903040000, 'y', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
      [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
      [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    /* [60, 'seconds', 1], // 60
      [120, '1 minute ago', '1 minute from now'], // 60*2
      [3600, 'minutes', 60], // 60*60, 60
      [7200, '1 hour ago', '1 hour from now'], // 60*60*2
      [86400, 'hours', 3600], // 60*60*24, 60*60
      [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
      [604800, 'days', 86400], // 60*60*24*7, 60*60*24
      [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
      [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
      [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
      [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
      [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
      [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
      [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
      [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100*/
    ];
    var seconds = (+new Date() - time) / 1000,
    // token = 'ago',
      token = '',
      list_choice = 1;

    if (seconds == 0) {
    // return 'Just now'
      return ''
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      //token = 'from now';
      token = '';
      list_choice = 2;
    }
    var i = 0,
      format;
    while (format = time_formats[i++])
      if (seconds < format[0]) {
        if (typeof format[2] == 'string')
          return format[list_choice];
        else
        // return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
          return Math.floor(seconds / format[2]) + '' + format[1] + '' + token;
      }
    return time;
  }
const options = [ 
    { value: 'hideEntry', label: 'Hidden' }, 
    { value: 'isChallengeWinner', label: 'Winner' }, 
  ];
const statusOptions=[ 'Pending','Billed','Active','Complete'];




class Adminentries extends React.Component {
    constructor(props) {
    super(props);
    this.state = {
      posts: [],
      loading: true,
      page: 1,
      pageSize: 100,
      total: 0,      
      filterTitle: '',
      pagestatus: 1,
      totalpostCount:0,
      entry_update_msg:null,
      selectedValue: {},
      loading1: true,
      page1: 1,
      pageSize1: 100,
      filterTitle1: '',
      totaluserCount:0,
      sortEntryField: 'createdAt',
      sortEntryOrder: 'desc',
      sortCreatorField: 'createdAt',
      sortCreatorOrder: 'asc',
      isNotificationOpen:false,
      post_Notification_Id:'',
      post_Notification_Msg:'',
      post_Notification_Comment:''
    };
    this.handleSearch = this.handleSearch.bind(this);
    //this.handleStatusChange = this.handleStatusChange.bind(this);
   // this.handleChange = this.handleChange.bind(this);
    let currentUser = Parse.User.current();   
        
        if(!currentUser || (currentUser.get('isAdmin')!==undefined && currentUser.get('isAdmin')!=="1"))
        {
            window.location.href = "/";
        }
  }

radioHandler = (pagestatus) => {
    this.setState({ pagestatus });
  };
   handleSearch = (event) => {
        const searchTerm = event.target.value;
        let reactTableData= this.state.posts;
        let searchreactTableData= this.state.postsdata;
        var self = this;
        if(event.target.value)
        {
            //this.getPostAux(searchTerm);
             const newreactTableData = searchreactTableData.filter(item => 
             //console.log(item.get('name'))       
              item.get('displayName').toLowerCase().includes(searchTerm.toLowerCase())
            );
              self.setState({posts:newreactTableData});
        }else{
             this.fetchData();
        }
    }

  componentDidMount() {
    this.fetchData();
    this.fetchCreatorData();
  }
  handlePageChange1 = (page1) => {
    this.setState({ page1, loading1: true }, () => {
      this.fetchCreatorData();//true
    });
  };
   toggleNotification = (postid,msg,comment) => {   console.log('toggleNotification');
      var self = this;
        self.setState(prevState => ({
          isNotificationOpen: !prevState.isNotificationOpen,
          post_Notification_Id: postid,
          post_Notification_Msg: msg,
          post_Notification_Comment:comment 
        }));
        //self.setState({ post_Notification_Id: postid });        
      };
   fetchCreatorData = () => {
    const { page1, pageSize1,filterTitle1,sortCreatorField, sortCreatorOrder   } = this.state;
    const skip1 = (page1 - 1) * pageSize1;

     var query = new Parse.Query('_User');
        query.limit(99999999999999999999999999);
        query.equalTo("isCreator", 1);  
       // query.include('Photo');      
        query.include("profilePictureSmall");
        query.include("displayName");  
        query.include("displayUsername");   
        query.include("instagram");
        query.include("facebook");
        query.include("pinterest");
        query.include("tiktok");
        query.include("youtube");
        query.include("website");
        query.include("name");
        query.include("email");
        //query.descending("createdAt");
         if(sortCreatorOrder==='desc')
        {
             query.descending(sortCreatorField);
        }else{
             query.ascending(sortCreatorField);
        } 
    query.limit(pageSize1);
    query.skip(skip1);
    if (filterTitle1) {
      query.contains('displayUsername', filterTitle1);
    }
    query.find()
      .then(results => {
        this.setState({
          users: results,
          usersdata: results,
          loading1: false
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        this.setState({ loading1: false });
      });
      var query1 = new Parse.Query('_User');
        query1.limit(99999999999999999999999999);
        query1.equalTo("isCreator", 1);  
        query1.include('Photo');      
        query1.include("profilePictureSmall");
        query1.include("displayName");
        query1.include("recipeRate");
        query1.include("videoRate");
        query1.include("photoRate");
        query1.include("instagram");
        query1.include("facebook");
        query1.include("pinterest");
        query1.include("tiktok");
        query1.include("youtube");
        query1.include("website");
        query1.include("name");
        query1.include("email");
        //query1.descending("createdAt");
        if(sortCreatorOrder==='desc')
        {
             query1.descending(sortCreatorField);
        }else{
             query1.ascending(sortCreatorField);
        } 
        //query1.limit(pageSize);
        query1.find().then(resultsdata => {
          this.setState({
            usersdata: resultsdata,
            totaluserCount: resultsdata.length,
          });
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          //this.setState({ loading: false });
        });
      };

  fetchData = () => {
    const { page, pageSize,filterTitle,sortEntryField, sortEntryOrder  } = this.state;
    const skip = (page - 1) * pageSize;
    let total_mainquery;
    let filterTitleMain=filterTitle.trim();
    var query = new Parse.Query('Photo');
    query.equalTo("isChallengeEntry", "1");
    query.include('challenge');
    query.include('user');
    query.include('user.displayUsername');
    query.select("user.displayUsername");
  
     if(sortEntryOrder==='desc')
    {
         query.descending(sortEntryField);
    }else{
         query.ascending(sortEntryField);
    } 

    query.limit(pageSize);
    query.skip(skip);
    if (filterTitleMain) {
        query.matchesQuery('user', new Parse.Query('_User').contains('displayUsername',filterTitleMain));
    }
    /*new code*/
    if(filterTitleMain)
    {
       var otherquery  = new Parse.Query('Photo');
    otherquery.equalTo("isChallengeEntry", "1");

    otherquery.include('challenge');
    otherquery.include('user');
    otherquery.select("homeCount");
    otherquery.include('user.displayUsername');
    if(sortEntryOrder==='desc')
    {
         otherquery.descending(sortEntryField);
    }else{
         otherquery.ascending(sortEntryField);
    }  
    otherquery.limit(pageSize);
    otherquery.skip(skip);
    if (filterTitleMain) {
        otherquery.matchesQuery('user', new Parse.Query('_User').contains('username',filterTitleMain));
    }

     total_mainquery = Parse.Query.or(query, otherquery);

    }else{
     total_mainquery=query;
    }

   
    total_mainquery.find()
      .then(results => {
        this.setState({
          posts: results,
          postsdata: results,
          loading: false
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        this.setState({ loading: false });
      });


        var query1 = new Parse.Query('Photo');
    query1.equalTo("isChallengeEntry", "1");
    query1.include('challenge');
    query1.include('user');
    query1.include('user.displayUsername');
    query1.limit(9999999999);
     if(sortEntryOrder==='desc')
    {
         query1.descending(sortEntryField);
    }else{
         query1.ascending(sortEntryField);
    } 
 query1.find()
      .then(results => {
        this.setState({
          postsdata: results,
           totalpostCount: results.length,
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        this.setState({ loading: false });
      });



  };

  handlePageChange = (page) => {
    this.setState({ page, loading: true }, () => {
      this.fetchData();//true
    });
  };
  socialLinkClass(linkVal)
    {
        if (linkVal && (linkVal != undefined) && (linkVal.length > 1))
        {
            return "sociallink sociallinkitem";
        }
        else
            return "displayNone sociallinkitem";
    }
      formatWebsite(linkVal)
    {
        if ((!linkVal) || (linkVal == undefined))
            return "";

        if (linkVal.includes("http://") || linkVal.includes("https://") || linkVal.includes("Https://") || linkVal.includes("Http://"))
            return linkVal;
        else
            return "https://" + linkVal;
    }

    formatFacebook(linkVal)
    {
        if ((!linkVal) || (linkVal == undefined))
            return "";

        linkVal.replace("@", "");

        linkVal.replace("m.facebook.com", "facebook.com");

        if (linkVal.includes("http://") || linkVal.includes("https://") || linkVal.includes("Https://") || linkVal.includes("Http://"))
            return linkVal;
        else if (linkVal.includes("facebook.com"))
            return "https://" + linkVal;
        else
            return "https://facebook.com/" + linkVal;
    }

    formatPinterest(linkVal)
    {
        if ((!linkVal) || (linkVal == undefined))
            return "";

        linkVal.replace("@", "");
        
        if (linkVal.includes("http://") || linkVal.includes("https://") || linkVal.includes("Https://") || linkVal.includes("Http://"))
            return linkVal;
        else if (linkVal.includes("pinterest.com"))
            return "https://" + linkVal;
        else
            return "https://pinterest.com/" + linkVal;
    }

    formatInstagram(linkVal)
    {
        if ((!linkVal) || (linkVal == undefined))
            return "";

        linkVal.replace("@", "");
        
        if (linkVal.includes("http://") || linkVal.includes("https://") || linkVal.includes("Https://") || linkVal.includes("Http://"))
            return linkVal;
        else if (linkVal.includes("instagram.com"))
            return "https://" + linkVal;
        else
            return "https://instagram.com/" + linkVal;
    }
   renderSocial(creator) {
      var result = ( <div> </div>
          );

      if (!creator)
          return result;

      var instagram = creator.get("instagram");
      var facebook = creator.get("facebook");
      var pinterest = creator.get("pinterest");
      var tiktok = creator.get("tiktok");
      var youtube = creator.get("youtube");
      var website = creator.get("website");

      result = (
        <div className='socialLinkWrp'>
          <div class={this.socialLinkClass(instagram)}>
            <a target="_blank" href={this.formatInstagram(instagram)}>
              <img src="/images/social/create-instagram.svg" width="32" height="32" />
            </a>
          </div>
          <div class={this.socialLinkClass(tiktok)}>
            <a target="_blank" href={this.formatWebsite(tiktok)}>
              <img src="/images/social/create-tiktok.svg" width="32" height="32" />
            </a>
          </div>
          <div class={this.socialLinkClass(youtube)}>
            <a target="_blank" href={this.formatWebsite(youtube)}>
              <img src="/images/social/create-youtube.svg" width="32" height="32" />
            </a>
          </div>
          <div class={this.socialLinkClass(website)}>
            <a target="_blank" href={this.formatWebsite(website)}>
              <img src="/images/social/create-website.svg" width="32" height="32" />
            </a>
          </div>
          <div className={this.socialLinkClass(facebook)}>
            <a target="_blank" href={this.formatFacebook(facebook)}>
              <img src="/images/social/facebook-app.png" width="32" height="32"/>
            </a>
          </div>
        </div>
      );
      return result;
    }
     handleFilterChange = (event) => {
    this.setState({ filterTitle: event.target.value }, () => {
      // Reset page to 1 when filter changes  true
      this.setState({ page: 1, loading: true }, () => {
        this.fetchData();
      });
    });
  };
   handleFilterChange1 = (event) => {
    this.setState({ filterTitle1: event.target.value }, () => {
      // Reset page to 1 when filter changes  true
      this.setState({ page1: 1, loading1: true }, () => {
        this.fetchCreatorData();
      });
    });
  };
 

  render() {
    const { posts, loading, page, pageSize,filterTitle,pagestatus,totalpostCount,users, loading1, page1, pageSize1,filterTitle1,totaluserCount,sortEntryOrder,sortEntryField,sortCreatorField, sortCreatorOrder } = this.state;
    var isNotificationOpen=this.state.isNotificationOpen; 
var post_Notification_Ids=this.state.post_Notification_Id; 
var post_Notification_Msg=this.state.post_Notification_Msg;
var post_Notification_Comment=this.state.post_Notification_Comment;
var self = this;
 const handleSelectChange = (event, id) => {
    const selectedValue = event.target.value;
    let entry_id= id;  
        const currentUser = Parse.User.current();
        if(currentUser)
        {
            var query = new Parse.Query('Photo');  
            query.equalTo('objectId', entry_id);
            var self = this;
            query.find().then(function(photo){
                    if(photo)
                    {
                        let photo_ob=photo[0];
                        //console.log(selectedValue+'selectedValue');
                        /*if(selectedValue==='hideEntry')
                        {
                            photo_ob.set('hideEntry', "1");
                            photo_ob.set('isChallengeWinner', false);
                        }
                        if(selectedValue==='isChallengeWinner')
                        {
                            photo_ob.set('isChallengeWinner', true);
                            photo_ob.set('hideEntry', "0");
                            self.toggleNotification(entry_id,'was awarded winner of the challenge','');
                            self.setState({isNotificationOpen:false,post_Notification_Id:'',post_Notification_Msg:'',post_Notification_Comment:''});
                            self.toggleNotification(entry_id,'You was awarded winner of the challenge','');
                            self.setState({isNotificationOpen:false,post_Notification_Id:'',post_Notification_Msg:'',post_Notification_Comment:''});
                        }
                        if(selectedValue=='')
                        {
                           
                            photo_ob.set('hideEntry', "0");
                            photo_ob.set('isChallengeWinner', false);
                        }
                        photo_ob.save().then((updatedentry) => {
                             self.setState({entry_update_msg:"Entry has been updated!"});
                        })*/
                        if(selectedValue==='hideEntry')
                        {
                            photo_ob.set('hideEntry', "1");
                            photo_ob.set('isChallengeWinner', false);
                        }
                        if(selectedValue==='isChallengeWinner')
                        {
                            photo_ob.set('isChallengeWinner', true);
                            photo_ob.set('hideEntry', "0");
                           
                            self.toggleNotification(entry_id,'was awarded winner of the challenge','');
                            self.setState({isNotificationOpen:false,post_Notification_Id:'',post_Notification_Msg:'',post_Notification_Comment:''});
                            self.toggleNotification(entry_id,'You was awarded winner of the challenge','');
                            self.setState({isNotificationOpen:false,post_Notification_Id:'',post_Notification_Msg:'',post_Notification_Comment:''});
                        }
                        if(selectedValue==='isChallengeWinnerfalse')
                        {
                            photo_ob.set('isChallengeWinner', false);
                        }
                        if(selectedValue==='hideEntryfalse')
                        {
                            photo_ob.set('hideEntry', "0");
                        }
                        if(selectedValue=='')
                        {
                           
                            photo_ob.set('hideEntry', "0");
                            photo_ob.set('isChallengeWinner', false);
                        }
                        photo_ob.save().then((updatedentry) => {
                             self.setState({entry_update_msg:"Entry has been updated!"});
                        })

                    }
                })
           
            self.setState({selectedValue:selectedValue});
            setTimeout(() => {
                  self.setState({entry_update_msg:null}); 
              }, 4000);
        }
      
       
  };
const handleCreatorSort = (field) => {
    const { sortCreatorField, sortCreatorOrder } = this.state;
    const newSortOrder = sortCreatorField === field && sortCreatorOrder === 'asc' ? 'desc' : 'asc';
    
    this.setState({ sortCreatorField: field, sortCreatorOrder: newSortOrder }, this.fetchCreatorData);
  };
const handleEntrySort = (field) => {
    const { sortEntryField, sortEntryOrder } = this.state;
    const newSortOrder = sortEntryField === field && sortEntryOrder === 'asc' ? 'desc' : 'asc';
    
    this.setState({ sortEntryField: field, sortEntryOrder: newSortOrder }, this.fetchData);
  };
    return (
      <div className="account_main_top"> {isNotificationOpen?<SaveNotification   isOpen={isNotificationOpen}  postNotificationid={post_Notification_Ids} postMsg={post_Notification_Msg} postPhotoid={post_Notification_Ids} postActivityComment={post_Notification_Comment} />:""} 
        <div className="inner_wrap">
          <div className="table_top_searchbar post_topbar">
            <div className='left-part'>
              {pagestatus === 1 && <> <h2>Entries</h2> </>}
              {pagestatus === 2 && <> <a href="javascript:void(0);">Invite</a> <h2 className='sub-info'>Invite creators to launch a campaign for your brand.</h2> </>}
            </div>
            <div className="menu-search-wrap menu-search-mn d-flex flex-row flex-wrap align-items-md-center">
              <div className="search_wrap">
                <form className="form-mn d-flex flex-row flex-wrap">
                  <div className='search_inpt_inner'>
                    {/*onClick={this.searchSuggestion} */}
                    {pagestatus === 1 && 
                      <div>
                        <input className="form-control" placeholder="Search" aria-label="Search" type="text" value={filterTitle} onChange={this.handleFilterChange} />
                      </div>
                    }
                    {pagestatus === 2 &&
                      <div>
                        <input className="form-control" placeholder="Search" aria-label="Search" type="text" value={filterTitle1} onChange={this.handleFilterChange1} />
                      </div>
                    }
                    {/* <input className="form-control" type="search" placeholder="Search" aria-label="Search"  onChange={this.handleSearch} />*/}
                    <button type="submit"><img src="/images/search.svg" alt="Search"/></button>
                    <button type="button" className='close-btn hide' onClick={this.searchSuggestionCloase} ><img src="/images/close_small.svg" alt="Close"/></button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {loading ? 
            (
              <div className='admin_table_loader'>Loading...</div>
            ) : (
              <div className='admin_table_wrapper admin_entries  entries-table'>
                <div className='tab_Wrap'>
                  <div  className={pagestatus === 1?"tab_link topNavActive":"tab_link topNavInactive"} ><label htmlFor='release0'><input type="radio" id="release0" name="release"  checked={pagestatus === 1} onClick={(e) => this.radioHandler(1)} />Total Entries <span className="counter-account">{totalpostCount? totalpostCount.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></label></div>
                  <div  className={pagestatus === 2?"tab_link topNavActive":"tab_link topNavInactive"}  ><label htmlFor='release1'><input type="radio" name="release"  id="release1"  checked={pagestatus === 2} onClick={(e) => this.radioHandler(2)} />Total Creators <span className="counter-account">{totaluserCount? totaluserCount.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></label></div>
                </div>
                {pagestatus === 1 &&
                  <div className='admin_table_inner totalEntry'>
                    <p className='profile_message_alert_red entry_msg'>{this.state.entry_update_msg}</p>
                    <div className="admin_table">
                      <table className="creatorTable table-responsive">
                        <thead>
                          <tr>
                            <th className={sortEntryField === 'thumbnail' && (sortEntryOrder !== '' ? 'picture sort-arrow' : 'picture')?"sort-arrow picture":"picture"}    onClick={(event) => handleEntrySort('thumbnail')} >Picture <span className="updown_arrow">{sortEntryField === 'thumbnail' && (sortEntryOrder === 'asc' ? '↑' : '↓')}</span></th>
                            <th className={sortEntryField === 'impr' && (sortEntryOrder !== '' ? 'views sort-arrow' : 'views')?"sort-arrow views":"views"}   onClick={(event) => handleEntrySort('impr')}>Views <span className="updown_arrow">{sortEntryField === 'impr' && (sortEntryOrder === 'asc' ? '↑' : '↓')}</span></th>                               
                            <th className={sortEntryField === 'imprcount' && (sortEntryOrder !== '' ? 'engagements sort-arrow' : 'engagements')?"sort-arrow engagements":"engagements"}   onClick={(event) => handleEntrySort('imprcount')}>Engagements <span className="updown_arrow">{sortEntryField === 'imprcount' && (sortEntryOrder === 'asc' ? '↑' : '↓')}</span></th>
                            <th className='platforms'>Platforms</th>
                            <th className='status'>Status</th>
                            <th className='creator'>Creator</th>
                            <th className='business'>Business</th>
                            <th className={sortEntryField === 'createdAt' && (sortEntryOrder !== '' ? 'postedon sort-arrow' : 'postedon')?"sort-arrow postedon":"postedon"}   onClick={(event) => handleEntrySort('createdAt')}>Posted <span className="updown_arrow">{sortEntryField === 'createdAt' && (sortEntryOrder === 'asc' ? '↑' : '↓')}</span></th>
                            <th className='job' >Job</th>
                            <th className='post'  >Post </th>
                          </tr>
                        </thead>
                      <tbody>
                          { posts.map(function (post, i) {
                            let hideEntry=post.get('hideEntry')?post.get('hideEntry'):'';
                            let isChallengeWinner=post.get('isChallengeWinner')?post.get('isChallengeWinner'):'';
                            let ischallege=post.get('isChallenge')==1?'challege':'';
                            let different_class='';
                            if(hideEntry==="1")
                            {
                              different_class='hiddenclass';
                            }
                            if(isChallengeWinner===true)
                            {
                              different_class='winnerclass';
                            }
                            if(ischallege) {
                              // let selectedOption=post.get('challengeStatus');
                            } {
                              return (
                                        <tr   key={post.id}   className={different_class}>
                                      <td><div class="user_image"><a  href='javascript:void(0);'><span className='thumb'><img class="userProfileImageCreator" src={post != undefined ?
                      (post.get("thumbnail") != undefined ? post.get("thumbnail").url() : "/images/genericuser.png" ) : ""}/></span></a></div></td>
                          <td>
                                              {post.get("impr")?post.get("impr"):'0'}
                                          </td>
                        <td>{post.get("imprcount")?post.get("imprcount"):'0'}</td>
                                          
                                          {/*<td><Challengeentries postData={post.id} /></td>*/}
                                          <td className='platform'>


                                          {post.get('user')?self.renderSocial(post.get('user')):''}{/*post.get('url')?<a target="_blank" href={post.get('url')}> <img src="/images/social/website.png" width="20" height="20"></img><i class="bi bi-envelope"></i></a>:''*/}
                        {/*self.renderSocial(post)*/}{/*post.get('user')*/}</td>
                                          
                                          {/*post.get('isChallenge')!=undefined && post.get('isChallenge')==1?<span>Challenge</span>:<Challengecheck postData={post.get('recipeV2').id} postDataType="type" />*/}
                                        
                                          
                                          {/*selectedOption  onChange={(selectedOption) => this.onSelectStatus(selectedOption, post.id)}*/ }
                                            <td className='status'>{/*ischallege?<span> <Dropdown options={statusOptions}
                              placeholder="Status" value={post.get('challengeStatus')}
                                className="dropdownFix col-md-8">
                              </Dropdown>




</span>


                              :''*/}
            { /*hideEntry && hideEntry==1 && (<select
              value="hideEntry"
              onChange={(event) => handleSelectChange(event, post.id)}
            > <option value=""></option>
                  <option value="hideEntry"  selected="selected" >Hidden</option>
                <option value="isChallengeWinner"  >Winner</option>
            </select>)*/}
{ /*isChallengeWinner && isChallengeWinner==true && (<div><select
              value="isChallengeWinner"
              onChange={(event) => handleSelectChange(event, post.id)}
            > <option value=""></option>
                <option value="hideEntry" >Hidden</option>
                <option value="isChallengeWinner"  selected="selected">Winner</option>
            </select></div>)*/}
  { /*(isChallengeWinner==false) && (hideEntry==0) && (<div><select
              value=""
              onChange={(event) => handleSelectChange(event, post.id)}
            > <option value=""></option>
                <option value="hideEntry" >Hidden</option>
                <option value="isChallengeWinner" >Winner</option>
            </select></div>)*/}

 { hideEntry && hideEntry==1 && (<select  style={{ width: "135px" }} 
              value=""
              onChange={(event) => handleSelectChange(event, post.id)}
            > <option value=""></option>
                  <option value="hideEntryfalse"  selected="selected" >Unhide Entry</option>
                <option value="isChallengeWinner"  >Award Winner</option>
            </select>)}
              { isChallengeWinner && isChallengeWinner==true && (<div><select  style={{ width: "135px" }} 
                            value="" 
                            onChange={(event) => handleSelectChange(event, post.id)}
                          > <option value=""></option>
                              <option value="hideEntry" >Hide Entry</option>
                              <option value="isChallengeWinnerfalse"  selected="selected">
De-award Winner</option>
                          </select></div>)}
                { (isChallengeWinner==false) && (hideEntry==0) && (<div><select  style={{ width: "135px" }} 
              value=""
              onChange={(event) => handleSelectChange(event, post.id)}
            > <option value=""></option>
                <option value="hideEntry" >Hide Entry</option>
                <option value="isChallengeWinner" >Award Winner</option>
            </select></div>)}


      {/*isChallengeWinner?<select
              value={isChallengeWinner}
              onChange={(event) => handleSelectChange(event, post.id)}
            > <option value=""></option>
                <option value="hideEntry">hideEntry</option>
                <option value="isChallengeWinner">isChallengeWinner</option>
            </select>?hideEntry?<select
              value={hideEntry}
              onChange={(event) => handleSelectChange(event, post.id)}
            > <option value=""></option>
                <option value="hideEntry">hideEntry</option>
                <option value="isChallengeWinner">isChallengeWinner</option>
            </select>:'':'':''*/}
            {/*hideEntry?<select
              value={hideEntry}
              onChange={(event) => handleSelectChange(event, post.id)}
            > <option value=""></option>
                <option value="hideEntry">hideEntry</option>
                <option value="isChallengeWinner">isChallengeWinner</option>
            </select>:''*/}
    
              {/*onChange={(event) => handleStatusChange(post, post.id)}*/}
                            
                                          {/*post.get('challengeStatus')*/}
                                          
                                          
                                          



                                            </td>
                                            <td> {post.get('user')?post.get('user').get('displayUsername')?<a  target="_blank"  href={post.get('user').get("displayUsername")?"https://www.joelskitchen.com/user/"+post.get('user').get("displayUsername"):''}>{post.get('user').get('displayUsername')}</a>:'':''}


                                            {/*ischallege?post.get('user')?post.get('user').get('displayUsername')?<a href="#">{post.get('user').get('displayUsername')}</a>:"":"":<Challengecheck postData={post.get('recipeV2').id} postDataType="types" />*/}</td>
                                            
                                              <td>
                                          

                                              {post.get('challenge')?post.get('challenge').get('businessAccount')?<a target="_blank" href={post.get('challenge').get('businessAccount')?"https://www.joelskitchen.com/user/"+post.get('challenge').get('businessAccount'):''}>{post.get('challenge').get('businessAccount')}</a>:'':''}

                                              </td>
                                              <td className='postedon'>
                                              {post.get('createdAt') != undefined ?((post.get('createdAt').getMonth()+1) + "/" + (post.get('createdAt').getDate()) + "/" + post.get('createdAt').getFullYear().toString().substr(-2)):''}

          </td>
                                                <td> {post.get('challenge')?post.get('challenge').get('jobNumber')?post.get('challenge').get('jobNumber'):'':''}{/*post.id*/}</td>
                                                <td>{post.id?post.id:''}</td>
                                      </tr>
                                  );
  
                              }

                          })}

            </tbody>
                      </table>
                      <div className="pagination_bottom">
                        <button
                          onClick={() => this.handlePageChange(page - 1)}
                          disabled={page === 1}
                        >
                          &#10094;
                        </button>
                        <span> Page {page} </span>
                        <button
                          onClick={() => this.handlePageChange(page + 1)}
                          disabled={posts.length < pageSize}
                        >
                          &#10095;
                        </button>
                      </div>
                    </div>
                  </div>
                }
                {pagestatus === 2 &&
                  <div className='admin_table_inner totalCreator'>
                    
                      {loading1 ? (
                        <div className='table-loader'>Loading...</div>
                        ) : (
                        <div className="admin_table">
                          <table className="creatorTable table-responsive">
                            <thead>
                                <tr>
                                    <th>Collaborate</th> 
                                    <th  className={sortCreatorField === 'displayUsername' && (sortCreatorOrder !== '' ? ' sort-arrow' : '')?"sort-arrow ":""}   onClick={(event) => handleCreatorSort('displayUsername')} >Account <span className="updown_arrow">{sortCreatorField === 'displayUsername' && (sortCreatorOrder === 'asc' ? '↑' : '↓')}</span></th>
                                    <th>Posts</th>
                                    <th  className={sortCreatorField === 'impr' && (sortCreatorOrder !== '' ? ' sort-arrow' : '')?"sort-arrow ":""}  onClick={(event) => handleCreatorSort('impr')} >Views <span className="updown_arrow">{sortCreatorField === 'impr' && (sortCreatorOrder === 'asc' ? '↑' : '↓')}</span></th>
                                    <th>Engagements</th>
                                    <th>Followers</th>
                                    <th className='platforms'>Social Link</th>
                                    <th  className={sortCreatorField === 'username' && (sortCreatorOrder !== '' ? ' sort-arrow' : '')?"sort-arrow ":""}   onClick={(event) => handleCreatorSort('username')}>Email <span className="updown_arrow">{sortCreatorField === 'username' && (sortCreatorOrder === 'asc' ? '↑' : '↓')}</span></th> 
                                </tr>
                            </thead>
                            <tbody>
                              { 
                                users.map(function (user, i) {
                                  //itemUser.fetch();
                                  {
                                    return (
                                      <tr>
                                        <td className='colbrte-check'>
                                          <div class="admin_check_radio">
                                            <label htmlFor="checkbox_id">
                                              <input type="checkbox" name="checkbox" id="checkbox_id" value="value" />
                                              <span></span>
                                            </label>
                                          </div>
                                        </td>
                                        <td className='userNamewMedia'>
                                          <div className="user_image">
                                            <a href={user.get("displayUsername")?"https://www.joelskitchen.com/user/"+user.get("displayUsername"):''}>
                                              <span className="thumb"><img class="userProfileImageCreator" src={user != undefined ? (user.get("profilePictureSmall") != undefined ? user.get("profilePictureSmall").url() : "/images/genericuser.png" ) : ""}/></span>
                                              <span className="name">{user.get("displayUsername")?user.get("displayUsername"):''}</span>
                                            </a>
                                          </div>
                                        </td>
                                        <td><Postcounts postData={user} /></td>
                                        <td>{user.get("impr")?user.get("impr"):'0'}</td>
                                        <td>{/*user.get("imprcount")?user.get("imprcount"):'0'*/}<Engagementcounts postDataUser={user} /></td>
                                        <td><Followerscounts postDataUser={user} /></td>
                                        <td className='platforms'>{self.renderSocial(user)}</td>
                                        <td className='user_email'>
                                          <a href={"mailto:"+user.get("username")}> {user.get("username")} <img src="/images/social/create-mail.svg" width="20" height="20" /></a>
                                        </td>
                                      </tr>
                                    );
                                  }
                                })
                              }
                            </tbody>
                          </table>    
                          <div className="pagination_bottom">
                            <button
                              onClick={() => this.handlePageChange1(page1 - 1)}
                              disabled={page1 === 1}
                            >
                               &#10094;
                            </button>
                            <span> Page {page1} </span>
                            <button
                              onClick={() => this.handlePageChange1(page1 + 1)}
                              disabled={users.length < pageSize1}
                            >
                               &#10095;
                            </button>
                          </div>
                        </div>
                        )
                      }
                    </div>
                }
              </div>
            )
          }
        </div>
      </div>
    );
  }
}
export default Adminentries;