import '../global.js'
import Parse from 'parse';
import React from 'react';

class entriesCommentCount extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            commentcount:0,
        };
      var self = this;
       var activityquery = new Parse.Query('Activity');  
        activityquery.equalTo("type", "comment");
        activityquery.equalTo("photo", "Photo$"+props.postData);
        activityquery.find().then(function(activity){
           if(activity){
             self.setState({commentcount: activity.length});
        }
        
        })
    }

    render() {
        var commentcount=this.state.commentcount;
        var result = (<>{commentcount} </> );            
        return result;
    }


}

export default entriesCommentCount;