import '../global.js'
import Parse from 'parse';
import React from 'react';

class Followerscounts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            followerscount:0
        };
        var followersquery = new Parse.Query('Activity');       
        followersquery.include("user");        
        followersquery.include("fromUser");
        followersquery.include("image");
        followersquery.include("thumbnail");
        followersquery.equalTo("type", "follow");
        followersquery.equalTo("toUser",props.postDataUser);
        var self = this;
        followersquery.find().then(function(followerresult){
            if(followerresult){
                 self.setState({followerscount: followerresult.length});
            }
        })
    }
    render() {
        var followerscount=this.state.followerscount;
        var result = ( <> {followerscount} </> );
        return result;
    }
}
export default Followerscounts;