import Parse from 'parse';
import React, { useState, useRef } from 'react';
import Textarea from 'react-textarea-autosize';
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import Dropdown from 'react-dropdown'
import $ from "jquery";
import ReactTable from "react-table-v6";
import 'react-table-v6/react-table.css'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { ReactNotifications, Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { ImagePicker } from 'react-file-picker'
import Login from "./login";
import Postcounts from "./postcounts.jsx";
import Followerscounts from "./followerscounts.jsx";
import Pagination from "./Pagination";
const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}
const ingredTypeCount = 3;
const createDropdownOptions = [
 "Recipe",
 "Collaboration",
 "Challenge"
]
class AllPosts extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            users: {},
            userEmails: "",
            sampleRecipe: null,
            curRecipe: null,
            ingredients: null,
            instructions: null,
            notes: null,
            vegan: 0,
            vegetarian: 0,
            keto: 0,
            sugarFree: 0,
            allergenFree: 0,
            glutenFree: 0,
            ingredTypes: ["Ingredients", "Instructions", "Notes", "Grains", "Seeds & Nuts", "Spices", "Products", "Instructions"],
            ingredFields: ["ingredientsData", "instructionsData", "notesData", "grainsData", "seedsData", "spicesData", "productsData", "instructionsData"],
            ingredImages: ["veggies.png", "fruits.png", "beans.png", "grains.png", "seeds.png", "spices.png", "products.png", "instructions.png"],
            curRecipeName: "Default",
            ingredArr: [],
            text: "",
            text2: "",
            events: [],
            curRecipeCollabUN: "-",
            curRecipeCollabUser: null,
            selectedCreateType: "recipe",
            reactTableData: [],
            creatorMenuOptions: ['-'],
            selected: [],
            selectedUser: [],
            dialogVisible: false,
            dialogPage: 0,
            campaignInstructions: "",
            campaignTitle: "",
            campaignCaption: "",
            curCampaign: null,
            saveEnabled: false,
            curImageData: null,
            curImageParseFile: null,
            uploadInProgress : false,
            campaignImageWidth : 0,
            campaignImageHeight : 0,
            personalpostsCount : 0,
            creatorpostsCount : 0,
            businesspostsCount : 0,
            followersCount : 0,
            userpostscount: 0,
            searchreactTableData: [],
            loadingtable:false,
            defaultPageSize:10,
            pages:5,
            numberofrecords:0,
            pageIndex:0
        };

        //window.alert("Hello " + this.props.userType);
        this.state.userType = this.props.userType;
        //console.log("Usertype " + this.props.userType);

        // Creates all lines:
        for(var i=0; i < 2; i++) {

          // Creates an empty line
          this.state.ingredArr.push([]);
          //this.state.ingredArr[i].push( []);

          // Adds cols to the empty line:
         //   this.state.ingredArr[i].push( new Array());
/*
          for(var j=0; j < cols; j++){
            // Initializes:
            arr[i][j] = defaultValue;
          }*/
        }

        this.getUsers = this.getUsers.bind(this);
        //this.getCurUser = this.getCurUser.bind(this);

        this.handleChange = this.handleChange.bind(this);
            this.handleChange2 = this.handleChange2.bind(this);

        this.handleCopyEmails = this.handleCopyEmails.bind(this);
        this.updateCheckOn = this.updateCheckOn.bind(this);
        this.updateCheckOff = this.updateCheckOff.bind(this);
        this.toggleShowInCD = this.toggleShowInCD.bind(this);
        this.isUserInCD = this.isUserInCD.bind(this);
        this.isUserInCDtest = this.isUserInCDtest.bind(this);
        this.handleInvite = this.handleInvite.bind(this);
        this.handleDialogNext = this.handleDialogNext.bind(this);
        this.handleDialogBack = this.handleDialogBack.bind(this);
        this.handleDialogSoftCancel = this.handleDialogSoftCancel.bind(this);
        this.handleDialogHardCancel = this.handleDialogHardCancel.bind(this);
        this.handleDialogUpload = this.handleDialogUpload.bind(this);
       // this.handleSaveCampaigns = this.handleSaveCampaigns(this);
        this.handleSaveCampaigns = this.handleSaveCampaigns.bind(this);

        this.handleCampaignTitleChange = this.handleCampaignTitleChange.bind(this);
        this.handleCampaignCaptionChange = this.handleCampaignCaptionChange.bind(this);
        this.handleCampaignInstructionsChange = this.handleCampaignInstructionsChange.bind(this);

        this.onSelectBusinessAcct = this.onSelectBusinessAcct.bind(this);
        this.onSelectStatus = this.onSelectStatus.bind(this);
        this.onSelectService = this.onSelectService.bind(this);
        this.onSelectPrivacy = this.onSelectPrivacy.bind(this);
        this.handleSearch = this.handleSearch.bind(this);


        this.getBusinessAcctsAux();



        

       /* if ((this.state.userType == "campaignsBiz") || (this.state.userType == "campaignsAdmin"))
        {
           this.getCampaignsAux();
        }else if (this.state.userType == "challengesdata") 
        {
            this.getChallengesAux();
        }
        else*/
        
        this.getPostAux();

         //   this.getPostAux();

        
        var query = new Parse.Query('GlobalStats');
        //query.equalTo("id", "tRYoc3dDiZ");
       
        var self = this;
       // console.log("GLOBALSTATS");
        query.find().then(function(result){
            if(result){
                self.setState({globalStats: result[0]});
               // console.log("GLOBALSTATS<><>:");
               // console.log(result);
            // If result was defined, the object with this objectID was found
                //promise.resolve(result);
            } else {
              //  console.log("No GLOBALSTATS found");
               // promise.resolve(null);
            }
        }, function(error){
               // console.error("Error searching for GLOBALSTATS  Error: " + error);
                //promise.error(error);
        });
     
        this.handleRecipeNameChange = this.handleRecipeNameChange.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this._onSelect = this._onSelect.bind(this);
        this._onSelectNew = this._onSelectNew.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleRecipeStoryChange = this.handleRecipeStoryChange.bind(this);
        this.handleVegan = this.handleVegan.bind(this);
        this.handleVegetarian = this.handleVegetarian.bind(this);
        this.handleKeto = this.handleKeto.bind(this);
        this.handleGlutenFree = this.handleGlutenFree.bind(this);
        this.handleSugarFree = this.handleSugarFree.bind(this);
        this.handleAllergenFree = this.handleAllergenFree.bind(this);

        this.getCreatorsAux();
        this.getTotalUsersCount();


    }
   checkpagination = (pageIndex) => {
       console.log(pageIndex);
       // var pageIndex=pageIndex+1;
       var self = this;
        


        var pageSizeOptions=document.querySelector('.select-wrap.-pageSizeOptions option:checked').value;
        var defaultPageSize=this.state.defaultPageSize;
        var pages=this.state.pages;
        var numberofrecords=this.state.numberofrecords;
        if(pageIndex>0)
        {
            this.state.pageIndex=pageIndex;
            //this.setState({pageIndex:pageIndex+1});
        }else{
            this.state.pageIndex=pageIndex;
            //this.setState({pageIndex:pageIndex});
        }//
        var pageIndexs=this.state.pageIndex;
        console.log('pageIndexs'+pageIndexs);
        
        this.getPostAux(defaultPageSize,pages,numberofrecords,pageSizeOptions,pageIndexs);
    //this.setState({ page: pageIndex }, () => {
     // this.fetchData();
   // });
  };
    handleSearch = (event) => {
        const searchTerm = event.target.value;
        let reactTableData= this.state.reactTableData;
        let searchreactTableData= this.state.searchreactTableData;
        var self = this;
        if(event.target.value)
        {
            //this.getPostAux(searchTerm);
             const newreactTableData = searchreactTableData.filter(item =>        
              item.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
              self.setState({reactTableData:newreactTableData});
        }else{
            self.getPostAux();
        }
    }

    getCreatorsAux() {
        var query = new Parse.Query('_User');

        //TEMP -- see all Products
        //if (!isAdmin)
        //    query.equalTo("user", Parse.User.current());

        // console.log("getCreatorsAux()");

        //query.ascending("name");
        query.limit(500);

        query.equalTo("isCreator", 1);
        query.include("displayName");
        query.include("displayUsername");
        query.ascending("displayUsername");


        var self = this;

        query.find().then(function(result){
            if(result){
                var newOptions = ["-"];
            
                for (var i = 0; i < result.length; i++)  {
                    var creator = result[i];
                    var creatorUn = creator.get("displayUsername");
                    newOptions.push(creatorUn);
                }
                self.setState({creatorMenuOptions: newOptions});
            } else {
            }
        }, function(error){
        });
    }


        handleNew(event) {
            event.preventDefault();
            if(this.state.selectedCreateType==='Challenge' || this.state.selectedCreateType==="Challenge") {
            //console.log('Challenge');
            this.forceUpdate();
            sleep(500).then(() => {
                if (global.productList && (global.productList != undefined))
                global.productList.forceUpdate();
                window.location.href = "/create-challenge";
            })
            } else {
                var recipe = new Parse.Object('RecipeV2');       
                recipe.set('user', Parse.User.current());
            if (this.state.selectedCreateType.includes('Collab')) {
            // console.log('Creating new collaboration.');
                recipe.set('isCollab', true);
                recipe.set('name', ' Untitled Collaboration');
            } else {
                //  console.log('Creating new recipe.');
                recipe.set('isCollab', false);
                recipe.set('name', ' Untitled Recipe');
            }
            recipe.set('vegan', 0);
            recipe.set('vegetarian', 0);
            recipe.set('keto', 0);
            recipe.set('glutenFree', 0);
            recipe.set('sugarFree', 0);
            recipe.set('allergenFree', 0);

        var self = this;

        return recipe.save().then(function() {
        //  console.log('Created new recipe.');
          //self.getRecipesAux();

          self.forceUpdate();

          sleep(500).then(() => {
            self.selectRecipe(recipe);
            })
        }
        , function(error) {
          //console.log('Creating recipe object failed. Error: ' + error);
        });
       }
    }

     handleCancel(event) {
       // console.log('Canceling recipe ' + this.state.curRecipe.get("name"));
        this.setState({curRecipe: null});
        global.curRecipe = null;
        if (global.productList)
            global.productList.forceUpdate();

        this.state.curRecipe.fetch();
        this.setState({curRecipe: null});
        this.setState({curRecipeStory: null});
        this.setState({curRecipeCollabUN: null});
        this.setState({curRecipeCollabUser: null});
        this.setState({ingredients: null});
        this.setState({instructions: null});
        this.setState({notes: null});
    }

    handleRecipeNameChange(event) {
        this.setState({curRecipeName: event.target.value});
        //this.state.curRecipe.set("name", event.target.value);
    }
    _onSelect (option) {
        //console.log('You selected ', option.label)
        this.setState({curRecipeCollabUN: option})

        var usernameSearchValue = option.label;

        if (usernameSearchValue == '-')
        {
            this.setState({curRecipeCollabUN: "-"});
            this.setState({curRecipeCollabUser: null});
            return;
        }
        const parseQuery = new Parse.Query(Parse.User);
        // Several query functions can be set to your Parse,Query, they will
        // only resolve when calling "find", for example
        if (usernameSearchValue !== '') {
        // "contains" will retrieve users whose username contain the searched value, case-sensitive
        //parseQuery.contains('displayUsername', usernameSearchValue);
        parseQuery.include('username');
        // 
        // or 
        // 
        // for case-insensitive string search, use "matches", that will take into account
        // an regexp for matching, in this case use only "i", which is the regexp modifier
        // for case-insensitive
        parseQuery.matches('displayUsername', usernameSearchValue, 'i');
        }


        var self = this;

        parseQuery.find().then(function(result){
            if(result){
               // console.log(result);

              if (result[0])
              {
                 // console.log('Successfully found ', option.label);
                 // console.log(result[0].get("username"));

                 // console.log('boomp1');


                  self.setState({curRecipeCollabUN: String(usernameSearchValue)});

                //  console.log('curRecipeCollabUN ', self.state.curRecipeCollabUN);

                  self.setState({curRecipeCollabUser: result[0]});

                 // console.log('curRecipeCollabUser', self.state.curRecipeCollabUser);
              }
              else
              {
               // console.log("User not found");
                window.alert("User not found");
                this.setState({curRecipeCollabUN: ""});
                this.forceUpdate();
              }
/*
              this.state.curRecipe.set("collabUN", String(usernameSearchValue));

              console.log('boomp2');

              this.state.curRecipe.set("collabUser", result[0]);
              
              console.log('boomp3');

              console.log('curRecipe found ', this.state.curRecipe.get("collabUser"));
              console.log("Set curRecipe collabUser to " , this.state.curRecipe.get("collabUser"));

                this.setState({curRecipeCollabUN: String(usernameSearchValue)});
                this.setState({curRecipeCollabUser: result[0]});
*/
                // If result was defined, the object with this objectID was found
                //promise.resolve(result);
            } else {
              //console.log('Error!');
               // promise.resolve(null);
              this.state.curRecipe.set("collabUser", null);
            }
        }, function(error){
                console.error("Error searching for Users  Error: " + error);
                //promise.error(error);
        });

    }

    _onSelectNew(option) {
       // console.log('You selected ', option.label)
        this.setState({selectedCreateType: option.label})
    }

    handleVegan(event) {

        event.preventDefault();
        event.target.blur();

        this.state.vegan = this.state.curRecipe.get("vegan") == null ? 0 : this.state.curRecipe.get("vegan");
        this.state.vegan = !this.state.vegan;
        this.state.curRecipe.set("vegan", (this.state.vegan ? 1 : 0));
        this.forceUpdate();

    }

    handleVegetarian(event) {
        event.preventDefault();
        event.target.blur();
        this.state.vegetarian = this.state.curRecipe.get("vegetarian") == null ? 0 : this.state.curRecipe.get("vegetarian");
        this.state.vegetarian = !this.state.vegetarian;
        this.state.curRecipe.set("vegetarian", (this.state.vegetarian ? 1 : 0));
        this.forceUpdate();

    }

    handleKeto(event) {

        event.preventDefault();
        event.target.blur();
        this.state.keto = this.state.curRecipe.get("keto") == null ? 0 : this.state.curRecipe.get("keto");
        this.state.keto = !this.state.keto;
        this.state.curRecipe.set("keto", (this.state.keto ? 1 : 0));
        this.forceUpdate();

    }

    handleGlutenFree(event) {

        event.preventDefault();
        event.target.blur();
        this.state.glutenFree = this.state.curRecipe.get("glutenFree") == null ? 0 : this.state.curRecipe.get("glutenFree");
        this.state.glutenFree = !this.state.glutenFree;
        this.state.curRecipe.set("glutenFree", (this.state.glutenFree ? 1 : 0));
        this.forceUpdate();

    }

    handleSugarFree(event) {

        event.preventDefault();
        event.target.blur();
        this.state.sugarFree = this.state.curRecipe.get("sugarFree") == null ? 0 : this.state.curRecipe.get("sugarFree");
        this.state.sugarFree = !this.state.sugarFree;
        this.state.curRecipe.set("sugarFree", (this.state.sugarFree ? 1 : 0));
        this.forceUpdate();

    }

    handleAllergenFree(event) {

        event.preventDefault();
        event.target.blur();
        this.state.allergenFree = this.state.curRecipe.get("allergenFree") == null ? 0 : this.state.curRecipe.get("allergenFree");
        this.state.allergenFree = !this.state.allergenFree;
        this.state.curRecipe.set("allergenFree", (this.state.allergenFree ? 1 : 0));
        this.forceUpdate();

    }


    getRecipesAux() {
       // console.log('Getting recipes for current user');

        if (!Parse.User.current())
            return;

        var query1 = new Parse.Query('RecipeV2');
       
       //TEMP -- see all recipes
        if (!(Parse.User.current() && Parse.User.current().get("isAdmin")))
            query1.equalTo("user", Parse.User.current());

        var query2 = new Parse.Query('RecipeV2');
        query2.equalTo("collabUser", Parse.User.current());

        var query = Parse.Query.or(query1, query2);

        query.include("story");
        query.include("instructions");
        query.include("ingredients");
        query.include("notes");
        query.include("name");
        query.include("vegan");
        query.include("vegetarian");
        query.include("keto");
        query.include("glutenFree");
        query.include("sugarFree");
        query.include("allergenFree");
        query.include("user");
        query.include("user.displayName");
       // query.descending("createdAt");
        query.ascending("name");
        query.limit(500); // limit to at most 10 results

        var self = this;

        
        Parse.User.current().fetch();

        query.find().then(function(result){
            if(result){
               // console.log(result);
                //console.log(self.state.recipes);


                var query = new Parse.Query('RecipeV2');
       /*
                query.get("nBuAGfOn2F").then((object) => {
                    // object is an instance of parse.object.
                    
                    self.state.sampleRecipe = object;*/
                {
                    self.setState({recipes: result});

                    self.reactTableData = [];

                   // console.log("Result length " + result.length);

                    var newReactTableData = [];

                    for (var i = 0; i < result.length; i++) {

                        var recipe = result[i];
                        var wrapper = {};

                        var itemUser = recipe.get("user");

                        //itemUser.fetchIfNeeded();


                        var itemUsername = itemUser.get("displayName");
                        var user = Parse.User.current();
                        var myUsername = Parse.User.current().get("displayName");
                        var collabUsername = recipe.get("collabUN");

                        if (itemUsername == myUsername)
                            wrapper.originator = " Me";
                        else
                            wrapper.originator = itemUsername;

                        if (collabUsername == myUsername)
                            wrapper.collaborator = " Me";
                        else
                            wrapper.collaborator = collabUsername;

                        wrapper.recipe = recipe;
                        wrapper.title = recipe.get("name");
                        wrapper.lastModified = recipe.get("updatedAt");//.getTime();//.toString();
                        wrapper.startDate = recipe.get("startDate");
                        wrapper.endDate = recipe.get("endDate");
                        wrapper.type = ((recipe.get("isCollab") || (recipe.get("collabUser")) ? "Collaboration" : "Recipe"));

                        //self.reactTableData.push(wrapper);
                        newReactTableData.push(wrapper);
                        const wrapperCopy = wrapper;
                        self.state.reactTableData.push(wrapperCopy);
                        const copy = self.state.reactTableData;

                        //console.log(wrapperCopy);
                    };

                  //  self.setState({reactTableData:newReactTableData});
                    //console.log("React table data: " + self.state.reactTableData);

                }
                /*).catch((error) =>  {
                 // error is an instance of parse.error.
                });
*/
                // If result was defined, the object with this objectID was found
                //promise.resolve(result);
            } else {
                console.log("No recipes found");
               // promise.resolve(null);
            }
        }, function(error){
                console.error("Error searching for recipes  Error: " + error);
                //promise.error(error);
        });

    }

    selectRecipe(recipe) {
      //this.setState({curRecipe:recipe});
        if (recipe)
         // console.log('Select recipe ' + recipe.get("name"));

         if (this.state.curRecipe != recipe) 
        {
            var isCollab = ((recipe.get("collabUser") != null) || (recipe.get("isCollab") == true));

            this.setState({curRecipeIsCollab: isCollab});

            this.setState({curRecipe: recipe});
            this.setState({curRecipeName: recipe.get("name")});
            this.setState({ingredients: recipe.get("ingredients")});
            this.setState({instructions: recipe.get("instructions")});
            this.setState({notes: recipe.get("notes")});
            this.setState({curRecipeStory: recipe.get("story")});
            this.setState({curRecipeCollabUN: recipe.get("collabUN")});
            this.setState({curRecipeCollabUser: recipe.get("collabUser")});


            this.setState({vegan: ((recipe.get("vegan") == null ) ? 0 : recipe.get("vegan"))});
            this.setState({vegetarian: ((recipe.get("vegetarian") == null ) ? 0 : recipe.get("vegetarian"))});
            this.setState({keto: ((recipe.get("keto") == null )? 0 : recipe.get("keto"))});
            this.setState({sugarFree: ((recipe.get("sugarFree") == null) ? 0 : recipe.get("sugarFree"))});
            this.setState({glutenFree: ((recipe.get("glutenFree") == null) ? 0 : recipe.get("glutenFree"))});
            this.setState({allergenFree: ((recipe.get("allergenFree") == null) ? 0 : recipe.get("allergenFree"))});
            
           // console.log("CurRecipe " + JSON.stringify(recipe));

            //load the itemArr
            var newIngredArr = [];

            for (var i = 0; i < ingredTypeCount; i++)
            {
                var curIngredType = this.state.ingredTypes[i].toLowerCase();

                var fieldName = this.state.ingredFields[i]; ///curIngredType + "Data";
                var serData = recipe.get(fieldName);
                if (serData)
                {
                   // console.log("Parsing serial data " + fieldName + " " + serData);
                    var serObj = JSON.parse(serData);
                    newIngredArr.push(serObj);
                }
                else
                    newIngredArr.push([]);
            }
            this.setState({ingredArr: newIngredArr});

            this.forceUpdate();

            global.curRecipe = recipe;
            if (global.productList && (global.productList != undefined))
                global.productList.forceUpdate();
           
        }
        //this.state.curRecipe = recipe;
    }

    getBusinessAcctsAux() 
    {
        var query = new Parse.Query('_User');
       
       //TEMP -- see all Products
        //if (!isAdmin)
        //    query.equalTo("user", Parse.User.current());

        var user = Parse.User.current();
        var isAdmin = false
        if ((user != null) && (user.get("isAdmin") == 1))
            isAdmin = true;


        var curUsername = (user ? user.get("displayUsername") : "");

        this.setState({businessAccountName: curUsername});

       //console.log("getCreatorsAux()");

        //query.ascending("name");
        query.limit(500);

        query.equalTo("isBusiness", 1);
        query.include("displayName");
        query.include("displayUsername");
        query.ascending("displayUsername");


        var self = this;

        query.find().then(function(result){
            if(result){
               // console.log("getBusinessAcctsAux() Result");

               // console.log(result);

                var newOptions = ["-"];

              //  console.log("result length " + result.length);
            
                for (var i = 0; i < result.length; i++) 
                {
                    
                    var creator = result[i];
                    var creatorUn = creator.get("displayUsername");
                    
                    //only admins get all options
                    if ((isAdmin || (Parse.User.current() && (Parse.User.current().get("displayUsername") == creatorUn))))
                        newOptions.push(creatorUn);
                  

                }

              
                self.setState({businessAccountMenuOptions: newOptions});

                // If result was defined, the object with this objectID was found
                //promise.resolve(result);
            } else {
                console.log("No Creators found");
               // promise.resolve(null);
            }
        }, function(error){
                console.error("Error searching for Creators  Error: " + error);
                //promise.error(error);
        });
    }

    onSelectBusinessAcct(option) {
        this.setState({businessAccountName: option.label})
    }

    onSelectPrivacy(option) {
        this.setState({campaignPrivacy: option.label})
    }

    onSelectStatus(option, wrapper) {

        this.setState({saveEnabled:true});

        wrapper.status = option.value;
        
        wrapper.needToSave = true;
    }

    onSelectService(option, wrapper) {

        this.setState({saveEnabled:true});

        wrapper.service = option.value;

        wrapper.needToSave = true;
    }

    handleChangeCost(event, wrapper) {

        this.setState({saveEnabled:true});

       // console.log('You selected ' + JSON.stringify(event.target.value) + " " + JSON.stringify(wrapper));
        wrapper.cost = event.target.value;
        
        wrapper.needToSave = true;
    }

    handleChangeBilled(event, wrapper) {

        this.setState({saveEnabled:true});

        wrapper.billed = event.target.value;
        
        wrapper.needToSave = true;
    }

    handleChangePaymentReceived(event, wrapper) {

        this.setState({saveEnabled:true});

        wrapper.paymentReceived = event.target.value;
        
        wrapper.needToSave = true;
    }

    handleCampaignTitleChange(event) {
        this.setState({campaignTitle: event.target.value});
    }

    handleCampaignCaptionChange(event) {
        this.setState({campaignCaption: event.target.value});
    }

    handleCampaignInstructionsChange(value) {
        this.setState({campaignInstructions: value});
    }

    isUserInCD(user) {
        var userStats = user.get("userStats");
        if (!userStats)
            return false;
        var cdState = userStats.get("showInCreatorDirectory");
        if (cdState)
            return true;
        return false;
    }

    isUserInCDtest(user)
    {
        //window.alert(JSON.stringify(user));
        //window.alert(JSON.stringify(user));
        user = user.thisUser;

        var userStats = user.get("userStats");
        if (!userStats)
            return false;
        var cdState = userStats.get("showInCreatorDirectory");
        if (cdState)
            return true;
        return false;
    }

    updateCheckOn(user) {
        /*
        user.set("showInCreatorDirectory", 1);
        user.set("sessionKey", "123");

        user.save(null, {
            success: function(user) {
                user.fetch();
            },
            error: function(user, error) {
                alert('Failed to update object, with error code: ' + error.message);
            }
        });*/
        if (this.isUserInCD(user))
            return;

        //window.alert("Check on");

        var un = user.get("username");
        var list = this.state.globalStats.get("showInCreatorDirectory");
        
        if (!list)
            list = un;
        else
            list = list + ", " + un;

        this.state.globalStats.set("showInCreatorDirectory", list);

        this.state.globalStats.save(null, {
            success: function(user) {
                this.state.globalStats.fetch();
            },
            error: function(user, error) {
                alert('Failed to update object, with error code: ' + error.message);
            }
        });
    }

    updateCheckOff(user) {
        if (!this.isUserInCD(user))
            return;
        

        var userStats = user.get("userStats");
    }

    setUserSelected(userInd, userValue) {
        this.state.selected[userInd] = 1;
        this.state.selectedUser[userInd] = userValue;

        this.forceUpdate();

        //user.set("selected", 1);
    }

    setUserNotSelected(userInd) {
        this.state.selected[userInd] = 0;
        //user.set("selected", 0);
        this.forceUpdate();
        
    }

    isUserSelected(user) {
        var selState = user.get("selected");
        return selState;
    }

    toggleShowInCD(user) {
        var userStats = user.get("userStats");
        var cdState = this.isUserInCD(user);
        var shouldSaveUserStats = false;
        const UserStatsType = Parse.Object.extend("UserStats");

        if (!userStats)
        {
            window.alert("This is a brand new user, you will need to wait until the next Creator Directory update before you can show them.");
            return;
        }
        if (cdState)
        {
            //window.alert("Turning off");
            userStats.set("showInCreatorDirectory", 0);
        }
        else
        {
            //window.alert("Turning on");
            userStats.set("showInCreatorDirectory", 1);
        }
        userStats.save().then((userStats) => {
            if (shouldSaveUserStats)
            {
                const pointer = UserStatsType.createWithoutData(userStats.objectID);
                user.set("userStats", pointer);
                user.save();
            }
        }, (error) => {
            window.alert('Failed to create new object, with error code: ' + error.message);
        });
    }

    handleCopyEmails() {
        var text = "Example text to appear on clipboard";

        text = this.state.userEmails;
        /*
        console.log(this.state.users.length);
        for (var i = 0; i < this.state.users.length; i++)
        {
            var user = this.state.users[i];
            console.log(user.get("username"));
            text = text + "\r\n";
            text = text + user.get("username");
        }*/

        //var text = "Example text to appear on clipboard";
        navigator.clipboard.writeText(text).then(function() {
          window.alert("Copied to clipboard!")
        }, function(err) {
          window.alert("Error copying to clipboard")
        });
    }

    handleSaveCampaigns(event)
    {

        this.setState({saveEnabled:false});

       /* for (var i = 0; i < this.state.reactTableData.length; i++)
        {
            var cur = this.state.reactTableData[i];
            if (cur.needToSave) {
               
                cur.thisCampaign.set("status", cur.status);
                cur.thisCampaign.set("service", cur.service);
                cur.thisCampaign.set("cost", cur.cost);
                cur.thisCampaign.set("billed", cur.billed);
                cur.thisCampaign.set("paymentReceived", cur.paymentReceived);
                cur.thisCampaign.save();
                cur.needToSave = false;
            }
        }*/

        Store.addNotification({
            title: "Success!",
            message: "Your changes have been saved.",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: false
            }
        });
    }

    handleSave2() {
        this.setState({saveEnabled:false});
        window.alert("Save2");
    }

    clearDialog() {
        this.setState({dialogPage: 0});
        this.setState({campaignTitle: ""});
        this.setState({campaignVideo: false});
        this.setState({campaignPhotos: false});
        this.setState({campaignInstaPost: false});
        this.setState({campaignInstaStory: false});
        this.setState({campaignInstaReel: false});
        this.setState({campaignTikTokVideo: false});
        this.setState({campaignYoutubeVideo: false});
        this.setState({campaignFacebookPost: false});
        this.setState({campaignPinterestPin: false});
        this.setState({campaignBlogPost: false});
        this.setState({campaignContentOnly: false});
        this.setState({campaignInstructions: ""});
        this.setState({curImageData:null});
        this.setState({curImageParseFile:null});
    }

    handleInvite()
    {
        if (Parse.User.current())
            Parse.User.current().fetch();
        else
        {
            window.alert("You must login to perform this action.");
            return;
        }
        var text = "Example text to appear on clipboard";
        //console.log(this.state.userEmails);

        this.setState({dialogVisible: true});
        //this.clearDialog();

        this.forceUpdate();
        /*
        for (var i = 0; i < this.state.selected.length; i++)
        {
            this.state.selected[i] = false;
        }*/
        //this.state.creatorAccountSelected
        var selectedCount = 0;
        var selectedList = "";

        for (var i = 0; i < this.state.selectedUser.length; i++)
        {
            if (this.state.selected[i])
            {
                var username = this.state.selectedUser[i].get("displayUsername");
                if (selectedCount == 0)
                {
                    selectedList = username;
                }
                else
                    selectedList = selectedList + ", " + username;

                selectedCount++;
            }
        }

        this.state.selectedUserCount = selectedCount;
        this.state.selectedUserList = selectedList;
        this.state.businessAccountName = Parse.User.current().get("displayUsername");
    }

    createCampaignAux2()
    {
       // console.log('Creating new campaign.');

        var campaign = new Parse.Object('Campaign');    
        
       // console.log(this.state.selectedCreateType);

        
        campaign.set('name', this.state.campaignTitle);   
        campaign.set('caption', this.state.campaignCaption);   
        campaign.set('user', Parse.User.current());
        campaign.set('businessAccountName', this.state.businessAccountName);
        campaign.set('businessAccountUser', this.state.businessAccountUser);
        campaign.set('instructions', this.state.campaignInstructions);
        campaign.set('creators', this.state.selectedUserList);
        campaign.set('creatorCount', this.state.selectedUserCount);
        campaign.set('hasVideo', this.state.campaignVideo);
        campaign.set('hasPhoto', this.state.campaignPhotos);
        campaign.set('instaReel', this.state.campaignInstaReel);
        campaign.set('instaPost', this.state.campaignInstaPost);
        campaign.set('instaStory', this.state.campaignInstaStory);
        campaign.set('tiktokVideo', this.state.campaignTikTokVideo);
        campaign.set('youtubeVideo', this.state.campaignYoutubeVideo);
        campaign.set('pinterestPin', this.state.campaignPinterestPin);
        campaign.set('blogPost', this.state.campaignBlogPost);
        campaign.set('contentOnly', this.state.campaignContentOnly);
        campaign.set('jobNumber', this.state.campaignJobNumber);

        campaign.set('photoFile', this.state.curImageParseFile);

        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

        campaign.set('dtMobile', isMobile ? "Mobile" : "Desktop" );

        var self = this;
        return campaign.save().then(function() {
         // console.log('Created new campaign.');
         
            self.forceUpdate();
            window.alert("Your business campaign is pending review for Joel's Kitchen Creator Marketplace.")

            //clear checkboxes
            let data= self.state.selected;

            for (var i = 0; i < data.length; i++)
            {
                data[i] = false;
            }
            self.setState({selected:data});
            self.clearDialog();

            self.forceUpdate();
            window.location.href = "/business/campaigns";
        }
        , function(error) {
         //   console.log('Creating recipe object failed. Error: ' + error);
            window.alert("Error creating campaign")
        });
    }

    createCampaignAux()
    {
        var usernameSearchValue = this.state.businessAccountName;

        const parseQuery = new Parse.Query("Campaign");
          // Several query functions can be set to your Parse,Query, they will
          // only resolve when calling "find", for example
        
        parseQuery.descending("jobNumber");
        parseQuery.limit(1);

        var self = this;

        parseQuery.find().then(function(result){
            if(result){
                //console.log(result);

              if (result[0])
              {
                //  console.log('Successfully found ', usernameSearchValue);
                //  console.log(result[0].get("jobNumber"));

                  self.setState({campaignJobNumber: (result[0].get("jobNumber") + 1)});

                  self.createCampaignAux2();
              }
              else
              {
               // console.log("Campaign not found");
                window.alert("Campaign not found");
                this.forceUpdate();
              }

            } else {
             // console.log('Error!');
            }
        }, function(error){
              //  console.error("Error searching for Campaigns  Error: " + error);
        });
    }

    createCampaign()
    {
        //lookup business account before creating campaign

        var usernameSearchValue = this.state.businessAccountName;

        const parseQuery = new Parse.Query(Parse.User);
          // Several query functions can be set to your Parse,Query, they will
          // only resolve when calling "find", for example
          if (usernameSearchValue !== '') {
            parseQuery.include('username');
            parseQuery.matches('displayUsername', usernameSearchValue, 'i');
          }


        var self = this;

        parseQuery.find().then(function(result){
            if(result){
             //   console.log(result);

              if (result[0])
              {
              //    console.log('Successfully found ', usernameSearchValue);
               //   console.log(result[0].get("username"));

                  self.setState({businessAccountUser: result[0]});

                  self.createCampaignAux();
              }
              else
              {
              //  console.log("User not found");
                window.alert("User not found");
                this.setState({curRecipeCollabUN: ""});
                this.forceUpdate();
              }
/*
              this.state.curRecipe.set("collabUN", String(usernameSearchValue));

              console.log('boomp2');

              this.state.curRecipe.set("collabUser", result[0]);
              
              console.log('boomp3');

              console.log('curRecipe found ', this.state.curRecipe.get("collabUser"));
              console.log("Set curRecipe collabUser to " , this.state.curRecipe.get("collabUser"));

                this.setState({curRecipeCollabUN: String(usernameSearchValue)});
                this.setState({curRecipeCollabUser: result[0]});
*/
                // If result was defined, the object with this objectID was found
                //promise.resolve(result);
            } else {
              console.log('Error!');
               // promise.resolve(null);\
            }
        }, function(error){
                console.error("Error searching for Users  Error: " + error);
                //promise.error(error);
        });

        
           
    }

    triggerUpload()
    {
       // window.alert("Uploading");
        var imageFile = new Parse.File("CampaignImage" + Math.floor(Math.random()*99999), {base64: this.state.curImageData});
        var that = this;

        this.setState({curImageParseFile: imageFile});

       // console.log("Upload started");

        return imageFile.save().then(function () {

            //return ParseReact.Mutation.Set(that.data.event[0], {'primaryPhoto':imageFile}).dispatch();
            //window.alert("Upload complete");
            that.handleDialogNext();

        }, function (error) {
          //  console.log("Error");
          //  console.log(error);
            window.alert("Error");
        });
    }
    handleDialogNext()
    {
        var newPage = this.state.dialogPage + 1;
        var needToShowUpload = false;
        if (this.curImageData)
            needToShowUpload = true;

        if ((this.state.dialogPage == 3) && (this.state.curImageData))
        {
            //trigger upload
            this.triggerUpload();
        }
        if (((this.state.dialogPage >= 3) && (!this.state.curImageData)) || (this.state.dialogPage >= 4))
        {
            this.createCampaign();
            this.setState({dialogVisible: false})
            this.setState({dialogPage: 0})
        }
        else
            this.setState({dialogPage: newPage}); 
    }

    handleDialogBack()
    {
        var newPage = this.state.dialogPage - 1;
        if (newPage < 0)
        {
            this.state.dialogPage = 0;
            this.handleDialogSoftCancel();
        }
        else
            this.setState({dialogPage: newPage}); 
    }

    handleDialogSoftCancel()
    {
        if (window.confirm('Discard post? If you leave, your edits won\'t be saved.')) {
          // Save it!
           // console.log('Discarding post.');
            this.setState({dialogVisible: false});
            this.clearDialog();
            window.location.href = window.location.href; //clear checkboxes
        } else {
          // Do nothing!
         // console.log('Not discarding post.');
        }
    }

    handleDialogHardCancel()
    {
        //var newPage = this.state.dialogPage - 1;
        //if (newPage < 0)
        
        this.setState({dialogVisible: false});

        //else
        //    this.setState({dialogPage: newPage}); 
    }

    handleDialogUpload()
    {
        
    }

    getCurRecipe() {
        return this.curUser;
    }
     handleDelete(event) {

        if (window.confirm('Are you sure you wish to delete this recipe?'))
        {

            var curRecipe = this.state.curRecipe;


            curRecipe.destroy().then((myObject) => {
              // The object was deleted from the Parse Cloud.
                this.setState({curRecipe: null});;

                this.setState({curRecipe: null});
                this.setState({curRecipeStory: null});
                this.setState({curRecipeCollabUN: null});
                this.setState({curRecipeCollabUser: null});
                this.setState({ingredients: null});
                this.setState({instructions: null});
                this.setState({notes: null});

                this.setState({recipes: this.state.recipes});;

                this.forceUpdate();
                if (window)
                    window.location.reload();
                else if (Location)
                    Location.reload();
            }, (error) => {
              // The delete failed.
              // error is a Parse.Error with an error code and message.
            });
        }
    }

  

    updateWrapperForCampaign(wrapper, cpgn)
    {
        wrapper.businessAccountName = cpgn.get("businessAccountName");

        var name = cpgn.get("name");
        var ccount = cpgn.get("creatorCount");
        var hasVideo = cpgn.get("hasVideo");
        var hasPhoto = cpgn.get("hasPhoto");
        var service = cpgn.get("service");
        var dtMobile = cpgn.get("dtMobile");
        var totalCampaigns = cpgn.get("totalCampaigns");
        var campaignsThisMonth = cpgn.get("campaignsThisMonth");
        var status = cpgn.get("status");
        var cost = cpgn.get("cost");
        var billed = cpgn.get("billed");
        var paymentReceived = cpgn.get("paymentReceived");

        wrapper.name = name;
        wrapper.creatorCountString = ccount + " creator" + ((ccount != 1) ? "s" : "");
        wrapper.jobNumber = cpgn.get("jobNumber");
        wrapper.service = service;
        wrapper.dtMobile = dtMobile;
        wrapper.totalCampaigns = totalCampaigns;
        wrapper.campaignsThisMonth = campaignsThisMonth;
        wrapper.status = status;
        wrapper.cost = cost;
        wrapper.billed = billed;
        wrapper.paymentReceived = paymentReceived;

        if (hasVideo && hasPhoto)
            wrapper.content = "Video & Photos";
        else if (hasVideo)
            wrapper.content = "Video";
        else if (hasPhoto)
            wrapper.content = "Photos";
        else 
            wrapper.content = "n/a";

        //if (!website.includes( "http"))
        //    website = "https://" + website;


        wrapper.lastModified = cpgn.get("updatedAt");
        wrapper.createdAt = cpgn.get("createdAt");
        wrapper.startDate = cpgn.get("startDate");
        wrapper.endDate = cpgn.get("endDate");
        wrapper.createdAtStr = ((wrapper.createdAt.getMonth()+1) + "/" + (wrapper.createdAt.getDate()) + "/" + wrapper.createdAt.getFullYear() )
        wrapper.lastModifiedStr = ((wrapper.lastModified.getMonth()+1) + "/" + (wrapper.lastModified.getDate()) + "/" + wrapper.lastModified.getFullYear() )
        wrapper.startDateStr = ((wrapper.startDate.getMonth()+1) + "/" + (wrapper.startDate.getDate()) + "/" + wrapper.startDate.getFullYear() )
        wrapper.endDateStr = ((wrapper.endDate.getMonth()+1) + "/" + (wrapper.endDate.getDate()) + "/" + wrapper.endDate.getFullYear() )

        wrapper.needToSave = false;
    }

  

   

    getTotalUsersCount() {
        var self = this;
        //this.state.personalpostsCount = 10;
        let aa = 10;
        var query = new Parse.Query('_User');
        query.count({
          success: function(count) {
            self.state.personalpostsCount = count.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
          error: function(error) {
            // The request failed
          }
        });
        var queryCreator = new Parse.Query('_User');
        queryCreator.equalTo("isCreator", 1);
        queryCreator.count({
          success: function(count) {
            self.state.creatorpostsCount = count.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
          error: function(error) {
            // The request failed
          }
        });
        
        var queryBusiness = new Parse.Query('_User');
        queryBusiness.equalTo("isBusiness", 1);
        queryBusiness.count({
          success: function(count) {
            self.state.businesspostsCount = count.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
          error: function(error) {
            // The request failed
          }
        });
    }


     componentDidMount() {
    this.getPostAux();
  }
    getPostAux(defaultPageSize,pages,numberofrecords,pageSizeOptions,pageIndex) {

        //if (!Parse.User.current())
        //    return;


        
        var query = new Parse.Query('_User');
        const photo =Parse.Object.extend("Photo");
       if(defaultPageSize!==undefined)
       {
           query.limit(defaultPageSize);
       }else{
           query.limit(this.state.defaultPageSize);
       }
       console.log('pageIndex lop'+pageIndex)
        
        query.skip(pageIndex * pageSizeOptions);
        
        query.equalTo("isCreator", 1);  
        query.include("profilePictureSmall");
        query.include("displayName");
        query.include("displayUsername");
        query.include("recipeRate");
        query.include("videoRate");
        query.include("photoRate");
        query.include("instagram");
        query.include("facebook");
        query.include("pinterest");
        query.include("tiktok");
        query.include("youtube");
        query.include("website");
        query.include("name");
        query.include("email");
        query.include("userStats");
        /*if(searchTerm!=='')
        {
            query.matches("displayUsername", searchTerm, "i");
        }*/

        
        
        
        
        
        
        query.include("userStats.instaFollowers");
        query.descending("createdAt");
        query.include('Photos');
        //query.include("_User.photo");

        var self = this;

        
        /*if (Parse.User.current())
            Parse.User.current().fetch();*/

        query.find().then(function(result){
            if(result){
                console.log(result);
               // console.log(result.get('photo'));
               //  var photodata = new Parse.Query('photo');

                //console.log(self.state.recipes);


               // var query = new Parse.Query('RecipeV2');
       /*
                query.get("nBuAGfOn2F").then((object) => {
                    // object is an instance of parse.object.
                    
                    self.state.sampleRecipe = object;*/
                {
                    self.setState({recipes: result});

                    self.reactTableData = [];

                 //   console.log("Result length " + result.length);

                    var newReactTableData = [];
                    var userEmailArr = [];
                    var theUserEmails = "";
                     self.setState({numberofrecords:result.length});


                    for (var i = 0; i < result.length; i++) {

                        var user = result[i];
                        var wrapper = {};

      /*let posts_count=0;
       var posts_query = new Parse.Query('Photo');
       // posts_query.equalTo('user', user);
       posts_query.equalTo('user', "_User$"+user.id);
       var self = this;
       posts_query.count({
          success: function(count) {
            //console.log(count);
               //this.setState({userpostscount:count});
           // posts_count=count;
             // this.setState({loadingtable:true});
            //wrapper.posts=count;
          },
          error: function(error) {
          }
        });*/
        //console.log(posts_count+'posts_count');


       /* querydata1.find().then(function(result){
             console.log(result.length+'creator');
            this.setState({userpostscount:result.length});
        }
        )
*/

                      //  const promises = posts.map(async post => {
     /* const postQuery = new Parse.Query('Photo');
      postQuery.equalTo('user', user);
      const comments =  postQuery.find();
      console.log(comments);*/
     // return { ...post.toJSON(), comments };
    //});
/*
                        var itemUser = recipe.get("user");

                        //itemUser.fetchIfNeeded();


                        var itemUsername = itemUser.get("displayName");
                        var user = Parse.User.current();
                        var myUsername = Parse.User.current().get("displayName");
                        var collabUsername = recipe.get("collabUN");

                        if (itemUsername == myUsername)
                            wrapper.originator = " Me";
                        else
                            wrapper.originator = itemUsername;

                        if (collabUsername == myUsername)
                            wrapper.collaborator = " Me";
                        else
                            wrapper.collaborator = collabUsername;

                        wrapper.recipe = recipe;
                        wrapper.title = recipe.get("name");
                        wrapper.lastModified = recipe.get("updatedAt");//.getTime();//.toString();
                        
                        wrapper.type = ((recipe.get("isCollab") || (recipe.get("collabUser")) ? "Collaboration" : "Recipe"));
*/
                        //self.reactTableData.push(wrapper);

                        wrapper.thisUser = user;


 



                        /*if (user.get('isCreator') && user.get('isBusiness'))
                            wrapper.type = "creatoraccount,businessaccount";
                        else if (user.get('isCreator'))
                            wrapper.type = "creatoraccount";
                        else if (user.get('isBusiness'))
                            wrapper.type = "businessaccount";
                        else
                            wrapper.type = "useraccount";*/


                       /* if (user.get('isCreator') && user.get('isBusiness'))
                            wrapper.account_type = "all";
                        else if (user.get('isCreator'))
                            wrapper.account_type = "type-creator";
                        else if (user.get('isBusiness'))
                            wrapper.account_type = "type-business";
                        else
                            wrapper.account_type = "type-community";*/


                        //TODO: pic
                        wrapper.pic = user.get("profilePictureSmall");
                        wrapper.thisUserAccount=user;
                        wrapper.postsViews=user.get("imprcount")?user.get("imprcount"):'0';
                        wrapper.engagementsPosts=user.get("impr")?user.get("impr"):'0';

                          var followerProps = {
                                    followerCount: user.get("userStats") ? user.get("userStats").get("instaFollowers") : "",
                                    followerChange: user.get("userStats") ? user.get("userStats").get("instaFollowersChange") : "",
                                    abbrev: false
                                }
                        var followerPropcount=   self.renderFollowerCount(followerProps);
                        wrapper.followersPosts=followerPropcount;
                        var sociallinks=self.renderSocial(user);
                        wrapper.socialLinks=sociallinks;
                        var useremail=user.get("username");
                       
                        wrapper.createrEmail=useremail;

                        var postCounts=self.renderPostCount(user);
                        const userPosts =''; //self.renderPostCount(user);// self.fetchUserPosts(user);
                       // console.log('userPosts');
                        wrapper.posts=user;//this.state.userpostscount;
                        //console.log(postCounts);
                        // wrapper.posts=user;
                       /* let postscountdata=0;
                        let userdata=[];
                        console.log(user.id+'out');
                        let postusers_id=user.id;
                        var posts_query = new Parse.Query('Photo');
                        posts_query.equalTo('user', user);
                        posts_query.find().then(function(result){
                            userdata.push({'id':postusers_id,'value':result.length});
                        })
                        console.log('userdata');
                        console.log(userdata);*/

        /*posts_query.count({
          success: function(count) {
            console.log(count);
            wrapper.posts=count;
            postscountdata=count;
            userdata.push({'id':user.id,'value':count});
          },
          error: function(error) {
          }
        });*/
       
       // wrapper.posts='11';

                       //var querydata = new Parse.Query('Photo');
       
       

      /*  querydata.equalTo("user", user);
        let postuser_id=user.id;
        console.log(user.id);
        querydata.find().then(function(result){
             console.log('result result');
             console.log(result);
             console.log(result.length+'creator'+postuser_id);
             $('#postid_'+postuser_id).html(result.length);

        }
        )*/

      /*  querydata.find().then(function(result){
             console.log('result result');
             console.log(result.length+'creator'+user.id);
             $('#postid_'+user.id).html(result.length);

        }
        )*/

                        var username = user.get("displayUsername");
                        var name = user.get("displayName");
                        var bio = user.get("bio");
                        var website = user.get("website");
                        var email = user.get("username");

                       /* if (bio && (bio.length > 64))
                        {
                            bio = bio.slice(0, 64);
                            bio = bio + "...";
                        }*/
                        
                        wrapper.username = username;
                        wrapper.name = name;
                       // wrapper.bio = bio;

                        //if (!website.includes( "http"))
                        //    website = "https://" + website;

                        /*wrapper.website = website;
                        wrapper.city = user.get("city");;
                        wrapper.email = email;

                        wrapper.instagram = user.get("instagram");
                        wrapper.tiktok = user.get("tiktok");
                        wrapper.youtube = user.get("youtube");

                      
                        wrapper.lastModified = user.get("updatedAt");
                        wrapper.createdAt = user.get("createdAt");
                        wrapper.startDate = user.get("startDate");
                        wrapper.endDate = user.get("endDate");
                        wrapper.createdAtStr = ((wrapper.createdAt.getMonth()) + "/" + (wrapper.createdAt.getDate()) + "/" + (wrapper.createdAt.getFullYear() % 100));
                        wrapper.lastModifiedStr = ((wrapper.lastModified.getMonth()) + "/" + (wrapper.lastModified.getDate()) + "/" + (wrapper.lastModified.getFullYear() % 100) );*/
                        //wrapper.startDateStr = ((wrapper.startDate.getMonth()) + "/" + (wrapper.startDate.getDate()) + "/" + (wrapper.startDate.getFullYear() % 100) );
                        //wrapper.endDateStr = ((wrapper.endDate.getMonth()+1) + "/" + (wrapper.endDate.getDate()) + "/" + (wrapper.endDate.getFullYear() % 100) );

                        //wrapper.lastModified = wrapper.lastModified.replace("3/32", "3/31");
                        //wrapper.createdAt = wrapper.createdAt.replace("3/32", "3/31");
                        
                        const wrapperCopy = wrapper;
                        var shouldAdd;
  
                        shouldAdd = true; 

                        //if ((this.state.userType == "business") && (!self.isUserInCD(user)))
                        ///business -> creatorrates

                        //if ((self.state.userType == "creatorrates")  && (!self.isUserInCD(user)))
                         //   shouldAdd = false;

                        if (shouldAdd)
                        {
                     
                            self.state.reactTableData.push(wrapperCopy);
                                
                            newReactTableData.push(wrapper);
                            // },1000);
                            self.state.loadingtable=true;
                        }

                        const copy = self.state.reactTableData;

                        //console.log(wrapperCopy);

                       // theUserEmails = theUserEmails + user.get("username") + "\r\n";

                        if ((email) && (email.includes("@")))
                           userEmailArr.push(email);
                    };//end for
/* console.log('newReactTableData');
  setTimeout(() => {
    newReactTableData=newReactTableData;
     },5000);*/


                   // console.log(newReactTableData);
                 

                    self.setState({reactTableData:newReactTableData});
                    self.setState({searchreactTableData:newReactTableData});
                   
 
                     

                    userEmailArr.sort(); //alphabetical

                    for (var i = 0; i < userEmailArr.length; i++)
                    {
                        theUserEmails = theUserEmails + userEmailArr[i] + "\r\n";
                        //if (((i+1) % 25) == 0)
                        //    theUserEmails = theUserEmails + "\r\n"; //blank line every 25
                    }

                    self.setState({userEmails:theUserEmails});
                   // console.log("theUserEmails: " + theUserEmails);
                    //console.log("React table data: " + self.state.reactTableData);

                }
                /*).catch((error) =>  {
                 // error is an instance of parse.error.
                });
*/
                // If result was defined, the object with this objectID was found
                //promise.resolve(result);
            } else {
                console.log("No recipes found");
               // promise.resolve(null);
            }
        }, function(error){
                console.error("Error searching for recipes  Error: " + error);
                //promise.error(error);
        });

    }


    getUsers(event) {
        event.preventDefault();
       // this.getPostAux();
    }

    getRecipeNameIfNotUntitled()
    {
        var val = this.state.curRecipeName; //curRecipe.get("name");
      //  console.log("getRecipeNameIfNotUntitled '" + val + "'");
        if (val.indexOf("Untitled") >= 0)
            return "";
        if (val.indexOf("Default") >= 0)
            return "";
        //if (val != "Untitled Recipe")
         //   return val;
        //else
            return val;
    }

    handleRecipeStoryChange(event) {
        this.setState({curRecipeStory: event.target.value});
        this.state.curRecipe.set("story", event.target.value);
    }

    formatRange(range) {
    return range
      ? [range.index, range.index + range.length].join(',')
      : 'none';
    }

    handleChange(value) {
      //  console.log("handleChange");
       // console.log("ingredients value: " + value);

        if (this.state.ingredients != value)
            this.state.ingredients = value;

        //this.setState({ingredients: value});
        //strip links
       // var value2 = value.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');

       // console.log("Editor value2: " + value2);

       // this.state.curRecipe.set("ingredients", value);
    }

    handleChange2(value) {
       // console.log("handleChange2");
       // console.log("instructions value: " + value);

        if (this.state.instructions != value)
            this.state.instructions = value;
        //this.setState({instructions: value});
        //strip links
        //value = value.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');

       // this.state.curRecipe.set("instructions", value);
    }
/*
    modules = {
    toolbar: [
          ['bold', 'italic', 'underline'],
          [{'list': 'ordered'}, {'list': 'bullet'}]
        ],
      };

      formats = [
        'bold', 'italic', 'underline',
        'list', 'bullet', 'indent'
      ];
*/
    modules = {
    toolbar: [
          [{'list': 'bullet'}]
        ],
      };

      formats = [
        'list', 'bullet'
      ];

    modules2 = {
    toolbar: [
          [{'list': 'ordered'}]
        ],
      };

    formats2 = [
        'list', 'bullet'
      ];


    formatCountNoAbbrev(count)
    {
        if ((!count) || (count == undefined))
            return "";
        
        return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    formatRate(rate)
    {
        if ((!rate) || (rate == undefined))
            return "";
        
        if (rate.includes("$"))
            return rate;
        else
            return "$" + rate;
    }

    formatSocialLink(rate)
    {
        if ((!rate) || (rate == undefined))
            return "";
        
        if (rate.includes("$"))
            return rate;
        else
            return "$" + rate;
    }

    socialLinkClass(linkVal)
    {
        if (linkVal && (linkVal != undefined) && (linkVal.length > 1))
        {
            return "sociallink";
        }
        else
            return "displayNone";
    }

    formatWebsite(linkVal)
    {
        if ((!linkVal) || (linkVal == undefined))
            return "";

        if (linkVal.includes("http://") || linkVal.includes("https://") || linkVal.includes("Https://") || linkVal.includes("Http://"))
            return linkVal;
        else
            return "https://" + linkVal;
    }

    formatFacebook(linkVal)
    {
        if ((!linkVal) || (linkVal == undefined))
            return "";

        linkVal.replace("@", "");

        linkVal.replace("m.facebook.com", "facebook.com");

        if (linkVal.includes("http://") || linkVal.includes("https://") || linkVal.includes("Https://") || linkVal.includes("Http://"))
            return linkVal;
        else if (linkVal.includes("facebook.com"))
            return "https://" + linkVal;
        else
            return "https://facebook.com/" + linkVal;
    }

    formatPinterest(linkVal)
    {
        if ((!linkVal) || (linkVal == undefined))
            return "";

        linkVal.replace("@", "");
        
        if (linkVal.includes("http://") || linkVal.includes("https://") || linkVal.includes("Https://") || linkVal.includes("Http://"))
            return linkVal;
        else if (linkVal.includes("pinterest.com"))
            return "https://" + linkVal;
        else
            return "https://pinterest.com/" + linkVal;
    }

    formatInstagram(linkVal)
    {
        if ((!linkVal) || (linkVal == undefined))
            return "";

        linkVal.replace("@", "");
        
        if (linkVal.includes("http://") || linkVal.includes("https://") || linkVal.includes("Https://") || linkVal.includes("Http://"))
            return linkVal;
        else
            return "https://instagram.com/" + linkVal;
    }

    // Takes a data URI and returns the Data URI corresponding to the resized image at the wanted size.
    resizedataURL(datas, wantedWidth, wantedHeight)
    {
        // We create an image to receive the Data URI
        var img = document.createElement('img');

        var self = this;

        // When the event "onload" is triggered we can resize the image.
        img.onload = function()
            {        
                // We create a canvas and get its context.
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d');

                // We set the dimensions at the wanted size.
                canvas.width = wantedWidth;
                canvas.height = wantedHeight;

                // We resize the image with the canvas method drawImage();
                ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);

                var dataURI = canvas.toDataURL('image/jpeg', 0.75);

                /////////////////////////////////////////
                // Use and treat your Data URI here !! //
                /////////////////////////////////////////
                self.setState({curImageData:dataURI});

            };

        // We put the Data URI in the image's src attribute
        img.src = datas;
    }
    // Use it like that : resizedataURL('yourDataURIHere', 50, 50);

    acceptImage(base64){
       // window.alert(base64);
        this.setState({curImageData:base64});

        const img = new Image();

        img.src = base64;

        var self = this;

        img.onload = function() {
            const imgWidth = img.naturalWidth;
            const imgHeight = img.naturalHeight;

          //  console.log('imgWidth: ', imgWidth);
          //  console.log('imgHeight: ', imgHeight);

            self.setState({campaignImageWidth : imgWidth});
            self.setState({campaignImageHeight : imgHeight});

            var maxSize = 600;

            if ((imgWidth > maxSize) || (imgHeight > maxSize))
            {
                var newWidth, newHeight;

                if (imgWidth > maxSize)
                {
                    newWidth = maxSize;
                    newHeight = imgHeight * (maxSize/imgWidth);
                }
                else
                {
                    newHeight = maxSize;
                    newWidth = imgWidth * (maxSize/imgHeight);
                }
                self.resizedataURL(base64, newWidth, newHeight);
            }

        };

    }

    imageUploadError(errMsg){
        window.alert(errMsg);
    }

    renderFollowerCount(followerProps) //followerCount, followerChange, abbrev)
    {  
        var followerCount = followerProps.followerCount;
        var followerChange = followerProps.followerChange;
        var abbrev = followerProps.abbrev;

        if (followerCount == undefined)
            followerCount = 0;
        if (followerChange == undefined)
            followerChange = 0;

        var count = abbrev ? this.formatCount(followerCount) : this.formatCountNoAbbrev(followerCount);
        var countChangeClass = followerChange >= 0 ? "followerChangeUp" : "followerChangeDown";

        if (followerChange >= 0)
            followerChange = "+" + followerChange;

        var result = (
            <div>
            {count}
            <div class={countChangeClass}>
             &nbsp;
            </div>
            </div>
        );

        return result;
    }
    handleShow(props)
    {
        //console.log(props.row.postid);
        window.location.href = "/edit-challenge/"+props.row.postid;
    }
 fetchUserPosts = async (user) => {
  const Post = Parse.Object.extend('Photo'); // Replace 'Post' with your actual class name
  const query = new Parse.Query(Post);

  // Assuming user is a Parse User object
  query.equalTo('user', user);

  try {
    const results = await query.find();
    return results.length;
  } catch (error) {
    console.error('Error while fetching posts:', error);
    throw error;
  }
};
    renderPostCount(creator)
    {
        const querydata = new Parse.Query('Photo');
        querydata.equalTo("user", creator);
        let postuser_id=creator.id;
        //console.log(creator.id);
        querydata.find().then(function(result){
            // console.log('result result');
            // console.log(result);
            // console.log(result.length+'creator'+postuser_id);
            // $('#postid_'+postuser_id).html(result.length);

        }
        )
    }

    renderSocial(creator) {
        var result = ( <div> </div>
            );

        if (!creator)
            return result;

        var instagram = creator.get("instagram");
        var facebook = creator.get("facebook");
        var pinterest = creator.get("pinterest");
        var tiktok = creator.get("tiktok");
        var youtube = creator.get("youtube");
        var website = creator.get("website");

        result = (
            <div>

            <div class={this.socialLinkClass(instagram)}>

            <a target="_blank" href={this.formatInstagram(instagram)}>
            <img src="/images/social/instagram.png" width="32" height="32"></img>
            </a>

            </div>
            <div class={this.socialLinkClass(tiktok)}>

            <a target="_blank" href={this.formatWebsite(tiktok)}>
            <img src="/images/social/tiktok.png" width="32" height="32"></img>
            </a>

            </div>
            <div class={this.socialLinkClass(youtube)}>
            
            <a target="_blank" href={this.formatWebsite(youtube)}>
            <img src="/images/social/youtube.png" width="32" height="32"></img>
            </a>

            </div>
            <div class={this.socialLinkClass(website)}>

            <a target="_blank" href={this.formatWebsite(website)}>
            <img src="/images/social/website.png" width="32" height="32"></img>
            </a>

            </div>
            </div>
            );
        return result;
    }

    renderChallenges() {
        var result = (
            <div className="container">
            </div>

        );


        /*const serviceOptions = ["Free Trial", "Custom Campaign"];
        const statusOptions = ["Waiting", "Listed", "Declined"];*/

        var challengesCols = [

            {
                Header: 'ID',
                accessor: 'postid',
                show:false,
            },
            {
                Header: 'Picture',
                width: 100,
                id: 'Photo',
                accessor: 'pic' ,
                Cell: props =>
                    <div class="chlngThumb">
                        <img class="userProfileImageCreator" src={props.value != undefined ? (props.value.get("thumbnail") != undefined ? props.value.get("thumbnail").url() : "/images/default_userpic0.png" ) : ""}/>
                    </div>
            },

            {
                Header: 'Title',
                width: 200,
                id: 'Title',
                accessor: 'name' , 
                Cell: props => <div className='adminUserData usrlink'>

                <a href='javascript:void(0);'  id={props.value.split("_")[1]} title={props.value.split("_")[0]}  onClick={() => this.handleShow(props)}>{props.value.split("_")[0]}</a></div> 
            },

            {
                Header: 'Status',
                width: 100,
                id: 'Status',
                accessor: 'status' ,
                
                Cell: props => <div className='adminUserData'>{props.value==="Pending"?<span className='stat-badges st-pending'>{props.value}</span>:props.value==="Active"?<span className='stat-badges st-active'>{props.value}</span>:props.value==="Billed"?<span className='stat-badges st-billed'>{props.value}</span>:props.value==="Complete"?<span className='stat-badges st-complete'>{props.value}</span>:""}</div>  
            },

            {
                Header: 'Business Account',
                width: 150,
                id: 'businessAccount',
                accessor: 'businessAccount' , 
                Cell: props => <div className='adminUserData usrAccnt'><a href='javascript:void(0);' title={props.value} >{props.value}</a></div> 
            },
            {
                Header: 'Last Modified',
                width: 150,
                id: 'lastModified',
                accessor: 'lastModified',
                sortType: (a, b) => {
                            var a1 = a.getTime();
                            var b1 = b.getTime();
                          if(a1<b1)
                          return 1;
                          else if(a1>b1)
                          return -1;
                          else
                          return 0;
                          },
                Cell: props => <div className='adminUserData'>{props.value != undefined ? ((props.value.getMonth()+1) + "/" + (props.value.getDate()) + "/" + props.value.getFullYear().toString().substr(-2) ) : "undef"}</div> 
            },
            /*{
                Header: 'Created On',
                width: 150,
                id: 'createdAt',
                accessor: 'createdAt',
                sortType: (a, b) => {
                            var a1 = a.getTime();
                            var b1 = b.getTime();
                          if(a1<b1)
                          return 1;
                          else if(a1>b1)
                          return -1;
                          else
                          return 0;
                          },
                          
                Cell: props => <div className='adminUserData'>{props.value != undefined ? ((props.value.getMonth()+1) + "/" + (props.value.getDate()) + "/" + props.value.getFullYear().toString().substr(-2) ) : "undef"}</div> 
            },*/
            {
                Header: 'Start Date',
                width: 100,
                id: 'startDate',
                accessor: 'startDate',

                Cell: props => {
                      if(props.value){
                        const date = new Date(props.value);
                          return (
                            <div className='adminUserData'>
                              {date.toLocaleDateString('en-US', {
                                year: '2-digit',
                                month: 'numeric',
                                day: 'numeric',
                              })}
                            </div>
                          );
                      }
                    }
            },
            {
                Header: 'End Date',
                width: 100,
                id: 'endDate',
                accessor: 'endDate',
                          
                Cell: props => {
                      if(props.value){
                        const date = new Date(props.value);
                          return (
                            <div className='adminUserData'>
                              {date.toLocaleDateString('en-US', {
                                year: '2-digit',
                                month: 'numeric',
                                day: 'numeric',
                              })}
                            </div>
                          );
                      }
                    }
            },            
            
            {
                Header: 'Job Number',
                width: 108,
                id: 'jobNumber',
                accessor: 'jobNumber' ,
                
                Cell: props => <div className='adminUserData jobnum'>{props.value}</div> 
            }
            

            /*{
                Header: 'Prize',
                width: 80,
                id: 'Prize',
                accessor: 'prize' ,
                
                Cell: props => <div className='adminUserData'>{props.value}</div> 
            },
            {
                Header: 'Caption',
                width: '100%',
                id: 'Caption',
                accessor: 'content' , 
                Cell: props => <div className='adminUserData'>{props.value}</div> 
            },*/
            
        ];

        var user = Parse.User.current();
        var isadmin = (user ? user.get("isAdmin") : "0");
         if(isadmin!=1)
        {
            window.location.href = "/";
        }

      
        if (1) 
        {
            var self = this;

        //new code

             const newOptions = this.state.creatorMenuOptions;

              //   console.log('newOptions');
           // console.log(this.state.curRecipe);
           // console.log('newOptions 123');

            if (this.state.curRecipe != null)
            {
                result =  (
                 <div key="-1">
                    <form className="form-recipe" >

                        <div class="joelSectionHeader" hidden={!this.state.curRecipeIsCollab}>
                        <h3>Creator Account</h3>
                        <Dropdown options={newOptions} onChange={this._onSelect} value={this.state.curRecipeCollabUN} placeholder="Add a collaborator" hidden={!this.state.curRecipeIsCollab}/>
                        </div>


                         <div class="joelSectionHeader">
                        <h3>Title</h3>
                        </div>
                        <Textarea name="title" className="form-control" placeholder="Title of Recipe" required
                               autoFocus onChange={this.handleRecipeNameChange} value={this.getRecipeNameIfNotUntitled()}/>
                         <div class="joelSectionHeader">
                        <h3>Caption</h3>
                        </div>
                        <Textarea name="story" className="form-control" 
                                onChange={this.handleRecipeStoryChange} value={this.state.curRecipe.get("story") ? this.state.curRecipe.get("story") : "" } placeholder="Write a caption..."/>
                        <div class="joelSectionHeader">
                        <h3>Ingredients</h3>
                        </div>
                        <ReactQuill value={this.state.ingredients ? this.state.ingredients : ""}
                            onChange={this.handleChange} 
                            modules={this.modules}
                            formats={this.formats}
                            placeholder="Add your ingredients here." />
                        <br/>
                        <div class="joelSectionHeader">
                        <h3>Instructions</h3>
                        </div>
                        <ReactQuill value={this.state.instructions ? this.state.instructions : ""}
                            onChange={this.handleChange2} 
                            modules={this.modules2}
                            formats={this.formats2}
                            placeholder="Add your instructions here." />

                        <br/>

                                   
                        <div class="joelSectionHeader">
                        <h3>Diet</h3>
                        Tap the boxes that best fit your recipe so we can begin to personalize Joel’s Kitchen for everyone.<br/>

                        <div class="diet">
                        <button className={this.state.curRecipe.get("vegan") != 1 ? "btn btn-med btn-outline-primary btn-rounded" : "btn btn-med btn-success btn-rounded"} onClick={(e) => {self.handleVegan(e)}}>Vegan</button>&nbsp;
                        <button className={this.state.curRecipe.get("vegetarian") != 1 ? "btn btn-med btn-outline-primary btn-rounded" : "btn btn-med btn-success btn-rounded"} onClick={(e) => self.handleVegetarian(e)}>Vegetarian</button>&nbsp;
                        <button className={this.state.curRecipe.get("keto") != 1 ? "btn btn-med btn-outline-primary btn-rounded" : "btn btn-med btn-success btn-rounded"} onClick={(e) => self.handleKeto(e)}>Keto</button>&nbsp;
                        <button className={this.state.curRecipe.get("glutenFree") != 1 ? "btn btn-med btn-outline-primary btn-rounded" : "btn btn-med btn-success btn-rounded"} onClick={(e) => self.handleGlutenFree(e)}>Gluten-Free</button>&nbsp;
                        <button className={this.state.curRecipe.get("sugarFree") != 1 ? "btn btn-med btn-outline-primary btn-rounded" : "btn btn-med btn-success btn-rounded"} onClick={(e) => self.handleSugarFree(e)}>Sugar-Free</button>&nbsp;
                        <button className={this.state.curRecipe.get("allergenFree") != 1 ? "btn btn-med btn-outline-primary btn-rounded" : "btn btn-med btn-success btn-rounded"} onClick={(e) => self.handleAllergenFree(e)}>Allergen-Free</button>&nbsp;
                        <br/><br/>
                        </div>
                        </div>
                        
                        <button className="btn btn-lg btn-primary btn-block" onClick={(e) => self.handleSave(e)}>Save Recipe</button>
                        <button className="btn btn-lg btn-danger btn-block" onClick={(e) => self.handleDelete(e)}>Delete Recipe</button>
                   
                        <button className="btn btn-lg btn-primary btn-block" onClick={(e) => self.handleCancel(e)}>Cancel</button>
                    </form>
                </div>
              );

            }
 //new code
  else if (global.curProduct == null)
            result =  (
                <div className="campaigns__mn" key="-1">
                    {/* <Login/>
                        <div class="createNewSection">
                            <center><div class="createNewBtnWrapper">
                            <br/>
                            <button className="btn btn-lg btn-primary btn-block createNewBtn" onClick={this.handleNew}>New</button>
                            </div>
                            &nbsp;
                            <div class="createNewDropdown">
                                <br/>
                                <Dropdown options={createDropdownOptions} onChange={this._onSelectNew} value="Recipe" placeholder="Recipe" />
                            </div>

                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            &nbsp;
                            </center> 
                        </div>
                    */}

                    {/*new code*/}
                    <div className="campaigns__inner">
                        <div className="screenHider" hidden={!this.state.dialogVisible}></div>
                        <div class="joelSectionHeader" width="100%"></div>
                        <div className='campaigns__header'>
                            <h2>Challenges </h2>
                           {/* <div className='campaigns__table'>
                                <ReactTable width={2000} data={this.state.reactTableData} columns={challengesCols} defaultPageSize={25} />
                            </div>*/}
                        </div>
                    </div>
                </div>
              );
        }
        else
        {
            result =  (


                 <div className="container" key="-1">

                 Login to continue.

                 <br/>
             </div>
              );
        }
        return result;
    }

    

    searchSuggestion() {
        $(".menu-search-wrap .search_wrap .form-mn .search_inpt_inner").siblings(".search_rstl_mn").addClass("active");
        $(".menu-search-wrap .search_wrap .form-mn button.close-btn").removeClass("hide");
      }

      searchSuggestionCloase() {
        $(".menu-search-wrap .search_wrap .form-mn .search_inpt_inner").siblings(".search_rstl_mn").removeClass("active");
        $(".menu-search-wrap .search_wrap .form-mn button.close-btn").addClass("hide");
      }

      accountRemove() {
        $(".sugested_acunt ul li a.cls-btn").closest("li").hide(200);
      }

      topAccounts() {
        $(".menu-search-wrap .search_wrap .form-mn>.search_rstl_mn>.inner aside.search_recent_box").hide("slow");
        $(".menu-search-wrap .search_wrap .form-mn>.search_rstl_mn>.inner aside.all-acunts").show("slow");
      }

      backAccounts() {
        $(".menu-search-wrap .search_wrap .form-mn>.search_rstl_mn>.inner aside.search_recent_box").show("slow");
        $(".menu-search-wrap .search_wrap .form-mn>.search_rstl_mn>.inner aside.all-acunts").hide("slow");
      }



    render() {

        if (this.state.userType == "challengesdata")
        {
            //return this.renderCreatorRates();
            return this.renderChallenges();
        }

        if (this.state.userType == "creatorrates")
        {
            return this.renderCreatorRates();
        }
        if (this.state.userType == "campaignsBiz")
        {
            return this.renderCampaignsBiz();
        }
        if (this.state.userType == "campaignsAdmin")
        {
            return this.renderCampaignsAdmin();
        }

        //console.log("Rendering recipe list");
        var result = (
            <div className="container">
            </div>

        );
         /*
          const columns = [{
            Header: 'Name',
            accessor: 'name' // String-based value accessors!
          }, {
            Header: 'Age',
            accessor: 'age',
            Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
          }, {
            id: 'friendName', // Required because our accessor is not a string
            Header: 'Friend Name',
            accessor: d => d.friend.name // Custom value accessors!
          }, {
            Header: props => <span>Friend Age</span>, // Custom header components!
            accessor: 'friend.age'
          }]

*/
//<a onClick={() => self.selectRecipe(recipe)} >


        var firstColumnNormal = [ {
            Header: 'Signup Date',
            width: 84,
            id: 'createdAt',
            accessor: 'createdAt',
            sortType: (a, b) => {
                        var a1 = a.getTime();
                        var b1 = b.getTime();
                      if(a1<b1)
                      return 1;
                      else if(a1>b1)
                      return -1;
                      else
                      return 0;
                      },
            //Cell: props => <div className='adminUserData'>{props.value != undefined ? ((props.value.getMonth()+1) + "/" + (props.value.getDate()+1) + "/" + props.value.getFullYear() ) : "undef"}</div> // Custom cell components!
            Cell: props => <div className='adminUserData'>{props.value != undefined ? ((props.value.getMonth()+1) + "/" + (props.value.getDate()) + "/" + props.value.getFullYear() ) : "undef"}</div> // Custom cell components!
          },
        ];

        var firstColumnCreator = [ {
            Header: 'Last Modified',
            width: 92,
            id: 'lastModified',
            accessor: 'lastModified',
            sortType: (a, b) => {
                        var a1 = a.getTime();
                        var b1 = b.getTime();
                      if(a1<b1)
                      return 1;
                      else if(a1>b1)
                      return -1;
                      else
                      return 0;
                      },
            Cell: props => <div className='adminUserData'>{props.value != undefined ? ((props.value.getMonth()+1) + "/" + (props.value.getDate()) + "/" + props.value.getFullYear() ) : "undef"}</div> // Custom cell components!
          }
        ];

        var columns = [
        /*{
            Header: 'Type',
            //accessor: 'recipe', // String-based value accessors!
            id: 'Type',
            accessor: d => { console.log("D is " + JSON.stringify(d)); return d.recipe; } , // Custom value accessors!
            Cell: props => <span className='number'>{(props.value != undefined) ?
            ( (props.value.get("isCollab") || (props.value.get("collabUser"))) ? "Collaboration" : "Recipe" )
            : "undef"}</span> // Custom cell components!
          },*/{
            Header: 'Collaborate',
            width: 120,
            //accessor: 'recipe', // String-based value accessors!
            id: 'thisUser',
            accessor: 'thisUser',
            Cell: props => <div class="chlngThumb">
                <input type="checkbox" name="checkbox" id="checkbox_id" value="value" />
                <label htmlFor="checkbox_id"></label>
            </div>
          },{
            Header: 'Account',
            width: 190,
            //accessor: 'recipe', // String-based value accessors!
            id: 'thisUserAccount',
            accessor: 'thisUserAccount',
            Cell: props => <div><div class="chlngThumb"><a href='javascript:void(0);'><img class="userProfileImageCreator" src={props.value != undefined ?
                        (props.value.get("profilePictureSmall") != undefined ? props.value.get("profilePictureSmall").url() : "/images/genericuser.png" ) : ""}/><span>creatoraccount</span></a></div><span><a href={props.value.get("displayUsername")?"https://www.joelskitchen.com/user/"+props.value.get("displayUsername"):''}>{props.value.get("displayUsername")?props.value.get("displayUsername"):''}</a></span></div>
          }, {
            Header: 'Posts',
            width: 100,
            //accessor: 'recipe', // String-based value accessors!
            id: 'posts',
            accessor: 'posts' , // Custom value accessors! Followerscounts
            Cell: props =><div className='adminUserData'><Postcounts postData={props.value} /></div>
          }, {
            Header: 'Views',
            width: 100,
            //accessor: 'recipe', // String-based value accessors!
            id: 'postsViews',
            accessor: 'postsViews' , // Custom value accessors!
            Cell: props =><div className='adminUserData'>{props.value}</div> 
          },{
            Header: 'Engagements',
            width: 110,
            //accessor: 'recipe', // String-based value accessors!
            id: 'engagementsPosts',
            accessor: 'engagementsPosts' , // Custom value accessors!
            Cell: props =><div className='adminUserData'>{props.value}</div>
          }, {
            Header: 'Followers',
            width: 100,
            //accessor: 'recipe', // String-based value accessors!
            id: 'followersPosts',
            accessor: 'followersPosts' , // Custom value accessors!
            Cell: props =><div className='adminUserData'>1{/*<Followerscounts postDataUser={props.value} />*/}{/*{props.value}*/}</div>
        }, {
            Header: 'Social Link',
            width: 110,
            //accessor: 'recipe', // String-based value accessors!
            id: 'socialLinks',
            accessor: 'socialLinks' , // Custom value accessors!
            Cell: props => <div className='adminUserData'>{props.value}</div>
          }, {
            Header: 'Email',
            width: 220,
            //accessor: 'recipe', // String-based value accessors!
            id: 'createrEmail',
            accessor: 'createrEmail' , // Custom value accessors!
            Cell: props => <div className='adminUserData'><a href={"mailto:"+props.value}>{props.value} <img src="images/social/website.png" width="20" height="20"></img><i class="bi bi-envelope"></i></a></div>
          }];

        var isChecked = "";


        var creatorCols = [
            {
            Header: 'Post Rate',
            width: 70,
            //accessor: 'recipe', // String-based value accessors!
            id: 'Post Rate',
            accessor: 'postRate' , // Custom value accessors!
            sortMethod: (a, b) => Number(a)-Number(b),
            Cell: props => <div className='adminUserData'>{this.formatRate(props.value)}</div> ,
          }, {
            Header: 'Recipe Rate',
            width: 85,
            //accessor: 'recipe', // String-based value accessors!
            id: 'Recipe Rate',
            accessor: 'recipeRate' , // Custom value accessors!
            sortMethod: (a, b) => Number(a)-Number(b),
            Cell: props => <div className='adminUserData'>{this.formatRate(props.value)}</div> ,
          },  {
            Header: 'Show',
            width: 50,
            sortMethod: (a, b) => {
                        //window.alert("sort");
                        //console.log("gllll");
                        var a1 = this.isUserInCD(a);
                        var b1 = this.isUserInCD(b);
                      if(a1<b1)
                      return 1;
                      else if(a1>b1)
                      return -1;
                      else
                      return 0;
                      },
            //accessor: 'recipe', // String-based value accessors!
            id: 'Show',
           // accessor: 'thisUser' , // Custom value accessors!
            accessor: d => d.thisUser,
            /*
            Cell: props => <div className='adminUserData'>
                <input type="checkbox" name="vehicle3" value="Boat" onChange={(event) => { event.target.checked ? this.updateCheckOn(props.value) : this.updateCheckOff(props.value); }}
                defaultChecked={(props.value && this.isUserInCD(props.value)) ? 1: 0} 
                  />*/
                /*
            Cell: props => <div className='adminUserData'>
                <a onClick={(event) => { this.toggleShowInCD(props.value); this.forceUpdate(); }} class={this.isUserInCD(props.value) ? "adminShowInCDOn" : "adminShowInCDOff"}>
                 {this.isUserInCD(props.value) ? "On" : "Off"} </a>
                </div>
                */

            Cell: props => <div className='adminUserData'>
                <a onClick={(event) => { this.toggleShowInCD(props.value); this.forceUpdate(); }} class={this.isUserInCD(props.value) ? "adminShowInCDOn" : "adminShowInCDOff"}>
                 {this.isUserInCD(props.value) ? "On" : "Off"} </a>
                </div>
          }, 
        ];

        var curUserCols = [];

        /*if (this.state.userType != "creator")
            curUserCols = Array.prototype.concat.apply([], [firstColumnNormal, columns]);
        else
            curUserCols = Array.prototype.concat.apply([], [firstColumnCreator, columns, creatorCols]);*/
        curUserCols = Array.prototype.concat.apply([], [columns]);

        var curTitle = "All Accounts";

        if (this.state.userType == "creatoraccounts")
            curTitle = "Creator Accounts";
        else if (this.state.userType == "businessaccounts")
            curTitle = "Business Accounts";

        var user = Parse.User.current();
        if ((user != null) && (user.get("isAdmin") == 1))
        {
            let users = (this.state.users.length !== undefined) ? this.state.users : [];
           // console.log("reactTableData length " + this.state.reactTableData.length);

            var self = this;

/*
                        <Textarea name = "instructions" rows="6" className="form-control" 
                                onChange={this.handleRecipeInstructionsChange} value={this.state.curRecipe.get("instructions")}
                                placeholder="Add your instructions here."
                                /> 
*/
    
          result =  (
            <div className="account_main_top" key="-1">
              <div className="inner_wrap">
                <div className="table_top_searchbar post_topbar">
                  <div className='left-part'>
                      <a href="javascript:void(0);">Invite</a>
                      <h2>Invite creators to launch a campaign for your brand.</h2>
                  </div>
                  <div className='menu-search-wrap menu-search-mn d-flex flex-row flex-wrap align-items-md-center'>
                    <div className="search_wrap">
                      <form className="form-mn d-flex flex-row flex-wrap">
                        <div className='search_inpt_inner'>
                        {/*onClick={this.searchSuggestion} */}
                          <input className="form-control" type="search" placeholder="Search" aria-label="Search"  onChange={this.handleSearch} />
                          <button type="submit"><img src="/images/search.svg" alt="Search"/></button>
                          <button type="button" className='close-btn hide' onClick={this.searchSuggestionCloase} ><img src="/images/close_small.svg" alt="Close"/></button>
                        </div>
                        <div className='search_rstl_mn'>
                          <div className='inner'>
                            <aside className="search_box sugested_acunt all-acunts" style={{"display": "none"}}>
                              <div className='tlt-part d-flex flex-row flex-wrap align-items-center justify-content-between '>
                                <a href="#" title='All Accounts' onClick={this.backAccounts}><img src="/images/back_top.svg" alt="Back to Top" /></a>
                                <h2>All accounts</h2>
                              </div>
                              <div className='cnt-part'>
                                <ul className="list_grp">
                                  <li>
                                    <a href="/user/orchidsnsweettea" title="orchidsnsweettea" className="media">
                                      <div className="media-left"><img src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/be32f00c681ba0887e6220be0e301b28_file.bin" width="40px" height="40px" alt="itsallgoodvegan" /></div>
                                      <div className="media-body">
                                        <h6 className="mt-0">orchidsnsweettea</h6>
                                        <span>Orchids + Sweet Tea</span>
                                      </div>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="/user/itsallgoodvegan" title="itsallgoodvegan" className="media">
                                      <div className="media-left"><img src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/012f10ac9cba7b5211b1229b9e453b2b_file.bin" width="40px" height="40px" alt="itsallgoodvegan" /></div>
                                      <div className="media-body">
                                        <h6 className="mt-0">itsallgoodvegan</h6>
                                        <span>Marissa Wong</span>
                                      </div>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="/user/itsallgoodvegan" title="itsallgoodvegan" className="media">
                                      <div className="media-left"><img src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/012f10ac9cba7b5211b1229b9e453b2b_file.bin" width="40px" height="40px" alt="itsallgoodvegan" /></div>
                                      <div className="media-body">
                                        <h6 className="mt-0">itsallgoodvegan</h6>
                                        <span>Marissa Wong</span>
                                      </div>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="/user/itsallgoodvegan" title="itsallgoodvegan" className="media">
                                      <div className="media-left"><img src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/012f10ac9cba7b5211b1229b9e453b2b_file.bin" width="40px" height="40px" alt="itsallgoodvegan" /></div>
                                      <div className="media-body">
                                        <h6 className="mt-0">itsallgoodvegan</h6>
                                        <span>Marissa Wong</span>
                                      </div>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="/user/itsallgoodvegan" title="itsallgoodvegan" className="media">
                                      <div className="media-left"><img src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/012f10ac9cba7b5211b1229b9e453b2b_file.bin" width="40px" height="40px" alt="itsallgoodvegan" /></div>
                                      <div className="media-body">
                                        <h6 className="mt-0">itsallgoodvegan</h6>
                                        <span>Marissa Wong</span>
                                      </div>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="/user/itsallgoodvegan" title="itsallgoodvegan" className="media">
                                      <div className="media-left"><img src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/012f10ac9cba7b5211b1229b9e453b2b_file.bin" width="40px" height="40px" alt="itsallgoodvegan" /></div>
                                      <div className="media-body">
                                        <h6 className="mt-0">itsallgoodvegan</h6>
                                        <span>Marissa Wong</span>
                                      </div>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="/user/itsallgoodvegan" title="itsallgoodvegan" className="media">
                                      <div className="media-left"><img src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/012f10ac9cba7b5211b1229b9e453b2b_file.bin" width="40px" height="40px" alt="itsallgoodvegan" /></div>
                                      <div className="media-body">
                                        <h6 className="mt-0">itsallgoodvegan</h6>
                                        <span>Marissa Wong</span>
                                      </div>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="/user/itsallgoodvegan" title="itsallgoodvegan" className="media">
                                      <div className="media-left"><img src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/012f10ac9cba7b5211b1229b9e453b2b_file.bin" width="40px" height="40px" alt="itsallgoodvegan" /></div>
                                      <div className="media-body">
                                        <h6 className="mt-0">itsallgoodvegan</h6>
                                        <span>Marissa Wong</span>
                                      </div>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="/user/itsallgoodvegan" title="itsallgoodvegan" className="media">
                                      <div className="media-left"><img src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/012f10ac9cba7b5211b1229b9e453b2b_file.bin" width="40px" height="40px" alt="itsallgoodvegan" /></div>
                                      <div className="media-body">
                                        <h6 className="mt-0">itsallgoodvegan</h6>
                                        <span>Marissa Wong</span>
                                      </div>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="/user/itsallgoodvegan" title="itsallgoodvegan" className="media">
                                      <div className="media-left"><img src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/012f10ac9cba7b5211b1229b9e453b2b_file.bin" width="40px" height="40px" alt="itsallgoodvegan" /></div>
                                      <div className="media-body">
                                        <h6 className="mt-0">itsallgoodvegan</h6>
                                        <span>Marissa Wong</span>
                                      </div>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </aside>
                            <aside className="search_box recent_box recent_search sugested_acunt" style={{"display": "none"}}>
                              <h2>Recent</h2>
                              <ul className="list_grp">
                                <li className="menu">
                                  <a className='cls-btn' onClick={this.accountRemove}><svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none"><path d="M1.90372 10.2268L0.903723 9.27106L4.90372 5.44818L0.903718 1.6253L1.90372 0.669587L5.90372 4.49246L9.90372 0.669592L10.9037 1.62531L6.90372 5.44818L10.9037 9.27106L9.90372 10.2268L5.90372 6.4039L1.90372 10.2268Z" fill="#1C1B1F"/></svg></a>
                                  <a href="/user/orchidsnsweettea" title="orchidsnsweettea" className="media">
                                    <div className="media-left"><img src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/be32f00c681ba0887e6220be0e301b28_file.bin" width="40px" height="40px" alt="itsallgoodvegan" /></div>
                                    <div className="media-body">
                                      <h6 className="mt-0">orchidsnsweettea</h6>
                                      <span>Orchids + Sweet Tea</span>
                                    </div>
                                  </a>
                                </li>
                                <li className="menu">
                                <a className='cls-btn'><svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none"><path d="M1.90372 10.2268L0.903723 9.27106L4.90372 5.44818L0.903718 1.6253L1.90372 0.669587L5.90372 4.49246L9.90372 0.669592L10.9037 1.62531L6.90372 5.44818L10.9037 9.27106L9.90372 10.2268L5.90372 6.4039L1.90372 10.2268Z" fill="#1C1B1F"/></svg></a>
                                  <a href="/user/itsallgoodvegan" title="itsallgoodvegan" className="media">
                                    <div className="media-left"><img src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/012f10ac9cba7b5211b1229b9e453b2b_file.bin" width="40px" height="40px" alt="itsallgoodvegan" /></div>
                                    <div className="media-body">
                                      <h6 className="mt-0">itsallgoodvegan</h6>
                                      <span>Marissa Wong</span>
                                    </div>
                                  </a>
                                </li>
                                <li className="menu">
                                <a className='cls-btn'><svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none"><path d="M1.90372 10.2268L0.903723 9.27106L4.90372 5.44818L0.903718 1.6253L1.90372 0.669587L5.90372 4.49246L9.90372 0.669592L10.9037 1.62531L6.90372 5.44818L10.9037 9.27106L9.90372 10.2268L5.90372 6.4039L1.90372 10.2268Z" fill="#1C1B1F"/></svg></a>
                                  <a href="/user/cheerfulchoices" title="cheerfulchoices" className="media">
                                    <div className="media-left"><img src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/6c7c3c80ecd4eac86050b504aa6bc8bb_file.bin" width="40px" height="40px" alt="itsallgoodvegan" /></div>
                                    <div className="media-body">
                                      <h6 className="mt-0">cheerfulchoices</h6>
                                      <span>Mackenzie Burgess</span>
                                    </div>
                                  </a>
                                </li>
                              </ul>
                            </aside>
                            <aside className="search_box recent_box recomnd_you img_post_grid" style={{"display": "none"}}>
                              <h2>Recommended for you </h2>
                              <ul className="list_grp">
                                <li>
                                  <a href="/user/orchidsnsweettea" title="orchidsnsweettea">
                                    <figure className="thumbnail">
                                      <img src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/776a874f37f7ab38752cc0d8ed5b6399_thumbnail.jpg" />
                                    </figure>
                                  </a>
                                </li>
                                <li>
                                  <a href="/user/orchidsnsweettea" title="orchidsnsweettea">
                                    <figure className="thumbnail">
                                      <img src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/776a874f37f7ab38752cc0d8ed5b6399_thumbnail.jpg" />
                                    </figure>
                                  </a>
                                </li>
                                <li>
                                  <a href="/user/orchidsnsweettea" title="orchidsnsweettea">
                                    <figure className="thumbnail">
                                      <img src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/776a874f37f7ab38752cc0d8ed5b6399_thumbnail.jpg" />
                                    </figure>
                                  </a>
                                </li>
                                <li>
                                  <a href="/user/orchidsnsweettea" title="orchidsnsweettea">
                                    <figure className="thumbnail">
                                      <img src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/776a874f37f7ab38752cc0d8ed5b6399_thumbnail.jpg" />
                                    </figure>
                                  </a>
                                </li>
                              </ul>
                            </aside>
                            <aside className="search_box search_recent_box top_account sugested_acunt">
                              <div className='tlt-part d-flex flex-row flex-wrap align-items-center justify-content-between '>
                                <h2>Top accounts</h2>
                                <a href="#" title='See more' className='see-link' onClick={this.topAccounts}>See more...</a>
                              </div>
                              <div className='cnt-part'>
                                <ul className="list_grp">
                                  <li className="menu">
                                    <a href="/user/orchidsnsweettea" title="orchidsnsweettea" className="media">
                                      <div className="media-left"><img src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/be32f00c681ba0887e6220be0e301b28_file.bin" width="40px" height="40px" alt="itsallgoodvegan" /></div>
                                      <div className="media-body">
                                        <h6 className="mt-0">orchidsnsweettea</h6>
                                        <span>Orchids + Sweet Tea</span>
                                      </div>
                                    </a>
                                  </li>
                                  <li className="menu">
                                    <a href="/user/itsallgoodvegan" title="itsallgoodvegan" className="media">
                                      <div className="media-left"><img src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/012f10ac9cba7b5211b1229b9e453b2b_file.bin" width="40px" height="40px" alt="itsallgoodvegan" /></div>
                                      <div className="media-body">
                                        <h6 className="mt-0">itsallgoodvegan</h6>
                                        <span>Marissa Wong</span>
                                      </div>
                                    </a>
                                  </li>
                                  <li className="menu">
                                    <a href="/user/cheerfulchoices" title="cheerfulchoices" className="media">
                                      <div className="media-left"><img src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/6c7c3c80ecd4eac86050b504aa6bc8bb_file.bin" width="40px" height="40px" alt="itsallgoodvegan" /></div>
                                      <div className="media-body">
                                        <h6 className="mt-0">cheerfulchoices</h6>
                                        <span>Mackenzie Burgess</span>
                                      </div>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </aside>
                            <aside className="search_box search_recent_box top_post img_post_grid">
                              <div className='tlt-part d-flex flex-row flex-wrap align-items-center justify-content-between '>
                                <h2>Top posts </h2>
                                <a href="#" title='See more' className='see-link'>See more...</a>
                              </div>
                              <div className='cnt-part'>
                                <ul className="list_grp">
                                  <li>
                                    <a href="/user/orchidsnsweettea" title="orchidsnsweettea">
                                      <figure className="thumbnail">
                                        <img src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/776a874f37f7ab38752cc0d8ed5b6399_thumbnail.jpg" />
                                      </figure>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="/user/orchidsnsweettea" title="orchidsnsweettea">
                                      <figure className="thumbnail">
                                        <img src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/776a874f37f7ab38752cc0d8ed5b6399_thumbnail.jpg" />
                                      </figure>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="/user/orchidsnsweettea" title="orchidsnsweettea">
                                      <figure className="thumbnail">
                                        <img src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/776a874f37f7ab38752cc0d8ed5b6399_thumbnail.jpg" />
                                      </figure>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="/user/orchidsnsweettea" title="orchidsnsweettea">
                                      <figure className="thumbnail">
                                        <img src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/776a874f37f7ab38752cc0d8ed5b6399_thumbnail.jpg" />
                                      </figure>
                                    </a>
                                  </li>
                                </ul>
                              </div>  
                            </aside>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className='admin_table_wrapper'>
                  <div class="joelSectionHeader" width="100%">
                    <h3>{curTitle}</h3>
                  </div>
                  <div className="admin_table">
                    {this.state.loadingtable && this.state.loadingtable==true?
                      <ReactTable
                        page={this.state.pageIndex}
                        data={this.state.reactTableData}
                        columns={curUserCols}                        
                        defaultPageSize={this.state.defaultPageSize}
                        pages={this.state.pages}                       
                        onPageChange={this.checkpagination} 
                      />
                    :''}
                  </div>
                </div>
              </div>
            </div>
          );
        }
        else
        {
            result =  (


                 <div className="container" key="-1">

                 This page is only accessible to admins.

                 <br/>
             </div>
              );
        }
        return result;

    }


}

export default AllPosts;

