import React from 'react';
import ReactDOM from 'react-dom';
import {
  Route,
  NavLink,
  BrowserRouter as Router,
  Switch
} from "react-router-dom";
import './index.css';
import App from './App';
import AppTop from './AppTop';
import Home from "./components/home";
import Login from "./components/login";
import Footer from './footer.jsx';
import Notfound from "./notfound";
import AdminUserTable from './components/adminusertable.jsx';
import AllUserTable from './components/allusertable.jsx';
import AllPosts from './components/allposts.jsx';
import AllCreators from './components/allcreators.jsx';
import registerServiceWorker from './registerServiceWorker';
import CreateChallenge from "./CreateChallenge.jsx";
import EditChallenge from "./EditChallenge.jsx";
import Menuleft from './Menuleft.jsx';
import Payments from './Payments.jsx';
import Stripepayment from './Stripepayment.jsx';
import CreatorList from "./components/creatorlist";
import Custompagination from "./components/custompagination";
import Allcreatorsnew from "./components/allcreatorsnew";
import Adminentries from "./components/adminentries";
import AdminAccounts from "./components/AdminAccounts";
import Adminkpi from "./components/adminkpi";
import Makepayment from './Makepayment';
import AdminAppPageVisit from './components/adminAppPageVisit.jsx';
import AdminkpiGraph from './components/adminkpiGraph.jsx';
import Creatordirectorytable from './components/creatordirectorytable.jsx';
import Tablenew from './components/tablenew.jsx';
//import Makepayment1 from './Makepayment1';
import Entries from './components/entries.jsx';
import JKCMAccounts from "./components/JKCMAccounts";
import AdminMenuleft from './AdminMenuleft.jsx';
const routing = (
  <Router>

      <Switch>
        <Route exact path="/" component={()=>(<><App/></>)} />

        <Route path="/business/campaigns/" component={()=>(
          <>
            <AppTop/>
            <section className='wrapper_sc'>
              <div className='wrapper_mn'>
                <div className='container'>
                  <div className='inner'>
                    <nav className='sidebar'>
                      <div className='wrapper'>
                        <aside className="menu_sidebar">
                          <h2>Campaigns</h2>
                          <Menuleft/>
                        </aside>                          
                      </div>
                    </nav>
                    <main className='listing'>
                      <AdminUserTable userType="campaignsBiz" />
                    </main>
                  </div>
                  <Footer/>
                </div>
              </div>
            </section>
          </>
        )} />

      <Route path="/business/tablenew/" component={()=>(
          <>
            <AppTop/>
            <section className='wrapper_sc'>
              <div className='wrapper_mn'>
                <div className='container'>
                  <div className='inner'>
                    <nav className='sidebar'>
                      <div className='wrapper'>
                        <aside className="menu_sidebar">
                          <h2>Demo</h2>
                          <Menuleft/>
                        </aside>                          
                      </div>
                    </nav>
                    <main className='listing'>
                    <Tablenew />
                    </main>
                  </div>
                  <Footer/>
                </div>
              </div>
            </section>
          </>
        )} />


        <Route path="/business/creatordirectorytable/" component={()=>(
          <>
            <AppTop/>
            <section className='wrapper_sc'>
              <div className='wrapper_mn'>
                <div className='container'>
                  <div className='inner'>
                    <nav className='sidebar'>
                      <div className='wrapper'>
                        <aside className="menu_sidebar">
                          <h2>Demo</h2>
                          <Menuleft/>
                        </aside>                          
                      </div>
                    </nav>
                    <main className='listing'>
                    <Creatordirectorytable />
                    </main>
                  </div>
                  <Footer/>
                </div>
              </div>
            </section>
          </>
        )} />

        <Route path="/business/creators" component={()=>(
          <>
            <AppTop/>
            <section className='wrapper_sc'>
              <div className='wrapper_mn'>
                <div className='container'>
                  <div className='inner'>
                    <nav className='sidebar'>
                      <div className='wrapper'>
                        <aside className="menu_sidebar">
                          <h2>Campaigns</h2>
                          <Menuleft/>
                        </aside>
                      </div>
                    </nav>
                    <main className='listing'>
                      <AdminUserTable userType="creatorrates" />
                    </main>
                  </div>
                  <Footer/>
                </div>
              </div>
            </section>
          </>
        )} />

        /*<Route path="/business/accounts" component={()=>(
          <>
            <AppTop/>
            <section className='wrapper_sc'>
              <div className='wrapper_mn'>
                <div className='container'>
                  <div className='inner'>
                    <nav className='sidebar'>
                      <div className='wrapper'>
                        <aside className="menu_sidebar">
                          <h2>Accounts</h2>
                          <Menuleft/>
                        </aside>
                      </div>
                    </nav>
                    <main className='listing'>
                      <AllUserTable />
                    </main>
                  </div>
                  <Footer/>
                </div>
              </div>
            </section>
          </>
        )} />*/

        <Route path="/business/challenges" component={()=>(
          <>
            <AppTop/>
            <section className='wrapper_sc'>
              <div className='wrapper_mn'>
                <div className='container'>
                  <div className='inner'>
                    <nav className='sidebar'>
                      <div className='wrapper'>
                        <aside className="menu_sidebar">
                          <h2>Campaigns</h2>
                          <Menuleft/>
                        </aside>
                      </div>
                    </nav>
                    <main className='listing'>
                      <AdminUserTable userType="challengesdata" />
                    </main>
                  </div>
                  <Footer/>
                </div>
              </div>
            </section>
          </>
        )} />

        <Route path="/business/" component={()=>(
          <>
            <AppTop/>
            <section className='wrapper_sc'>
              <div className='wrapper_mn'>
                <div className='container'>
                  <div className='inner'>
                    <nav className='sidebar'>
                      <div className='wrapper'>
                        <aside className="menu_sidebar">
                          <h2>Campaigns</h2>
                          <Menuleft/>
                        </aside>
                      </div>
                    </nav>
                    <main className='listing'>
                      <AdminUserTable userType="creatorrates" />
                    </main>
                  </div>
                  <Footer/>
                </div>
              </div>
            </section>
          </>
        )} />

        <Route path="/admin/campaigns/" component={()=>(
          <>
            <AppTop/>
            <section className='wrapper_sc'>
              <div className='wrapper_mn'>
                <div className='container'>
                  <div className='inner'>
                    <nav className='sidebar'>
                      <div className='wrapper'>
                        <aside className="menu_sidebar">
                          <h2>Admin Pages</h2>
                          <div className="sbNavArea">
                            <div className="sbNavInactive" ><a href="/admin/signups">Accounts</a></div>
                            <div className="sbNavActive" ><a href="/business/campaigns">Campaigns</a></div>
                          </div>
                        </aside>
                      </div>
                    </nav>
                    <main className='listing'>
                      <AdminUserTable userType="campaignsAdmin" />
                    </main>
                  </div>
                  <Footer/>
                </div>
              </div>
            </section>
          </>
        )} />

        <Route path="/admin/signups/" component={()=>(
          <>
            <AppTop/>
            <section className='wrapper_sc'>
              <div className='wrapper_mn'>
                <div className='container'>
                  <div className='inner'>
                    <nav className='sidebar'>
                      <div className='wrapper'>
                        <aside className="menu_sidebar">
                          <h2>Accounts</h2>
                        </aside>
                      </div>
                    </nav>
                    <main className='listing'>
                      <AllUserTable userType="allaccounts" />
                    </main>
                  </div>
                  <Footer/>
                </div>
              </div>
            </section>
          </>
        )} />

        <Route path="/posts" component={()=>(
          <>
            <AppTop/>
            <section className='wrapper_sc'>
              <div className='wrapper_mn'>
                <div className='container'>
                  <div className='inner'>
                    <nav className='sidebar'>
                      <div className='wrapper'>
                        <aside className="menu_sidebar">
                          <Menuleft/>
                        </aside>
                      </div>
                    </nav>
                    <main className='listing'>
                      <AllPosts />
                    </main>
                  </div>
                  <Footer/>
                </div>
              </div>
            </section>
          </>
        )} />

        <Route path="/entries" component={()=>(
          <>
            <AppTop/>
            <section className='wrapper_sc'>
              <div className='wrapper_mn'>
                <div className='container'>
                  <div className='inner'>
                    <nav className='sidebar'>
                      <div className='wrapper'>
                        <aside className="menu_sidebar">
                          <Menuleft/>
                        </aside>
                      </div>
                    </nav>
                    <main className='listing'>
                      <Entries />
                    </main>
                  </div>
                  <Footer/>
                </div>
              </div>
            </section>
          </>
        )} />

        <Route path="/creators-page1" component={()=>(
          <>
            <AppTop/>
            <section className='wrapper_sc'>
              <div className='wrapper_mn'>
                <div className='container'>
                  <div className='inner'>
                    <nav className='sidebar'>
                      <div className='wrapper'>
                        <aside className="menu_sidebar">
                          <Menuleft/>
                        </aside>
                      </div>
                    </nav>
                    <main className='listing'>
                      <AllCreators />
                    </main>
                  </div>
                  <Footer/>
                </div>
              </div>
            </section>
          </>
        )} />
         <Route path="/creators" component={()=>(
          <>
            <AppTop/>
            <section className='wrapper_sc'>
              <div className='wrapper_mn'>
                <div className='container'>
                  <div className='inner'>
                    <nav className='sidebar'>
                      <div className='wrapper'>
                        <aside className="menu_sidebar">
                          <Menuleft/>
                        </aside>
                      </div>
                    </nav>
                    <main className='listing'>
                      <JKCMAccounts />
                    </main>
                  </div>
                  <Footer/>
                </div>
              </div>
            </section>
          </>
        )} />

        <Route path="/admin/creator/" component={()=>(
          <>
            <AppTop/>
            <section className='wrapper_sc'>
              <div className='wrapper_mn'>
                <div className='container'>
                  <div className='inner'>
                    <nav className='sidebar'>
                      <div className='wrapper'>
                        <aside className="menu_sidebar">
                          <h2>Accounts</h2>
                        </aside>
                      </div>
                    </nav>
                    <main className='listing'>
                      <AllUserTable userType="creatoraccounts" />
                    </main>
                  </div>
                  <Footer/>
                </div>
              </div>
            </section>
          </>
        )} />

        <Route path="/admin/business/" component={()=>(
          <>
            <AppTop/>
            <section className='wrapper_sc'>
              <div className='wrapper_mn'>
                <div className='container'>
                  <div className='inner'>
                    <nav className='sidebar'>
                      <div className='wrapper'>
                        <aside className="menu_sidebar">
                          <h2>Accounts</h2>
                        </aside>
                      </div>
                    </nav>
                    <main className='listing'>
                      <AllUserTable userType="businessaccounts" />
                    </main>
                  </div>
                  <Footer/>
                </div>
              </div>
            </section>
          </>
        )} />

{/*<Route path="/admin/allaccounts/" component={()=>(
  <>
    <AppTop/>
    <section className='wrapper_sc'>
      <div className='wrapper_mn'>
        <div className='container'>
          <div className='inner'>
            <nav className='sidebar'>
              <div className='wrapper'>
                <aside className="menu_sidebar">
                  <h3>Accounts</h3>
                </aside>
              </div>
            </nav>
            <main className='listing'>
              <AllUserTable userType="allaccounts" />
            </main>
          </div>
          <Footer/>
        </div>
      </div>
    </section>
  </>
)} />
<Route path="/admin/creatoraccounts/" component={()=>(
  <>
    <AppTop/>
    <section className='wrapper_sc'>
      <div className='wrapper_mn'>
        <div className='container'>
          <div className='inner'>
            <nav className='sidebar'>
              <div className='wrapper'>
                <aside className="menu_sidebar">
                  <h3>Accounts</h3>
                </aside>
              </div>
            </nav>
            <main className='listing'>
              <AllUserTable userType="creatoraccounts" />
            </main>
          </div>
          <Footer/>
        </div>
      </div>
    </section>
  </>
)} />
<Route path="/admin/businessaccounts/" component={()=>(
  <>
    <AppTop/>
    <section className='wrapper_sc'>
      <div className='wrapper_mn'>
        <div className='container'>
          <div className='inner'>
            <nav className='sidebar'>
              <div className='wrapper'>
                <aside className="menu_sidebar">
                  <h3>Accounts</h3>
                </aside>
              </div>
            </nav>
            <main className='listing'>
              <AllUserTable userType="businessaccounts" />
            </main>
          </div>
          <Footer/>
        </div>
      </div>
    </section>
  </>
)} />  */}      
        <Route path="/create-challenge" component={()=>(<><AppTop/><CreateChallenge /><Footer/></>)} />
        {/*<Route path="/edit-challenge" component={()=>(<><AppTop/><EditChallenge /><Footer/></>)} />*/}
         <Route path="/edit-challenge/:id?" component={()=>(<><AppTop/><EditChallenge /><Footer/></>)} />
        {/*<Route path="/admin/creator/" component={()=>(<div><AppTop/><br/><Login/><AdminUserTable userType="creator" /><Footer/></div>)} />
        <Route path="/admin/creatorrates/" component={()=>(<div><AppTop/><br/><Login/><AdminUserTable userType="creatorrates" /><Footer/></div>)} />
        <Route path="/admin/business/" component={()=>(<div><AppTop/><br/><Login/><div class="sidebarLeft">Hello</div><div class="sidebarRight"><AdminUserTable userType="business" /></div><Footer/></div>)} />
        <Route path="/admin"  component={()=>(<div><AppTop/><br/><Login/><AdminUserTable userType="personal" /><Footer/></div>)} />*/}
        <Route path="/payments-old"  component={()=>(<React.Fragment><AppTop/><Payments /><Footer/></React.Fragment>)} />
          <Route path="/stripepayment"  component={()=>(<React.Fragment><AppTop/><Stripepayment /><Footer/></React.Fragment>)} />
             <Route path="/creatorlist"  component={()=>(<React.Fragment><AppTop/><CreatorList /><Footer/></React.Fragment>)} />
                <Route path="/custompagination"  component={()=>(<React.Fragment><AppTop/><Custompagination /><Footer/></React.Fragment>)} />
                  {/*/creators-page*/}
         <Route path="/admin/creators" component={()=>(
          <>
            <AppTop/>
            <section className='wrapper_sc'>
              <div className='wrapper_mn'>
                <div className='container'>
                  <div className='inner'>
                    <nav className='sidebar'>
                      <div className='wrapper'>
                        {/*<aside className="menu_sidebar adminposts">
                          <h2><a href="/admin/accounts/">Accounts </a></h2>
                          <h2><a href="/admin/kpi/">KPI</a></h2>
                          <h2><a href="/admin/apppage-visit/">App Page Visits</a></h2>
                          <h2><a href="/admin/creators">Creators</a></h2>
                          <h2><a href="/admin/entries/">Entries</a></h2>
                        </aside>*/}
                      <AdminMenuleft />
                      </div>
                    </nav>
                    <main className='listing'>
                      <Allcreatorsnew />
                    </main>
                  </div>
                  <Footer/>
                </div>
              </div>
            </section>
          </>
        )} />
         <Route path="/admin/entries/" component={()=>(
          <>
            <AppTop/>
            <section className='wrapper_sc'>
              <div className='wrapper_mn'>
                <div className='container'>
                  <div className='inner'>
                    <nav className='sidebar'>
                      <div className='wrapper'>
                        {/*<aside className="menu_sidebar adminposts">
                          <h2><a href="/admin/accounts/">Accounts </a></h2>
                          <h2><a href="/admin/kpi/">KPI</a></h2>
                          <h2><a href="/admin/apppage-visit/">App Page Visits</a></h2>
                          <h2><a href="/admin/creators">Creators</a></h2>
                          <h2><a href="/admin/entries/">Entries</a></h2>
                        </aside>*/}
                      <AdminMenuleft />
                      </div>
                    </nav>
                    <main className='listing'>
                      <Adminentries />
                    </main>
                  </div>
                  <Footer/>
                </div>
              </div>
            </section>
          </>
        )} />
          <Route path="/admin/accounts/" component={()=>(
          <>
            <AppTop/>
            <section className='wrapper_sc'>
              <div className='wrapper_mn'>
                <div className='container'>
                  <div className='inner'>
                    <nav className='sidebar'>
                      <div className='wrapper'>
                       {/* <aside className="menu_sidebar adminposts">
                          <h2><a href="/admin/accounts/">Accounts </a></h2>
                          <h2><a href="/admin/kpi/">KPI</a></h2>
                          <h2><a href="/admin/apppage-visit/">App Page Visits</a></h2>
                          <h2><a href="/admin/creators">Creators</a></h2>
                          <h2><a href="/admin/entries/">Entries</a></h2>
                        </aside>*/}
                      <AdminMenuleft />
                      </div>
                    </nav>
                    <main className='listing'>
                      <AdminAccounts />
                    </main>
                  </div>
                  <Footer/>
                </div>
              </div>
            </section>
          </>
        )} />
        <Route path="/admin/kpi/" component={()=>(
          <>
            <AppTop/>
            <section className='wrapper_sc'>
              <div className='wrapper_mn'>
                <div className='container'>
                  <div className='inner'>
                    <nav className='sidebar'>
                      <div className='wrapper'>
                       { /*<aside className="menu_sidebar adminposts">
                          <h2><a href="/admin/accounts/">Accounts </a></h2>
                          <h2><a href="/admin/kpi/">KPI</a></h2>
                          <h2><a href="/admin/apppage-visit/">App Page Visits</a></h2>
                          <h2><a href="/admin/creators">Creators</a></h2>
                          <h2><a href="/admin/entries/">Entries</a></h2>
                        </aside>*/}
                      <AdminMenuleft />
                      </div>
                    </nav>
                    <main className='listing'>
                      <Adminkpi />
                    </main>
                  </div>
                  <Footer/>
                </div>
              </div>
            </section>
          </>
        )} />
        <Route path="/admin/kpigraph/" component={()=>(
          <>
            <AppTop/>
            <section className='wrapper_sc'>
              <div className='wrapper_mn'>
                <div className='container'>
                  <div className='inner'>
                    <nav className='sidebar'>
                      <div className='wrapper'>
                        {/*<aside className="menu_sidebar adminposts">
                          <h2><a href="/admin/accounts/">Accounts </a></h2>
                          <h2><a href="/admin/kpi/">KPI</a></h2>
                          <h2><a href="/admin/apppage-visit/">App Page Visits</a></h2>
                          <h2><a href="/admin/creators">Creators</a></h2>
                          <h2><a href="/admin/entries/">Entries</a></h2>
                        </aside>*/}
                      <AdminMenuleft />
                      </div>
                    </nav>
                    <main className='listing'>
                      <AdminkpiGraph />
                    </main>
                  </div>
                  <Footer/>
                </div>
              </div>
            </section>
          </>
        )} />
        <Route path="/admin/apppage-visit/" component={()=>(
          <>
            <AppTop/>
            <section className='wrapper_sc'>
              <div className='wrapper_mn'>
                <div className='container'>
                  <div className='inner'>
                    <nav className='sidebar'>
                      <div className='wrapper'>
                       {/* <aside className="menu_sidebar adminposts">
                          <h2><a href="/admin/accounts/">Accounts </a></h2>
                          <h2><a href="/admin/kpi/">KPI</a></h2>
                          <h2><a href="/admin/apppage-visit/">App Page Visits</a></h2>
                          <h2><a href="/admin/creators">Creators</a></h2>
                          <h2><a href="/admin/entries/">Entries</a></h2>
                        </aside>*/}<AdminMenuleft />
                      </div>
                    </nav>
                    <main className='listing'>
                      <AdminAppPageVisit />
                    </main>
                  </div>
                  <Footer/>
                </div>
              </div>
            </section>
          </>
        )} />
        <Route path="/payments/:id?"  component={()=>(<React.Fragment><AppTop/><Makepayment /><Footer/></React.Fragment>)} />
          {/*  <Route path="/makepayment1/:id?"  component={()=>(<React.Fragment><AppTop/><Makepayment /><Footer/></React.Fragment>)} />*/}
                  

        <Route component={Notfound} />
      </Switch>
  </Router>
);

ReactDOM.render(routing, document.getElementById("root"));


registerServiceWorker();
