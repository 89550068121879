import '../global.js'
import Parse from 'parse';
import React from 'react';

class Postcounts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            postcount:0
        };
        var query = new Parse.Query('Photo');
        query.limit(99999999999999999999999999);
        query.exists("user");
        query.exists("image");//added
        query.exists("thumbnail");//added
        query.doesNotExist("isChallenge");//added

        query.equalTo("user", props.postData);
        var self = this;

        query.find().then(function(result){
            if(result){  //  console.log(result); 
                 self.setState({postcount: result.length});
            }
        })

    }
    render() {
        var postcount=this.state.postcount;
        var result = ( <>{postcount}</> );        
        return result;
    }
}

export default Postcounts;