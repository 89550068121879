import '../global.js'
import React, { Component } from 'react';
import Parse from 'parse';

class AdminAppPageVisit extends React.Component {
    constructor(props) {
      super(props);
      this.state = { };
      let currentUser = Parse.User.current();   
        
        if(!currentUser || (currentUser.get('isAdmin')!==undefined && currentUser.get('isAdmin')!=="1"))
        {
            window.location.href = "/";
        }
    }
    render() {
      return (
        <div className="account_main_top kpi-listing">
          <div className="inner_wrap">
            <div className="table_top_searchbar post_topbar">
              <div className='left-part'>
                <h2>App Page Visits</h2> 
              </div>
            </div>
            <div className='admin_table_wrapper kpi-table appPage-visit'>
              <div className='admin_table_inner'>
                <div className="admin_table">
                  <table>
                    <thead>
                      <tr>
                          <th className='date'>Date</th>
                          <th className='home'>Home</th>                               
                          <th className='explore'>Explore</th>
                          <th className='search'>Search</th>
                          <th className='add'>Add</th>
                          <th className='notification'>Notification</th>
                          <th className='profile'>Profile</th>
                          <th className='settings'>Settings</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className='date'>7/1/2024</td>
                        <td className='home'>274</td>                               
                        <td className='explore'>50%</td>
                        <td className='search'>48</td>
                        <td className='add'>10</td>
                        <td className='notification'>5</td>
                        <td className='profile'>1</td>
                        <td className='settings'>10</td>
                      </tr>
                      <tr>
                        <td className='date'>7/1/2024</td>
                        <td className='home'>274</td>                               
                        <td className='explore'>50%</td>
                        <td className='search'>48</td>
                        <td className='add'>10</td>
                        <td className='notification'>5</td>
                        <td className='profile'>1</td>
                        <td className='settings'>10</td>
                      </tr>
                      <tr>
                        <td className='date'>7/1/2024</td>
                        <td className='home'>274</td>                               
                        <td className='explore'>50%</td>
                        <td className='search'>48</td>
                        <td className='add'>10</td>
                        <td className='notification'>5</td>
                        <td className='profile'>1</td>
                        <td className='settings'>10</td>
                      </tr>
                      <tr>
                        <td className='date'>7/1/2024</td>
                        <td className='home'>274</td>                               
                        <td className='explore'>50%</td>
                        <td className='search'>48</td>
                        <td className='add'>10</td>
                        <td className='notification'>5</td>
                        <td className='profile'>1</td>
                        <td className='settings'>10</td>
                      </tr>
                      <tr>
                        <td className='date'>7/1/2024</td>
                        <td className='home'>274</td>                               
                        <td className='explore'>50%</td>
                        <td className='search'>48</td>
                        <td className='add'>10</td>
                        <td className='notification'>5</td>
                        <td className='profile'>1</td>
                        <td className='settings'>10</td>
                      </tr>
                      <tr>
                        <td className='date'>7/1/2024</td>
                        <td className='home'>274</td>                               
                        <td className='explore'>50%</td>
                        <td className='search'>48</td>
                        <td className='add'>10</td>
                        <td className='notification'>5</td>
                        <td className='profile'>1</td>
                        <td className='settings'>10</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
}
export default AdminAppPageVisit;