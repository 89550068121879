import '../global.js'
import React, { Component } from 'react';
import Parse from 'parse';
import ReactTable from "react-table-v6";

class Tablenew extends React.Component {
    constructor(props) {
      super(props);
      this.state = { };
      let currentUser = Parse.User.current();   
        
        if(!currentUser || (currentUser.get('isAdmin')!==undefined && currentUser.get('isAdmin')!=="1"))
        {
            window.location.href = "/";
        }
    }
    render() {
      return (
        <div className="account_main_top kpi-listing">
          <div className="inner_wrap">
            <div className="table_top_searchbar post_topbar">
              <div className='left-part'>
                <h2>tablenew</h2> 
              </div>
            </div>
            
            
            <div className='admin_table_wrapper'>
              <div className='admin_table_inner'>
              <div class="admin_table">
                <table class="creatorTable table-responsive">
                  <thead>
                    <tr>
                      <th class="picture">Account</th>
                      <th class="views">Type</th>
                      <th class="engagements">Posts</th>
                      <th class="platforms">Engagements</th>
                      <th class="status">Views</th>
                      <th class="creator">Followers</th>
                      <th class="business">Sign up</th>
                      <th class="postedon">Last Active</th>
                      <th class="job">Social Links</th>
                      <th class="post">Email</th>
                      <th class="post">Account Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="user_image">
                          <a href="javascript:void(0);">
                            <span class="thumb">
                              <img class="userProfileImageCreator" src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/4ff7b2c290ad4bb8ef4dd0ed1b53cee9_image.jpg" />
                            </span>
                            <span class="name">businessaccount</span>
                          </a>
                        </div>
                      </td>
                      <td>
                        <div class="type_select_option">
                          <select class="form-select form-select-width" aria-label="Type">
                            <option class="all">All</option><option class="type-community">Community</option>
                            <option class="type-creator">Creator</option><option class="type-business">Business</option>
                          </select>
                        </div>
                      </td>
                      <td>400</td>
                      <td>
                        55
                      </td>
                      <td class="status">
                        65
                      </td>
                      <td>
                        125
                      </td>
                      <td>
                        7/9/2024
                      </td>
                      <td>
                        7/9/2024
                      </td>
                      <td class="platform social_flex">
                        <div class="adminUserData">
                          <div class="socialLinkWrp">
                            <div class="socialLinkWrp">
                              <div class="sociallinkitem">
                                <a target="_blank" href="">
                                  <img src="/images/social/create-instagram.svg" width="32" height="32" />
                                </a>
                              </div>
                              <div class="sociallinkitem">
                                <a target="_blank" href="">
                                  <img src="/images/social/create-tiktok.svg" width="32" height="32" />
                                </a>
                              </div>
                              <div class="sociallinkitem">
                                <a target="_blank" href="">
                                  <img src="/images/social/create-youtube.svg" width="32" height="32" />
                                </a>
                              </div>
                              <div class="sociallink sociallinkitem">
                                <a target="_blank" href="https://joelskitchen.5edev.com/">
                                  <img src="/images/social/create-website.svg" width="32" height="32" />
                                </a>
                              </div>
                              <div class="sociallinkitem">
                                <a target="_blank" href="">
                                  <img src="/images/social/facebook-app.png" width="32" height="32" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>admintest@gmail.com</td>
                      <td>125</td>
                    </tr>
                    <tr>
                      <td>
                        <div class="user_image">
                          <a href="javascript:void(0);">
                            <span class="thumb">
                              <img class="userProfileImageCreator" src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/4ff7b2c290ad4bb8ef4dd0ed1b53cee9_image.jpg" />
                            </span>
                            <span class="name">businessaccount</span>
                          </a>
                        </div>
                      </td>
                      <td>
                        <div class="type_select_option">
                          <select class="form-select form-select-width" aria-label="Type">
                            <option class="all">All</option><option class="type-community">Community</option>
                            <option class="type-creator">Creator</option><option class="type-business">Business</option>
                          </select>
                        </div>
                      </td>
                      <td>400</td>
                      <td>
                        55
                      </td>
                      <td class="status">
                        65
                      </td>
                      <td>
                        125
                      </td>
                      <td>
                        7/9/2024
                      </td>
                      <td>
                        7/9/2024
                      </td>
                      <td class="platform social_flex">
                        <div class="adminUserData">
                          <div class="socialLinkWrp">
                            <div class="socialLinkWrp">
                              <div class="sociallinkitem">
                                <a target="_blank" href="">
                                  <img src="/images/social/create-instagram.svg" width="32" height="32" />
                                </a>
                              </div>
                              <div class="sociallinkitem">
                                <a target="_blank" href="">
                                  <img src="/images/social/create-tiktok.svg" width="32" height="32" />
                                </a>
                              </div>
                              <div class="sociallinkitem">
                                <a target="_blank" href="">
                                  <img src="/images/social/create-youtube.svg" width="32" height="32" />
                                </a>
                              </div>
                              <div class="sociallink sociallinkitem">
                                <a target="_blank" href="https://joelskitchen.5edev.com/">
                                  <img src="/images/social/create-website.svg" width="32" height="32" />
                                </a>
                              </div>
                              <div class="sociallinkitem">
                                <a target="_blank" href="">
                                  <img src="/images/social/facebook-app.png" width="32" height="32" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>admintest@gmail.com</td>
                      <td>125</td>
                    </tr>
                    <tr>
                      <td>
                        <div class="user_image">
                          <a href="javascript:void(0);">
                            <span class="thumb">
                              <img class="userProfileImageCreator" src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/4ff7b2c290ad4bb8ef4dd0ed1b53cee9_image.jpg" />
                            </span>
                            <span class="name">businessaccount</span>
                          </a>
                        </div>
                      </td>
                      <td>
                        <div class="type_select_option">
                          <select class="form-select form-select-width" aria-label="Type">
                            <option class="all">All</option><option class="type-community">Community</option>
                            <option class="type-creator">Creator</option><option class="type-business">Business</option>
                          </select>
                        </div>
                      </td>
                      <td>400</td>
                      <td>
                        55
                      </td>
                      <td class="status">
                        65
                      </td>
                      <td>
                        125
                      </td>
                      <td>
                        7/9/2024
                      </td>
                      <td>
                        7/9/2024
                      </td>
                      <td class="platform social_flex">
                        <div class="adminUserData">
                          <div class="socialLinkWrp">
                            <div class="socialLinkWrp">
                              <div class="sociallinkitem">
                                <a target="_blank" href="">
                                  <img src="/images/social/create-instagram.svg" width="32" height="32" />
                                </a>
                              </div>
                              <div class="sociallinkitem">
                                <a target="_blank" href="">
                                  <img src="/images/social/create-tiktok.svg" width="32" height="32" />
                                </a>
                              </div>
                              <div class="sociallinkitem">
                                <a target="_blank" href="">
                                  <img src="/images/social/create-youtube.svg" width="32" height="32" />
                                </a>
                              </div>
                              <div class="sociallink sociallinkitem">
                                <a target="_blank" href="https://joelskitchen.5edev.com/">
                                  <img src="/images/social/create-website.svg" width="32" height="32" />
                                </a>
                              </div>
                              <div class="sociallinkitem">
                                <a target="_blank" href="">
                                  <img src="/images/social/facebook-app.png" width="32" height="32" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>admintest@gmail.com</td>
                      <td>125</td>
                    </tr>
                    <tr>
                      <td>
                        <div class="user_image">
                          <a href="javascript:void(0);">
                            <span class="thumb">
                              <img class="userProfileImageCreator" src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/4ff7b2c290ad4bb8ef4dd0ed1b53cee9_image.jpg" />
                            </span>
                            <span class="name">businessaccount</span>
                          </a>
                        </div>
                      </td>
                      <td>
                        <div class="type_select_option">
                          <select class="form-select form-select-width" aria-label="Type">
                            <option class="all">All</option><option class="type-community">Community</option>
                            <option class="type-creator">Creator</option><option class="type-business">Business</option>
                          </select>
                        </div>
                      </td>
                      <td>400</td>
                      <td>
                        55
                      </td>
                      <td class="status">
                        65
                      </td>
                      <td>
                        125
                      </td>
                      <td>
                        7/9/2024
                      </td>
                      <td>
                        7/9/2024
                      </td>
                      <td class="platform social_flex">
                        <div class="adminUserData">
                          <div class="socialLinkWrp">
                            <div class="socialLinkWrp">
                              <div class="sociallinkitem">
                                <a target="_blank" href="">
                                  <img src="/images/social/create-instagram.svg" width="32" height="32" />
                                </a>
                              </div>
                              <div class="sociallinkitem">
                                <a target="_blank" href="">
                                  <img src="/images/social/create-tiktok.svg" width="32" height="32" />
                                </a>
                              </div>
                              <div class="sociallinkitem">
                                <a target="_blank" href="">
                                  <img src="/images/social/create-youtube.svg" width="32" height="32" />
                                </a>
                              </div>
                              <div class="sociallink sociallinkitem">
                                <a target="_blank" href="https://joelskitchen.5edev.com/">
                                  <img src="/images/social/create-website.svg" width="32" height="32" />
                                </a>
                              </div>
                              <div class="sociallinkitem">
                                <a target="_blank" href="">
                                  <img src="/images/social/facebook-app.png" width="32" height="32" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>admintest@gmail.com</td>
                      <td>125</td>
                    </tr>
                    <tr>
                      <td>
                        <div class="user_image">
                          <a href="javascript:void(0);">
                            <span class="thumb">
                              <img class="userProfileImageCreator" src="https://elasticbeanstalk-us-east-1-592910437950.s3.amazonaws.com/4ff7b2c290ad4bb8ef4dd0ed1b53cee9_image.jpg" />
                            </span>
                            <span class="name">businessaccount</span>
                          </a>
                        </div>
                      </td>
                      <td>
                        <div class="type_select_option">
                          <select class="form-select form-select-width" aria-label="Type">
                            <option class="all">All</option><option class="type-community">Community</option>
                            <option class="type-creator">Creator</option><option class="type-business">Business</option>
                          </select>
                        </div>
                      </td>
                      <td>400</td>
                      <td>
                        55
                      </td>
                      <td class="status">
                        65
                      </td>
                      <td>
                        125
                      </td>
                      <td>
                        7/9/2024
                      </td>
                      <td>
                        7/9/2024
                      </td>
                      <td class="platform social_flex">
                        <div class="adminUserData">
                          <div class="socialLinkWrp">
                            <div class="socialLinkWrp">
                              <div class="sociallinkitem">
                                <a target="_blank" href="">
                                  <img src="/images/social/create-instagram.svg" width="32" height="32" />
                                </a>
                              </div>
                              <div class="sociallinkitem">
                                <a target="_blank" href="">
                                  <img src="/images/social/create-tiktok.svg" width="32" height="32" />
                                </a>
                              </div>
                              <div class="sociallinkitem">
                                <a target="_blank" href="">
                                  <img src="/images/social/create-youtube.svg" width="32" height="32" />
                                </a>
                              </div>
                              <div class="sociallink sociallinkitem">
                                <a target="_blank" href="https://joelskitchen.5edev.com/">
                                  <img src="/images/social/create-website.svg" width="32" height="32" />
                                </a>
                              </div>
                              <div class="sociallinkitem">
                                <a target="_blank" href="">
                                  <img src="/images/social/facebook-app.png" width="32" height="32" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>admintest@gmail.com</td>
                      <td>125</td>
                    </tr>
                  </tbody>
                </table>
                <div class="pagination_bottom">
                  <button disabled="">❮</button>
                  <span> Page 1 </span>
                  <button>❯</button>
                </div>
              </div>
              </div>
            </div>
            
          </div>
        </div>
      );
    }
}


export default Tablenew;