import React, { Component } from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm1';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51N1Iw3F2uVaAxW23DxC7agGfTzAW2GmNeWovzSsHYi2laozcLAIdliI06EIKpXPS34FaaOGTyTYqrHqhqrm19MKi001fafzmiU');

export default function App() {
   const options = {
    mode: 'payment',
    amount: 1099,
    currency: 'usd',
    // Fully customizable with appearance API.
    appearance: {},
  };



  return (
    <Elements stripe={stripePromise} options={options}>
    test
      <CheckoutForm />
      test1
    </Elements>
  );
};