import React, { Component } from 'react';
import Parse from 'parse';
import $ from "jquery";
class AdminMenuleft extends Component {
  constructor(props) {
    super(props);
    var url = window.location.href;
    this.state = {
        isadminuser:0,
        menuactive:"",
    };    
    let currentUser = Parse.User.current(); 
    if(currentUser)
    {
      if((currentUser.get('isAdmin')==="1"))
      {
        this.isadminuser = true;
      }
    }
    
    if(url.includes('admin/accounts'))
    {
      this.menuactive = 'admin/accounts';
    }
    if(url.includes('admin/kpi'))
    {
      this.menuactive = 'admin/kpi';
    }
    if(url.includes('admin/apppage-visit'))
    {
      this.menuactive = 'admin/apppage-visit';
    }
    if(url.includes('admin/creators'))
    {
      this.menuactive = 'admin/creators';
    }
    if(url.includes('admin/entries'))
    {
      this.menuactive = 'admin/entries';
    }
    
  }
  handleNewfunc(event) {
        event.preventDefault();
        $('#new_open_popup').trigger('click');
  }
render() {   
 let isAdmin=this.isadminuser;
 let menuactive=this.menuactive;
 
    return (
        <>
         
             <aside className="menu_sidebar adminposts">
                          <h2  className={menuactive === 'admin/accounts' ? 'sbNavActive menu' : 'sbNavInactive menu'}><a href="/admin/accounts/">Accounts</a></h2>
                          <h2  className={menuactive === 'admin/kpi' ? 'sbNavActive menu' : 'sbNavInactive menu'}><a href="/admin/kpi/">KPI</a></h2>
                          <h2  className={menuactive === 'admin/apppage-visit' ? 'sbNavActive menu' : 'sbNavInactive menu'}><a href="/admin/apppage-visit/">App Page Visits</a></h2>
                          <h2 className={menuactive === 'admin/creators' ? 'sbNavActive menu' : 'sbNavInactive menu'}><a href="/admin/creators">Creators</a></h2>
                          <h2 className={menuactive === 'admin/entries' ? 'sbNavActive menu' : 'sbNavInactive menu'}><a href="/admin/entries/">Entries</a></h2>
                        </aside>
            </>
         
       
    );
  }
}

export default AdminMenuleft;
