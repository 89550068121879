import '../global.js'
import Parse from 'parse';
import React from 'react';
import Textarea from 'react-textarea-autosize';

const ingredTypeCount = 3;

class ProductList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            products: {},
            curProduct: null,
            ingredTypes: ["Whole Plants", "Processed", "Additives"],
            ingredFields: ["plantsData", "processedData", "additivesData"],
            ingredEmojis: ["🥦", "🥫", "🔬"],
            ingredImages: ["plants.png", "processed.png", "additives.png", "grains.png", "seeds.png", "spices.png", "products.png", "instructions.png"],
            curProductName: "Default",
            ingredArr: [],
        };

        // Creates all lines:
        for(var i=0; i < ingredTypeCount; i++) {

          // Creates an empty line
          this.state.ingredArr.push([]);
          //this.state.ingredArr[i].push( []);

          // Adds cols to the empty line:
         //   this.state.ingredArr[i].push( new Array());
/*
          for(var j=0; j < cols; j++){
            // Initializes:
            arr[i][j] = defaultValue;
          }*/
        }


        this.handleProductNameChange = this.handleProductNameChange.bind(this);
        this.handleProductStoryChange = this.handleProductStoryChange.bind(this);
        this.handleProductIngredientsChange = this.handleProductIngredientsChange.bind(this);
        this.handleProductNotesChange = this.handleProductNotesChange.bind(this);
        this.handleProductWebsiteChange = this.handleProductWebsiteChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleRememberMeClick = this.handleRememberMeClick.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleAdd = this.handleAdd.bind(this);

        this.handleIngredNameChange = this.handleIngredNameChange.bind(this);
        //this.handleIngredAmountChange = this.handleIngredAmountChange.bind(this);
        //this.handleIngredDescripChange = this.handleIngredDescripChange.bind(this);
        this.handleIngredDelete = this.handleIngredDelete.bind(this);

        this.getProducts = this.getProducts.bind(this);
        this.getCurProduct = this.getCurProduct.bind(this);

        this.getProductsAux();

        global.productList = this;
    }

    getCurProduct() {
        return this.curProduct;
    }
    handleRememberMeClick() {
        this.setState({rememberMe: !this.state.rememberMe});
    }

    handleProductNameChange(event) {
        this.setState({curProductName: event.target.value});
        this.state.curProduct.set("name", event.target.value);
    }

    handleProductStoryChange(event) {
        this.setState({curProductStory: event.target.value});
        this.state.curProduct.set("story", event.target.value);
    }

    
    handleProductIngredientsChange(event) {
        this.setState({ingredients: event.target.value});
        this.state.curProduct.set("ingredients", event.target.value);
    }

    handleProductNotesChange(event) {
        this.setState({notes: event.target.value});
        this.state.curProduct.set("notes", event.target.value);
    }

    handleProductWebsiteChange(event) {
        this.setState({website: event.target.value});
        this.state.curProduct.set("website", event.target.value);
    }

    handlePasswordChange(event) {
        this.setState({password: event.target.value});
    }

    handleSave(event) {
        console.log('Saving Product! to ' + this.state.curProduct.get("name"));

        console.log("Serialized ingredArr[0] " + JSON.stringify(this.state.ingredArr[0]));

        for (var i = 0; i < ingredTypeCount; i++)
        {
            var curIngredType = this.state.ingredTypes[i].toLowerCase();

            var serData =  JSON.stringify(this.state.ingredArr[i]);
            
            var fieldName = this.state.ingredFields[i]; ///curIngredType + "Data";

            this.state.curProduct.set(fieldName, serData);
        }
        this.state.curProduct.save();

        this.setState({curProduct: null});
        global.curProduct = null;
        global.curRecipe = null;
        if (global.recipeList && (global.recipeList != undefined))
            global.recipeList.forceUpdate();
       // global.recipeList.forceUpdate();
       // global.productList.forceUpdate();

        this.setState({curProduct: null});
        this.setState({curProductStory: null});
        this.setState({ingredients: null});
        this.setState({notes: null});
        this.setState({website: null});
    }

    handleDelete(event) {

        if (window.confirm('Are you sure you wish to delete this product?'))
        {

            var curProduct = this.state.curProduct;


            curProduct.destroy().then((myObject) => {
              // The object was deleted from the Parse Cloud.
                this.setState({curProduct: null});;

                this.setState({curProduct: null});
                this.setState({curProductStory: null});
                this.setState({ingredients: null});
                this.setState({notes: null});
                this.setState({website: null});

                this.setState({products: this.state.products});;

                this.forceUpdate();
                if (window)
                    window.location.reload();
                else if (Location)
                    Location.reload();
            }, (error) => {
              // The delete failed.
              // error is a Parse.Error with an error code and message.
            });
        }
    }

    handleCancel(event) {
        console.log('Canceling Product ' + this.state.curProduct.get("name"));
        this.setState({curProduct: null});
        global.curProduct = null;
        global.curRecipe = null;
        if (global.recipeList && (global.recipeList != undefined))
            global.recipeList.forceUpdate();


        this.setState({curProduct: null});
        this.setState({curProductStory: null});
        this.setState({ingredients: null});
        this.setState({notes: null});
        this.setState({website: null});
    }

    handleIngredNameChange(i, j, event) {

        event.preventDefault();
        j -= 1;
        j = event.target.getAttribute("data-k");

        console.log("i " + i + " j " + j + " object " + this.state.ingredArr[i][j]);
        if (this.state.ingredArr[i][j])
             this.state.ingredArr[i][j].name = event.target.value;
        this.forceUpdate();

    }

    handleIngredAmountChange(i, j, event) {

        event.preventDefault();
        j -= 1;
        j = event.target.getAttribute("data-k");
        
        console.log("i " + i + " j " + j + " object " + JSON.stringify(this.state.ingredArr[i][j]));
        if (this.state.ingredArr[i][j])
             this.state.ingredArr[i][j].amount = event.target.value;
        this.forceUpdate();

        
        //debug print ingredArr
        var line = "";
         for (var tempI = 0; tempI < 3; tempI++)
         {
            line += "IngredArr [" + tempI + "] :  ";
            for (var tempJ = 0; tempJ < this.state.ingredArr[tempI].length; tempJ++)
            {
                line += "'" + JSON.stringify(this.state.ingredArr[tempI][tempJ]) + "', ";
            }
            line += "\r\n";
         }
         console.log(line);
         this.forceUpdate();

         console.log("EVENT TARGET " + event.target.getAttribute("data-k"));
    }
    
    handleIngredDescripChange(i, j, event) {


        event.preventDefault();
        j -= 1;
        j = event.target.getAttribute("data-k");
        
        console.log("i " + i + " j " + j + " object " + JSON.stringify(this.state.ingredArr[i][j]));
        if (this.state.ingredArr[i][j])
             this.state.ingredArr[i][j].description = event.target.value;
        this.forceUpdate();
        
    }

    handleIngredDelete(i, j, event) {

        event.preventDefault();
        j -= 1;
        j = event.target.getAttribute("data-k");
        
        console.log("DELETE INGRED i " + i + " j " + j + " object " + JSON.stringify(this.state.ingredArr[i][j]));

        console.log("i " + i + " j " + j + " object " + this.state.ingredArr[i][j]);
        if (this.state.ingredArr[i][j])
        {
             console.log("POP");

             if (this.state.ingredArr[i].length == 1)
                this.state.ingredArr[i] = [];
            else
                console.log("Removed: " + this.state.ingredArr[i].splice(j, 1));
        }

        var line = "";
         for (var tempI = 0; tempI < 3; tempI++)
         {
            line += "IngredArr [" + tempI + "] :  ";
            for (var tempJ = 0; tempJ < this.state.ingredArr[tempI].length; tempJ++)
            {
                line += "'" + JSON.stringify(this.state.ingredArr[tempI][tempJ]) + "', ";
            }
            line += "\r\n";
         }
         console.log(line);

        this.forceUpdate();
    }

    handleNew(event) {
        event.preventDefault();
        console.log('Creating ? new Product.');

        var Product = new Parse.Object('ProductV2');                                
        Product.set('name', 'Untitled Product');
        Product.set('user', Parse.User.current());

        var self = this;
        return Product.save().then(function() {
          console.log('Created new Product.');
          self.getProductsAux();
          self.selectProduct(Product);
          self.forceUpdate();
        }
        , function(error) {
          console.log('Creating Product object failed. Error: ' + error);
        });


    }
 

    getProductNameIfNotUntitled()
    {
        if (!this.state.curProduct)
            return null;
        var val = this.state.curProduct.get("name");
        console.log("getProductNameIfNotUntitled '" + val + "'");
        if (val && (val != undefined) && (val.indexOf("Untitled") >= 0))
            return null;
        //if (val != "Untitled Recipe")
         //   return val;
        //else
            return val;
    }

    getProductsAux() {
        console.log('Getting Products for current user');


        var isAdmin = false;
        if (Parse.User.current())
            isAdmin = Parse.User.current().get("isAdmin");

        var query = new Parse.Query('ProductV2');
       
       //TEMP -- see all Products
        if (!isAdmin)
            query.equalTo("user", Parse.User.current());

        //query.ascending("name");
        query.limit(500);

        query.include("story");
        query.include("user.displayName");
        query.include("instructions");
        query.include("notes");
        query.include("website");
        query.include("name");
        query.descending("createdAt");

        var self = this;

        query.find().then(function(result){
            if(result){
                console.log(result);
                self.setState({products: result});
                console.log(self.state.products);
                // If result was defined, the object with this objectID was found
                //promise.resolve(result);
            } else {
                console.log("No Products found");
               // promise.resolve(null);
            }
        }, function(error){
                console.error("Error searching for Products  Error: " + error);
                //promise.error(error);
        });

    }

    selectProduct(product) {
      //this.setState({curProduct:product});
        if (product)
          console.log('Select product ' + product.get("name"));

         if (this.state.curProduct != product) 
        {
            this.setState({curProduct: product});
            global.curProduct = product;
            if (global.recipeList && (global.recipeList != undefined))
                global.recipeList.forceUpdate();

            this.setState({curProductName: product.get("name")});
            
            console.log("CurProduct " + JSON.stringify(product));

            //load the itemArr
            var newIngredArr = [];

            for (var i = 0; i < ingredTypeCount; i++)
            {
                var curIngredType = this.state.ingredTypes[i].toLowerCase();

                var fieldName = this.state.ingredFields[i]; ///curIngredType + "Data";
                var serData = product.get(fieldName);
                if (serData)
                {
                    console.log("Parsing serial data " + fieldName + " " + serData);
                    var serObj = JSON.parse(serData);
                    newIngredArr.push(serObj);
                }
                else
                    newIngredArr.push([]);
            }
            this.setState({ingredArr: newIngredArr});

            this.forceUpdate();

            global.curProduct = product;
            global.recipeList.forceUpdate();
        }
        //this.state.curProduct = product;
    }

    handleAdd(ingredIndex) {
      //this.setState({curProduct:product});
        //if (ingredIndex)
          console.log('Add ingredient to section ' + ingredIndex);

        var newIngred = {};
        newIngred.name = "";
        newIngred.amount = "";
        newIngred.description = "";

        this.state.ingredArr[ingredIndex].push(newIngred);

        //debug print ingredArr
        var line = "";
         for (var tempI = 0; tempI < 3; tempI++)
         {
            line += "IngredArr [" + tempI + "] :  ";
            for (var tempJ = 0; tempJ < this.state.ingredArr[tempI].length; tempJ++)
            {
                line += "'" + JSON.stringify(this.state.ingredArr[tempI][tempJ]) + "', ";
            }
            line += "\r\n";
         }
         console.log(line);

          this.forceUpdate();
        //this.state.curProduct = product;
    }

    getProducts(event) {
        event.preventDefault();
        this.getProductsAux();
    }

    renderIngredientType(ingredType, i) {
        let buffer = [];

        //console.log("renderIngredientType " + i + " ingredArr len " + this.state.ingredArr[i].length)
        for (var k = 0; k < this.state.ingredArr[i].length; k++)
        {
            var ingred = this.state.ingredArr[i][k];

           // console.log("ingredArr " + i + " " + ingred.name);

            /* ======================== INGREDIENT COMPONENTS =========================== */

            if (0) //i != 3) 
            {
            buffer.push( <table><tbody>
                    <tr class = "ingredRow" key={500+((i+1)*(k+1))}>
                           <td class = "ingredAmount" key={1000+((i+1)*(k+1))}>
                                <input  type="text" data-k={k} className="form-control" placeholder="Amount" 
                                onChange={(e) =>  this.handleIngredAmountChange(i, k, e)} value={this.state.ingredArr[i][k].amount}/>

                           </td>
                           <td class = "ingredName" key={2000+((i+1)*(k+1))} >

                                <input type="text" data-k={k} className="form-control" placeholder="Name" 
                                onChange={(e) => this.handleIngredNameChange(i, k, e)} value={this.state.ingredArr[i][k].name}/>

                           </td>
                           <td class = "ingredDescrip" key={3000+((i+1)*(k+1))} >

                                <input type="text"  data-k={k} className="form-control" placeholder="Description" 
                                onChange={(e) => this.handleIngredDescripChange(i, k, e)} value={this.state.ingredArr[i][k].description}/>

                           </td>
                           <td class = "ingredDelete"  key={4000+((i+1)*(k+1))} >
                        
                                <a  data-k={k} onClick={(e) => this.handleIngredDelete(i, k, e )} >x</a>

                           </td>
                        </tr>
                    </tbody></table>);
            }
            else
            {
                //instructions
                var stepText = "Ingredient";

                //**/
                buffer.push( <table><tbody>
                <tr class = "ingredRow" key={500+((i+1)*(k+1))}>

                           <td class = "ingredName" key={3000+((i+1)*(k+1))} >

                                <input type="text"  data-k={k} className="form-control" placeholder="Name" 
                                onChange={(e) => this.handleIngredNameChange(i, k, e)} value={this.state.ingredArr[i][k].name}/>

                           </td>
                           <td class = "ingredDescrip" key={3000+((i+1)*(k+1))} >

                                <input type="text"  data-k={k} className="form-control" placeholder="Comment"
                                onChange={(e) => this.handleIngredDescripChange(i, k, e)} value={this.state.ingredArr[i][k].description} />

                           </td>
                           <td class = "ingredDelete"  key={4000+((i+1)*(k+1))} >
                        
                                <a  data-k={k} onClick={(e) => this.handleIngredDelete(i, k, e )} >&nbsp;&nbsp;x&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>

                           </td>
                        </tr>
                    </tbody></table>);
            }
        }
        //console.log("ingredArr length " + this.state.ingredArr[i].length);
        if ((this.state.ingredArr[i].length == 0) && (i != 3))
        {
            if (i == 0)
            {
                buffer.push( 
                                <span class="nonethismeal">No whole plants were added to this product.</span>
                            );
            }
            else if (i == 1) {
                buffer.push( 
                                <span class="nonethismeal">No processed foods were added to this product.</span>
                            );
            }
            else if (i == 2) {
                buffer.push( 
                                <span class="nonethismeal">No additives were added to this product.</span>
                            );
            }
        }
        else if (i != 3)
        {/* *
            buffer.unshift( <table><tbody>
                            <tr class = "ingredRow" key={499+(i+1)}>
                           <td class = "ingredAmountHeader">

                                Amount

                           </td>
                           <td class = "ingredNameHeader">

                               Name

                           </td>
                           <td class = "ingredDescripHeader">

                               Description

                           </td>
                           <td class = "ingredDeleteHeader">
                            ..

                           </td>
                        </tr>
                    </tbody></table>
                        );*/
        }

        var ingredImage = "images/" + this.state.ingredImages[i];
        var ingredEmoji = this.state.ingredEmojis[i];

        return (
            <div>
            <div class="joelSectionHeader" width="100%">
            
            <img class="ingredTypeImage" src={ingredImage} /><br/>
            <h3>{ingredType}<br/></h3><h4><a class="addIngred" onClick={() => this.handleAdd(i)} >+</a>
            </h4>
            </div>
            <div>
                    {buffer}
            </div>
            </div>
            );
    }

    renderIngredients()
    {
        //console.log("renderIngredients");
        //return      <span key="66"><h4> ingredType  <a onClick={() => this.handleAdd(0)} >Add</a></h4></span>;

        let buffer = [];

        for (var i = 0; i < this.state.ingredTypes.length; i++)
        {
            var ingredType = this.state.ingredTypes[i];
            if (ingredType != undefined)
            buffer.push(<div key={69*i}>
                            {this.renderIngredientType(ingredType, i)}
                        </div>);
        }
        

        return ( <div className="container flex center" key="11">
                {buffer}
                </div>
        );
        /*
        console.log("map" + ingredType + i);
            return (
               <div>
               <span key={i+100}><h4> {ingredType}  <a onClick={() => this.handleAdd(i)} >Add</a></h4></span>
               </div>
            );

            
        });*/
    }

    render() {
        //console.log("Rendering product list");
        var result = (
            <div className="container">
            </div>

        );
        var user = Parse.User.current();
        if (user)
            user.fetch();

        console.log(JSON.stringify(user));

        //if ((user == null) || (user.get("isAdmin" == true)))
        if ((!user) || (user.get("isAdmin") != true))
            return result;

        if (user != null)
        {
            let products = (this.state.products.length !== undefined) ? this.state.products : [];
            //console.log("Products length " + products.length);

            var self = this;

           // if (this.state.curProduct != null)
            if (global.curProduct != null) 
            {
                result =  (
                 <div key="-1">
                    <form className="form-recipe" >
                         <div class="joelSectionHeader">
                        <h3>Name</h3>
                        </div>
                        <input type="text" className="recipeNameInput" placeholder="Name of Product" required
                               autoFocus onChange={this.handleProductNameChange} value={this.getProductNameIfNotUntitled()}/>

                        <div class="joelSectionHeader">
                        <h3>Story</h3>
                        </div>
                        <Textarea name = "story" rows="6" className="form-control" placeholder="Share your personal story about this product." 
                                onChange={this.handleProductStoryChange} value={this.state.curProduct ? this.state.curProduct.get("story") : null}/>

                        <div class="joelSectionHeader">
                        <h3>Ingredients</h3>
                        </div>
                        <Textarea name = "ingredients" rows="6" className="form-control" placeholder="List of ingredients"
                                onChange={this.handleProductIngredientsChange} value={this.state.curProduct ? this.state.curProduct.get("ingredients") : null}/>
                        <div class="joelSectionHeader">
                        <h3>Notes</h3>
                        </div>
                        <Textarea name = "notes" rows="6" className="form-control" placeholder="Add your notes about this product and ingredients."
                                onChange={this.handleProductNotesChange} value={this.state.curProduct ? this.state.curProduct.get("notes"): null}/>
                        <div class="joelSectionHeader">       
                        <h3>Website</h3>
                        </div>
                        <Textarea name = "notes" rows="6" className="form-control" placeholder="Add a link to this product."
                                onChange={this.handleProductWebsiteChange} value={this.state.curProduct ? this.state.curProduct.get("website") : null}/>
                        <br/>

                        <button className="btn btn-lg btn-primary btn-block" onClick={(e) => self.handleSave(e)}>Save Product</button>
                        <button className="btn btn-lg btn-danger btn-block" onClick={(e) => self.handleDelete(e)}>Delete Product</button>
                   
                        <button className="btn btn-lg btn-primary btn-block" onClick={(e) => self.handleCancel(e)}>Cancel</button>
                    </form>
                </div>
              );

            }
            else if (global.curRecipe == null)
             result =  (
                 <div className="container" key="-1">
                 <div class="joelSectionHeader" width="100%">
                 <h3>My Products</h3>
                 </div>
                 { products.map(function (product, i) {

                        var itemUser = product.get("user");
                        //itemUser.fetch();
                        var itemUsername = itemUser.get("displayName");
                        var myUsername = Parse.User.current().get("displayName");
/*
                        if (itemUsername == myUsername)
                        {

                            return (
                               <div class="productListEntry" key={i}> <a class="itemLink" onClick={() => self.selectProduct(product)} >{product.get("name")}</a> by me<br/></div>
                            );
                        }
                        else*/
                        {

                            return (
                               <div class="productListEntry" key={i}  style={{"display": "none"}}> <a class="itemLink" onClick={() => self.selectProduct(product)} >{product.get("name")}</a> by {itemUsername}</div>
                            );
                        }
                    })}
                 <br/>
                <form className="form-signup"  style={{"display": "none"}}>
                    <button className="btn btn-lg btn-primary btn-block" onClick={this.handleNew}>New Product</button>
                </form>
             </div>
              );

         /*

                  { self.state.ingredTypes.map(function (ingredType, i) {
                        return (
                           <span key={i+100}><h4> {ingredType}  <a onClick={() => self.handleAdd(i)} >Add</a></h4></span>

                           { self.state.ingredArr[i].map(function (ingred, j) {
                                return (
                                   <span key={(i*j)+100}><h4> {ingred}  <a onClick={() => self.handleDelete(i,j))} >Delete</a></h4></span>
                                );
                            })}
                        );
                    })}
                    */
/*
            result =  (
                 <div className="container" key="-1">
                 <h3>My Products</h3>
                 {buffer}
                 <span >Test<a onClick={this.selectProduct}>(selectProduct?)</a></span>
                 <br/>
                <form className="form-signup" onSubmit={this.handleNew}>
                    <button className="btn btn-lg btn-primary btn-block" type="submit">New Product</button>
                </form>
                <span><a onClick={this.selectProduct}>
                       TEST
                    </a></span>
             </div>
              );
              */

/*
  <li>
                  <div className='view'>
                    <label>{product.title}</label>
                    <button className="destroy" onClick={this.deleteTodo.bind(null, todo)} />
                  </div>
                </li>

            result = (
                 <div className="container">
                 <h3>My Products</h3>
                 <br/>
                <form className="form-signup" onSubmit={this.handleNew}>
                    <br/><br/>
                    <button className="btn btn-lg btn-primary btn-block" type="submit">New Product</button>
                </form>
            </div>
            );


/*
            return (
               <span key={i+100}><h4> {ingredType}  <a onClick={() => self.handleAdd(i)} >Add</a></h4></span>

               { self.state.ingredArr[i].map(function (ingred, j) {
                    return (
                       <span key={(i*j)+100}><h4> {ingred}  <a onClick={() => self.handleDelete(i,j))} >Delete</a></h4></span>
                    );
                })}
            );*/


        }
        return result;
        /* 
                    <label className="checkbox">
                        <input type="checkbox" value="remember-me" onChange={this.handleRememberMeClick}/>
                        &nbsp; Remember me
                    </label>
                    */

    }


}

export default ProductList;