import '../global.js'
import React, { Component } from 'react';
import Parse from 'parse';
import $ from "jquery";
import EntriesLikeCount from "./entriesLikeCount.jsx";
import EntriesCommentCount from "./entriesCommentCount.jsx";
import SaveNotification from "../saveNotification.jsx";
 function time_ago(time) {

    switch (typeof time) {
      case 'number':
        break;
      case 'string':
        time = +new Date(time);
        break;
      case 'object':
        if (time.constructor === Date) time = time.getTime();
        break;
      default:
        time = +new Date();
    }
    var time_formats = [
      [60, 's', 1], // 60
      [120, '1m', '1 minute from now'], // 60*2
      [3600, 'm', 60], // 60*60, 60
      [7200, '1h', '1 hour from now'], // 60*60*2
      [86400, 'h', 3600], // 60*60*24, 60*60
      [172800, '1d', 'Tomorrow'], // 60*60*24*2
      [604800, 'd', 86400], // 60*60*24*7, 60*60*24
      [1209600, '1w', 'Next week'], // 60*60*24*7*4*2
      [2419200, 'w', 604800], // 60*60*24*7*4, 60*60*24*7
      [4838400, '1m', 'Next month'], // 60*60*24*7*4*2
      [29030400, 'm', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
      [58060800, '1y', 'Next year'], // 60*60*24*7*4*12*2
      [2903040000, 'y', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
      [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
      [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  
    ];
    var seconds = (+new Date() - time) / 1000,
      token = '',
      list_choice = 1;

    if (seconds == 0) {
      return ''
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      token = '';
      list_choice = 2;
    }
    var i = 0,
      format;
    while (format = time_formats[i++])
      if (seconds < format[0]) {
        if (typeof format[2] == 'string')
          return format[list_choice];
        else
          return Math.floor(seconds / format[2]) + '' + format[1] + '' + token;
      }
    return time;
  }
const options = [ 
    { value: 'hideEntry', label: 'Hidden' }, 
    { value: 'isChallengeWinner', label: 'Winner' }, 
  ];


class Entries extends React.Component {
    constructor(props) {
    super(props);
    this.state = {
      posts: [],
      loading: true,
      page: 1,
      pageSize: 100,
      total: 0,      
      filterTitle: '',
      pagestatus: 1,
      totalpostCount:0,
      entry_update_msg:null,
      selectedValue: {},    
      totaluserCount:0,
      sortEntryField: 'createdAt',
      sortEntryOrder: 'desc',
      isNotificationOpen:false,
      post_Notification_Id:'',
      post_Notification_Msg:'',
      post_Notification_Comment:''
    };
    let currentUser = Parse.User.current();   
        
    if(!currentUser)
    {
        window.location.href = "/";
    }
    
  
  }

radioHandler = (pagestatus) => {
    this.setState({ pagestatus });
  };
  

  componentDidMount() {
    this.fetchData();
  }
 
 toggleNotification = (postid,msg,comment) => { 
      var self = this;
        self.setState(prevState => ({
          isNotificationOpen: !prevState.isNotificationOpen,
          post_Notification_Id: postid,
          post_Notification_Msg: msg,
          post_Notification_Comment:comment 
        }));
        //self.setState({ post_Notification_Id: postid });        
      };
  fetchData = () => {
    const { page, pageSize,filterTitle,sortEntryField, sortEntryOrder  } = this.state;
    const skip = (page - 1) * pageSize;
    let total_mainquery;
    let currentUser = Parse.User.current(); 

    let filterTitleMain=filterTitle.trim();
    if((currentUser.get('isAdmin') && currentUser.get('isAdmin')==="1"))
    {

     var query  = new Parse.Query('Photo');
    query.equalTo("isChallengeEntry", "1");

      
    if (filterTitleMain) {
       // query.matchesQuery('user', new Parse.Query('_User').contains('displayUsername',filterTitleMain));
        query.contains('displayUsername', filterTitleMain);
    }
    query.include('challenge');
    query.include('user');
    //query.select("homeCount");
    //query.include('user.displayUsername');
    if(sortEntryOrder==='desc')
    {
         query.descending(sortEntryField);
    }else{
         query.ascending(sortEntryField);
    }  
    query.limit(pageSize);
    query.skip(skip);
    

    /*new code*/
    if(filterTitleMain!=='')
    {
      var otherquery  = new Parse.Query('Photo');
      otherquery.equalTo("isChallengeEntry", "1");

    
    if(!currentUser.get('isAdmin') && currentUser.get('isAdmin')!=="1")
    {
      otherquery.equalTo("user", currentUser); 
    }
     
    otherquery.include('challenge');
    otherquery.include('user');
    otherquery.matchesQuery('user', new Parse.Query('_User').contains('username',filterTitleMain));
    otherquery.select("homeCount");
    otherquery.include('user.displayUsername');
    if(sortEntryOrder==='desc')
    {
         otherquery.descending(sortEntryField);
    }else{
         otherquery.ascending(sortEntryField);
    }  

    otherquery.limit(pageSize);
    otherquery.skip(skip);
   // 
      
    //}

    /*new code*/

    total_mainquery = Parse.Query.or(query, otherquery);
  
  }else{
      total_mainquery =query;
  }





    }else{

       var query  = new Parse.Query('Photo');
    query.equalTo("isChallengeEntry", "1");
  
    query.equalTo("user", currentUser); 
    if (filterTitleMain) {
        query.matchesQuery('user', new Parse.Query('_User').contains('displayUsername',filterTitleMain));
       // query.contains('displayUsername', filterTitleMain);
    }
    query.include('challenge');
    query.include('user');
    query.select("homeCount");
    query.include('user.displayUsername');
    if(sortEntryOrder==='desc')
    {
         query.descending(sortEntryField);
    }else{
         query.ascending(sortEntryField);
    }  
    query.limit(pageSize);
    query.skip(skip);

    /*if(filterTitleMain!=='')
    {
      query.matchesQuery('user', new Parse.Query('_User').contains('username',filterTitleMain));
     
    }*/
    

    /*new code*/
  /*  if(filterTitleMain!=='')
    {
      var otherquery  = new Parse.Query('Photo');
      otherquery.equalTo("isChallengeEntry", "1");

    
    if(!currentUser.get('isAdmin') && currentUser.get('isAdmin')!=="1")
    {
      otherquery.equalTo("user", currentUser); 
    }
     
    otherquery.include('challenge');
    otherquery.include('user');
    otherquery.matchesQuery('user', new Parse.Query('_User').contains('username',filterTitleMain));
    otherquery.select("homeCount");
    otherquery.include('user.displayUsername');
    if(sortEntryOrder==='desc')
    {
         otherquery.descending(sortEntryField);
    }else{
         otherquery.ascending(sortEntryField);
    }  

    otherquery.limit(pageSize);
    otherquery.skip(skip);
  

console.log('filterTitleMain business'+filterTitleMain);
    

    total_mainquery = Parse.Query.or(query, otherquery);
    console.log(total_mainquery);
  }else{
      total_mainquery =query;
  }*/
 total_mainquery =query;



    }


   
    


    total_mainquery.find()
      .then(results => {    //console.log(results);   
        this.setState({
          posts: results,
          postsdata: results,
          loading: false
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        this.setState({ loading: false });
      });



    var query1 = new Parse.Query('Photo');
    query1.equalTo("isChallengeEntry", "1");
    if(currentUser.get('isAdmin') && currentUser.get('isAdmin')==="1")
    {

    }else{
        query1.equalTo("user", currentUser);
    }
    query1.include('challenge');
    query1.include('user');

    query1.include('user.displayUsername');  
    if(sortEntryOrder==='desc')
    {
         query1.descending(sortEntryField);
    }else{
         query1.ascending(sortEntryField);
    }
    query1.limit(9999999999);
    query1.find()
      .then(results => {
        this.setState({
          postsdata: results,
           totalpostCount: results.length,
        });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        this.setState({ loading: false });
      });
  };

  handlePageChange = (page) => {
    this.setState({ page, loading: true }, () => {
      this.fetchData();//true
    });
  };
  socialLinkClass(linkVal)
    {
        if (linkVal && (linkVal != undefined) && (linkVal.length > 1))
        {
            return "sociallink sociallinkitem";
        }
        else
            return "displayNone sociallinkitem";
    }
      formatWebsite(linkVal)
    {
        if ((!linkVal) || (linkVal == undefined))
            return "";

        if (linkVal.includes("http://") || linkVal.includes("https://") || linkVal.includes("Https://") || linkVal.includes("Http://"))
            return linkVal;
        else
            return "https://" + linkVal;
    }

    formatFacebook(linkVal)
    {
        if ((!linkVal) || (linkVal == undefined))
            return "";

        linkVal.replace("@", "");

        linkVal.replace("m.facebook.com", "facebook.com");

        if (linkVal.includes("http://") || linkVal.includes("https://") || linkVal.includes("Https://") || linkVal.includes("Http://"))
            return linkVal;
        else if (linkVal.includes("facebook.com"))
            return "https://" + linkVal;
        else
            return "https://facebook.com/" + linkVal;
    }

    formatPinterest(linkVal)
    {
        if ((!linkVal) || (linkVal == undefined))
            return "";

        linkVal.replace("@", "");
        
        if (linkVal.includes("http://") || linkVal.includes("https://") || linkVal.includes("Https://") || linkVal.includes("Http://"))
            return linkVal;
        else if (linkVal.includes("pinterest.com"))
            return "https://" + linkVal;
        else
            return "https://pinterest.com/" + linkVal;
    }

    formatInstagram(linkVal)
    {
        if ((!linkVal) || (linkVal == undefined))
            return "";

        linkVal.replace("@", "");
        
        if (linkVal.includes("http://") || linkVal.includes("https://") || linkVal.includes("Https://") || linkVal.includes("Http://"))
            return linkVal;
        else if (linkVal.includes("instagram.com"))
            return "https://" + linkVal;
        else
            return "https://instagram.com/" + linkVal;
    }
   renderSocial(creator) {
      var result = ( <div> </div>
          );

      if (!creator)
          return result;

      var instagram = creator.get("instagram");
      var facebook = creator.get("facebook");
      var pinterest = creator.get("pinterest");
      var tiktok = creator.get("tiktok");
      var youtube = creator.get("youtube");
      var website = creator.get("website");

      result = (
        <div className='socialLinkWrp'>
          <div class={this.socialLinkClass(instagram)}>
            <a target="_blank" href={this.formatInstagram(instagram)}>
              <img src="/images/social/create-instagram.svg" width="32" height="32" />
            </a>
          </div>
          <div class={this.socialLinkClass(tiktok)}>
            <a target="_blank" href={this.formatWebsite(tiktok)}>
              <img src="/images/social/create-tiktok.svg" width="32" height="32" />
            </a>
          </div>
          <div class={this.socialLinkClass(youtube)}>
            <a target="_blank" href={this.formatWebsite(youtube)}>
              <img src="/images/social/create-youtube.svg" width="32" height="32" />
            </a>
          </div>
          <div class={this.socialLinkClass(website)}>
            <a target="_blank" href={this.formatWebsite(website)}>
              <img src="/images/social/create-website.svg" width="32" height="32" />
            </a>
          </div>
          <div className={this.socialLinkClass(facebook)}>
            <a target="_blank" href={this.formatFacebook(facebook)}>
              <img src="/images/social/facebook-app.png" width="32" height="32"/>
            </a>
          </div>
        </div>
      );
      return result;
    }
     handleFilterChange = (event) => {
    this.setState({ filterTitle: event.target.value }, () => {
      // Reset page to 1 when filter changes  true
      this.setState({ page: 1, loading: true }, () => {
        this.fetchData();
      });
    });
  };


  render() {
const { posts, loading, page, pageSize,filterTitle,pagestatus,totalpostCount,users,totaluserCount,sortEntryOrder,sortEntryField } = this.state;
var isNotificationOpen=this.state.isNotificationOpen; 
var post_Notification_Ids=this.state.post_Notification_Id; 
var post_Notification_Msg=this.state.post_Notification_Msg;
var post_Notification_Comment=this.state.post_Notification_Comment;
var self = this;
 const handleSelectChange = (event, id) => {
    const selectedValue = event.target.value;
    let entry_id= id;  
        const currentUser = Parse.User.current();
        if(currentUser)
        {
            var query = new Parse.Query('Photo');  
            query.equalTo('objectId', entry_id);
            var self = this;
            query.find().then(function(photo){
                    if(photo)
                    {
                        let photo_ob=photo[0];
                        //console.log(selectedValue+'selectedValue');
                        if(selectedValue==='hideEntry')
                        {
                            photo_ob.set('hideEntry', "1");
                            photo_ob.set('isChallengeWinner', false);
                        }
                        if(selectedValue==='isChallengeWinner')
                        {
                            photo_ob.set('isChallengeWinner', true);
                            photo_ob.set('hideEntry', "0");
                           
                            self.toggleNotification(entry_id,'was awarded winner of the challenge','');
                            self.setState({isNotificationOpen:false,post_Notification_Id:'',post_Notification_Msg:'',post_Notification_Comment:''});
                            self.toggleNotification(entry_id,'You was awarded winner of the challenge','');
                            self.setState({isNotificationOpen:false,post_Notification_Id:'',post_Notification_Msg:'',post_Notification_Comment:''});
                        }
                        if(selectedValue==='isChallengeWinnerfalse')
                        {
                            photo_ob.set('isChallengeWinner', false);
                        }
                        if(selectedValue==='hideEntryfalse')
                        {
                            photo_ob.set('hideEntry', "0");
                        }
                        if(selectedValue=='')
                        {
                           
                            photo_ob.set('hideEntry', "0");
                            photo_ob.set('isChallengeWinner', false);
                        }
                        photo_ob.save().then((updatedentry) => {
                             self.setState({entry_update_msg:"Entry has been updated!"});
                        })

                    }
                })
           
            self.setState({selectedValue:selectedValue});
            setTimeout(() => {
                  self.setState({entry_update_msg:null}); 
              }, 4000);
        }
      
       
  };

const handleEntrySort = (field) => {
    const { sortEntryField, sortEntryOrder } = this.state;
    const newSortOrder = sortEntryField === field && sortEntryOrder === 'asc' ? 'desc' : 'asc';
    this.setState({ sortEntryField: field, sortEntryOrder: newSortOrder }, this.fetchData);
  };
    return (
      <div className="account_main_top user_entry">{isNotificationOpen?<SaveNotification   isOpen={isNotificationOpen}  postNotificationid={post_Notification_Ids} postMsg={post_Notification_Msg} postPhotoid={post_Notification_Ids} postActivityComment={post_Notification_Comment} />:""} 
        <div className="inner_wrap">
          <div className="table_top_searchbar post_topbar">
            <div className='left-part'>
              {pagestatus === 1 && <> <div className="menu-search-wrap menu-search-mn d-flex flex-row flex-wrap align-items-md-center">
              <div className="search_wrap">
                <form className="form-mn d-flex flex-row flex-wrap">
                  <div className='search_inpt_inner'>
                    {pagestatus === 1 && 
                      <div>
                        <input className="form-control" placeholder="Search" aria-label="Search" type="text" value={filterTitle} onChange={this.handleFilterChange} />
                      </div>
                    }
                   
                    <button type="submit"><img src="/images/search.svg" alt="Search"/></button>
                    <button type="button" className='close-btn hide' onClick={this.searchSuggestionCloase} ><img src="/images/close_small.svg" alt="Close"/></button>
                  </div>
                </form>
              </div>
            </div> </>}
            </div>
            
          </div>
          {loading ? 
            (
              <div className='admin_table_loader'>Loading...</div>
            ) : (
              <div className='admin_table_wrapper jkcm_entries entries-table'>
                <div className='tab_Wrap'>
                  <div  className={pagestatus === 1?"tab_link topNavActive":"tab_link topNavInactive"} ><label htmlFor='release0'><input type="radio" id="release0" name="release"  checked={pagestatus === 1} onClick={(e) => this.radioHandler(1)} />Total Entries <span className="counter-account">{totalpostCount? totalpostCount.toString().replace(/,/g,"").replace(/\B(?=(\d{3})+(?!\d))/g, ","):''}</span></label></div>
                
                </div>
                {pagestatus === 1 &&
                  <div className='admin_table_inner totalEntry'>
                    <p className='profile_message_alert_red entry_msg'>{this.state.entry_update_msg}</p>
                    <div className="admin_table">
                      <table className="creatorTable table-responsive">
                        <thead>
                          <tr>
                            <th className={sortEntryField === 'thumbnail' && (sortEntryOrder !== '' ? 'picture sort-arrow' : 'picture')?"sort-arrow picture":"picture"}   onClick={(event) => handleEntrySort('thumbnail')} >Pic <span className="updown_arrow">{sortEntryField === 'thumbnail' && (sortEntryOrder === 'asc' ? '↑' : '↓')}</span></th>
                            <th className='creator'   >Creator </th>
                            <th className='likes'   >Likes </th>
                            <th className='likes'   >Comments </th>
                            <th className={sortEntryField === 'impr' && (sortEntryOrder !== '' ? 'views sort-arrow' : 'views')?"sort-arrow views":"views"}    onClick={(event) => handleEntrySort('impr')} >Views <span className="updown_arrow">{sortEntryField === 'impr' && (sortEntryOrder === 'asc' ? '↑' : '↓')}</span></th> 
                            <th className={sortEntryField === 'homeCount' && (sortEntryOrder !== '' ? 'hometaps sort-arrow' : 'hometaps')?"sort-arrow hometaps":"hometaps"}     onClick={(event) => handleEntrySort('homeCount')} >Taps <span className="updown_arrow">{sortEntryField === 'homeCount' && (sortEntryOrder === 'asc' ? '↑' : '↓')}</span></th>
                                                          
                           {/* <th  className={sortEntryField === 'imprcount' && (sortEntryOrder !== '' ? 'engagements sort-arrow' : 'engagements')?"sort-arrow engagements":"engagements"}   onClick={(event) => handleEntrySort('imprcount')} >Engagements <span className="updown_arrow">{sortEntryField === 'imprcount' && (sortEntryOrder === 'asc' ? '↑' : '↓')}</span></th>*/}
                            <th className='job'>Title</th>
                             <th  className={sortEntryField === 'createdAt' && (sortEntryOrder !== '' ? 'postedon sort-arrow' : 'postedon')?"sort-arrow postedon":"postedon"} 
                              onClick={(event) => handleEntrySort('createdAt')} >Posted <span className="updown_arrow">{sortEntryField === 'createdAt' && (sortEntryOrder === 'asc' ? '↑' : '↓')}</span></th>
                               <th className='status'  >Manage</th>
                            <th className='statuss' >Status</th>
                           
                            
                           
                            
                            <th  className={sortEntryField === '_id' && (sortEntryOrder !== '' ? 'post sort-arrow' : 'post')?"sort-arrow post":"post"} 
                           onClick={(event) => handleEntrySort('_id')} >Entry # <span className="updown_arrow">{sortEntryField === '_id' && (sortEntryOrder === 'asc' ? '↑' : '↓')}</span></th>
                            <th className='business'>Business</th>
                          </tr>
                        </thead>
                      <tbody>
                          { posts.map(function (post, i) {
                            let hideEntry=post.get('hideEntry')?post.get('hideEntry'):'';
                            let isChallengeWinner=post.get('isChallengeWinner')?post.get('isChallengeWinner'):'';
                            let ischallege=post.get('isChallenge')==1?'challege':'';
                            let ischallegeStatus=post.get('isChallenge')==1?'challege':'';
                            let different_class='';
                            if(hideEntry==="1")
                            {
                              different_class='hiddenclass';
                            }
                            if(isChallengeWinner===true)
                            {
                              different_class='winnerclass';
                            }
                          
                            if(ischallege) {
                              // let selectedOption=post.get('challengeStatus');
                            } {
                              return (
                                      <tr  key={post.id} className={different_class}>
                                      <td><div class="user_image"><a  href={"https://www.joelskitchen.com/post/"+post.id}  target="_blank"><span className='thumb'><img class="userProfileImageCreator" src={post != undefined ?
                      (post.get("thumbnail") != undefined ? post.get("thumbnail").url() : "/images/genericuser.png" ) : ""}/></span></a></div></td>
                         <td> {post.get('user')?post.get('user').get('displayUsername')?<a  target="_blank"  href={post.get('user').get("displayUsername")?"https://www.joelskitchen.com/user/"+post.get('user').get("displayUsername"):''}>{post.get('user').get('displayUsername')}</a>:'':''}</td>
                         <td><EntriesLikeCount postData={post.id} /></td>
                         <td><EntriesCommentCount postData={post.id} /></td>
                         <td>{post.get("impr")?post.get("impr"):'0'}</td>

                      <td> {post.get("homeCount")?post.get("homeCount"):'0'} </td>
                          <td> {post.get('challenge')?post.get('challenge').get('challengeTitle')?<a target="_blank" href={post.get('challenge').get('challengeTitle')?"https://joelskitchen.com/challenge/"+post.get('challenge').id:''} className="post_atag">{post.get('challenge').get('challengeTitle')}</a>  :'':''}</td>
                        <td className='postedon'>
                                              {post.get('createdAt') != undefined ?((post.get('createdAt').getMonth()+1) + "/" + (post.get('createdAt').getDate()) + "/" + post.get('createdAt').getFullYear().toString().substr(-2)):''}</td>
                      
                     {/* <td>{post.get("imprcount")?post.get("imprcount"):'0'}</td>*/}
                                                   <td className='status'>
            { hideEntry && hideEntry==1 && (<select  style={{ width: "135px" }} 
              value=""
              onChange={(event) => handleSelectChange(event, post.id)}
            > <option value=""></option>
                  <option value="hideEntryfalse"  selected="selected" >Unhide Entry</option>
                <option value="isChallengeWinner"  >Award Winner</option>
            </select>)}
              { isChallengeWinner && isChallengeWinner==true && (<div><select  style={{ width: "135px" }} 
                            value="" 
                            onChange={(event) => handleSelectChange(event, post.id)}
                          > <option value=""></option>
                              <option value="hideEntry" >Hide Entry</option>
                              <option value="isChallengeWinnerfalse"  selected="selected">
De-award Winner</option>
                          </select></div>)}
                { (isChallengeWinner==false) && (hideEntry==0) && (<div><select  style={{ width: "135px" }} 
              value=""
              onChange={(event) => handleSelectChange(event, post.id)}
            > <option value=""></option>
                <option value="hideEntry" >Hide Entry</option>
                <option value="isChallengeWinner" >Award Winner</option>
            </select></div>)}

                                            </td>
                      <td>{post.get('challenge')?post.get('challenge').get('challengeStatus')?post.get('challenge').get('challengeStatus'):'':''}</td>
                  
               
                 
               

                    <td>{post.id?post.id:''}</td>
                    <td>{post.get('challenge')?post.get('challenge').get('businessAccount')?<a  target="_blank"  href={post.get('challenge').get('businessAccount')?"https://www.joelskitchen.com/user/"+post.get('challenge').get('businessAccount'):''}>{post.get('challenge').get('businessAccount')}</a>:'':''}</td>
                    </tr>
                                  );
  
                              }

                          })}

            </tbody>
                      </table>
                      <div className="pagination_bottom">
                        <button
                          onClick={() => this.handlePageChange(page - 1)}
                          disabled={page === 1}
                        >
                          &#10094;
                        </button>
                        <span> Page {page} </span>
                        <button
                          onClick={() => this.handlePageChange(page + 1)}
                          disabled={posts.length < pageSize}
                        >
                          &#10095;
                        </button>
                      </div>
                    </div>
                  </div>
                }
              
              </div>
            )
          }
        </div>
      </div>
    );
  }
}
export default Entries;